import { createContext, useContext, useEffect, useMemo } from 'react';
import { Control, FormState, useForm, UseFormRegister } from 'react-hook-form';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';

import { MessageType, showMessage, useIdFromParams } from 'helpers';
import { fetchPromoCode, postPromoCode, putPromoCode } from 'services/promoCodes';

import { PROMO_CODE_VALIDATION_SCHEMA } from './const';
import { PromoCodeForm } from '../types';
import { promoCodeToPromoCodeForm } from './transformation';
import { defaultPromoCode } from '../consts';

const PromoCodePageContext = createContext<{
  formState: FormState<PromoCodeForm>;
  isCreate: boolean;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  register: UseFormRegister<PromoCodeForm>;
  control: Control<PromoCodeForm, any>;
}>({} as any);

// This will be used in case we state shared inside the module
export const PromoCodePageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id: promoCodeId, isCreate } = useIdFromParams();

  const { data: promoCode } = useQuery(['promoCodes', promoCodeId], () => fetchPromoCode(promoCodeId), {
    enabled: !isCreate,
  });
  const { mutateAsync: updatePromoCode } = useMutation(putPromoCode);
  const { mutateAsync: createPromoCode } = useMutation(postPromoCode);

  const { handleSubmit, register, formState, reset, control } = useForm<PromoCodeForm>({
    defaultValues: defaultPromoCode,
    resolver: yupResolver(PROMO_CODE_VALIDATION_SCHEMA),
  });

  const promoCodeForm = useMemo(() => {
    if (promoCode) {
      return promoCodeToPromoCodeForm(promoCode);
    }
    return defaultPromoCode;
  }, [promoCode]);

  useEffect(() => {
    reset(promoCodeForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoCodeForm]);

  const onSubmit = handleSubmit(async (values: PromoCodeForm) => {
    if (isCreate) {
      try {
        await createPromoCode(values);
        showMessage('Promo code successfully created.', MessageType.Success);
        queryClient.invalidateQueries('promoCodes');
        navigate('/promo-codes');
      } catch {
        showMessage('Theres been an error!', MessageType.Error);
      }
      return;
    }

    try {
      await updatePromoCode(values);
      showMessage('Promo code successfully updated.', MessageType.Success);
      queryClient.invalidateQueries('promoCodes');
      navigate('/promo-codes');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const providerValue = useMemo(
    () => ({ formState, isCreate, onSubmit, register, control }),
    [formState, isCreate, onSubmit, register, control],
  );

  return <PromoCodePageContext.Provider value={providerValue}>{children}</PromoCodePageContext.Provider>;
};

export const usePromoCodePage = () => {
  return useContext(PromoCodePageContext);
};

interface Props {
  children?: React.ReactNode;
}
