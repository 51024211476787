import { Setting } from 'services/settings';
import { PageType } from 'helpers';
import { initialImage } from 'services/images/consts';

import { DriverForm, DriversPageAdvancedSearchForm } from './types';

export const getDefaultDriverForm = (setting?: Setting): DriverForm => {
  return {
    id: PageType.Create,
    address1: '',
    address2: '',
    approveDenyNote: '',
    city: '',
    dateOfBirth: null,
    description: '',
    driverGroupIds: [],
    driversLicenseExpirationDate: null,
    driversLicenseFile: initialImage,
    driversLicenseNumber: '',
    driversLicenseState: '',
    email: '',
    expectedAvailabilityId: '',
    firstHelperPaymentPercentage: setting?.firstHelperPercentage ?? null,
    firstName: '',
    isAdmin: false,
    isCustomer: false,
    isDriver: true,
    isHelper: false,
    isMerchant: false,
    lastName: '',
    maximumDrivingDistanceMiles: null,
    orderPaymentPercentage: setting?.driverPercentage ?? null,
    password: '',
    phoneNumber: '',
    profilePictureFile: initialImage,
    secondHelperPaymentPercentage: setting?.secondHelperPercentage ?? null,
    state: '',
    status: null,
    vehicleColor: '',
    vehicleInsuranceFile: initialImage,
    vehicleMake: '',
    vehicleModel: '',
    vehicleRegistrationFile: initialImage,
    vehicleYear: null,
    zip: '',
  };
};

export const defaultDriversPageAdvancedSearchForm: DriversPageAdvancedSearchForm = {
  status: '',
};
