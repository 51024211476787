import { ItemPickupTimes, MerchantForm } from 'modules/Merchants/types';
import { resToUser } from '../users';
import { Merchant } from './types';

export const resToMerchant = (res: any): Merchant => {
  return {
    id: res.id,
    user: res.user ? resToUser(res.user) : null,
    businessName: res.businessName ?? '',
    isSendingPriceToOnfleet: res.isSendingPriceToOnfleet ?? false,
    driverGroupId: res.driverGroupId ?? '',
    itemPickupTimes: (res.itemPickupTimes ?? []).map(transformItemPickupTimes),
    merchantPaymentPercentage: res.merchantPaymentPercentage,
  };
};

export const merchantToPostReq = (merchant: MerchantForm): any => {
  return {
    user: {
      email: merchant.email,
      firstName: merchant.firstName,
      lastName: merchant.lastName,
      phoneNumber: merchant.phone,
      password: merchant.password,
      isDriver: false,
      isCustomer: false,
      isMerchant: true,
      isAdmin: false,
    },
    businessName: merchant.businessName,
    isSendingPriceToOnfleet: merchant.isSendingPriceToOnfleet,
    driverGroupId: merchant.driverGroupId,
    itemPickupTimes: (merchant.itemPickupTimes ?? []).map(transformItemPickupTimes),
    merchantPaymentPercentage: merchant.merchantPaymentPercentage,
  };
};

export const merchantToPutReq = (merchant: MerchantForm): any => {
  const payload: any = {
    user: {
      email: merchant.email,
      firstName: merchant.firstName,
      lastName: merchant.lastName,
      phoneNumber: merchant.phone,
      isMerchant: true,
    },
    businessName: merchant.businessName,
    isSendingPriceToOnfleet: merchant.isSendingPriceToOnfleet,
    driverGroupId: merchant.driverGroupId,
    itemPickupTimes: (merchant.itemPickupTimes ?? []).map(transformItemPickupTimes),
    merchantPaymentPercentage: merchant.merchantPaymentPercentage,
  };

  return payload;
};

export const transformItemPickupTimes = (res: any): ItemPickupTimes => {
  return {
    name: res.name ?? '',
    pickupTimeFrom: res.pickupTimeFrom ?? '',
    pickupTimeTo: res.pickupTimeTo ?? '',
  };
};
