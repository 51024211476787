import { DataWithMeta, PaginationRequest, paginationRequestToUrl } from 'helpers';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api } from 'services/api';
import { uploadImages } from 'services/images';
import { resToItemCategory, itemCategoryToReq, transformToItemCategoryFilters } from './transformations';
import { ItemCategory, ItemCategoryFormData } from './types';

enum QueryKeys {
  ItemCategories = 'itemCategories',
  SingleItemCategory = 'singleItemCategory',
}

export const fetchItemCategories = async (pagination: PaginationRequest): Promise<DataWithMeta<ItemCategory>> => {
  const url = paginationRequestToUrl('/item_categories', pagination, transformToItemCategoryFilters);
  const { data: res } = await api.get(url);
  return {
    data: res.data.map(resToItemCategory),
    meta: res.meta,
  };
};

export const fetchAllItemCategories = async (): Promise<ItemCategory[]> => {
  const resp = await api.get(`/item_categories`);
  return resp.data.data.map(resToItemCategory);
};

export const fetchItemCategory = async (id: string): Promise<ItemCategory> => {
  const res = await api.get(`/item_categories/${id}`);
  return resToItemCategory(res.data);
};

export const postItemCategory = async (data: ItemCategoryFormData): Promise<ItemCategory> => {
  const images = await uploadImages({ itemCategoryImageFile: data.itemCategoryImageFile });

  const res = await api.post('/item_categories', itemCategoryToReq({ ...data, ...images }));
  return resToItemCategory(res.data);
};

export const putItemCategory = async (data: ItemCategoryFormData, id: string): Promise<ItemCategory> => {
  const images = await uploadImages({ itemCategoryImageFile: data.itemCategoryImageFile });

  const res = await api.put(`/item_categories/${id}`, itemCategoryToReq({ ...data, ...images }));
  return resToItemCategory(res.data);
};

export const deleteItemCategory = async (id: string): Promise<ItemCategory> => {
  const res = await api.delete(`/item_categories/${id}`);
  return resToItemCategory(res.data);
};

// GET itemCategories

export const useItemCategories = ({ currentPage, pageSize, sortBy, sortDirection, debouncedSearch, filters }: any) => {
  return useQuery(
    [QueryKeys.ItemCategories, currentPage, pageSize, sortBy, sortDirection, debouncedSearch, filters],
    () => fetchItemCategories({ page: currentPage, pageSize, sortBy, sortDirection, search: debouncedSearch, filters }),
  );
};

export const useItemCategory = ({ id, isCreate }: { id: string; isCreate: boolean }) => {
  return useQuery([QueryKeys.SingleItemCategory, id], () => fetchItemCategory(id), {
    enabled: !isCreate,
  });
};

// ItemCategories mutation

export const useItemCategoryMutations = () => {
  const queryClient = useQueryClient();

  return {
    addItemCategory: useMutation(postItemCategory),
    editItemCategory: useMutation(({ data, id }: { data: ItemCategoryFormData; id: string }) =>
      putItemCategory(data, id),
    ),
    deleteItemCategory: useMutation(deleteItemCategory, {
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.ItemCategories]),
    }),
  };
};
