import { forwardRef } from 'react';
import clsx from 'clsx';

type TextareaProps = {
  error?: boolean;
} & JSX.IntrinsicElements['textarea'];

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(function Textarea(
  { className, error = false, ...props },
  ref,
) {
  return (
    <div className="relative">
      <textarea
        className={clsx(
          // eslint-disable-next-line max-len
          'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm',
          {
            'border-gray-300 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500': !error,
            'border-red-300 placeholder-red-400 focus:ring-red-500 focus:border-red-500': error,
          },
          className,
        )}
        {...props}
        ref={ref}
      />
    </div>
  );
});
