export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const PIE_CHART_COLORS = [
  '#2274a5',
  '#FF2934',
  '#f1c40f',
  '#f75c03',
  '#d90368',
  '#00cc66',
  '#226f54',
  '#ff006e',
  '#ff00ff',
  '#00ff00',
  '#4b0082',
  '#800000',
];
