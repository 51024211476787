import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { MessageType, showMessage, usePagination } from 'helpers';
import { deleteUser, fetchUsers, User } from 'services/users';

export const useUsersPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
  } = usePagination({ filters: { isAdmin: true } });

  const [userToDelete, setUserToDelete] = useState<User | null>(null);

  const { data: queryData, isLoading: usersLoading } = useQuery(
    ['users', currentPage, pageSize, sortBy, sortDirection, debouncedSearch, filters],
    () => fetchUsers({ page: currentPage, pageSize, sortBy, sortDirection, search: debouncedSearch, filters }),
  );
  const { mutateAsync: removeUser } = useMutation(deleteUser);

  const users = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onRowClick = (row: User) => {
    navigate(`/users/${row.id}`);
  };

  const onNewClick = () => {
    navigate('/users/new');
  };

  const onDeleteConfirm = async () => {
    if (userToDelete) {
      try {
        await removeUser(userToDelete.id);
        showMessage('User successfully deleted.', MessageType.Success);
        queryClient.invalidateQueries('users');
        setUserToDelete(null);
      } catch {
        showMessage('Something went wrong.', MessageType.Error);
      }
    }
  };

  const openConfirmationModal = (selectedUser: User | null) => {
    if (selectedUser) setUserToDelete(selectedUser);
  };

  const closeConfirmationModal = () => {
    setUserToDelete(null);
  };

  return {
    users,
    metaData,
    currentPage,
    search,
    isLoading: usersLoading,
    userToDelete,
    openConfirmationModal,
    closeConfirmationModal,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onDeleteConfirm,
    onRowClick,
    onNewClick,
    onSortChange,
  };
};
