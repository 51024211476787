import { idAndUrlToImage } from 'services/images';
import { ItemCategory, ItemCategoryFormData } from 'services/itemCategories';

export const itemCategoryToItemCategoryForm = (itemCategory: ItemCategory): ItemCategoryFormData => {
  return {
    id: itemCategory.id,
    name: itemCategory.name,
    itemGroupId: itemCategory.itemGroupId,
    itemCategoryImageFile: idAndUrlToImage(itemCategory.itemCategoryImageFileId, itemCategory.itemCategoryImageFileUrl),
    showInApp: itemCategory.showInApp,
  };
};
