import { PageType } from 'helpers';
import { initialImage } from 'services/images/consts';

import { DriverGroupAdvancedSearchForm, DriverGroupForm } from './types';

export const defaultDriverGroupForm: DriverGroupForm = {
  id: PageType.Create,
  bookingFee: null,
  description: '',
  name: '',
  position: null,
  showInApp: false,
  vehicleImageFile: initialImage,
  isTest: false,
  isHelper: false,
  relatedDriverGroupIds: [],
  trailers: [],
  trailerIds: [],
};

export const defaultDriverGroupAdvancedSearchForm: DriverGroupAdvancedSearchForm = {
  name: '',
};
