import { useNavigate } from 'react-router-dom';

import { Button, Container, Header, Divider, FormItem, Input } from 'components';

import { useStopPricePage } from './StopPricePageProvider';

export const StopPricePage = () => {
  const { isCreate, formState, onSubmit, register } = useStopPricePage();
  const navigate = useNavigate();

  return (
    <>
      <Header title={isCreate ? 'New stop price' : 'Edit stop price'} onGoBackPress={() => navigate(-1)}>
        <Button type="submit" onClick={onSubmit} label="Save" form="stopPriceForm" />
      </Header>

      <Container>
        <div className="overflow-hidden bg-white rounded-lg shadow">
          <div className="px-4 py-5 sm:p-6">
            <form className="space-y-10" onSubmit={onSubmit} noValidate id="stopPriceForm">
              <Divider title="Stop price information" />

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <FormItem label="Stops from" htmlFor="stopsFrom" error={formState.errors.stopsFrom?.message} required>
                  <Input
                    {...register('stopsFrom')}
                    id="stopsFrom"
                    type="number"
                    placeholder="Enter stops from"
                    autoComplete="stopsFrom"
                    required
                    error={!!formState.errors.stopsFrom?.message}
                  />
                </FormItem>

                <FormItem label="Stops to" htmlFor="stopsTo" error={formState.errors.stopsTo?.message} required>
                  <Input
                    {...register('stopsTo')}
                    id="stopsTo"
                    type="number"
                    placeholder="Enter stops to"
                    autoComplete="stopsTo"
                    required
                    error={!!formState.errors.stopsTo?.message}
                  />
                </FormItem>

                <FormItem
                  label="Price factor"
                  htmlFor="shippingFactor"
                  error={formState.errors.shippingFactor?.message}
                  required
                >
                  <Input
                    {...register('shippingFactor')}
                    id="shippingFactor"
                    type="number"
                    placeholder="Enter price factor"
                    autoComplete="shippingFactor"
                    required
                    error={!!formState.errors.shippingFactor?.message}
                  />
                </FormItem>

                <FormItem
                  label="First helper factor"
                  htmlFor="firstHelperFactor"
                  error={formState.errors.firstHelperFactor?.message}
                  required
                >
                  <Input
                    {...register('firstHelperFactor')}
                    id="firstHelperFactor"
                    type="number"
                    placeholder="Enter first helper factor"
                    autoComplete="firstHelperFactor"
                    required
                    error={!!formState.errors.firstHelperFactor?.message}
                  />
                </FormItem>

                <FormItem
                  label="Second helper factor"
                  htmlFor="secondHelperFactor"
                  error={formState.errors.secondHelperFactor?.message}
                  required
                >
                  <Input
                    {...register('secondHelperFactor')}
                    id="secondHelperFactor"
                    type="number"
                    placeholder="Enter second helper factor"
                    autoComplete="secondHelperFactor"
                    required
                    error={!!formState.errors.secondHelperFactor?.message}
                  />
                </FormItem>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};
