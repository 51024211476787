import { createContext, useContext, useEffect, useMemo } from 'react';
import { Control, FormState, useForm, UseFormRegister } from 'react-hook-form';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';

import { MessageType, showMessage, useIdFromParams } from 'helpers';
import { fetchUser, postUser, putUser } from 'services/users';

import { USER_VALIDATION_SCHEMA } from './const';
import { UserForm } from '../types';
import { defaultUserForm } from '../const';
import { userToUserForm } from './transformation';

const UserPageContext = createContext<{
  formState: FormState<UserForm>;
  isCreate: boolean;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  register: UseFormRegister<UserForm>;
  control: Control<UserForm, any>;
}>({} as any);

// This will be used in case we state shared inside the module
export const UserPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id: userId, isCreate } = useIdFromParams();

  const { data: user } = useQuery(['users', userId], () => fetchUser(userId), {
    enabled: !isCreate,
  });
  const { mutateAsync: updateUser } = useMutation(putUser);
  const { mutateAsync: createUser } = useMutation(postUser);

  const { handleSubmit, register, formState, reset, control } = useForm<UserForm>({
    defaultValues: defaultUserForm,
    resolver: yupResolver(USER_VALIDATION_SCHEMA),
  });

  const userForm = useMemo(() => {
    if (user) {
      return userToUserForm(user);
    }
    return defaultUserForm;
  }, [user]);

  useEffect(() => {
    reset(userForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userForm]);

  const onSubmit = handleSubmit(async (values: UserForm) => {
    if (isCreate) {
      try {
        await createUser(values);
        showMessage('User successfully created.', MessageType.Success);
        queryClient.invalidateQueries('users');
        navigate('/users');
      } catch {
        showMessage('Theres been an error!', MessageType.Error);
      }
      return;
    }

    try {
      await updateUser(values);
      showMessage('User successfully updated.', MessageType.Success);
      queryClient.invalidateQueries('users');
      navigate('/users');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const providerValue = useMemo(
    () => ({ formState, isCreate, onSubmit, register, control }),
    [formState, isCreate, onSubmit, register, control],
  );

  return <UserPageContext.Provider value={providerValue}>{children}</UserPageContext.Provider>;
};

export const useUserPage = () => {
  return useContext(UserPageContext);
};

interface Props {
  children?: React.ReactNode;
}
