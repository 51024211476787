import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

const OrdersContext = React.createContext<{}>({} as any);

// This will be used in case we state shared inside the module
export const OrdersProvider = ({ children = <Outlet /> }: Props) => {
  const providerValue = useMemo(() => ({}), []);

  return <OrdersContext.Provider value={providerValue}>{children}</OrdersContext.Provider>;
};

interface Props {
  children?: React.ReactNode;
}
