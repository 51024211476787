import { createContext, useContext, useEffect, useMemo } from 'react';
import { Control, FormState, useForm, UseFormRegister } from 'react-hook-form';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';

import { MessageType, showMessage } from 'helpers';
import { fetchSetting, putSetting } from 'services/settings';

import { SETTINGS_VALIDATION_SCHEMA } from './const';
import { SettingForm } from '../types';
import { settingToSettingForm } from './transformation';
import { defaultSettingForm } from '../consts';

const SettingPageContext = createContext<{
  formState: FormState<SettingForm>;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  register: UseFormRegister<SettingForm>;
  control: Control<SettingForm, any>;
}>({} as any);

// This will be used in case we state shared inside the module
export const SettingPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: setting } = useQuery(['settings'], () => fetchSetting());
  const { mutateAsync: updateSetting } = useMutation(putSetting);

  const { handleSubmit, register, formState, reset, control } = useForm<SettingForm>({
    defaultValues: defaultSettingForm,
    resolver: yupResolver(SETTINGS_VALIDATION_SCHEMA),
  });

  const settingForm = useMemo(() => {
    if (setting) {
      return settingToSettingForm(setting);
    }
    return defaultSettingForm;
  }, [setting]);

  useEffect(() => {
    reset(settingForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingForm]);

  const onSubmit = handleSubmit(async (values: SettingForm) => {
    try {
      await updateSetting(values);
      showMessage('Setting successfully updated.', MessageType.Success);
      queryClient.invalidateQueries('settings');
      navigate('/settings');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const providerValue = useMemo(
    () => ({ formState, onSubmit, register, control }),
    [formState, onSubmit, register, control],
  );

  return <SettingPageContext.Provider value={providerValue}>{children}</SettingPageContext.Provider>;
};

export const useSettingPage = () => {
  return useContext(SettingPageContext);
};

interface Props {
  children?: React.ReactNode;
}
