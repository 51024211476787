import {
  BriefcaseIcon,
  CogIcon,
  HomeIcon,
  TruckIcon,
  UserIcon,
  BanIcon,
  TrendingDownIcon,
  TrendingUpIcon,
  TagIcon,
  FlagIcon,
  CashIcon,
  StarIcon,
  UploadIcon,
  PhoneIcon,
  DatabaseIcon,
  BookOpenIcon,
  /*  ChatIcon, */
} from '@heroicons/react/solid';
import { useAuth } from 'GlobalProvider';

import { NavigationItem } from '../MenuItem';

export const useNavigation = (): NavigationItem[] => {
  const { user } = useAuth();
  const navigation: NavigationItem[] = [
    {
      to: '/',
      name: 'Dashboard',
      icon: HomeIcon,
    },
    {
      to: '/driver-groups',
      name: 'Driver groups',
      icon: TruckIcon,
    },
    {
      to: '/trailers',
      name: 'Trailers',
      icon: TruckIcon,
    },
    {
      to: '/merchants',
      name: 'Merchants',
      icon: BriefcaseIcon,
    },
    {
      to: '/promo-codes',
      name: 'Promo Codes',
      icon: TagIcon,
    },
    {
      to: '/orders',
      name: 'Orders',
      icon: FlagIcon,
    },
    {
      to: '',
      name: 'Item Categories',
      icon: BookOpenIcon,
      hide: !user?.isAdmin,
      subMenu: [
        {
          to: '/item-groups',
          name: 'Item Groups',
          icon: DatabaseIcon,
        },
        {
          to: '/item-categories',
          name: 'Categories',
          icon: DatabaseIcon,
        },
      ],
    },
    {
      to: '/driver-payments',
      name: 'Driver Payments',
      icon: CashIcon,
    },
    {
      to: '/merchant-payments',
      name: 'Merchant Payments',
      icon: CashIcon,
    },
    {
      to: '/reviews',
      name: 'Driver Reviews',
      icon: StarIcon,
    },
    {
      to: '/upload',
      name: 'Upload CSV',
      icon: UploadIcon,
    },
    {
      to: '/notification',
      name: 'SMS Notification',
      icon: PhoneIcon,
    },
    /*    {
      to: '/chat',
      name: 'Chat',
      icon: ChatIcon,
    }, */
    {
      to: '',
      name: 'User Management',
      icon: UserIcon,
      subMenu: [
        {
          to: '/drivers',
          name: 'Drivers',
          icon: TruckIcon,
        },
        {
          to: '/customers',
          name: 'Customers',
          icon: UserIcon,
        },
        {
          to: '/users',
          name: 'Users',
          icon: UserIcon,
        },
      ],
    },
    {
      to: '',
      name: 'Settings',
      icon: CogIcon,
      subMenu: [
        {
          to: '/settings',
          name: 'Global Settings',
          icon: CogIcon,
        },
        {
          to: '/stop-prices',
          name: 'Stop Prices',
          icon: BanIcon,
        },
        {
          to: '/weight-prices',
          name: 'Weight Prices',
          icon: TrendingDownIcon,
        },
        {
          to: '/distance-prices',
          name: 'Distance Prices',
          icon: TrendingUpIcon,
        },
      ],
    },
  ];

  return navigation.filter((item) => !item.hide);
};
