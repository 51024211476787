import { useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import clsx from 'clsx';

import { NavigationItem } from './types';

type MenuItemProps = {
  item: NavigationItem;
  onClickItem?: () => void;
};

export function MenuItem({ item, onClickItem }: MenuItemProps) {
  const { pathname } = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const isSubItemActive = useMemo(
    () => (item.subMenu || []).some((subItem) => pathname.startsWith((subItem.to || '') as string)),
    [item, pathname],
  );

  useEffect(() => {
    setIsOpen(isSubItemActive);
  }, [pathname, isSubItemActive]);

  if (!item.subMenu?.length) {
    return (
      <div key={item.name}>
        <NavLink
          to={item.to}
          className={({ isActive }) =>
            clsx('group flex items-center px-2 py-2 text-base font-medium rounded-md', {
              'bg-sidebar-red text-white': isActive,
              'text-black hover:bg-sidebar-red hover:text-white': !isActive,
            })
          }
          onClick={onClickItem ? () => (!item.subMenu || item.subMenu.length < 1 ? onClickItem() : null) : undefined}
        >
          {({ isActive }) => (
            <>
              <item.icon
                className={clsx('mr-4 flex-shrink-0 h-6 w-6', {
                  'text-white': isActive,
                  'text-gray-700 group-hover:text-white': !isActive,
                })}
                aria-hidden="true"
              />
              {item.name}
            </>
          )}
        </NavLink>
      </div>
    );
  }

  return (
    <Disclosure as="div" key={item.name} className="space-y-1" defaultOpen={isSubItemActive}>
      {() => {
        return (
          <>
            <Disclosure.Button
              className={clsx(
                'group w-full flex items-center pl-2 pr-1 py-2 text-left font-medium rounded-md focus:outline-none',
                {
                  'bg-sidebar-red text-white': isOpen,
                  'text-black hover:bg-sidebar-red hover:text-white': !isOpen,
                },
              )}
              onClick={() => setIsOpen(!isOpen)}
            >
              <item.icon
                className={clsx('mr-4 flex-shrink-0 h-6 w-6', {
                  'text-white': isOpen,
                  'text-gray-700 group-hover:text-white': !isOpen,
                })}
                aria-hidden="true"
              />
              <span className="flex-1">{item.name}</span>
              <svg
                className={clsx(
                  isOpen ? 'text-white rotate-90' : 'text-black',
                  'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-white',
                )}
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
              </svg>
            </Disclosure.Button>
            {isOpen && (
              <div>
                <Disclosure.Panel static className="space-y-1">
                  {item.subMenu!.map((subItem) => (
                    <NavLink
                      key={subItem.name}
                      to={subItem.to || ''}
                      className={({ isActive }) =>
                        clsx('group w-full flex items-center pl-8 px-2 py-2 text-sm font-medium rounded-md', {
                          'text-black': isOpen && !isActive,
                          'bg-sidebar-red text-white': isActive,
                          // 'text-white group-hover:text-white': !isOpen,
                          'hover:bg-sidebar-red hover:text-white': !isActive,
                        })
                      }
                      onClick={onClickItem}
                    >
                      {({ isActive }) => (
                        <>
                          <subItem.icon
                            className={clsx('mr-2 flex-shrink-0 h-5 w-5', {
                              'text-white': isActive || isOpen,
                              'text-gray-800 group-hover:text-white': !isActive || !isOpen,
                            })}
                            aria-hidden="true"
                          />
                          {subItem.name}
                        </>
                      )}
                    </NavLink>
                  ))}
                </Disclosure.Panel>
              </div>
            )}
          </>
        );
      }}
    </Disclosure>
  );
}
