import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { MessageType, showMessage, SortDirection, usePagination } from 'helpers';
import { deleteWeightPrice, fetchWeightPrices, WeightPrice } from 'services/weightPrices';

// Drivers page state
export const useWeightPricesPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
  } = usePagination({ sortBy: 'weightLbsFrom', sortDirection: SortDirection.Asc });

  const [weightPriceToDelete, setWeightPriceToDelete] = useState<WeightPrice | null>(null);

  const { data: queryData, isLoading: driversLoading } = useQuery(
    ['weightPrices', currentPage, pageSize, sortBy, sortDirection, debouncedSearch],
    () => fetchWeightPrices({ page: currentPage, pageSize, sortBy, sortDirection, search: debouncedSearch }),
  );
  const { mutateAsync: removeWeightPrice } = useMutation(deleteWeightPrice);

  const weightPrices = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onRowClick = (row: WeightPrice) => {
    navigate(`/weight-prices/${row.id}`);
  };

  const onNewClick = () => {
    navigate('/weight-prices/new');
  };

  const onDeleteConfirm = async () => {
    if (weightPriceToDelete) {
      try {
        await removeWeightPrice(weightPriceToDelete.id);
        showMessage('Weight price successfully deleted.', MessageType.Success);
        queryClient.invalidateQueries('weightPrices');
        setWeightPriceToDelete(null);
      } catch {
        showMessage('Something went wrong.', MessageType.Error);
      }
    }
  };

  const openConfirmationModal = (selectedWeightPrice: WeightPrice | null) => {
    if (selectedWeightPrice) setWeightPriceToDelete(selectedWeightPrice);
  };

  const closeConfirmationModal = () => {
    setWeightPriceToDelete(null);
  };

  return {
    weightPrices,
    metaData,
    currentPage,
    search,
    isLoading: driversLoading,
    weightPriceToDelete,
    openConfirmationModal,
    closeConfirmationModal,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onDeleteConfirm,
    onRowClick,
    onNewClick,
    onSortChange,
  };
};
