import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { MessageType, showMessage, useIdFromParams } from 'helpers';
import { ItemGroupForm, useItemGroup, useItemGroupMutations } from 'services/itemGroups';

import { ITEM_GROUP_VALIDATION_SCHEMA } from './validations';
import { itemGroupToItemGroupForm } from './transformation';
import { defaultItemGroupForm } from './consts';

export const useItemGroupPage = () => {
  const navigate = useNavigate();
  const { id: itemGroupId, isCreate } = useIdFromParams();

  const { data: itemGroup, isLoading: itemGroupLoading } = useItemGroup({ id: itemGroupId, isCreate });
  const { addItemGroup, editItemGroup } = useItemGroupMutations();

  const { formState, control, handleSubmit, register, reset } = useForm<ItemGroupForm>({
    defaultValues: defaultItemGroupForm,
    resolver: yupResolver(ITEM_GROUP_VALIDATION_SCHEMA),
  });

  useEffect(() => {
    if (itemGroup) {
      reset(itemGroupToItemGroupForm(itemGroup));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemGroup]);

  const onSubmit = handleSubmit(async (values: ItemGroupForm) => {
    if (isCreate) {
      try {
        await addItemGroup.mutateAsync(values);
        showMessage('Item Group successfully created.', MessageType.Success);
        navigate('/item-groups');
      } catch {
        showMessage('Theres been an error!', MessageType.Error);
      }
      return;
    }

    try {
      await editItemGroup.mutateAsync({ data: values, id: itemGroupId });
      showMessage('Item Group successfully updated.', MessageType.Success);
      navigate('/item-groups');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  return {
    register,
    onSubmit,
    isCreate,
    itemGroupLoading,
    formState,
    control,
  };
};
