import { api } from 'services';

export const searchAddress = async (search: string): Promise<any> => {
  const url = `/geolocation/search?searchText=${search}`;

  const resp = await api.get(url);
  return resp.data;
};

export const verifyAddress = async (placeId: string): Promise<any> => {
  const url = `/geolocation/details?placeId=${placeId}`;

  const resp = await api.get(url);

  return resp.data.result;
};
