import { DriverReviewForm } from 'modules/DriverReviews/DriverReviewPage/types';
import { Review } from './types';

export const transformToReviewFilters = (filters: any): any => {
  return {
    date: filters.date,
    reviewStatus: filters.status,
    customerName: filters.customerFirstName,
    driverName: filters.driverFirstName,
    rate: filters.score,
  };
};

export const resToReview = (res: any): Review => {
  return {
    createdAt: res.createdAt ?? '',
    id: res.id ?? '',
    driverId: res.driverId ?? '',
    customerId: res.customerId ?? '',
    orderId: res.orderId ?? '',
    score: res.score ?? null,
    description: res.description ?? '',
    status: res.status ?? false,
    driverFirstName: res.driverFirstName ?? '',
    driverLastName: res.driverLastName ?? '',
    customerFirstName: res.customerFirstName ?? '',
    customerLastName: res.customerLastName ?? '',
  };
};

export const reviewToPutReq = (review: DriverReviewForm) => {
  const payload: any = {
    id: review.id,
    driverId: review.driverId,
    customerId: review.customerId,
    orderId: review.orderId,
    score: review.score,
    description: review.description,
    status: review.status,
  };
  return payload;
};
