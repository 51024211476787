import { useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { MessageType, showMessage, usePagination } from 'helpers';
// sortDirection here

import { Trailer, useTrailerMutations, useTrailers } from 'services/trailers';

export const useTrailersPage = () => {
  const navigate = useNavigate();
  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onFiltersChange,
  } = usePagination({ sortBy: 'title' });

  const [trailerToDelete, setTrailerToDelete] = useState<Trailer | null>(null);

  const { data: trailersData, isLoading: isTrailersDataLoading } = useTrailers({
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onFiltersChange,
  });

  const trailers = useMemo(() => trailersData?.data ?? [], [trailersData?.data]);
  const metaData = useMemo(() => trailersData?.meta, [trailersData?.meta]);

  const { deleteTrailer } = useTrailerMutations();

  const onTrailerRowClick = useCallback(
    (row: Trailer) => {
      navigate(`/trailers/${row.id}`);
    },
    [navigate],
  );

  const onNewTrailerClick = useCallback(() => {
    navigate('/trailers/new');
  }, [navigate]);

  const onDeleteConfirm = useCallback(async () => {
    if (trailerToDelete) {
      try {
        await deleteTrailer.mutateAsync(trailerToDelete.id);
        showMessage('Trailer successfully deleted.', MessageType.Success);
      } catch (err) {
        showMessage((err as any).response.data.title || 'Something went wrong.', MessageType.Error);
      }
      setTrailerToDelete(null);
    }
  }, [trailerToDelete, deleteTrailer]);

  const openConfirmationModal = useCallback((selectedTrailer: Trailer | null) => {
    if (selectedTrailer) setTrailerToDelete(selectedTrailer);
  }, []);

  const closeConfirmationModal = useCallback(() => {
    setTrailerToDelete(null);
  }, []);

  return {
    trailers,
    metaData,
    isTrailersDataLoading,
    trailerToDelete,
    isTrailerDeleting: deleteTrailer.isLoading,
    onTrailerRowClick,
    onNewTrailerClick,
    onDeleteConfirm,
    openConfirmationModal,
    closeConfirmationModal,
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onFiltersChange,
  };
};
