import { Control } from 'react-hook-form';
import { CurrencyInput, FormItem, Modal } from 'components';
import { ModalProps } from 'components/Modal/Modal/types';
import { OrderBonusPayments } from 'modules/Orders/types';

export const BonusPayementsModal = (props: Props) => {
  const { control, isLoading, open, useCancelButton = true, title = '', onSaveLabel = 'Save', onSave, onClose } = props;
  return (
    <Modal
      open={open}
      onSave={onSave}
      onClose={onClose}
      useCancelButton={useCancelButton}
      title={title}
      onSaveLabel={onSaveLabel}
      isLoading={isLoading}
    >
      <div className="grid grid-cols-1 gap-4 py-5 lg:grid-cols-2">
        <FormItem label="Driver Bonus Payment" htmlFor="driverBonusPayment">
          <CurrencyInput
            id="driverBonusPayment"
            name="driverBonusPayment"
            control={control}
            placeholder="Enter driver bonus amount"
          />
        </FormItem>
        <FormItem label="Helper Bonus Payment" htmlFor="helperBonusPayment">
          <CurrencyInput
            id="helperBonusPayment"
            name="helperBonusPayment"
            control={control}
            placeholder="Enter helper bonus amount"
          />
        </FormItem>
      </div>
    </Modal>
  );
};

type Props = {
  control: Control<OrderBonusPayments, any>;
} & ModalProps;
