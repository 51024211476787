import { PageType } from 'helpers';
import { DriverGroupForm } from 'modules/DriverGroups/types';
import { resToTrailer } from 'services/trailers';

import { DriverGroup } from './types';

export const resToDriverGroup = (res: any): DriverGroup => {
  return {
    id: res.id,
    bookingFee: res.bookingFee ?? null,
    description: res.description ?? '',
    isTest: res.isTest ?? false,
    isHelper: res.isHelper ?? false,
    name: res.name ?? '',
    onFleetTeamId: res.onFleetTeamId ?? '',
    position: res.position ?? null,
    showInApp: res.showInApp ?? false,
    vehicleImageFileId: res.vehicleImageFileId ?? '',
    vehicleImageFileUrl: res.vehicleImageFileUrl ?? '',
    relatedDriverGroupIds: res.relatedDriverGroupIds ?? [],
    trailers: res.trailers?.map(resToTrailer) ?? [],
  };
};

export const driverGroupToPostReq = (driverGroup: DriverGroupForm): any => {
  const payload: any = {
    name: driverGroup.name,
    bookingFee: driverGroup.bookingFee,
    showInApp: driverGroup.showInApp,
    description: driverGroup.description,
    isTest: driverGroup.isTest,
    isHelper: driverGroup.isHelper,
    relatedDriverGroupIds: driverGroup.relatedDriverGroupIds,
    trailerIds: driverGroup.trailerIds,
  };

  if (driverGroup.vehicleImageFile.id !== PageType.Create) {
    payload.vehicleImageFileId = driverGroup.vehicleImageFile.id;
  }

  return payload;
};

export const driverGroupToPutReq = (driverGroup: DriverGroupForm): any => {
  const payload: any = {
    name: driverGroup.name,
    bookingFee: driverGroup.bookingFee,
    showInApp: driverGroup.showInApp,
    description: driverGroup.description,
    position: driverGroup.position,
    isTest: driverGroup.isTest,
    isHelper: driverGroup.isHelper,
    relatedDriverGroupIds: driverGroup.relatedDriverGroupIds,
    trailerIds: driverGroup.trailerIds,
  };

  if (driverGroup.vehicleImageFile.id !== PageType.Create) {
    payload.vehicleImageFileId = driverGroup.vehicleImageFile.id;
  }

  return payload;
};

export const transformToDriverGroupsFilters = (filters: any): any => {
  return {
    name: filters.name,
  };
};
