import { useNavigate } from 'react-router-dom';

import { Button, Container, Header, Divider, FormItem, Input, ImageUpload, Checkbox, AsyncSelect } from 'components';
import { fetchItemGroups, ItemGroup } from 'services/itemGroups';

import { useItemCategoryPage } from './hooks';

export const ItemCategoryPage = () => {
  const { isCreate, formState, onSubmit, register, control, itemCategoryLoading, itemGroup } = useItemCategoryPage();
  const navigate = useNavigate();

  return (
    <>
      <Header title={isCreate ? 'New Item Category' : 'Edit Item Category'} onGoBackPress={() => navigate(-1)}>
        <Button
          type="submit"
          onClick={onSubmit}
          label="Save"
          form="itemCategoryForm"
          disabled={formState.isSubmitting}
        />
      </Header>

      <Container isLoading={itemCategoryLoading}>
        <div className="overflow-hidden bg-white rounded-lg shadow">
          <div className="px-4 py-5 sm:p-6">
            <form className="space-y-10" onSubmit={onSubmit} noValidate id="itemCategoryForm">
              <Divider title="ItemCategory information" />

              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <FormItem label="Name" htmlFor="name" error={formState.errors.name?.message} required>
                  <Input
                    {...register('name')}
                    id="name"
                    type="text"
                    placeholder="Enter name"
                    required
                    error={!!formState.errors.name?.message}
                    disabled={formState.isSubmitting}
                  />
                </FormItem>

                <FormItem label="Item Group">
                  <AsyncSelect
                    asyncFunction={fetchItemGroups}
                    getOptionLabel={(option: ItemGroup) => `${option.name ?? ''}`}
                    control={control}
                    name="itemGroupId"
                    valueKey="id"
                    initialValue={itemGroup}
                    placeholder="Select Item Group"
                    error={formState.errors.itemGroupId?.message}
                  />
                </FormItem>
                <ImageUpload
                  name="itemCategoryImageFile"
                  control={control}
                  label="Image"
                  error={formState.errors.itemCategoryImageFile?.url?.message}
                />
              </div>
              <Checkbox
                {...register('showInApp')}
                id="showInApp"
                label="Is this item category visible on mobile app for customers?"
                name="showInApp"
              />
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};
