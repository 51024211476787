import { OrderBonusPayments } from 'modules/Orders/types';
import { BulkOrder, BulkOrderForm } from 'modules/Upload/types';
import { resToMerchant } from 'services/merchants';

import { resToAddress } from '../addresses';
import { resToCustomer } from '../customers';
import { resToDriver } from '../drivers';
import { Order, OrderFormData, OrderImage, OrderStop, PaymentStatus, PaymentType } from './types';

export const resToOrder = (res: any): Order => {
  return {
    id: res.id ?? '',
    bookingPrice: res.bookingPrice ?? null,
    createdAt: res.createdAt ?? '',
    customer: res.customer ? resToCustomer(res.customer) : null,
    customerId: res.customerId ?? '',
    customerName: res.customerName ?? '',
    deliveryAddresses: res.deliveryAddresses ?? [],
    deliveryWindow: res.deliveryWindow ?? null,
    deviceType: res.deviceType ?? '',
    driver: res.driver ? resToDriver(res.driver) : null,
    driverGroupId: res.driverGroupId ?? '',
    driverGroupName: res.driverGroupName ?? '',
    driverId: res.driverId ?? '',
    driverName: res.driverName ?? '',
    driverPayment: res.driverPayment ?? null,
    expeditedFee: res.expeditedFee ?? null,
    extraTip: res.extraTip ?? null,
    finalPrice: res.finalPrice ?? null,
    firstHelperFee: res.firstHelperFee ?? null,
    firstHelperLoadPrice: res.firstHelperLoadPrice ?? null,
    firstHelperUnloadPrice: res.firstHelperUnloadPrice ?? null,
    height: res.height ?? '',
    helper: res.helper ? resToDriver(res.driver) : null,
    helperPayment: res.helperPayment ?? null,
    images: (res.images ?? []).map(resToOrderImages),
    itemsDescription: res.itemsDescription ?? '',
    length: res.length ?? '',
    loadHelperCount: res.loadHelperCount ?? null,
    loadHelperPrice: res.loadHelperPrice ?? null,
    merchantId: res.merchantId ?? '',
    merchant: res.merchant ? resToMerchant(res.merchant) : null,
    merchantOrderNumber: res.merchantOrderNumber ?? '',
    mileagePrice: res.mileagePrice ?? null,
    orderStatus: res.orderStatus ?? '',
    paymentDate: res.paymentDate ?? '',
    paymentStatus: res.paymentStatus ?? PaymentStatus.Pending,
    paymentTransactionId: res.paymentTransactionId ?? '',
    paymentType: res.paymentType ?? '',
    pickupAddress: res.pickupAddress ?? '',
    pickupType: res.pickupType ?? null,
    promoCodeDiscount: res.promoCodeDiscount ?? null,
    promoCodeId: res.promoCodeId ?? '',
    secondHelperFee: res.secondHelperFee ?? null,
    secondHelperLoadPrice: res.secondHelperLoadPrice ?? null,
    secondHelperUnloadPrice: res.secondHelperUnloadPrice ?? null,
    startDateTime: res.startDateTime ?? '',
    stops: (res.stops || []).map(resToOrderStop),
    tipOverride: res.tipOverride ?? null,
    tipPrice: res.tipPrice ?? null,
    totalMileage: res.totalMileage ?? null,
    trailerPrice: res.trailerPrice ?? null,
    unloadHelperCount: res.unloadHelperCount ?? null,
    unloadHelperPrice: res.unloadHelperPrice ?? null,
    weight: res.weight ?? null,
    weightPrice: res.weightPrice ?? null,
    width: res.width ?? '',
    pickupTimeFrom: res.pickupTimeFrom ?? '',
    pickupTimeUntil: res.pickupTimeUntil ?? '',
    driverBonusPayment: res.driverBonusPayment ?? 0,
    helperBonusPayment: res.helperBonusPayment ?? 0,
    pickupLocationDescription: res.pickupLocationDescription ?? '',
    deliveryLocationDescription: res.deliveryLocationDescription ?? '',
    itemCost: res.itemCost ?? 0,
  };
};

export const resToOrderImages = (res: any): OrderImage => {
  return {
    id: res.id ?? '',
    fileId: res.fileId ?? '',
    fileUrl: res.fileUrl ?? '',
  };
};

export const resToOrderStop = (res: any): OrderStop => {
  return {
    id: res.id,
    orderId: res.orderId ?? '',
    fromAddress: res.fromAddress ? resToAddress(res.fromAddress) : null,
    toAddress: res.toAddress ? resToAddress(res.toAddress) : null,
    stopNumber: res.stopNumber ?? null,
    shippingDistance: res.shippingDistance ?? null,
    shippingDistancePrice: res.shippingDistancePrice ?? null,
    itemQuantity: res.itemQuantity ?? null,
    itemLength: res.itemLength ?? null,
    itemWidth: res.itemWidth ?? null,
    itemHeight: res.itemHeight ?? null,
    itemWeight: res.itemWeight ?? null,
    deliveryDateTime: res.deliveryDateTime ?? '',
    pickupDateTime: res.pickupDateTime ?? '',
    driverPickupNote: res.pickupNote ?? '',
    driverDeliveryNote: res.driverDeliveryNote ?? '',
    senderPersonName: res.senderPersonName ?? '',
    senderPersonPhone: res.senderPersonPhone ?? '',
    receivePersonName: res.contactName ?? '',
    receivePersonPhone: res.contactPhone ?? '',
  };
};

export const transformOrderBulk = (res: any): BulkOrder => {
  return {
    pickupName: res.pickupName ?? '',
    pickupPhone: res.pickupPhone ?? '',
    pickupAddress1: res.pickupAddress1 ?? '',
    pickupAddress2: res.pickupAddress2 ?? '',
    pickupCity: res.pickupCity ?? '',
    pickupState: res.pickupState ?? '',
    pickupZip: res.pickupZip ?? '',
    pickupNote: res.pickupNote ?? '',
    deliveryName: res.deliveryName ?? '',
    deliveryPhone: res.deliveryPhone ?? '',
    deliveryAddress1: res.deliveryAddress1 ?? '',
    deliveryAddress2: res.deliveryAddress2 ?? '',
    deliveryCity: res.deliveryCity ?? '',
    deliveryState: res.deliveryState ?? '',
    deliveryZip: res.deliveryZip ?? '',
    deliveryNote: res.deliveryNote ?? '',
    itemPickupDate: res.itemPickupDate ?? '',
    itemPickupTime: res.itemPickupTime ?? '',
    numOfLoadHelpers: res.numberOfLoadHelpers ?? 0,
    numOfUnloadHelpers: res.numberOfUnloadHelpers ?? 0,
    deliveryTypeId: res.deliveryTypeId ?? '',
    itemDetails: res.itemDetails ?? '',
    merchantOrderNumber: res.merchantOrderNumber ?? '',
  };
};

export const transformToPostBulkOrder = (bulkForm: BulkOrderForm): any => {
  return {
    merchantId: bulkForm.merchantId ?? '',
    orders: bulkForm.orders.map((order) => {
      return {
        pickupName: order.pickupName ?? '',
        pickupPhone: order.pickupPhone ?? '',
        pickupAddress1: order.pickupAddress1 ?? '',
        pickupAddress2: order.pickupAddress2 ?? '',
        pickupCity: order.pickupCity ?? '',
        pickupState: order.pickupState ?? '',
        pickupZip: order.pickupZip ?? '',
        pickupNote: order.pickupNote ?? '',
        deliveryName: order.deliveryName ?? '',
        deliveryPhone: order.deliveryPhone ?? '',
        deliveryAddress1: order.deliveryAddress1 ?? '',
        deliveryAddress2: order.deliveryAddress2 ?? '',
        deliveryCity: order.deliveryCity ?? '',
        deliveryState: order.deliveryState ?? '',
        deliveryZip: order.deliveryZip ?? '',
        deliveryNote: order.deliveryNote ?? '',
        itemPickupDate: order.itemPickupDate ?? '',
        pickupTimeFrom: order.itemPickupTime.split(' - ')[0] ?? '',
        pickupTimeUntil: order.itemPickupTime.split(' - ')[1] ?? '',
        numberOfLoadHelpers: order.numOfLoadHelpers ?? null,
        numberOfUnloadHelpers: order.numOfUnloadHelpers ?? null,
        deliveryTypeId: order.deliveryTypeId ?? '',
        itemDetails: order.itemDetails ?? '',
        merchantOrderNumber: order.merchantOrderNumber ?? '',
      };
    }),
  };
};

export const transformToOrderFilters = (filters: any): any => {
  return {
    'startDateTime[gt]': filters.dateFrom?.toISOString(),
    'startDateTime[lt]': filters.dateTo?.toISOString(),
    orderStatus: filters.status,
    paymentStatus: filters.paymentStatus,
    'customer_name[like]': filters.customerName,
    'driverName[like]': filters.driverName,
    paymentType: filters.isInvoiced ? PaymentType.Invoiced : null,
    driverId: filters.driverId,
    customerId: filters.customerId,
    merchantId: filters.merchantId,
  };
};

export const resToPostOrderBonusPayments = (orderBonusPayments: OrderBonusPayments): any => {
  return {
    driverBonusPayment: Number(orderBonusPayments.driverBonusPayment) ?? 0,
    helperBonusPayment: Number(orderBonusPayments.helperBonusPayment) ?? 0,
  };
};

export const transformToOrderData = (order: OrderFormData): any => {
  return {
    customerId: order.customerId ?? '',
    driverGroupId: order.driverGroupId ?? '',
    driverId: order.driverId || null,
    merchantId: order.merchantId || null,
    promoCodeId: order.promoCodeId || null,
    loadHelperCount: order.loadHelperCount ?? 0,
    unloadHelperCount: order.unloadHelperCount ?? 0,
    weight: order.weight ?? 0,
    length: order.length ?? '',
    width: order.width ?? '',
    height: order.height ?? '',
    tipOverride: order.tipOverride ?? 0,
    startDateTime: order.startDateTime ? new Date(order.startDateTime) : null,
    startDateTimeClientTimeZone: order.startDateTimeClientTimeZone ?? '',
    paymentType: order.paymentType ?? '',
    deviceType: order.deviceType ?? '',
    stops: order.stops ?? [],
    images:
      order.images.map((image) => {
        return { fileId: image.id };
      }) ?? [],
    itemsDescription: order.itemsDescription ?? '',
    pickupType: order.pickupType ?? '',
    deliveryWindow: order.deliveryWindow ?? '',
    pickupTimeFrom: order.pickupTimeFrom ?? '',
    pickupTimeUntil: order.pickupTimeUntil ?? '',
    merchantOrderNumber: order.merchantOrderNumber || null,
    completedAt: order.completedAt ? new Date(order.completedAt) : null,
    trailerId: order.trailerId || null,
    categoryId: order.categoryId ?? '',
    pickupLocationDescription: order.pickupLocationDescription ?? '',
    deliveryLocationDescription: order.deliveryLocationDescription ?? '',
    itemCost: order.itemCost ?? 0,
  };
};
