import { DistancePriceForm } from 'modules/DistancePrices/types';

import { DistancePrice } from './types';

export const resToDistancePrice = (res: any): DistancePrice => {
  return {
    id: res.id,
    distanceMilesFrom: res.distanceMilesFrom,
    distanceMilesTo: res.distanceMilesTo,
    pricePerMile: res.pricePerMile,
  };
};

export const distancePriceToPostReq = (price: DistancePriceForm): any => {
  return {
    distanceMilesFrom: price.distanceMilesFrom,
    distanceMilesTo: price.distanceMilesTo,
    pricePerMile: price.pricePerMile,
  };
};

export const distancePriceToPutReq = (price: DistancePriceForm): any => {
  return {
    distanceMilesFrom: price.distanceMilesFrom,
    distanceMilesTo: price.distanceMilesTo,
    pricePerMile: price.pricePerMile,
  };
};
