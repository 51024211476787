/* eslint-disable jsx-a11y/label-has-associated-control */
import { PlusIcon } from '@heroicons/react/outline';
import { Button, ConfirmationModal, Container, Header, Table } from 'components';
import { HeaderSearch } from 'components/Header/HeaderSearch';
import { Trailer } from 'services/trailers';

import { columns } from './const';
import { useTrailersPage } from './hooks';

export const TrailersPage = () => {
  const {
    trailers,
    metaData,
    isTrailersDataLoading,
    trailerToDelete,
    isTrailerDeleting,
    currentPage,
    search,
    onTrailerRowClick,
    onDeleteConfirm,
    onNewTrailerClick,
    openConfirmationModal,
    closeConfirmationModal,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onSearchChange,
  } = useTrailersPage();

  return (
    <>
      <Header title="Trailers">
        <div className="flex justify-between flex-1">
          <HeaderSearch onChange={(e: any) => onSearchChange(e.target.value)} value={search} />
          <Button onClick={onNewTrailerClick} label="New Trailer" icon={<PlusIcon />} />
        </div>
      </Header>

      <Container>
        <Table<Trailer>
          data={trailers}
          pagination={metaData}
          currentPage={currentPage}
          isLoading={isTrailersDataLoading}
          columns={columns(openConfirmationModal)}
          onRowClick={onTrailerRowClick}
          pageClicked={onPageChange}
          pageSizeClicked={onPageSizeChange}
          headerClicked={onSortChange}
        />
      </Container>
      <ConfirmationModal
        title="Delete Trailer"
        body="Are you sure you want to delete this trailer?"
        open={!!trailerToDelete}
        onAction={onDeleteConfirm}
        onClose={closeConfirmationModal}
        actionButtonDisabled={isTrailerDeleting}
      />
    </>
  );
};
