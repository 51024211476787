import { StopPrice } from 'services/stopPrices';

import { StopPriceForm } from '../types';

export const stopPriceToStopPriceForm = (price: StopPrice): StopPriceForm => {
  return {
    id: price.id,
    stopsFrom: price.stopsFrom,
    stopsTo: price.stopsTo,
    shippingFactor: price.shippingFactor,
    firstHelperFactor: price.firstHelperFactor,
    secondHelperFactor: price.secondHelperFactor,
  };
};
