import { DataWithMeta, PaginationRequest } from 'helpers';
import { WeightPriceForm } from 'modules/WeightPrices/types';

import { api } from '../api';
import { resToWeightPrice, weightPriceToPostReq, weightPriceToPutReq } from './transformations';
import { WeightPrice } from './types';

export const fetchWeightPrices = async (pagination: PaginationRequest): Promise<DataWithMeta<WeightPrice>> => {
  let url = `/shipping_weight_prices?page=${pagination.page}&pageSize=${pagination.pageSize}`;

  if (pagination.sortBy) url += `&sortBy=${pagination.sortBy}`;
  if (pagination.sortDirection) url += `&sortDirection=${pagination.sortDirection}`;
  if (pagination.search) url += `&search=${pagination.search}`;

  const resp = await api.get(url);
  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToWeightPrice),
  };
};

export const fetchWeightPrice = async (id: string): Promise<WeightPrice> => {
  const resp = await api.get(`/shipping_weight_prices/${id}`);
  return resToWeightPrice(resp.data);
};

export const putWeightPrice = async (data: WeightPriceForm): Promise<WeightPrice> => {
  const resp = await api.put(`/shipping_weight_prices/${data.id}`, weightPriceToPutReq(data));
  return resToWeightPrice(resp.data);
};

export const postWeightPrice = async (data: WeightPriceForm): Promise<WeightPrice> => {
  const resp = await api.post(`/shipping_weight_prices`, weightPriceToPostReq(data));
  return resToWeightPrice(resp.data);
};

export const deleteWeightPrice = async (id: string): Promise<WeightPrice> => {
  const resp = await api.delete(`/shipping_weight_prices/${id}`);
  return resToWeightPrice(resp.data);
};
