import { useEffect, useMemo, useState } from 'react';
import { useReportingUser } from 'services/reporting';

import { initialCustomerData } from './TotalCustomerChart/const';
import { InnerChartCardData } from '../../types';
import { initialDriverData } from './TotalDriverChart/const';
import { initialDriverGroupData } from './DriverGroupChart/const';
import { ChartType } from '../types';
import { useDashboardPageContext } from '../../DashboardPageProvider';
import { resolveLabels } from '../../helpers';
import { PIE_CHART_COLORS } from '../../consts';

export const useUserReportingPage = () => {
  const { startDate, endDate, resolution } = useDashboardPageContext();
  const { data: dataByUser } = useReportingUser({ startDate, endDate, resolution });

  const [customerData, setCustomerData] = useState<ChartType>(initialCustomerData);
  const [driverData, setDriverData] = useState<ChartType>(initialDriverData);
  const [driverGroupData, setDriverGroupData] = useState<ChartType>(initialDriverGroupData);

  const customerDataFormat = useMemo(() => {
    const totalCustomerSum = dataByUser?.totalCustomers;
    const totalDriverSum = dataByUser?.totalDrivers;
    const totalHelperSum = dataByUser?.totalHelpers;
    const newCustomersData =
      dataByUser?.customersOverTimeInterval.map((i) => ({
        day: i.day,
        week: i.week,
        month: i.month,
        year: i.year,
        newCustomers: i.newCustomers,
      })) || [];
    const newDriversData =
      dataByUser?.driversOverTimeInterval.map((i) => ({
        day: i.day,
        week: i.week,
        month: i.month,
        year: i.year,
        newDrivers: i.newDrivers,
      })) || [];
    const driverGroupLables = dataByUser?.driversPerDriverGroup.map((i) => i.driverGroupName) || [];
    const driverGroupSeriesCount = dataByUser?.driversPerDriverGroup.map((i) => i.driverCount) || [];

    return {
      totalCustomerSum,
      totalDriverSum,
      totalHelperSum,
      newCustomersData,
      newDriversData,
      driverGroupLables,
      driverGroupSeriesCount,
    };
  }, [dataByUser]);

  useEffect(() => {
    setCustomerData((old) => {
      return {
        series: [
          {
            name: 'New Customers',
            data: customerDataFormat.newCustomersData.map((item) => item.newCustomers),
          },
        ],
        options: {
          ...old.options,
          chart: {
            type: 'bar',
            height: 700,
            animations: {
              enabled: false,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: customerDataFormat.newCustomersData.map((item) => resolveLabels(item, resolution)),
          },
        },
        totalSum: customerDataFormat.totalCustomerSum || 0,
      };
    });
  }, [customerDataFormat, resolution]);

  useEffect(() => {
    setDriverData((old) => {
      return {
        series: [
          {
            name: 'New Drivers',
            data: customerDataFormat.newDriversData.map((i) => i.newDrivers),
          },
        ],
        options: {
          ...old.options,
          chart: {
            type: 'bar',
            height: 700,
            animations: {
              enabled: false,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: customerDataFormat.newDriversData.map((item) => resolveLabels(item, resolution)),
          },
        },
        totalSum: customerDataFormat.totalDriverSum || 0,
      };
    });
  }, [customerDataFormat, resolution]);

  useEffect(() => {
    setDriverGroupData((old) => {
      return {
        series: customerDataFormat?.driverGroupSeriesCount,
        options: {
          ...old.options,
          labels: customerDataFormat?.driverGroupLables,
          colors: PIE_CHART_COLORS,
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false,
                },
              },
            },
          },
        },
        totalSum: customerDataFormat.totalHelperSum || 0,
      };
    });
  }, [customerDataFormat]);

  const innerCardCustomer: InnerChartCardData[] = useMemo(() => {
    return [
      {
        data: customerData.totalSum,
        type: 'display',
        title: 'Total Customers',
      },
    ];
  }, [customerData]);

  const innerCardDriver: InnerChartCardData[] = useMemo(() => {
    return [
      {
        data: driverData.totalSum,
        type: 'display',
        title: 'Total Drivers',
      },
    ];
  }, [driverData]);
  const innerCardDriverGroup: InnerChartCardData[] = useMemo(() => {
    return [
      {
        data: driverGroupData.totalSum,
        type: 'display',
        title: 'Total Helpers',
      },
    ];
  }, [driverGroupData]);

  return {
    customerData,
    innerCardCustomer,
    driverData,
    innerCardDriver,
    driverGroupData,
    innerCardDriverGroup,
  };
};
