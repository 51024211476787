import { Resolution } from 'modules/Dashboard/DashboardPage/types';

export const resolveUrl = (
  route: string,
  startDate: string | null,
  endDate: string | null,
  resolution?: Resolution,
) => {
  let url = route;

  if (startDate) {
    url = `${route}?startDateTime=${startDate}`;

    if (endDate) {
      url = `${route}?startDateTime=${startDate}&endDateTime=${endDate}`;
    }
  }

  if (startDate) {
    url = `${url}&resolution=${resolution}`;
  }
  return url;
};
