import { useNavigate } from 'react-router-dom';

import {
  Button,
  Container,
  Header,
  Divider,
  FormItem,
  Input,
  ImageUpload,
  Textarea,
  CurrencyInput,
  Checkbox,
} from 'components';

import { useTrailerPage } from './hooks';

export const TrailerPage = () => {
  const { isCreate, formState, onSubmit, register, control, trailerLoading } = useTrailerPage();
  const navigate = useNavigate();

  return (
    <>
      <Header title={isCreate ? 'New trailer' : 'Edit trailer'} onGoBackPress={() => navigate(-1)}>
        <Button type="submit" onClick={onSubmit} label="Save" form="trailerForm" disabled={formState.isSubmitting} />
      </Header>

      <Container isLoading={trailerLoading}>
        <div className="overflow-hidden bg-white rounded-lg shadow">
          <div className="px-4 py-5 sm:p-6">
            <form className="space-y-10" onSubmit={onSubmit} noValidate id="trailerForm">
              <Divider title="Trailer information" />

              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <FormItem label="Name" htmlFor="name" error={formState.errors.title?.message} required>
                  <Input
                    {...register('title')}
                    id="title"
                    type="text"
                    placeholder="Enter name"
                    required
                    error={!!formState.errors.title?.message}
                    disabled={formState.isSubmitting}
                  />
                </FormItem>

                <FormItem
                  label="Booking fee"
                  htmlFor="bookingFee"
                  error={formState.errors.bookingFee?.message}
                  required
                >
                  <CurrencyInput
                    control={control}
                    name="bookingFee"
                    id="bookingFee"
                    placeholder="Enter booking fee"
                    required
                    error={!!formState.errors.bookingFee?.message}
                    disabled={formState.isSubmitting}
                  />
                </FormItem>
                <FormItem label="Description" htmlFor="description" error={formState.errors.description?.message}>
                  <Textarea
                    {...register('description')}
                    id="description"
                    placeholder="Enter first description"
                    autoComplete="description"
                    className="h-28"
                    error={!!formState.errors.description?.message}
                    disabled={formState.isSubmitting}
                  />
                </FormItem>

                <ImageUpload
                  name="trailerImageFile"
                  control={control}
                  label="Image"
                  error={formState.errors.trailerImageFile?.url?.message}
                />
              </div>
              <Checkbox
                {...register('showInApp')}
                id="showInApp"
                label="Is this trailer visible on mobile app for customers?"
                name="showInApp"
              />
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};
