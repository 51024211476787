import { WeightPrice } from 'services/weightPrices';

import { WeightPriceForm } from '../types';

export const weightPriceToWeightPriceForm = (merchant: WeightPrice): WeightPriceForm => {
  return {
    id: merchant.id,
    weightLbsFrom: merchant.weightLbsFrom,
    weightLbsTo: merchant.weightLbsTo,
    weightFee: merchant.weightFee,
  };
};
