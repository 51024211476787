import { api } from 'services';
import { DataWithMeta, PaginationFilters, PaginationRequest, paginationRequestToUrl } from 'helpers';
import { DriverForm } from 'modules/Drivers/types';
import { SmsNotificationsForm } from 'modules/SmsNotifications/types';

import { driverToPostReq, driverToPutReq, resToDriver, transformToDriversFilters } from './transformations';
import { Driver } from './types';
import { uploadImages } from '../images';

export const fetchDrivers = async (pagination: PaginationRequest): Promise<DataWithMeta<Driver>> => {
  const url = paginationRequestToUrl('/drivers', pagination, transformToDriversFilters);

  const resp = await api.get(url);
  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToDriver),
  };
};

export const fetchDriver = async (id: string): Promise<Driver> => {
  const resp = await api.get(`/drivers/${id}`);
  return resToDriver(resp.data);
};

export const putDriver = async (data: DriverForm): Promise<Driver> => {
  const images = await uploadImages({
    driversLicenseFile: data.driversLicenseFile,
    profilePictureFile: data.profilePictureFile,
    vehicleInsuranceFile: data.vehicleInsuranceFile,
    vehicleRegistrationFile: data.vehicleRegistrationFile,
  });

  const resp = await api.put(`/drivers/${data.id}`, driverToPutReq({ ...data, ...images }));
  return resToDriver(resp.data);
};

export const postDriver = async (data: any): Promise<Driver> => {
  const images = await uploadImages({
    driversLicenseFile: data.driversLicenseFile,
    profilePictureFile: data.profilePictureFile,
    vehicleInsuranceFile: data.vehicleInsuranceFile,
    vehicleRegistrationFile: data.vehicleRegistrationFile,
  });

  const resp = await api.post(`/drivers`, driverToPostReq({ ...data, ...images }));
  return resToDriver(resp.data);
};

export const postSmsNotifications = async (data: SmsNotificationsForm): Promise<Driver> => {
  const resp = await api.post(`/drivers/notifications`, data);
  return resToDriver(resp.data);
};

export const deleteDriver = async (id: string): Promise<Driver> => {
  const resp = await api.delete(`/drivers/${id}`);
  return resToDriver(resp.data);
};

export const approveDriver = async (id: string): Promise<Driver> => {
  const resp = await api.put(`/drivers/${id}/status`, { status: 'approved' });
  return resToDriver(resp.data);
};

export const denyDriver = async (id: string): Promise<Driver> => {
  const resp = await api.put(`/drivers/${id}/status`, { status: 'denied' });
  return resToDriver(resp.data);
};

export const exportDrivers = async (filters: PaginationFilters, search: string): Promise<any> => {
  const url = paginationRequestToUrl(
    '/drivers/export',
    { page: 1, pageSize: 999, filters, search },
    transformToDriversFilters,
  );

  const resp = await api.post(url);

  return resp.data;
};
