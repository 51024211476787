import { PageType } from 'helpers';
import { initialImage } from 'services/images/consts';
import { TrailerFormData } from 'services/trailers';

export const defaultTrailerForm: TrailerFormData = {
  id: PageType.Create,
  title: '',
  description: '',
  bookingFee: null,
  trailerImageFile: initialImage,
  driverGroupId: null,
  showInApp: false,
};
