import * as yup from 'yup';
import { ReviewStatus } from 'services/reviews/consts';
import { DriverReviewForm } from './DriverReviewPage/types';

export const defaultDriverReviewForm: DriverReviewForm = {
  id: '',
  driverId: '',
  customerId: '',
  orderId: '',
  score: null,
  description: '',
  status: ReviewStatus.PENDING,
  firstName: '',
  lastName: '',
};

export const DRIVER_REVIEW_VALIDATION_SCHEMA = yup.object({
  score: yup
    .number()
    .typeError('Score is required')
    .min(0, 'Score must be 1 or greater')
    .max(5, 'Score must be 5 or less')
    .required('Score is required'),
  firstName: yup.string().required('Field is required'),
  lastName: yup.string().required('Field is required'),
  status: yup.string().required('Status is required').nullable(),
  orderId: yup.string().required('OrderId is required'),
  description: yup.string(),
});
