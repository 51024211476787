export enum ReviewStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  DENIED = 'denied',
}

export interface PutReview {
  id: string;
  status: ReviewStatus;
}
