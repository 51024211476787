import { numberFormatter } from 'helpers';
import { InnerChartCardData } from 'modules/Dashboard/DashboardPage/types';
import { useEffect, useMemo, useState } from 'react';

import { useReportingOrder } from 'services/reporting';

import { useDashboardPageContext } from '../../DashboardPageProvider';
import { ChartType } from '../types';
import { initialTotalOrdersByStatusData } from './TotalOrdersByStatusChart/consts';
import { initialTotalOrdersGroupData } from './TotalOrdersDriverGroupChart/consts';
import { initialTotalOrderTimeData } from './TotalOrdersOverTimeChart/consts';
import { resolveLabels } from '../../helpers';
import { PIE_CHART_COLORS } from '../../consts';

export const useTotalOrderCharts = () => {
  const { startDate, endDate, resolution } = useDashboardPageContext();
  const { data: dataByOrder } = useReportingOrder({ startDate, endDate, resolution });

  const [totalOrdersGroupData, setTotalOrdersGroupData] = useState<ChartType>(initialTotalOrdersGroupData);
  const [totalOrdersTimeData, setTotalOrdersTimeData] = useState<ChartType>(initialTotalOrderTimeData);
  const [totalOrdersByStatusData, setTotalOrdersByStatusData] = useState<ChartType>(initialTotalOrdersByStatusData);

  const totalOrdersTimeDataFormat = useMemo(() => {
    const series = dataByOrder?.ordersOverTime.map((item) => item.orderCount) || [];
    const labels =
      dataByOrder?.ordersOverTime.map((item) => ({
        day: item.day,
        week: item.week,
        month: item.month,
        year: item.year,
        totalOrders: item.orderCount,
      })) || [];

    return {
      series,
      labels,
    };
  }, [dataByOrder]);

  const totalOrdersGroupFormat = useMemo(() => {
    const series = dataByOrder?.driverGroupOrders.map((item) => item.orderCount) || [];
    const labels = dataByOrder?.driverGroupOrders.map((item) => item.driverGroupName) || [];

    return {
      series,
      labels,
    };
  }, [dataByOrder]);

  const totalOrdersByStatusFormat = useMemo(() => {
    const series = dataByOrder?.statusOrders.map((item) => item.orderCount) || [];
    const labels = dataByOrder?.statusOrders.map((item) => item.status) || [];

    return {
      series,
      labels,
    };
  }, [dataByOrder]);

  useEffect(() => {
    setTotalOrdersTimeData((old) => {
      return {
        series: [
          {
            name: 'Total Orders',
            data: totalOrdersTimeDataFormat.series,
          },
        ],
        options: {
          ...old.options,
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: totalOrdersTimeDataFormat.labels.map((item) => resolveLabels(item, resolution)),
          },
        },
      };
    });
  }, [totalOrdersTimeDataFormat, resolution]);

  useEffect(() => {
    setTotalOrdersGroupData((old) => {
      return {
        series: totalOrdersGroupFormat?.series,
        options: {
          ...old.options,
          labels: totalOrdersGroupFormat?.labels,
          colors: PIE_CHART_COLORS,
          tooltip: {
            y: {
              formatter: (val: number) => numberFormatter(val),
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false,
                },
              },
            },
          },
        },
      };
    });
  }, [totalOrdersGroupFormat]);

  useEffect(() => {
    setTotalOrdersByStatusData((old) => {
      return {
        series: totalOrdersByStatusFormat?.series,
        options: {
          ...old.options,
          labels: totalOrdersByStatusFormat?.labels,
          colors: PIE_CHART_COLORS,
          tooltip: {
            y: {
              formatter: (val: number) => numberFormatter(val),
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false,
                },
              },
            },
          },
        },
      };
    });
  }, [totalOrdersByStatusFormat]);

  const innerCardTotalOrders: InnerChartCardData[] = useMemo(() => {
    return [
      {
        data: dataByOrder?.totalNumberOfOrders,
        type: 'display',
        title: 'Total Orders',
      },
    ];
  }, [dataByOrder]);

  return { totalOrdersGroupData, totalOrdersTimeData, totalOrdersByStatusData, innerCardTotalOrders };
};
