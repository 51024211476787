import { PageType } from 'helpers';

import { UserForm } from './types';

export const defaultUserForm: UserForm = {
  id: PageType.Create,
  email: '',
  password: '',
  confirmPassword: '',
  firstName: '',
  lastName: '',
  isDriver: false,
  isCustomer: false,
  isMerchant: false,
  isAdmin: true,
};
