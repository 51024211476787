import { CellProps } from 'react-table';

import { TableColumn, Tooltip } from 'components';
import { shortenId } from 'helpers';
import { StopPrice } from 'services/stopPrices';

export const columns = (deleteClicked: (rowData: StopPrice) => void): TableColumn<StopPrice>[] => [
  {
    Header: 'ID',
    accessor: 'id',
    isSortable: true,
    Cell: ({ row }: CellProps<StopPrice>) => {
      const rowData = row.original;
      return (
        <Tooltip
          popoverMessage="Copied!"
          tooltipMessage={rowData.id}
          onClick={() => navigator.clipboard.writeText(rowData.id)}
        >
          {shortenId(rowData.id)}
        </Tooltip>
      );
    },
  },
  {
    Header: 'Stop From',
    accessor: 'stopsFrom' as any,
    isSortable: true,
  },
  {
    Header: 'Stop To',
    accessor: 'stopsTo' as any,
    isSortable: true,
  },
  {
    Header: 'Price Factor',
    accessor: 'shippingFactor' as any,
    isSortable: true,
  },
  {
    Header: 'First helper factor',
    accessor: 'firstHelperFactor' as any,
    isSortable: true,
  },
  {
    Header: 'Second helper factor',
    accessor: 'secondHelperFactor' as any,
    isSortable: true,
  },
  {
    id: 'deleteButton',
    Header: '',
    Cell: ({ row }: CellProps<StopPrice>) => {
      const rowData = row.original;

      return (
        <div className="flex justify-end">
          <button
            type="button"
            className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={(ev) => {
              ev.stopPropagation();
              deleteClicked(rowData);
            }}
          >
            Delete
          </button>
        </div>
      );
    },
    width: 'auto',
  },
];
