import { PageType } from 'helpers';
import { Trailer, TrailerFormData } from './types';

export const resToTrailer = (res: any): Trailer => {
  return {
    id: res.id,
    title: res.title ?? '',
    trailerImageFileId: res.trailerImageFileId ?? '',
    trailerImageFileUrl: res.trailerImageFileUrl ?? '',
    bookingFee: res.bookingFee ?? 0,
    description: res.description ?? '',
    driverGroupId: res.driverGroupId ?? null,
    showInApp: res.showInApp ?? false,
  };
};

export const trailerToReq = (trailer: TrailerFormData): any => {
  const payload: any = {
    title: trailer.title,
    bookingFee: trailer.bookingFee,
    description: trailer.description,
    driverGroupId: trailer.driverGroupId,
    showInApp: trailer.showInApp,
  };
  if (trailer.trailerImageFile.id !== PageType.Create) {
    payload.trailerImageFileId = trailer.trailerImageFile.id;
  }

  return payload;
};

export const transformToTrailerFilters = (filters: any): any => {
  return {
    ...filters,
  };
};
