import * as yup from 'yup';

export const ORDER_VALIDATION_SCHEMA = yup.object({
  categoryId: yup.string().required('Name is required'),
  customerId: yup.string().required('Customer is required'),
  driverGroupId: yup.string().required('Driver group is required'),
  loadHelperCount: yup.number().required('Load helper count is required'),
  unloadHelperCount: yup.number().required('Unload helper count is required'),
  weight: yup.number().required('Weight is required'),
  length: yup.string().required('Length is required'),
  width: yup.string().required('Width is required'),
  height: yup.string().required('Height is required'),
  tipOverride: yup.number().required('Tip override is required'),
  startDateTime: yup.string().required('Start date time is required').nullable(),
  startDateTimeClientTimeZone: yup.string().required('Start date time client time zone is required'),
  paymentType: yup.string().required('Payment type is required'),
  deviceType: yup.string().required('Device type is required'),
  stops: yup.array(
    yup.object({
      contactName: yup.string().required('Sender name is required'),
      contactPhone: yup.string().required('Sender phone is required'),
      pickupNote: yup.string().required('Pickup note is required'),
      driverDeliveryNote: yup.string().required('Delivery note is required'),
      itemsToBeDeliveredNote: yup.string().required('Items to be delivered note is required'),
    }),
  ),
  itemsDescription: yup.string().required('Items description is required'),
  pickupType: yup.string().required('Pickup type is required'),
  deliveryWindow: yup.string().required('Delivery window is required'),
  pickupTimeFrom: yup.string().required('Pickup time from is required'),
  pickupTimeUntil: yup.string().required('Pickup time until is required'),
  completedAt: yup.string().required('Completed at is required').nullable(),
  pickupLocationDescription: yup.string().required('Pickup location description is required'),
  deliveryLocationDescription: yup.string().required('Delivery location description is required'),
});
