import { toast } from 'react-toastify';

export enum MessageType {
  Success,
  Error,
  Warning,
}

export const showMessage = (msg: string, type: MessageType) => {
  switch (type) {
    case MessageType.Success:
      toast.success(msg);
      return;
    case MessageType.Error: {
      if (msg.length > 100 || msg.length === 0) {
        toast.error('Something went wrong!');
      } else {
        toast.error(msg);
      }
      return;
    }
    case MessageType.Warning:
      toast.warning(msg);
      break;
    default:
  }
};
