import { CellProps } from 'react-table';
import { Control } from 'react-hook-form';

import { Select, TableColumn, Tooltip } from 'components';
import { formatDate, formatPrice, shortenId } from 'helpers';
import { Order, PaymentStatus, PaymentType } from 'services/orders';

import { OrderRowForm } from './types';
import { PaymentStatusIndicator } from './components';

export const columns = (
  control: Control<OrderRowForm, any>,
  onPaymentStatusChange: (id: string, paymentStatus: PaymentStatus) => void,
): TableColumn<Order>[] => [
  {
    Header: 'ID',
    accessor: 'id',
    isSortable: true,
    Cell: ({ row }: CellProps<Order>) => {
      const rowData = row.original;
      return (
        <Tooltip
          popoverMessage="Copied!"
          tooltipMessage={rowData.id}
          onClick={() => navigator.clipboard.writeText(rowData.id)}
        >
          {shortenId(rowData.id)}
        </Tooltip>
      );
    },
  },
  {
    Header: 'Date',
    accessor: 'createdAt' as any,
    isSortable: true,
    Cell: ({ row }: CellProps<Order>) => {
      const rowData = row.original;
      const date = rowData.createdAt ? formatDate(new Date(rowData.createdAt)) : '';

      return <>{date}</>;
    },
  },
  {
    Header: 'Delivery Date',
    accessor: 'startDateTime' as any,
    isSortable: true,
    Cell: ({ row }: CellProps<Order>) => {
      const rowData = row.original;
      const date = rowData.startDateTime ? formatDate(new Date(rowData.startDateTime)) : '';

      return <>{date}</>;
    },
  },
  {
    Header: 'Customer',
    accessor: 'customer.name' as any,
    isSortable: false,
    Cell: ({ row }: CellProps<Order>) => {
      const rowData = row.original;

      if (!rowData.customer) {
        return <></>;
      }

      return <>{`${rowData.customer.userFirstName} ${rowData.customer.userLastName}`}</>;
    },
  },
  {
    Header: 'Driver',
    accessor: 'driver.name' as any,
    isSortable: false,
    Cell: ({ row }: CellProps<Order>) => {
      const rowData = row.original;

      if (!rowData.driver) {
        return <></>;
      }

      return <>{`${rowData.driver.userFirstName} ${rowData.driver.userLastName}`}</>;
    },
  },
  {
    Header: 'Merchant',
    accessor: 'merchant.businessName' as any,
    isSortable: false,
    Cell: ({ row }: CellProps<Order>) => {
      const rowData = row.original;

      if (!rowData.merchant) {
        return <></>;
      }

      return <>{rowData.merchant.businessName}</>;
    },
  },
  {
    Header: 'Pickup Address',
    accessor: 'pickupAddress' as any,
    isSortable: false,
    Cell: ({ row }: CellProps<Order>) => {
      const rowData = row.original;
      return <>{rowData.pickupAddress}</>;
    },
  },
  {
    Header: 'Delivery Address',
    accessor: 'deliveryAddresses' as any,
    isSortable: false,
    Cell: ({ row }: CellProps<Order>) => {
      const rowData = row.original;
      const { deliveryAddresses } = rowData;

      if (deliveryAddresses.length === 0) {
        return <></>;
      }

      return (
        <Tooltip tooltipMessage={deliveryAddresses.join('<br/>')}>{`${deliveryAddresses[0]} ${
          deliveryAddresses.length > 1 ? `(+${deliveryAddresses.length - 1} more)` : ''
        }`}</Tooltip>
      );
    },
  },
  {
    Header: 'Invoiced?',
    accessor: 'invoiced' as any,
    isSortable: false,
    Cell: ({ row }: CellProps<Order>) => {
      const rowData = row.original;
      const { paymentType } = rowData;

      return <>{paymentType === PaymentType.Invoiced ? 'Yes' : 'No'}</>;
    },
  },
  {
    Header: 'Payment Status',
    accessor: 'paymentStatus' as any,
    isSortable: false,
    Cell: ({ row }: CellProps<Order>) => {
      const { index, original: rowData } = row;
      const { paymentStatus, paymentType, id } = rowData;

      if (paymentType !== PaymentType.Invoiced) {
        return <PaymentStatusIndicator status={paymentStatus} />;
      }

      return (
        <Select
          options={Object.values(PaymentStatus)}
          control={control}
          name={`orders[${index}].paymentStatus`}
          onChange={(status) => onPaymentStatusChange(id, status)}
          removeClear
        />
      );
    },
  },
  {
    Header: 'Amount',
    accessor: 'amount' as any,
    isSortable: false,
    Cell: ({ row }: CellProps<Order>) => {
      const rowData = row.original;
      return <>{rowData.finalPrice ? formatPrice(rowData.finalPrice) : ''}</>;
    },
  },
  {
    Header: 'Status',
    accessor: 'orderStatus' as any,
    isSortable: true,
  },
];
