import { AddressFormData } from 'services/addresses';
import { OrderFormData, OrderStopFormData } from 'services/orders';

export const defaultAddress: AddressFormData = {
  city: '',
  country: '',
  lat: 0,
  lng: 0,
  state: '',
  street1: '',
  street2: '',
  zip: '',
};

export const defaultOrderStopForm: OrderStopFormData = {
  address: defaultAddress,
  addressName: '',
  pickupNote: '',
  driverDeliveryNote: '',
  itemsToBeDeliveredNote: '',
  contactName: '',
  contactPhone: '',
  stopNumber: 1,
};

export const defaultOrderForm: OrderFormData = {
  customerId: '',
  driverGroupId: '',
  driverId: '',
  merchantId: '',
  promoCodeId: '',
  loadHelperCount: 0,
  unloadHelperCount: 0,
  weight: 0,
  length: '',
  width: '',
  height: '',
  tipOverride: 0,
  startDateTime: null,
  startDateTimeClientTimeZone: '',
  paymentType: '',
  deviceType: '',
  stops: [defaultOrderStopForm],
  images: [],
  itemsDescription: '',
  pickupType: '',
  deliveryWindow: '',
  pickupTimeFrom: '',
  pickupTimeUntil: '',
  merchantOrderNumber: '',
  completedAt: null,
  trailerId: '',
  categoryId: '',
  pickupLocationDescription: '',
  deliveryLocationDescription: '',
  itemCost: 0,
};
