/* eslint-disable jsx-a11y/label-has-associated-control */
import { PlusIcon } from '@heroicons/react/outline';
import { Button, ConfirmationModal, Container, Header, Table } from 'components';
import { HeaderSearch } from 'components/Header/HeaderSearch';
import { DriverGroup } from 'services/driverGroups';

import { useDriverGroupsPage } from './DriverGroupsPageProvider';
import { columns } from './const';

export const DriverGroupsPage = () => {
  const {
    driverGroups,
    metaData,
    currentPage,
    isLoading,
    search,
    driverGroupToDelete,
    openConfirmationModal,
    closeConfirmationModal,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onRowClick,
    onDeleteConfirm,
    onNewClick,
    onSortChange,
  } = useDriverGroupsPage();

  return (
    <>
      <Header title="Driver groups">
        <div className="flex justify-between flex-1">
          <HeaderSearch onChange={(e: any) => onSearchChange(e.target.value)} value={search} />

          <Button onClick={onNewClick} label="New driver group" icon={<PlusIcon />} />
        </div>
      </Header>

      <Container>
        <Table<DriverGroup>
          data={driverGroups ?? []}
          columns={columns(openConfirmationModal)}
          pagination={metaData}
          currentPage={currentPage}
          isLoading={isLoading}
          onRowClick={onRowClick}
          pageClicked={onPageChange}
          pageSizeClicked={onPageSizeChange}
          headerClicked={onSortChange}
        />
      </Container>

      <ConfirmationModal
        title="Delete driver group"
        body="Are you sure you want to delete this driver group?"
        open={!!driverGroupToDelete}
        onAction={onDeleteConfirm}
        onClose={closeConfirmationModal}
      />
    </>
  );
};
