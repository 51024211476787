import { DataWithMeta, PaginationRequest } from 'helpers';
import { DistancePriceForm } from 'modules/DistancePrices/types';

import { api } from '../api';
import { distancePriceToPostReq, distancePriceToPutReq, resToDistancePrice } from './transformations';
import { DistancePrice } from './types';

export const fetchDistancePrices = async (pagination: PaginationRequest): Promise<DataWithMeta<DistancePrice>> => {
  let url = `/shipping_distance_prices?page=${pagination.page}&pageSize=${pagination.pageSize}`;

  if (pagination.sortBy) url += `&sortBy=${pagination.sortBy}`;
  if (pagination.sortDirection) url += `&sortDirection=${pagination.sortDirection}`;
  if (pagination.search) url += `&search=${pagination.search}`;

  const resp = await api.get(url);
  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToDistancePrice),
  };
};

export const fetchDistancePrice = async (id: string): Promise<DistancePrice> => {
  const resp = await api.get(`/shipping_distance_prices/${id}`);
  return resToDistancePrice(resp.data);
};

export const putDistancePrice = async (data: DistancePriceForm): Promise<DistancePrice> => {
  const resp = await api.put(`/shipping_distance_prices/${data.id}`, distancePriceToPutReq(data));
  return resToDistancePrice(resp.data);
};

export const postDistancePrice = async (data: DistancePriceForm): Promise<DistancePrice> => {
  const resp = await api.post(`/shipping_distance_prices`, distancePriceToPostReq(data));
  return resToDistancePrice(resp.data);
};

export const deleteDistancePrice = async (id: string): Promise<DistancePrice> => {
  const resp = await api.delete(`/shipping_distance_prices/${id}`);
  return resToDistancePrice(resp.data);
};
