import * as yup from 'yup';

export const DRIVER_GROUP_VALIDATION_SCHEMA = yup.object({
  name: yup.string().required('Name is required'),
  bookingFee: yup
    .number()
    .typeError('Booking fee is required')
    .min(0, 'Booking fee must be 0 or greater')
    .required('Booking fee is required'),
});
