import { FormItem, Input, MultipleSelect, Select } from 'components';
import { DatePicker } from 'components/DatePicker';

import { useDriverPage } from '../../DriverPageProvider';
import { ExpectedAvailability } from './consts';

export const VehicleInformation = () => {
  const { formState, register, control, formattedDriverGroupsData } = useDriverPage();

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <FormItem
        label="Driver's License Number"
        htmlFor="driverLicenseNumber"
        error={formState.errors.driversLicenseNumber?.message}
        required
      >
        <Input
          {...register('driversLicenseNumber')}
          id="driversLicenseNumber"
          type="text"
          placeholder="Enter driver's license number"
          autoComplete="driversLicenseNumber"
          required
          error={!!formState.errors.driversLicenseNumber?.message}
        />
      </FormItem>

      <FormItem
        label="Driver's License State"
        htmlFor="driversLicenseState"
        error={formState.errors.driversLicenseState?.message}
        required
      >
        <Input
          {...register('driversLicenseState')}
          id="driversLicenseState"
          type="text"
          placeholder="Enter driver's license state"
          autoComplete="driversLicenseState"
          required
          error={!!formState.errors.driversLicenseState?.message}
        />
      </FormItem>

      <FormItem
        label="Driver's License Expiration Date"
        htmlFor="driversLicenseExpirationDate"
        error={formState.errors.driversLicenseExpirationDate?.message}
        required
      >
        <DatePicker
          control={control}
          name="driversLicenseExpirationDate"
          error={!!formState.errors.driversLicenseExpirationDate?.message}
        />
      </FormItem>

      <FormItem label="Vehicle Color" htmlFor="vehicleColor" error={formState.errors.vehicleColor?.message} required>
        <Input
          {...register('vehicleColor')}
          id="vehicleColor"
          type="text"
          placeholder="Enter vehicle color"
          autoComplete="vehicleColor"
          required
          error={!!formState.errors.vehicleColor?.message}
        />
      </FormItem>

      <FormItem label="Vehicle Make" htmlFor="vehicleMake" error={formState.errors.vehicleMake?.message} required>
        <Input
          {...register('vehicleMake')}
          id="vehicleMake"
          type="text"
          placeholder="Enter vehicle make"
          autoComplete="vehicleMake"
          required
          error={!!formState.errors.vehicleMake?.message}
        />
      </FormItem>

      <FormItem label="Vehicle Model" htmlFor="vehicleModel" error={formState.errors.vehicleModel?.message} required>
        <Input
          {...register('vehicleModel')}
          id="vehicleModel"
          type="text"
          placeholder="Enter vehicle model"
          autoComplete="vehicleModel"
          required
          error={!!formState.errors.vehicleModel?.message}
        />
      </FormItem>

      <FormItem label="Vehicle Year" htmlFor="vehicleYear" error={formState.errors.vehicleYear?.message} required>
        <Input
          {...register('vehicleYear')}
          id="vehicleYear"
          type="number"
          placeholder="Enter vehicle year"
          autoComplete="vehicleYear"
          required
          error={!!formState.errors.vehicleYear?.message}
        />
      </FormItem>

      <FormItem label="Driver Groups" htmlFor="driverGroupIds" error={formState.errors.driverGroupIds?.message}>
        <MultipleSelect
          options={formattedDriverGroupsData}
          control={control}
          name="driverGroupIds"
          error={!!formState.errors.driverGroupIds?.message}
          placeholder="Select driver groups"
        />
      </FormItem>

      <FormItem
        label="How far are you willing to drive?"
        htmlFor="maximumDrivingDistanceMiles"
        error={formState.errors.maximumDrivingDistanceMiles?.message}
        required
      >
        <Input
          {...register('maximumDrivingDistanceMiles')}
          id="maximumDrivingDistanceMiles"
          type="number"
          placeholder="Enter miles"
          autoComplete="maximumDrivingDistanceMiles"
          required
          error={!!formState.errors.maximumDrivingDistanceMiles?.message}
        />
      </FormItem>

      <FormItem
        label="Expected availability"
        htmlFor="expectedAvailabilityId"
        error={formState.errors.expectedAvailabilityId?.message}
      >
        <Select options={Object.values(ExpectedAvailability)} control={control} name="expectedAvailabilityId" />
      </FormItem>

      <FormItem label="Description" htmlFor="description" error={formState.errors.description?.message}>
        <Input
          {...register('description')}
          id="description"
          type="text"
          placeholder="Enter description"
          autoComplete="description"
          required
          error={!!formState.errors.description?.message}
        />
      </FormItem>

      <FormItem
        label="Order Payment (%)"
        htmlFor="orderPaymentPercentage"
        error={formState.errors.orderPaymentPercentage?.message}
        required
      >
        <Input
          {...register('orderPaymentPercentage')}
          id="orderPaymentPercentage"
          type="number"
          placeholder="Enter order payment"
          autoComplete="orderPaymentPercentage"
          required
          error={!!formState.errors.orderPaymentPercentage?.message}
        />
      </FormItem>

      <FormItem
        label="Driver Help Payment (%)"
        htmlFor="firstHelperPaymentPercentage"
        error={formState.errors.firstHelperPaymentPercentage?.message}
        required
      >
        <Input
          {...register('firstHelperPaymentPercentage')}
          id="firstHelperPaymentPercentage"
          type="number"
          placeholder="Enter driver help payment"
          autoComplete="firstHelperPaymentPercentage"
          required
          error={!!formState.errors.firstHelperPaymentPercentage?.message}
        />
      </FormItem>

      <FormItem
        label="Helper Payment (%)"
        htmlFor="secondHelperPaymentPercentage"
        error={formState.errors.secondHelperPaymentPercentage?.message}
        required
      >
        <Input
          {...register('secondHelperPaymentPercentage')}
          id="secondHelperPaymentPercentage"
          type="number"
          placeholder="Enter helper payment"
          autoComplete="secondHelperPaymentPercentage"
          required
          error={!!formState.errors.secondHelperPaymentPercentage?.message}
        />
      </FormItem>

      <FormItem label="Approve/Deny Note" htmlFor="approveDenyNote" error={formState.errors.approveDenyNote?.message}>
        <Input
          {...register('approveDenyNote')}
          id="approveDenyNote"
          type="text"
          placeholder="Enter approve/deny note"
          autoComplete="approveDenyNote"
          required
          error={!!formState.errors.approveDenyNote?.message}
        />
      </FormItem>
    </div>
  );
};
