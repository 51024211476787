/* eslint-disable react/jsx-boolean-value */
import { useState, useEffect } from 'react';
import clsx from 'clsx';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { Control, useController } from 'react-hook-form';

export const NumberInput = ({
  name,
  error,
  placeholder = '',
  control,
  required = false,
  prefix = '',
  decimalPlaces,
}: Props) => {
  const { field } = useController({
    control,
    name,
  });

  const [number, setNumber] = useState<number | null>();

  useEffect(() => {
    setNumber(field.value);
  }, [field.value]);

  return (
    <NumberFormat
      thousandSeparator={true}
      onValueChange={(e: NumberFormatValues) => {
        setNumber(e.floatValue);
        field.onChange(e.floatValue);
      }}
      value={number}
      decimalSeparator="."
      prefix={prefix}
      required={required}
      placeholder={placeholder}
      decimalScale={decimalPlaces}
      fixedDecimalScale={!!decimalPlaces}
      className={clsx(
        // eslint-disable-next-line max-len
        'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm',
        {
          'border-gray-300 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500': !error,
          'border-red-300 placeholder-red-400 focus:ring-red-500 focus:border-red-500': error,
        },
      )}
    />
  );
};

type Props = {
  name: string;
  decimalPlaces?: number;
  error?: boolean;
  placeholder?: string;
  control: Control<any, any>;
  required?: boolean;
  prefix?: string;
};
