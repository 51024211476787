import { api } from 'services';
import { User } from 'services/users';

export class LocalStorage {
  // Manage token
  static getToken = () => localStorage.getItem('token');

  static setToken = (token: string) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem('token', token);
  };

  static removeToken = () => {
    api.defaults.headers.common.Authorization = '';
    localStorage.removeItem('token');
  };

  // Manage user
  static getUser = (): User | null => {
    const stringUser = localStorage.getItem('user');
    if (!stringUser) {
      return null;
    }
    return JSON.parse(stringUser);
  };

  static setUser = (user: User) => localStorage.setItem('user', JSON.stringify(user));

  static removeUser = () => localStorage.removeItem('user');
}
