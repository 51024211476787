import { createContext, useContext, useMemo, useEffect } from 'react';
import { FormState, useForm, UseFormRegister, Control } from 'react-hook-form';
import { Outlet, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { MessageType, showMessage, useIdFromParams } from 'helpers';
import { fetchReview, putDriverReview } from 'services/reviews';
import { defaultDriverReviewForm, DRIVER_REVIEW_VALIDATION_SCHEMA } from '../consts';
import { DriverReviewForm } from './types';

import { driverReviewToDriverReviewForm } from './transformation';

const DriverReviewPageContext = createContext<{
  formState: FormState<DriverReviewForm>;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  register: UseFormRegister<DriverReviewForm>;
  control: Control<DriverReviewForm, any>;
}>({} as any);

export const DriverReviewPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id: driverReviewId } = useIdFromParams();

  const { data: review } = useQuery(['driverReview', driverReviewId], () => fetchReview(driverReviewId));

  const { handleSubmit, register, formState, reset, control } = useForm<DriverReviewForm>({
    defaultValues: defaultDriverReviewForm,
    resolver: yupResolver(DRIVER_REVIEW_VALIDATION_SCHEMA),
  });

  const driverReviewForm = useMemo(() => {
    if (review) {
      return driverReviewToDriverReviewForm(review);
    }
    return defaultDriverReviewForm;
  }, [review]);

  const { mutateAsync: updateDriverReview } = useMutation(putDriverReview);

  useEffect(() => {
    reset(driverReviewForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverReviewForm]);

  const onSubmit = handleSubmit(async (values: DriverReviewForm) => {
    try {
      await updateDriverReview(values);
      showMessage('Review successfully updated.', MessageType.Success);
      queryClient.invalidateQueries('driverReview');
      navigate('/reviews');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const providerValue = useMemo(
    () => ({
      register,
      formState,
      onSubmit,
      control,
    }),
    [register, formState, onSubmit, control],
  );

  return <DriverReviewPageContext.Provider value={providerValue}>{children}</DriverReviewPageContext.Provider>;
};

export const useDriverReviewPage = () => {
  return useContext(DriverReviewPageContext);
};

interface Props {
  children?: React.ReactNode;
}
