import { Container, Header, Table, ConfirmationModal } from 'components';
import { HeaderSearch } from 'components/Header/HeaderSearch';
import { Review } from 'services/reviews';

import { useDriverReviewPage } from './DriverReviewsPageProvider';

import { columns } from './const';

export const DriverReviewsPage = () => {
  const {
    driverReviews,
    metaData,
    reviewsLoading,
    search,
    currentPage,
    reviewToDelete,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    openConfirmationModal,
    closeConfirmationModal,
    onRowClick,
    onDeleteConfirm,
    onApproval,
  } = useDriverReviewPage();

  return (
    <>
      <Header title="Driver reviews">
        <div className="flex justify-between flex-1">
          <HeaderSearch onChange={(e: any) => onSearchChange(e.target.value)} value={search} />
        </div>
      </Header>

      <Container>
        <Table<Review>
          data={driverReviews ?? []}
          columns={columns(openConfirmationModal, onApproval)}
          pagination={metaData}
          currentPage={currentPage}
          isLoading={reviewsLoading}
          onRowClick={onRowClick}
          pageClicked={onPageChange}
          pageSizeClicked={onPageSizeChange}
          headerClicked={onSortChange}
        />
      </Container>

      <ConfirmationModal
        title={`Delete ${reviewToDelete?.customerFirstName || ''} review?`}
        body="Are you sure you want to delete review?"
        open={!!reviewToDelete}
        onAction={onDeleteConfirm}
        onClose={closeConfirmationModal}
      />
    </>
  );
};
