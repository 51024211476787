import { createContext, useContext, useMemo, useState, useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Control, useForm, UseFormRegister } from 'react-hook-form';

import { MessageType, showMessage, SortDirection, usePagination } from 'helpers';
import { fetchDriverGroups, DriverGroup, deleteDriverGroup } from 'services/driverGroups';

import { DriverGroupAdvancedSearchForm } from '../types';
import { defaultDriverGroupAdvancedSearchForm } from '../consts';

const DriverGroupsPageContext = createContext<{
  driverGroups: DriverGroup[];
  metaData: any;
  currentPage: number;
  search: string;
  isLoading: boolean;
  control: Control<DriverGroupAdvancedSearchForm, any>;
  driverGroupToDelete: any;
  register: UseFormRegister<DriverGroupAdvancedSearchForm>;
  onSearchChange: (search: string) => void;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onRowClick: (row: DriverGroup) => void;
  onSortChange: (accessor: string, sort: SortDirection) => void;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  onClearFilters: () => void;
  onNewClick: () => void;
  onDeleteConfirm: () => void;
  openConfirmationModal: (selectedDriverGroup: DriverGroup | null) => void;
  closeConfirmationModal: () => void;
}>({} as any);

// This will be used in case we state shared inside the module
export const DriverGroupsPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onFiltersChange,
  } = usePagination({ sortBy: 'position' });

  const { register, control, reset, handleSubmit } = useForm<DriverGroupAdvancedSearchForm>({
    defaultValues: defaultDriverGroupAdvancedSearchForm,
  });

  const [driverGroupToDelete, setDriverGroupToDelete] = useState<DriverGroup | null>(null);

  const { data: queryData, isLoading: driversLoading } = useQuery(
    ['driverGroups', currentPage, pageSize, sortBy, sortDirection, debouncedSearch, filters],
    () => fetchDriverGroups({ page: currentPage, pageSize, sortBy, sortDirection, search: debouncedSearch, filters }),
  );

  const { mutateAsync: removeDriverGroup } = useMutation(deleteDriverGroup);

  const driverGroups = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onRowClick = useCallback(
    (row: DriverGroup) => {
      navigate(`/driver-groups/${row.id}`);
    },
    [navigate],
  );

  const onSubmit = handleSubmit((data: DriverGroupAdvancedSearchForm) => {
    onFiltersChange(data as any);
  });

  const onClearFilters = useCallback(() => {
    reset(defaultDriverGroupAdvancedSearchForm);
    onSubmit();
  }, [reset, onSubmit]);

  const onNewClick = useCallback(() => {
    navigate('/driver-groups/new');
  }, [navigate]);

  const onDeleteConfirm = useCallback(async () => {
    if (driverGroupToDelete) {
      try {
        await removeDriverGroup(driverGroupToDelete.id);
        showMessage('Driver group successfully deleted.', MessageType.Success);
        queryClient.invalidateQueries('driverGroups');
      } catch (err) {
        showMessage((err as any).response.data.title || 'Something went wrong.', MessageType.Error);
      }
      setDriverGroupToDelete(null);
    }
  }, [driverGroupToDelete, queryClient, removeDriverGroup]);

  const openConfirmationModal = useCallback((selectedDriverGroup: DriverGroup | null) => {
    if (selectedDriverGroup) setDriverGroupToDelete(selectedDriverGroup);
  }, []);

  const closeConfirmationModal = useCallback(() => {
    setDriverGroupToDelete(null);
  }, []);

  const providerValue = useMemo(
    () => ({
      driverGroups,
      metaData,
      currentPage,
      search,
      isLoading: driversLoading,
      control,
      driverGroupToDelete,
      register,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onRowClick,
      onSortChange,
      onNewClick,
      onDeleteConfirm,
      openConfirmationModal,
      closeConfirmationModal,
      onSubmit,
      onClearFilters,
    }),
    [
      driverGroups,
      metaData,
      currentPage,
      search,
      driversLoading,
      control,
      driverGroupToDelete,
      register,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onRowClick,
      onSortChange,
      onSubmit,
      onClearFilters,
      onNewClick,
      onDeleteConfirm,
      openConfirmationModal,
      closeConfirmationModal,
    ],
  );

  return <DriverGroupsPageContext.Provider value={providerValue}>{children}</DriverGroupsPageContext.Provider>;
};

export const useDriverGroupsPage = () => {
  return useContext(DriverGroupsPageContext);
};

interface Props {
  children?: React.ReactNode;
}
