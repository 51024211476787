import { ChartCard } from 'components';
import { useUserReportingPage } from '../hooks';

export function TotalCustomerChart() {
  const { customerData, innerCardCustomer } = useUserReportingPage();

  return (
    <ChartCard
      title="New Customers"
      subtitle="Over Time"
      chartData={customerData}
      type="bar"
      innerCardData={innerCardCustomer}
      height="100%"
    />
  );
}
