import { useNavigate } from 'react-router-dom';

import { Button, Container, Header, Divider, FormItem, Input } from 'components';

import { useUserPage } from './UserPageProvider';

export const UserPage = () => {
  const { isCreate, onSubmit, register, formState } = useUserPage();
  const navigate = useNavigate();

  return (
    <>
      <Header title={isCreate ? 'New user' : 'Edit user'} onGoBackPress={() => navigate(-1)}>
        <Button type="submit" onClick={onSubmit} label="Save" form="userForm" />
      </Header>

      <Container>
        <div className="overflow-hidden bg-white rounded-lg shadow">
          <div className="px-4 py-5 sm:p-6">
            <form className="space-y-10" onSubmit={onSubmit} noValidate id="userForm">
              <Divider title="User information" />

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <FormItem label="First name" htmlFor="firstName" error={formState.errors.firstName?.message} required>
                  <Input
                    {...register('firstName')}
                    id="firstName"
                    type="text"
                    placeholder="Enter first name"
                    autoComplete="firstName"
                    required
                    error={!!formState.errors.firstName?.message}
                  />
                </FormItem>

                <FormItem label="Last name" htmlFor="lastName" error={formState.errors.lastName?.message} required>
                  <Input
                    {...register('lastName')}
                    id="lastName"
                    type="text"
                    placeholder="Enter last name"
                    autoComplete="off"
                    required
                    error={!!formState.errors.lastName?.message}
                  />
                </FormItem>

                <FormItem label="Email" htmlFor="email" error={formState.errors.email?.message} required>
                  <Input
                    {...register('email')}
                    id="email"
                    type="text"
                    placeholder="Enter email"
                    autoComplete="email"
                    required
                    error={!!formState.errors.email?.message}
                  />
                </FormItem>

                <FormItem
                  label="Password"
                  htmlFor="password"
                  error={formState.errors.password?.message}
                  required={isCreate}
                >
                  <Input
                    {...register('password')}
                    id="password"
                    type="password"
                    placeholder="Enter password"
                    autoComplete="off"
                    required
                    error={!!formState.errors.password?.message}
                  />
                </FormItem>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};
