import { createContext, useContext, useEffect, useMemo } from 'react';
import { Control, FormState, useForm, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';

import { MessageType, showMessage, useIdFromParams } from 'helpers';
import { fetchDriver, postDriver, putDriver } from 'services/drivers';
import { DriverGroup, fetchDriverGroups } from 'services/driverGroups';
import { fetchSetting } from 'services/settings';

import { getDriverValidationSchema } from './const';
import { DriverForm } from '../types';
import { driverToDriverForm } from './transformation';
import { getDefaultDriverForm } from '../const';

const DriverPageContext = createContext<{
  formState: FormState<DriverForm>;
  isCreate: boolean;
  formattedDriverGroupsData: {
    value: string;
    label: string;
  }[];
  control: Control<DriverForm, any>;
  driverGroups: DriverGroup[];
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  register: UseFormRegister<DriverForm>;
  watch: UseFormWatch<DriverForm>;
}>({} as any);

// This will be used in case we state shared inside the module
export const DriverPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id: driverId, isCreate } = useIdFromParams();

  const { data: driverGroups } = useQuery(['driverGroups'], () => fetchDriverGroups({ page: 1, pageSize: 100 }));
  const { data: driver } = useQuery(['drivers', driverId], () => fetchDriver(driverId), {
    enabled: !isCreate,
  });
  const { data: setting } = useQuery(['settings'], fetchSetting);
  const { mutateAsync: updateDriver } = useMutation(putDriver);
  const { mutateAsync: createDriver } = useMutation(postDriver);

  const { formState, reset, control, watch, handleSubmit, register } = useForm<DriverForm>({
    defaultValues: getDefaultDriverForm(setting),
    resolver: (data, context, options) => yupResolver(getDriverValidationSchema(data.isHelper))(data, context, options),
  });

  const driverForm = useMemo(() => {
    if (driver) {
      return driverToDriverForm(driver);
    }
    return getDefaultDriverForm(setting);
  }, [driver, setting]);

  const driverGroupsData = useMemo(() => driverGroups?.data || [], [driverGroups]);

  const formattedDriverGroupsData = useMemo(
    () => (driverGroupsData && driverGroupsData.map((group) => ({ value: group.id, label: group.name }))) || [],
    [driverGroupsData],
  );

  const driverGroupHelperId = useMemo(() => {
    const helperDriverGroup = driverGroupsData.find((group) => group.isHelper);

    if (helperDriverGroup) {
      return helperDriverGroup.id;
    }

    return '';
  }, [driverGroupsData]);

  useEffect(() => {
    reset(driverForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverForm]);

  const onSubmit = handleSubmit(async (values: DriverForm) => {
    if (isCreate) {
      try {
        await createDriver(values.isHelper ? { ...values, driverGroupIds: [driverGroupHelperId] } : values);
        showMessage('Driver successfully created.', MessageType.Success);
        queryClient.invalidateQueries('drivers');
        navigate('/drivers');
      } catch {
        showMessage('There has been an error!', MessageType.Error);
      }
      return;
    }

    try {
      await updateDriver(
        values.isHelper
          ? {
              ...values,
              driverGroupIds: [driverGroupHelperId],
            }
          : values,
      );
      showMessage('Driver successfully updated.', MessageType.Success);
      queryClient.invalidateQueries('drivers');
      navigate('/drivers');
    } catch {
      showMessage('There has been an error!', MessageType.Error);
    }
  });

  const providerValue = useMemo(
    () => ({
      formState,
      isCreate,
      formattedDriverGroupsData,
      control,
      driverGroups: driverGroupsData,
      onSubmit,
      register,
      watch,
    }),
    [formState, isCreate, formattedDriverGroupsData, control, driverGroupsData, watch, onSubmit, register],
  );

  return <DriverPageContext.Provider value={providerValue}>{children}</DriverPageContext.Provider>;
};

export const useDriverPage = () => {
  return useContext(DriverPageContext);
};

interface Props {
  children?: React.ReactNode;
}
