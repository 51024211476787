import { BulkOrder, BulkOrderForm } from './types';

export const defaultBulkOrderForm: BulkOrderForm = {
  merchantId: '',
  orders: [],
};

export const defaultBulkOrderRow: BulkOrder = {
  pickupName: '',
  pickupPhone: '',
  pickupAddress1: '',
  pickupAddress2: '',
  pickupCity: '',
  pickupState: '',
  pickupZip: '',
  pickupNote: '',
  deliveryName: '',
  deliveryPhone: '',
  deliveryAddress1: '',
  deliveryAddress2: '',
  deliveryCity: '',
  deliveryState: '',
  deliveryZip: '',
  deliveryNote: '',
  itemPickupDate: '',
  itemPickupTime: '',
  numOfLoadHelpers: 0,
  numOfUnloadHelpers: 0,
  deliveryTypeId: '',
  itemDetails: '',
  merchantOrderNumber: '',
};
