import { DataWithMeta, PaginationRequest } from 'helpers';

import { api } from '../api';
import { resToStopPrice, stopPriceToPostReq, stopPriceToPutReq } from './transformations';
import { StopPrice } from './types';

export const fetchStopPrices = async (pagination: PaginationRequest): Promise<DataWithMeta<StopPrice>> => {
  let url = `/shipping_stop_prices?page=${pagination.page}&pageSize=${pagination.pageSize}`;

  if (pagination.sortBy) url += `&sortBy=${pagination.sortBy}`;
  if (pagination.sortDirection) url += `&sortDirection=${pagination.sortDirection}`;
  if (pagination.search) url += `&search=${pagination.search}`;

  const resp = await api.get(url);
  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToStopPrice),
  };
};

export const fetchStopPrice = async (id: string): Promise<StopPrice> => {
  const resp = await api.get(`/shipping_stop_prices/${id}`);
  return resToStopPrice(resp.data);
};

export const putStopPrice = async (data: any): Promise<StopPrice> => {
  const resp = await api.put(`/shipping_stop_prices/${data.id}`, stopPriceToPutReq(data));
  return resToStopPrice(resp.data);
};

export const postStopPrice = async (data: any): Promise<StopPrice> => {
  const resp = await api.post(`/shipping_stop_prices`, stopPriceToPostReq(data));
  return resToStopPrice(resp.data);
};

export const deleteStopPrice = async (id: string): Promise<StopPrice> => {
  const resp = await api.delete(`/shipping_stop_prices/${id}`);
  return resToStopPrice(resp.data);
};
