import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { MessageType, showMessage, useIdFromParams } from 'helpers';
import { TrailerFormData, useTrailer, useTrailerMutations } from 'services/trailers';

import { TRAILER_VALIDATION_SCHEMA } from './validations';
import { trailerToTrailerForm } from './transformation';
import { defaultTrailerForm } from './consts';

export const useTrailerPage = () => {
  const navigate = useNavigate();
  const { id: trailerId, isCreate } = useIdFromParams();

  const { data: trailer, isLoading: trailerLoading } = useTrailer({ id: trailerId, isCreate });
  const { addTrailer, editTrailer } = useTrailerMutations();

  const { formState, control, handleSubmit, register, reset } = useForm<TrailerFormData>({
    defaultValues: defaultTrailerForm,
    resolver: yupResolver(TRAILER_VALIDATION_SCHEMA),
  });

  useEffect(() => {
    if (trailer) {
      reset(trailerToTrailerForm(trailer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trailer]);

  const onSubmit = handleSubmit(async (values: TrailerFormData) => {
    if (isCreate) {
      try {
        await addTrailer.mutateAsync(values);
        showMessage('Trailer successfully created.', MessageType.Success);
        navigate('/trailers');
      } catch {
        showMessage('Theres been an error!', MessageType.Error);
      }
      return;
    }

    try {
      await editTrailer.mutateAsync({ data: values, id: trailerId });
      showMessage('Trailer successfully updated.', MessageType.Success);
      navigate('/trailers');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  return {
    register,
    onSubmit,
    isCreate,
    trailerLoading,
    formState,
    control,
  };
};
