import clsx from 'clsx';

import { PaymentStatus } from 'helpers';

export const StatusIndicator: React.FC<Props> = (props) => {
  const { status } = props;

  return (
    <span
      className={clsx(`px-2 py-1 text-sm font-bold text-white rounded-md`, {
        'bg-stone-500': status === PaymentStatus.Pending,
        'bg-primary': status === PaymentStatus.Deleted,
        'bg-green-500': status === PaymentStatus.Paid,
      })}
    >
      {status}
    </span>
  );
};

interface Props {
  status: PaymentStatus;
}
