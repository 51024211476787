import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';

import { MessageType, MetaData, showMessage, SortDirection, usePagination } from 'helpers';
import { DriverOrderPayment, fetchDriverOrderPayments } from 'services/driverPayments';
import { PaymentStatus, postPaymentStatus } from 'services/orders';

const DriverReportContext = createContext<{
  driverOrderPayments: DriverOrderPayment[];
  metaData?: MetaData;
  currentPage: number;
  pendingPayment: boolean;
  driversLoading: boolean;
  updateDriverPaymentStatus: (id: string, paymentStatus: PaymentStatus) => Promise<void>;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
}>({} as any);

// This will be used in case we state shared inside the module
export const DriverReportPageProvider = ({ children = <Outlet /> }: Props) => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
  } = usePagination({ sortBy: 'createdAt', sortDirection: SortDirection.Desc });

  const [pendingPayment, setPendingPayment] = useState<boolean>(false);

  const { data: queryData, isLoading: driversLoading } = useQuery(
    ['driverOrderPayments', currentPage, pageSize, sortBy, sortDirection, debouncedSearch],
    () =>
      fetchDriverOrderPayments({ page: currentPage, pageSize, sortBy, sortDirection, search: debouncedSearch }, id!),
  );

  const driverOrderPayments = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const updateDriverPaymentStatus = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    async (id: string, paymentStatus: PaymentStatus) => {
      try {
        setPendingPayment(true);
        await postPaymentStatus(id, paymentStatus);
        queryClient.invalidateQueries('driverOrderPayments');
        showMessage('Success', MessageType.Success);
      } catch (err) {
        showMessage('Something went wrong.', MessageType.Error);
      }

      setPendingPayment(false);
    },
    [queryClient],
  );

  const providerValue = useMemo(
    () => ({
      id,
      driverOrderPayments,
      metaData,
      driversLoading,
      search,
      currentPage,
      pendingPayment,
      updateDriverPaymentStatus,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onSortChange,
    }),
    [
      id,
      driverOrderPayments,
      metaData,
      driversLoading,
      search,
      currentPage,
      pendingPayment,
      updateDriverPaymentStatus,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onSortChange,
    ],
  );

  return <DriverReportContext.Provider value={providerValue}>{children}</DriverReportContext.Provider>;
};

export const useDriverReportPage = () => {
  return useContext(DriverReportContext);
};

interface Props {
  children?: React.ReactNode;
}
