import { createContext, useContext, useEffect, useMemo } from 'react';
import { Control, FormState, useForm, UseFormRegister } from 'react-hook-form';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';

import { MessageType, showMessage, useIdFromParams } from 'helpers';
import { fetchStopPrice, postStopPrice, putStopPrice } from 'services/stopPrices';

import { STOP_PRICE_VALIDATION_SCHEMA } from './const';
import { StopPriceForm } from '../types';
import { stopPriceToStopPriceForm } from './transformation';
import { defaultStopPriceForm } from '../consts';

const StopPricePageContext = createContext<{
  formState: FormState<StopPriceForm>;
  isCreate: boolean;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  register: UseFormRegister<StopPriceForm>;
  control: Control<StopPriceForm, any>;
}>({} as any);

// This will be used in case we state shared inside the module
export const StopPricePageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id: stopPriceId, isCreate } = useIdFromParams();

  const { data: stopPrice } = useQuery(['stopPrices', stopPriceId], () => fetchStopPrice(stopPriceId), {
    enabled: !isCreate,
  });
  const { mutateAsync: updateStopPrice } = useMutation(putStopPrice);
  const { mutateAsync: createStopPrice } = useMutation(postStopPrice);

  const { handleSubmit, register, formState, reset, control } = useForm<StopPriceForm>({
    defaultValues: defaultStopPriceForm,
    resolver: yupResolver(STOP_PRICE_VALIDATION_SCHEMA),
  });

  const stopPriceForm = useMemo(() => {
    if (stopPrice) {
      return stopPriceToStopPriceForm(stopPrice);
    }
    return defaultStopPriceForm;
  }, [stopPrice]);

  useEffect(() => {
    reset(stopPriceForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopPriceForm]);

  const onSubmit = handleSubmit(async (values: StopPriceForm) => {
    if (isCreate) {
      try {
        await createStopPrice(values);
        showMessage('Stop price successfully created.', MessageType.Success);
        queryClient.invalidateQueries('stopPrices');
        navigate('/stop-prices');
      } catch {
        showMessage('Theres been an error!', MessageType.Error);
      }
      return;
    }

    try {
      await updateStopPrice(values);
      showMessage('Stop price successfully updated.', MessageType.Success);
      queryClient.invalidateQueries('stopPrices');
      navigate('/stop-prices');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const providerValue = useMemo(
    () => ({ formState, isCreate, onSubmit, register, control }),
    [formState, isCreate, onSubmit, register, control],
  );

  return <StopPricePageContext.Provider value={providerValue}>{children}</StopPricePageContext.Provider>;
};

export const useStopPricePage = () => {
  return useContext(StopPricePageContext);
};

interface Props {
  children?: React.ReactNode;
}
