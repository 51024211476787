import * as yup from 'yup';
import { PromoCodeType } from '../types';

export const PROMO_CODE_VALIDATION_SCHEMA = yup.object({
  code: yup.string().required('Code is required'),
  quantity: yup.number().typeError('Quantity is required'),
  maxUsesPerUser: yup.number().typeError('Max uses per user is required'),
  amount: yup
    .number()
    .typeError('Amount must be number')
    .required('Amount is required')
    .positive('Amount must be positive')
    .when('type', {
      is: (type: PromoCodeType) => type === PromoCodeType.Percentage,
      then: yup
        .number()
        .typeError('Amount must be number')
        .required('Amount is required')
        .min(0, 'Amount must be betweeen 0 and 100')
        .max(100, 'Amount must be betweeen 0 and 100'),
    }),
  type: yup.string().required('Type is required'),
  status: yup.string().required('Status is required'),
});
