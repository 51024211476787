import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useDebounce } from 'helpers';

export interface DataWithMeta<T = any> {
  meta: MetaData;
  data: T[];
}

export interface MetaData {
  pageNumber: number;
  pageSize: number;
  totalRows: number;
  totalPages: number;
  sortDirection: SortDirection;
}

export interface PaginationFilters {
  [key: string]: any;
}

export interface PaginationRequest {
  page?: number;
  pageSize?: number;
  search?: string;
  sortBy?: string;
  sortDirection?: SortDirection;
  filters?: PaginationFilters;
}

export enum SortDirection {
  None = '',
  Asc = 'asc',
  Desc = 'desc',
}

export const initialPaginationRequest: PaginationRequest = {
  page: 1,
  pageSize: 10,
  search: '',
  sortBy: '',
  sortDirection: SortDirection.None,
};

export const usePagination = (defaultPagination?: PaginationRequest) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamsObj = Object.fromEntries(searchParams.entries()) as any;

  const initialCurrentPage =
    Number(searchParams.get('page')) || defaultPagination?.page || initialPaginationRequest.page;
  const initialPageSize =
    Number(searchParams.get('pageSize')) || defaultPagination?.pageSize || initialPaginationRequest.pageSize;
  const initialSearch = searchParams.get('search') || defaultPagination?.search || initialPaginationRequest.search;
  const initialSortBy = searchParams.get('sortBy') || defaultPagination?.sortBy || initialPaginationRequest.sortBy;
  const initialSortDirection =
    (searchParams.get('sortDirection') as SortDirection) ||
    defaultPagination?.sortDirection ||
    initialPaginationRequest.sortDirection;
  const initialFilters = searchParams.get('filters')
    ? JSON.parse(searchParams.get('filters')!)
    : defaultPagination?.filters || initialPaginationRequest.filters || {};

  const [currentPage, setCurrentPage] = useState(initialCurrentPage ?? 1);
  const [pageSize, setPageSize] = useState(initialPageSize ?? 10);
  const [sortBy, setSortBy] = useState(initialSortBy ?? '');
  const [sortDirection, setSortDirection] = useState<SortDirection>(initialSortDirection ?? SortDirection.None);
  const [search, setSearch] = useState(initialSearch ?? '');
  const [filters, setFilters] = useState<PaginationFilters>(initialFilters ?? {});
  const debouncedSearch = useDebounce<string>(search, 500);

  const onPageChange = (newPage: number) => {
    setCurrentPage(newPage);
    setSearchParams({ ...searchParamsObj, page: newPage.toString() });
  };

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setCurrentPage(1);
    setSearchParams({ ...searchParamsObj, page: '1', pageSize: newPageSize.toString() });
  };

  const onSortChange = (accessor: string, sort: SortDirection) => {
    setSortBy(accessor);
    setSortDirection(sort);
    setCurrentPage(1);
    setSearchParams({ ...searchParamsObj, page: '1', sortBy: accessor, sortDirection: sort });
  };

  const onSearchChange = (searchValue: string) => {
    setSearch(searchValue);
    setCurrentPage(1);
    setSearchParams({ ...searchParamsObj, page: '1', search: searchValue });
  };

  const onSearchReset = () => {
    setSearch('');
    setCurrentPage(1);
    setSearchParams({ ...searchParamsObj, page: '1', search: '' });
  };

  const onFiltersChange = (newFilters: PaginationFilters) => {
    setCurrentPage(1);
    setFilters(newFilters);
    setSearchParams({ ...searchParamsObj, page: '1', filters: JSON.stringify(newFilters) });
  };

  return {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onSearchReset,
    onFiltersChange,
  };
};

export const paginationRequestToUrl = (
  url: string,
  pagination: PaginationRequest,
  filtersTransformation?: (filters: PaginationFilters) => { [key: string]: any },
) => {
  let newUrl = `${url}?page=${pagination.page}&pageSize=${pagination.pageSize}`;

  if (pagination.sortBy) newUrl += `&sortBy=${pagination.sortBy}`;
  if (pagination.sortDirection) newUrl += `&sortDirection=${pagination.sortDirection}`;
  if (pagination.search) newUrl += `&search=${pagination.search}`;
  if (pagination.filters) {
    const transformedFilters = filtersTransformation ? filtersTransformation(pagination.filters) : pagination.filters;

    // remove empty values
    const preparedFilters = Object.keys(transformedFilters).reduce((acc: any, key) => {
      if (transformedFilters[key] !== '' && transformedFilters[key] != null) {
        acc[key] = transformedFilters[key];
      }
      return acc;
    }, {});

    // filters are encoded as query params
    const filters = Object.keys(preparedFilters)
      .map((key) => `${key}=${preparedFilters![key]}`)
      .join(',');

    newUrl += `&filter=${encodeURI(filters)}`;
  }

  return newUrl;
};
