import { PageType } from 'helpers';

import { DistancePriceForm } from './types';

export const defaultDistancePriceForm: DistancePriceForm = {
  id: PageType.Create,
  distanceMilesFrom: null,
  distanceMilesTo: null,
  pricePerMile: null,
};
