import { ChartCard } from 'components';
import { useTotalOrderCharts } from '../hooks';

export const TotalOrdersDriverGroupChart = () => {
  const { totalOrdersGroupData } = useTotalOrderCharts();
  return (
    <ChartCard
      title="Total Orders"
      subtitle="By Vehicle Size"
      chartData={totalOrdersGroupData}
      height="100%"
      type="pie"
    />
  );
};
