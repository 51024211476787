import { DataWithMeta, PaginationRequest, paginationRequestToUrl } from 'helpers';
import { DriverGroupForm } from 'modules/DriverGroups/types';

import { api } from '../api';
import { uploadImages } from '../images';
import {
  driverGroupToPostReq,
  driverGroupToPutReq,
  resToDriverGroup,
  transformToDriverGroupsFilters,
} from './transformations';
import { DriverGroup } from './types';

export const fetchDriverGroups = async (pagination: PaginationRequest): Promise<DataWithMeta<DriverGroup>> => {
  const url = paginationRequestToUrl('/driver_groups', pagination, transformToDriverGroupsFilters);

  const resp = await api.get(url);
  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToDriverGroup),
  };
};

export const fetchAllDriverGroup = async (): Promise<DriverGroup[]> => {
  const resp = await api.get(`/driver_groups`);
  return resp.data.data.map(resToDriverGroup);
};

export const fetchDriverGroup = async (id: string): Promise<DriverGroup> => {
  const resp = await api.get(`/driver_groups/${id}`);
  return resToDriverGroup(resp.data);
};

export const putDriverGroup = async (data: DriverGroupForm): Promise<DriverGroup> => {
  const images = await uploadImages({ vehicleImageFile: data.vehicleImageFile });

  const resp = await api.put(`/driver_groups/${data.id}`, driverGroupToPutReq({ ...data, ...images }));

  return resToDriverGroup(resp.data);
};

export const postDriverGroup = async (data: DriverGroupForm): Promise<DriverGroup> => {
  const images = await uploadImages({ vehicleImageFile: data.vehicleImageFile });

  const resp = await api.post(`/driver_groups`, driverGroupToPostReq({ ...data, ...images }));
  return resToDriverGroup(resp.data);
};

export const deleteDriverGroup = async (id: string): Promise<DriverGroup> => {
  const resp = await api.delete(`/driver_groups/${id}`);
  return resToDriverGroup(resp.data);
};
