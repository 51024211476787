import { useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { MessageType, showMessage, usePagination } from 'helpers';
// sortDirection here

import { ItemCategory, useItemCategoryMutations, useItemCategories } from 'services/itemCategories';

export const useItemCategoriesPage = () => {
  const navigate = useNavigate();
  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onFiltersChange,
  } = usePagination({ sortBy: 'name' });

  const [itemCategoryToDelete, setItemCategoryToDelete] = useState<ItemCategory | null>(null);

  const { data: itemCategoriesData, isLoading: isItemCategoriesDataLoading } = useItemCategories({
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onFiltersChange,
  });

  const itemCategories = useMemo(() => itemCategoriesData?.data ?? [], [itemCategoriesData?.data]);
  const metaData = useMemo(() => itemCategoriesData?.meta, [itemCategoriesData?.meta]);

  const { deleteItemCategory } = useItemCategoryMutations();

  const onItemCategoryRowClick = useCallback(
    (row: ItemCategory) => {
      navigate(`/item-categories/${row.id}`);
    },
    [navigate],
  );

  const onNewItemCategoryClick = useCallback(() => {
    navigate('/item-categories/new');
  }, [navigate]);

  const onDeleteConfirm = useCallback(async () => {
    if (itemCategoryToDelete) {
      try {
        await deleteItemCategory.mutateAsync(itemCategoryToDelete.id);
        showMessage('Item Category successfully deleted.', MessageType.Success);
      } catch (err) {
        showMessage((err as any).response.data.title || 'Something went wrong.', MessageType.Error);
      }
      setItemCategoryToDelete(null);
    }
  }, [itemCategoryToDelete, deleteItemCategory]);

  const openConfirmationModal = useCallback((selectedItemCategory: ItemCategory | null) => {
    if (selectedItemCategory) setItemCategoryToDelete(selectedItemCategory);
  }, []);

  const closeConfirmationModal = useCallback(() => {
    setItemCategoryToDelete(null);
  }, []);

  return {
    itemCategories,
    metaData,
    isItemCategoriesDataLoading,
    itemCategoryToDelete,
    isItemCategoryDeleting: deleteItemCategory.isLoading,
    onItemCategoryRowClick,
    onNewItemCategoryClick,
    onDeleteConfirm,
    openConfirmationModal,
    closeConfirmationModal,
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onFiltersChange,
  };
};
