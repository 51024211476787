import { useDashboardPageContext } from 'modules/Dashboard/DashboardPage/DashboardPageProvider';
import { useEffect, useMemo, useState } from 'react';
import { useReportingDeviceType } from 'services/reporting';
import { PIE_CHART_COLORS } from 'modules/Dashboard/DashboardPage/consts';

import { ChartType } from '../../types';
import { initialOrdersByDeviceTypeChartData } from './const';

export const useOrdersByDeviceTypeChart = () => {
  const { startDate, endDate } = useDashboardPageContext();
  const { data: deviceTypeData } = useReportingDeviceType({ startDate, endDate });

  const [ordersByDeviceType, setOrdersByDeviceType] = useState<ChartType>(initialOrdersByDeviceTypeChartData);

  const deviceTypeDataFormat = useMemo(() => {
    const countSeries = deviceTypeData?.map((i) => i.count) || [];
    const labels = deviceTypeData?.map((i) => i.deviceType) || [];

    return {
      countSeries,
      labels,
    };
  }, [deviceTypeData]);

  useEffect(() => {
    setOrdersByDeviceType((old) => {
      return {
        series: deviceTypeDataFormat?.countSeries,
        options: {
          ...old.options,
          labels: deviceTypeDataFormat?.labels,
          colors: PIE_CHART_COLORS,
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false,
                },
              },
            },
          },
        },
      };
    });
  }, [deviceTypeDataFormat]);

  return {
    ordersByDeviceType,
  };
};
