import React, { createContext, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

const MerchantReportsContext = createContext<{}>({} as any);

export const MerchantReportsProvider = ({ children = <Outlet /> }: Props) => {
  const providerValue = useMemo(() => ({}), []);

  return <MerchantReportsContext.Provider value={providerValue}>{children}</MerchantReportsContext.Provider>;
};

interface Props {
  children?: React.ReactNode;
}
