import { DriverReport } from './types';

export const resToDriverReport = (res: any): DriverReport => {
  const parsedUnpaidOrderPaymentIds = JSON.parse(res.unpaidOrderPaymentIds);

  return {
    driverId: res.driverId,
    driverName: res.driverName,
    orderCount: res.orderCount,
    orderTotalAmount: res.orderTotalAmount,
    orderPaidAmount: res.orderPaidAmount,
    orderPaidCount: res.orderPaidCount,
    orderUnpaidAmount: res.orderUnpaidAmount,
    orderUnpaidCount: res.orderUnpaidCount,
    unpaidOrderPaymentIds: parsedUnpaidOrderPaymentIds ?? [],
  };
};

export const transformToDriverReportsFilters = (filters: any): any => {
  return {
    driverId: filters.driverId,
  };
};
