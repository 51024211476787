import { PromoCode } from 'services/promoCodes';

import { PromoCodeForm, PromoCodeType } from '../types';

export const promoCodeToPromoCodeForm = (promoCode: PromoCode): PromoCodeForm => {
  const type = promoCode.discountAmount !== null ? PromoCodeType.Amount : PromoCodeType.Percentage;
  const amount = promoCode.discountAmount ?? promoCode.discountPercentage;

  return {
    id: promoCode.id,
    code: promoCode.code,
    activeFrom: promoCode.activeFrom ? new Date(promoCode.activeFrom) : null,
    activeTo: promoCode.activeTo ? new Date(promoCode.activeTo) : null,
    type,
    amount,
    quantity: promoCode.quantity,
    maxUsesPerUser: promoCode.maxUsesPerUser,
    status: promoCode.status,
  };
};
