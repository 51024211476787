import { resToAddress } from '../addresses';
import { User } from './types';

export const resToUser = (res: any): User => {
  return {
    id: res.id,
    dateOfBirth: res.dateOfBirth ?? new Date(),
    email: res.email ?? '',
    firstName: res.firstName ?? '',
    homeAddress: res.homeAddress ? resToAddress(res.homeAddress) : null,
    isAdmin: res.isAdmin ?? false,
    isCustomer: res.isCustomer ?? false,
    isDriver: res.isDriver ?? false,
    isMerchant: res.isMerchant ?? false,
    lastName: res.lastName ?? '',
    phoneNumber: res.phoneNumber ?? '',
    workAddress: res.workAddress ? resToAddress(res.workAddress) : null,
  };
};

export const userToPutReq = (data: any) => {
  const mustData = {
    firstName: data.firstName,
    lastName: data.lastName,
    isDriver: data.isDriver,
    isCustomer: data.isCustomer,
    isMerchant: data.isMerchant,
    isAdmin: data.isAdmin,
  };

  if (data.password) {
    return {
      ...mustData,
      password: data.password,
    };
  }

  return mustData;
};

export const userToPostReq = (data: any) => {
  return {
    email: data.email,
    password: data.password,
    firstName: data.firstName,
    lastName: data.lastName,
    isDriver: data.isDriver,
    isCustomer: data.isCustomer,
    isMerchant: data.isMerchant,
    isAdmin: data.isAdmin,
  };
};
