import { SettingForm } from 'modules/Settings/types';

import { api } from '../api';
import { settingToPutReq, resToSetting } from './transformations';
import { Setting } from './types';

export const fetchSetting = async (): Promise<Setting> => {
  const resp = await api.get(`/settings`);
  return resToSetting(resp.data);
};

export const putSetting = async (data: SettingForm): Promise<Setting> => {
  const resp = await api.put(`/settings`, settingToPutReq(data));
  return resToSetting(resp.data);
};
