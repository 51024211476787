import { createContext, useContext, useMemo, useState } from 'react';
import { MessageType, showMessage } from 'helpers';
import { FormState, Control, useForm, UseFormRegister } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Outlet } from 'react-router-dom';
import { DriverGroup, fetchAllDriverGroup } from 'services/driverGroups';
import { yupResolver } from '@hookform/resolvers/yup';
import { postSmsNotifications } from 'services/drivers';
import { defaultSmsNotificationsForm } from '../const';
import { SmsNotificationsForm } from '../types';
import { SMS_NOTIFICATIONS_VALIDATION_SCHEMA } from './const';

const SmsNotificationsPageContext = createContext<{
  driverGroups: DriverGroup[];
  formState: FormState<SmsNotificationsForm>;
  driverGroupsOptions: any;
  loadingSms: boolean;
  control: Control<SmsNotificationsForm, any>;
  register: UseFormRegister<SmsNotificationsForm>;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
}>({} as any);

export const SmsNotificationsPageProvider = ({ children = <Outlet /> }: Props) => {
  const { register, control, handleSubmit, formState, reset } = useForm<SmsNotificationsForm>({
    defaultValues: defaultSmsNotificationsForm,
    resolver: yupResolver(SMS_NOTIFICATIONS_VALIDATION_SCHEMA),
  });

  const { data: queryData } = useQuery(['allDriverGroups'], () => fetchAllDriverGroup());

  const driverGroups = useMemo(() => queryData ?? [], [queryData]);
  const [loadingSms, setLoadingSms] = useState(false);

  const driverGroupsOptions = useMemo(
    () =>
      driverGroups.map((driverGroup: DriverGroup) => ({
        id: driverGroup?.id,
        name: driverGroup.name,
      })),
    [driverGroups],
  );

  const onSubmit = handleSubmit(async (data: any) => {
    setLoadingSms(true);

    try {
      await postSmsNotifications(data);
      setLoadingSms(false);
      showMessage('Message sent successfully.', MessageType.Success);
      reset(defaultSmsNotificationsForm);
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
      setLoadingSms(false);
      reset(defaultSmsNotificationsForm);
    }
  });

  const providerValue = useMemo(
    () => ({ driverGroups, driverGroupsOptions, loadingSms, control, formState, register, onSubmit }),
    [driverGroups, driverGroupsOptions, loadingSms, control, formState, register, onSubmit],
  );

  return <SmsNotificationsPageContext.Provider value={providerValue}>{children}</SmsNotificationsPageContext.Provider>;
};

export const useSmsNotificationsPageProvider = () => {
  return useContext(SmsNotificationsPageContext);
};

interface Props {
  children?: React.ReactNode;
}
