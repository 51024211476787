import { useNavigate } from 'react-router-dom';

import { Button, Container, Header, Divider, FormItem, Input, DatePicker, RadioButton, Select } from 'components';

import { usePromoCodePage } from './PromoCodePageProvider';
import { PromoCodeType } from '../types';

export const PromoCodePage = () => {
  const { isCreate, formState, onSubmit, register, control } = usePromoCodePage();
  const navigate = useNavigate();

  return (
    <>
      <Header title={isCreate ? 'New promo code' : 'Edit promo code'} onGoBackPress={() => navigate(-1)}>
        <Button type="submit" onClick={onSubmit} label="Save" form="promoCodeForm" />
      </Header>

      <Container>
        <div className="overflow-hidden bg-white rounded-lg shadow">
          <div className="px-4 py-5 sm:p-6">
            <form className="space-y-10" onSubmit={onSubmit} noValidate id="promoCodeForm">
              <Divider title="Promo code information" />

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <FormItem label="Code" htmlFor="code" error={formState.errors.code?.message} required>
                  <Input
                    {...register('code')}
                    id="code"
                    type="text"
                    placeholder="Enter code"
                    autoComplete="code"
                    required
                    error={!!formState.errors.code?.message}
                  />
                </FormItem>

                <FormItem label="Status" htmlFor="status" error={formState.errors.status?.message} required>
                  <Select
                    control={control}
                    name="status"
                    options={[
                      { name: 'Active', id: 'active' },
                      { name: 'Inactive', id: 'inactive' },
                    ]}
                    error={!!formState.errors.status?.message}
                  />
                </FormItem>

                <FormItem
                  label="Active from"
                  htmlFor="activeFrom"
                  error={formState.errors.activeFrom?.message}
                  required
                >
                  <DatePicker
                    error={!!formState.errors.activeFrom?.message}
                    control={control}
                    name="activeFrom"
                    placeholder="Select active from date"
                  />
                </FormItem>

                <FormItem label="Active to" htmlFor="activeTo" error={formState.errors.activeTo?.message} required>
                  <DatePicker
                    error={!!formState.errors.activeTo?.message}
                    control={control}
                    name="activeTo"
                    placeholder="Select active to date"
                  />
                </FormItem>

                <FormItem label="Type" htmlFor="type">
                  <RadioButton
                    label="Amount"
                    {...register('type')}
                    value={PromoCodeType.Amount}
                    id="promoCodeTypeAmount"
                  />
                  <RadioButton
                    label="Percentage"
                    {...register('type')}
                    value={PromoCodeType.Percentage}
                    id="promoCodeTypePercentage"
                  />
                </FormItem>

                <FormItem label="Amount" htmlFor="amount" error={formState.errors.amount?.message} required>
                  <Input
                    {...register('amount')}
                    id="amount"
                    type="number"
                    placeholder="Enter amount"
                    autoComplete="amount"
                    required
                    error={!!formState.errors.amount?.message}
                  />
                </FormItem>

                <FormItem
                  label="Max uses per user"
                  htmlFor="maxUsesPerUser"
                  error={formState.errors.maxUsesPerUser?.message}
                  required
                >
                  <Input
                    {...register('maxUsesPerUser')}
                    id="maxUsesPerUser"
                    type="number"
                    placeholder="Enter max uses per user"
                    autoComplete="maxUsesPerUser"
                    required
                    error={!!formState.errors.maxUsesPerUser?.message}
                  />
                </FormItem>

                <FormItem label="Quantity" htmlFor="quantity" error={formState.errors.quantity?.message} required>
                  <Input
                    {...register('quantity')}
                    id="quantity"
                    type="number"
                    placeholder="Enter quantity"
                    autoComplete="quantity"
                    required
                    error={!!formState.errors.quantity?.message}
                  />
                </FormItem>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};
