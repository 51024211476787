import { ChartCard } from 'components';
import { useUserReportingPage } from '../hooks';

export function DriverGroupChart() {
  const { driverGroupData, innerCardDriverGroup } = useUserReportingPage();

  return (
    <ChartCard
      title="Total Drivers"
      subtitle="By Driver Group"
      chartData={driverGroupData}
      type="pie"
      innerCardData={innerCardDriverGroup}
      height="100%"
    />
  );
}
