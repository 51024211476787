import { DriverGroup } from 'services/driverGroups';
import { idAndUrlToImage } from 'services/images';

import { DriverGroupForm } from '../types';

export const driverGroupToDriverGroupForm = (driverGroup: DriverGroup): DriverGroupForm => {
  return {
    id: driverGroup.id,
    bookingFee: driverGroup.bookingFee,
    description: driverGroup.description,
    isTest: driverGroup.isTest,
    name: driverGroup.name,
    position: driverGroup.position,
    showInApp: driverGroup.showInApp,
    vehicleImageFile: idAndUrlToImage(driverGroup.vehicleImageFileId, driverGroup.vehicleImageFileUrl),
    isHelper: driverGroup.isHelper,
    relatedDriverGroupIds: driverGroup.relatedDriverGroupIds,
    trailers: driverGroup.trailers,
    trailerIds: driverGroup.trailers.map((trailer) => trailer.id),
  };
};
