import { DataWithMeta, PaginationRequest, paginationRequestToUrl } from 'helpers';
import { MerchantForm } from 'modules/Merchants/types';
import { transformToMerchantReportsFilters } from 'services/merchantReports';

import { api } from '../api';
import { merchantToPostReq, merchantToPutReq, resToMerchant } from './transformations';
import { Merchant } from './types';

export const fetchMerchants = async (pagination: PaginationRequest): Promise<DataWithMeta<Merchant>> => {
  const url = paginationRequestToUrl('/merchants', pagination, transformToMerchantReportsFilters);

  const resp = await api.get(url);
  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToMerchant),
  };
};

export const fetchAllMerchants = async (): Promise<Merchant[]> => {
  const resp = await api.get(`/merchants`);
  return resp.data.data.map(resToMerchant);
};

export const fetchMerchant = async (id: string): Promise<Merchant> => {
  const resp = await api.get(`/merchants/${id}`);
  return resToMerchant(resp.data);
};

export const putMerchant = async (data: MerchantForm): Promise<Merchant> => {
  const resp = await api.put(`/merchants/${data.id}`, merchantToPutReq(data));
  return resToMerchant(resp.data);
};

export const postMerchant = async (data: MerchantForm): Promise<Merchant> => {
  const resp = await api.post(`/merchants`, merchantToPostReq(data));
  return resToMerchant(resp.data);
};

export const deleteMerchant = async (id: string): Promise<Merchant> => {
  const resp = await api.delete(`/merchants/${id}`);
  return resToMerchant(resp.data);
};
