import { idAndUrlToImage } from 'services/images';
import { Trailer, TrailerFormData } from 'services/trailers';

export const trailerToTrailerForm = (trailer: Trailer): TrailerFormData => {
  return {
    id: trailer.id,
    driverGroupId: trailer.driverGroupId,
    trailerImageFile: idAndUrlToImage(trailer.trailerImageFileId, trailer.trailerImageFileUrl),
    description: trailer.description,
    bookingFee: trailer.bookingFee,
    title: trailer.title,
    showInApp: trailer.showInApp,
  };
};
