import { useCallback, useMemo, useState, useContext, createContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Control, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { createDownloadLink, MessageType, MetaData, showMessage, SortDirection, usePagination } from 'helpers';
import { approveDriver, deleteDriver, Driver, fetchDrivers, denyDriver, exportDrivers } from 'services/drivers';

import { DriversPageAdvancedSearchForm } from '../types';
import { defaultDriversPageAdvancedSearchForm } from '../const';

interface ConfirmationModal {
  action: 'restore' | 'deny' | 'delete' | 'approve' | null;
  isOpen: boolean;
  title: string;
  message: string;
  actionButtonLabel: string;
}

const initialConfirmationModal = {
  action: null,
  isOpen: false,
  title: '',
  message: '',
  actionButtonLabel: 'Cancel',
};

const DriversPageContext = createContext<{
  drivers: Driver[];
  metaData: MetaData | undefined;
  currentPage: number;
  search: string;
  isLoading: boolean;
  activeDriver: Driver | null;
  confirmationModal: ConfirmationModal;
  control: Control<DriversPageAdvancedSearchForm, any>;
  isLoadingExport: boolean;
  exportDriversCSV: () => Promise<void>;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  onClearFilters: () => void;
  onDeleteClick: (row: Driver) => void;
  onApproveClick: (row: Driver) => void;
  onDenyClick: (row: Driver) => void;
  onRestoreClick: (row: Driver) => void;
  closeConfirmationModal: () => void;
  onSearchChange: (searchValue: string) => void;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  onConfirmClick: () => Promise<void>;
  onRowClick: (row: Driver) => void;
  onNewClick: () => void;
  onSortChange: (accessor: string, sort: SortDirection) => void;
}>({} as any);

export const DriversPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    filters,
    onFiltersChange,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
  } = usePagination();

  const [activeDriver, setActiveDriver] = useState<Driver | null>(null);
  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModal>(initialConfirmationModal);
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const { control, handleSubmit, reset } = useForm<DriversPageAdvancedSearchForm>({
    defaultValues: defaultDriversPageAdvancedSearchForm,
  });

  const { data: queryData, isLoading: driversLoading } = useQuery(
    ['drivers', currentPage, pageSize, sortBy, sortDirection, debouncedSearch, filters],
    () => fetchDrivers({ page: currentPage, pageSize, sortBy, sortDirection, search: debouncedSearch, filters }),
    { refetchInterval: 5000 },
  );
  const { mutateAsync: removeDriver } = useMutation(deleteDriver);
  const { mutateAsync: approve } = useMutation(approveDriver);
  const { mutateAsync: deny } = useMutation(denyDriver);

  const drivers = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onSubmit = handleSubmit((data: DriversPageAdvancedSearchForm) => {
    onFiltersChange(data as any);
  });

  const onClearFilters = useCallback(() => {
    reset(defaultDriversPageAdvancedSearchForm);
    onSubmit();
  }, [reset, onSubmit]);

  const onRowClick = useCallback(
    (row: Driver) => {
      navigate(`/drivers/${row.id}`);
    },
    [navigate],
  );

  const onNewClick = useCallback(() => {
    navigate('/drivers/new');
  }, [navigate]);

  const closeConfirmationModal = useCallback(() => {
    setActiveDriver(null);
    setConfirmationModal(initialConfirmationModal);
  }, [setActiveDriver, setConfirmationModal]);

  const deleteConfirm = useCallback(async () => {
    if (activeDriver) {
      try {
        await removeDriver(activeDriver.id);
        showMessage('Driver successfully deleted.', MessageType.Success);
        queryClient.invalidateQueries('drivers');
        closeConfirmationModal();
      } catch (err: any) {
        const errorMsg = err?.response?.data?.title;
        showMessage(errorMsg, MessageType.Error);
      }
    }
  }, [activeDriver, closeConfirmationModal, queryClient, removeDriver]);

  const approveConfirm = useCallback(async () => {
    if (activeDriver) {
      try {
        await approve(activeDriver.id);
        showMessage('Driver successfully approved.', MessageType.Success);
        queryClient.invalidateQueries('drivers');
        closeConfirmationModal();
      } catch (err: any) {
        const errorMsg = err?.response?.data?.title;
        showMessage(errorMsg, MessageType.Error);
      }
    }
  }, [activeDriver, approve, closeConfirmationModal, queryClient]);

  const denyConfirm = useCallback(async () => {
    if (activeDriver) {
      try {
        await deny(activeDriver.id);
        showMessage('Driver successfully denied.', MessageType.Success);
        queryClient.invalidateQueries('drivers');
        closeConfirmationModal();
      } catch (err: any) {
        const errorMsg = err?.response?.data?.title;
        showMessage(errorMsg, MessageType.Error);
      }
    }
  }, [activeDriver, closeConfirmationModal, deny, queryClient]);

  const restoreConfirm = useCallback(async () => {
    if (activeDriver) {
      try {
        await deny(activeDriver.id);
        showMessage('Driver successfully restored.', MessageType.Success);
        queryClient.invalidateQueries('drivers');
        closeConfirmationModal();
      } catch (err: any) {
        const errorMsg = err?.response?.data?.title;
        showMessage(errorMsg, MessageType.Error);
      }
    }
  }, [activeDriver, deny, queryClient, closeConfirmationModal]);

  const onConfirmClick = useCallback(async () => {
    if (confirmationModal.action === 'delete') {
      deleteConfirm();
    } else if (confirmationModal.action === 'approve') {
      approveConfirm();
    } else if (confirmationModal.action === 'deny') {
      denyConfirm();
    } else {
      restoreConfirm();
    }
  }, [confirmationModal.action, deleteConfirm, approveConfirm, denyConfirm, restoreConfirm]);

  const onDeleteClick = useCallback(
    (row: Driver) => {
      setActiveDriver(row);
      setConfirmationModal({
        action: 'delete',
        isOpen: true,
        title: `Delete ${row?.user?.firstName || 'driver'} ${row?.user?.lastName || ''}?`,
        message: `Are you sure you want to delete ${activeDriver?.user?.firstName || 'driver'} ${
          activeDriver?.user?.lastName || ''
        }?`,
        actionButtonLabel: 'Delete',
      });
    },
    [activeDriver?.user?.firstName, activeDriver?.user?.lastName, setActiveDriver, setConfirmationModal],
  );

  const onApproveClick = useCallback(
    (row: Driver) => {
      setActiveDriver(row);
      setConfirmationModal({
        action: 'approve',
        isOpen: true,
        title: `Approve ${row?.user?.firstName || 'driver'} ${row?.user?.lastName || ''}?`,
        message: `Are you sure you want to approve ${activeDriver?.user?.firstName || 'driver'} ${
          activeDriver?.user?.lastName || ''
        }?`,
        actionButtonLabel: 'Approve',
      });
    },
    [activeDriver?.user?.firstName, activeDriver?.user?.lastName, setActiveDriver, setConfirmationModal],
  );

  const onDenyClick = useCallback(
    (row: Driver) => {
      setActiveDriver(row);
      setConfirmationModal({
        action: 'deny',
        isOpen: true,
        title: `Deny ${row?.user?.firstName || 'driver'} ${row?.user?.lastName || ''}?`,
        message: `Are you sure you want to deny ${activeDriver?.user?.firstName || 'driver'} ${
          activeDriver?.user?.lastName || ''
        }?`,
        actionButtonLabel: 'Deny',
      });
    },
    [activeDriver?.user?.firstName, activeDriver?.user?.lastName, setActiveDriver, setConfirmationModal],
  );

  const onRestoreClick = useCallback(
    (row: Driver) => {
      setActiveDriver(row);
      setConfirmationModal({
        action: 'restore',
        isOpen: true,
        title: `Restore ${row?.user?.firstName || 'driver'} ${row?.user?.lastName || ''}?`,
        message: `Are you sure you want to restore ${activeDriver?.user?.firstName || 'driver'} ${
          activeDriver?.user?.lastName || ''
        }?`,
        actionButtonLabel: 'Restore',
      });
    },
    [activeDriver?.user?.firstName, activeDriver?.user?.lastName, setActiveDriver, setConfirmationModal],
  );

  const exportDriversCSV = useCallback(async () => {
    setIsLoadingExport(true);
    try {
      const data = await exportDrivers(filters, search);
      createDownloadLink(data, 'drivers');
    } catch {
      showMessage('An has error occured while trying to export drivers data', MessageType.Error);
    }
    setIsLoadingExport(false);
  }, [filters, search]);

  const providerValue = useMemo(
    () => ({
      drivers,
      metaData,
      currentPage,
      search,
      isLoading: driversLoading,
      activeDriver,
      confirmationModal,
      control,
      isLoadingExport,
      exportDriversCSV,
      onSubmit,
      onClearFilters,
      onDeleteClick,
      onApproveClick,
      onDenyClick,
      onRestoreClick,
      closeConfirmationModal,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onConfirmClick,
      onRowClick,
      onNewClick,
      onSortChange,
    }),
    [
      drivers,
      metaData,
      currentPage,
      search,
      driversLoading,
      activeDriver,
      confirmationModal,
      control,
      isLoadingExport,
      exportDriversCSV,
      onSubmit,
      onClearFilters,
      onDeleteClick,
      onApproveClick,
      onDenyClick,
      onRestoreClick,
      closeConfirmationModal,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onConfirmClick,
      onRowClick,
      onNewClick,
      onSortChange,
    ],
  );

  return <DriversPageContext.Provider value={providerValue}>{children}</DriversPageContext.Provider>;
};

export const useDriversPage = () => {
  return useContext(DriversPageContext);
};

interface Props {
  children?: React.ReactNode;
}
