/* eslint-disable react/no-danger */
import { ReactNode, useState } from 'react';

export function Tooltip({ children, tooltipMessage, popoverMessage, onClick }: Props) {
  const [textMessage, setTextMessage] = useState<string | undefined>(tooltipMessage);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleClick = (e: any) => {
    if (!onClick) {
      return;
    }
    e.stopPropagation();
    onClick();
    setTextMessage(popoverMessage);
  };

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
    setTextMessage(tooltipMessage);
  };

  return (
    <div
      aria-hidden="true"
      className="relative w-fit"
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={` ${
          !showTooltip && 'invisible'
        } absolute left-12 top-4 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-70 transition-opacity duration-300 tooltip dark:bg-gray-00`}
        dangerouslySetInnerHTML={textMessage ? { __html: textMessage } : undefined}
      />
      {children}
    </div>
  );
}

interface Props {
  onClick?: () => void;
  tooltipMessage?: string;
  popoverMessage?: string;
  children: ReactNode;
}

export default Tooltip;
