import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

export const ItemCategoriesContext = React.createContext<{}>(undefined!);

export const ItemCategoriesProvider = ({ children = <Outlet /> }: Props) => {
  const providerValue = useMemo(() => ({}), []);

  return <ItemCategoriesContext.Provider value={providerValue}>{children}</ItemCategoriesContext.Provider>;
};

interface Props {
  children?: React.ReactNode;
}
