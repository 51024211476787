import { DataWithMeta, PaginationRequest, paginationRequestToUrl } from 'helpers';

import { api } from '../api';
import { resToDriverReport, transformToDriverReportsFilters } from './transformations';
import { DriverReport } from './types';

export const fetchDriverReports = async (
  pagination: PaginationRequest,
  fromDate: Date | null,
  toDate: Date | null,
): Promise<DataWithMeta<DriverReport>> => {
  let url = paginationRequestToUrl('/reports/driver_payments', pagination, transformToDriverReportsFilters);

  if (fromDate) url += `&fromDate=${fromDate.toISOString()}`;
  if (toDate) url += `&toDate=${toDate.toISOString()}`;

  const resp = await api.get(url);
  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToDriverReport),
  };
};
