import { useOrderPage } from '../../OrderPageProvider';

export const OrderImages = () => {
  const { order } = useOrderPage();

  return (
    <div className="pt-5 grid grid-cols-1 gap-4 lg:grid-cols-3">
      {order.images.map((img, idx) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          onClick={() => window.open(img.fileUrl, '_blank')}
          role="link"
          tabIndex={idx}
          className="items-center w-full border border-gray-300 cursor-pointer grid rounded-md"
          key={img.id}
        >
          <img className="h-64 m-auto rounded-md" src={img.fileUrl} alt={img.fileId} />
        </div>
      ))}
    </div>
  );
};
