import { PageType } from 'helpers';
import { DriverForm } from 'modules/Drivers/types';

import { resToUser } from '../users';
import { Driver } from './types';

export const resToDriver = (res: any): Driver => {
  const driver: Driver = {
    id: res.id,
    approveDenyNote: res.approveDenyNote ?? '',
    description: res.description ?? '',
    driverGroupIds: res.driverGroupIds ?? [],
    driverGroupName: res.driverGroupName ?? '',
    driversLicenseExpirationDate: res.driversLicenseExpirationDate ?? null,
    driversLicenseNumber: res.driversLicenseNumber ?? '',
    driversLicenseState: res.driversLicenseState ?? '',
    driversLicenseFileId: res.driversLicenseFileId ?? '',
    driversLicenseFileUrl: res.driversLicenseFileUrl ?? '',
    expectedAvailabilityId: res.expectedAvailabilityId ?? '',
    firstHelperPaymentPercentage: res.firstHelperPaymentPercentage ?? null,
    isHelper: res.isHelper ?? false,
    maximumDrivingDistanceMiles: res.maximumDrivingDistanceMiles ?? null,
    orderPaymentPercentage: res.orderPaymentPercentage ?? null,
    profilePictureFileId: res.profilePictureFileId ?? '',
    profilePictureFileUrl: res.profilePictureFileUrl ?? '',
    secondHelperPaymentPercentage: res.secondHelperPaymentPercentage ?? null,
    status: res.status ?? '',
    user: res.user ? resToUser(res.user) : null,
    userFirstName: '',
    userLastName: '',
    userEmail: '',
    vehicleColor: res.vehicleColor ?? '',
    vehicleInsuranceFileId: res.vehicleInsuranceFileId ?? '',
    vehicleInsuranceFileUrl: res.vehicleInsuranceFileUrl ?? '',
    vehicleRegistrationFileId: res.vehicleRegistrationFileId ?? '',
    vehicleRegistrationFileUrl: res.vehicleRegistrationFileUrl ?? '',
    vehicleMake: res.vehicleMake ?? '',
    vehicleModel: res.vehicleModel ?? '',
    vehicleYear: res.vehicleYear ?? null,
    isOnDuty: res.isOnDuty ?? false,
  };

  if (!res.user) {
    driver.userFirstName = res.userFirstName ?? '';
    driver.userLastName = res.userLastName ?? '';
    driver.userEmail = res.userEmail ?? '';
  } else {
    driver.userFirstName = res.user.firstName ?? '';
    driver.userLastName = res.user.lastName ?? '';
    driver.userEmail = res.user.email ?? '';
  }

  return driver;
};

export const driverToPutReq = (data: DriverForm): any => {
  const payload: any = {
    user: {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      dateOfBirth: data.dateOfBirth?.toISOString() ?? null,
      isDriver: true,
      isCustomer: data.isCustomer,
      isMerchant: data.isMerchant,
      isAdmin: data.isAdmin,
      homeAddress: {
        street1: data.address1,
        street2: data.address2,
        city: data.city,
        state: data.state,
        zip: data.zip,
        country: 'US',
        lat: 0,
        lng: 0,
      },
    },
    status: data.status,
    description: data.description,
    driverGroupIds: data.driverGroupIds,
    driversLicenseNumber: data.driversLicenseNumber,
    driversLicenseState: data.driversLicenseState,
    driversLicenseExpirationDate: data.driversLicenseExpirationDate?.toISOString() ?? null,
    vehicleMake: data.vehicleMake,
    vehicleModel: data.vehicleModel,
    vehicleYear: data.vehicleYear,
    vehicleColor: data.vehicleColor,
    isHelper: data.isHelper,
    maximumDrivingDistanceMiles: data.maximumDrivingDistanceMiles,
    expectedAvailabilityId: data.expectedAvailabilityId,
    orderPaymentPercentage: data.orderPaymentPercentage,
    firstHelperPaymentPercentage: data.firstHelperPaymentPercentage,
    secondHelperPaymentPercentage: data.secondHelperPaymentPercentage,
    approveDenyNote: data.approveDenyNote,
  };

  if (data.password) {
    payload.user.password = data.password;
  }

  if (data.driversLicenseFile.id !== PageType.Create) {
    payload.driversLicenseFileId = data.driversLicenseFile.id;
  }

  if (data.profilePictureFile.id !== PageType.Create) {
    payload.profilePictureFileId = data.profilePictureFile.id;
  }

  if (data.vehicleInsuranceFile.id !== PageType.Create) {
    payload.vehicleInsuranceFileId = data.vehicleInsuranceFile.id;
  }

  if (data.vehicleRegistrationFile.id !== PageType.Create) {
    payload.vehicleRegistrationFileId = data.vehicleRegistrationFile.id;
  }

  return payload;
};

export const driverToPostReq = (data: DriverForm): any => {
  let payload: any = {
    user: {
      email: data.email,
      password: data.password,
      firstName: data.firstName,
      lastName: data.lastName,
      isDriver: true,
      isCustomer: false,
      isMerchant: false,
      isAdmin: false,
      phoneNumber: data.phoneNumber,
      dateOfBirth: data.dateOfBirth?.toISOString() ?? null,
      homeAddress: {
        street1: data.address1,
        street2: data.address2,
        city: data.city,
        state: data.state,
        zip: data.zip,
        country: 'US',
        lat: 0,
        lng: 0,
      },
    },
    status: data.status,
    isHelper: data.isHelper,
    driverGroupIds: data.driverGroupIds,
    orderPaymentPercentage: 0,
  };

  if (!data.isHelper) {
    payload = {
      ...payload,
      description: data.description,
      driversLicenseNumber: data.driversLicenseNumber,
      driversLicenseState: data.driversLicenseState,
      driversLicenseExpirationDate: data.driversLicenseExpirationDate?.toISOString() ?? null,
      driverGroupIds: data.driverGroupIds,
      vehicleMake: data.vehicleMake,
      vehicleModel: data.vehicleModel,
      vehicleYear: data.vehicleYear,
      vehicleColor: data.vehicleColor,
      maximumDrivingDistanceMiles: data.maximumDrivingDistanceMiles,
      expectedAvailabilityId: data.expectedAvailabilityId,
      orderPaymentPercentage: data.orderPaymentPercentage,
      firstHelperPaymentPercentage: data.firstHelperPaymentPercentage,
      secondHelperPaymentPercentage: data.secondHelperPaymentPercentage,
      approveDenyNote: data.approveDenyNote,
    };
  }

  if (data.driversLicenseFile.id !== PageType.Create) {
    payload.driversLicenseFileId = data.driversLicenseFile.id;
  }

  if (data.profilePictureFile.id !== PageType.Create) {
    payload.profilePictureFileId = data.profilePictureFile.id;
  }

  if (data.vehicleInsuranceFile.id !== PageType.Create) {
    payload.vehicleInsuranceFileId = data.vehicleInsuranceFile.id;
  }

  if (data.vehicleRegistrationFile.id !== PageType.Create) {
    payload.vehicleRegistrationFileId = data.vehicleRegistrationFile.id;
  }

  return payload;
};

export const transformToDriversFilters = (filters: any): any => {
  return {
    status: filters.status,
  };
};
