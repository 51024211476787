import { DistancePrice } from 'services/distancePrices';

import { DistancePriceForm } from '../types';

export const distancePriceToDistancePriceForm = (price: DistancePrice): DistancePriceForm => {
  return {
    id: price.id,
    distanceMilesFrom: price.distanceMilesFrom,
    distanceMilesTo: price.distanceMilesTo,
    pricePerMile: price.pricePerMile,
  };
};
