import { Merchant } from 'services/merchants';

import { MerchantForm } from '../types';

export const merchantToMerchantForm = (merchant: Merchant): MerchantForm => {
  return {
    id: merchant.id,
    firstName: merchant.user?.firstName || '',
    lastName: merchant.user?.lastName || '',
    email: merchant.user?.email || '',
    phone: merchant.user?.phoneNumber || '',
    password: '',
    businessName: merchant.businessName || '',
    isSendingPriceToOnfleet: merchant.isSendingPriceToOnfleet || false,
    driverGroupId: merchant.driverGroupId || null,
    itemPickupTimes: merchant.itemPickupTimes || [],
    merchantPaymentPercentage: merchant.merchantPaymentPercentage || 0,
  };
};
