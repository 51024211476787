import { PageType } from 'helpers';
import { initialImage } from 'services/images/consts';
import { ItemCategoryFormData } from 'services/itemCategories';

export const defaultItemCategoryForm: ItemCategoryFormData = {
  id: PageType.Create,
  name: '',
  itemCategoryImageFile: initialImage,
  itemGroupId: null,
  showInApp: false,
};
