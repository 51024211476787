import { useNavigate } from 'react-router-dom';

import { Button, Container, Header, Divider } from 'components';

import { useDriverPage } from './DriverPageProvider';
import { DriverImages, PersonalInformation, VehicleInformation } from './components';

export const DriverPage = () => {
  const { isCreate, onSubmit, watch } = useDriverPage();
  const navigate = useNavigate();

  const isHelper = watch('isHelper');

  return (
    <>
      <Header title={isCreate ? 'New driver' : 'Edit driver'} onGoBackPress={() => navigate(-1)}>
        <Button type="submit" onClick={onSubmit} label="Save" form="driverForm" />
      </Header>

      <Container>
        <div className="overflow-hidden bg-white rounded-lg shadow">
          <div className="px-4 py-5 sm:p-6">
            <form className="space-y-10" onSubmit={onSubmit} noValidate id="driverForm">
              <Divider title="Personal information" />

              <PersonalInformation />

              {!isHelper && (
                <>
                  <Divider title="Vehicle information" />

                  <VehicleInformation />

                  <Divider title="Images" />

                  <DriverImages />
                </>
              )}
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};
