import { Merchant } from 'services/merchants';
import { Image } from 'services/images';
import { Address, AddressFormData } from '../addresses';
import { Customer } from '../customers';
import { Driver } from '../drivers';

export enum PaymentType {
  CreditCard = 'cc',
  Invoiced = 'invoice',
}

export enum PaymentStatus {
  Paid = 'paid',
  Pending = 'pending',
  Failed = 'failed',
}

export enum OrderStatus {
  NotAssigned = 'notAssigned',
  Assigned = 'assigned',
  OutForPickup = 'outForPickup',
  OutForDelivery = 'outForDelivery',
  Delivered = 'delivered',
  CancelledOrFailed = 'cancelledOrFailed',
  PickedUp = 'pickedUp',
}

export enum DeliveryWindow {
  Morning = 'morning',
  LateMorning = 'lateMorning',
  Noon = 'noon',
  LateNoon = 'lateNoon',
  Afternoon = 'afterNoon',
}

export const DeliveryWindowName: Record<string, string> = {
  morning: 'Morning',
  lateMorning: 'Late Morning',
  noon: 'Noon',
  lateNoon: 'Late Noon',
  afterNoon: 'Afternoon',
};

export const DeliveryWindowTime: Record<string, string> = {
  morning: '8:00 AM - 12:00 PM',
  lateMorning: '10:00 AM - 2:00 PM',
  noon: '12:00 PM - 4:00 PM',
  lateNoon: '2:00 PM - 6:00 PM',
  afterNoon: '4:00 PM - 8:00 PM',
};

export enum ItemPickupTime {
  morning = '8:00 AM - 12:00 PM',
  noon = '12:00 PM - 4:00 PM',
  afterNoon = '4:00 PM - 8:00 PM',
}

export enum PickupType {
  Today = 'today',
  Expedited = 'expedited',
  Scheduled = 'schedule',
}

export enum DeviceType {
  Android = 'Android',
  Ios = 'iOS',
  Web = 'Web',
  Csv = 'csv',
}
export const PickupTypeName: Record<string, string> = {
  today: 'Today',
  expedited: 'Expedited',
  schedule: 'Scheduled',
};

export interface Order {
  id: string;
  bookingPrice: number | null;
  createdAt: string;
  customer: Customer | null;
  customerId: string;
  customerName: string;
  deliveryAddresses: string[];
  deliveryWindow: DeliveryWindow | null;
  deviceType: string;
  driver: Driver | null;
  driverGroupId: string;
  driverGroupName: string;
  driverId: string;
  driverName: string;
  driverPayment: number | null;
  expeditedFee: number | null;
  extraTip: number | null;
  finalPrice: number | null;
  firstHelperFee: number | null;
  firstHelperLoadPrice: number | null;
  firstHelperUnloadPrice: number | null;
  height: string | null;
  helper: Driver | null;
  helperPayment: number | null;
  images: OrderImage[];
  itemsDescription: string;
  length: string | null;
  loadHelperCount: number | null;
  loadHelperPrice: number | null;
  merchant: Merchant | null;
  merchantId: string;
  merchantOrderNumber: string;
  mileagePrice: number | null;
  orderStatus: string;
  paymentDate: string;
  paymentStatus: PaymentStatus | null;
  paymentTransactionId: string;
  paymentType: PaymentType | null;
  pickupAddress: string;
  pickupType: PickupType | null;
  promoCodeDiscount: number | null;
  promoCodeId: string;
  secondHelperFee: number | null;
  secondHelperLoadPrice: number | null;
  secondHelperUnloadPrice: number | null;
  startDateTime: string;
  stops: OrderStop[];
  tipOverride: number | null;
  tipPrice: number | null;
  totalMileage: number | null;
  trailerPrice: number | null;
  unloadHelperCount: number | null;
  unloadHelperPrice: number | null;
  weight: number | null;
  weightPrice: number | null;
  width: string | null;
  pickupTimeFrom: string;
  pickupTimeUntil: string;
  driverBonusPayment: number;
  helperBonusPayment: number;
  pickupLocationDescription: string;
  deliveryLocationDescription: string;
  itemCost: number;
}

export type OrderFormData = {
  customerId: string;
  driverGroupId: string;
  driverId: string;
  merchantId: string;
  promoCodeId: string;
  loadHelperCount: number;
  unloadHelperCount: number;
  weight: number;
  length: string;
  width: string;
  height: string;
  tipOverride: number;
  startDateTime: Date | null;
  startDateTimeClientTimeZone: string;
  paymentType: string;
  deviceType: string;
  stops: OrderStopFormData[];
  images: Image[];
  itemsDescription: string;
  pickupType: string;
  deliveryWindow: string;
  pickupTimeFrom: string;
  pickupTimeUntil: string;
  merchantOrderNumber: string;
  completedAt: Date | null;
  trailerId: string;
  categoryId: string;
  pickupLocationDescription: string;
  deliveryLocationDescription: string;
  itemCost: number;
  images2?: Image[];
};

export interface OrderStop {
  id: string;
  orderId: string;
  fromAddress: Address | null;
  toAddress: Address | null;
  stopNumber: number | null;
  shippingDistance: number | null;
  shippingDistancePrice: number | null;
  itemQuantity: number | null;
  itemLength: number | null;
  itemWidth: number | null;
  itemHeight: number | null;
  itemWeight: number | null;
  deliveryDateTime: string;
  pickupDateTime: string;
  driverPickupNote: string;
  driverDeliveryNote: string;
  senderPersonName: string;
  senderPersonPhone: string;
  receivePersonName: string;
  receivePersonPhone: string;
}

export interface OrderStopFormData {
  address: AddressFormData | null;
  pickupNote: string;
  driverDeliveryNote: string;
  itemsToBeDeliveredNote: string;
  contactName: string;
  contactPhone: string;
  stopNumber: number | null;
  addressName?: string;
}

export interface OrderImage {
  id: string;
  fileId: string;
  fileUrl: string;
}

export interface OrderImageFormData {
  fileId: string;
}
