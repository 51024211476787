import { ChartCard } from 'components';
import { useUserReportingPage } from '../hooks';

export function TotalDriverChart() {
  const { driverData, innerCardDriver } = useUserReportingPage();

  return (
    <ChartCard
      title="New Drivers"
      subtitle="Over Time"
      chartData={driverData}
      type="bar"
      innerCardData={innerCardDriver}
      height="100%"
    />
  );
}
