// generating pickup times with 15 minutes interval
export const allItemPickupTimes = Array.from({ length: 96 }, (_, i) => {
  const hour = Math.floor(i / 4);
  const minute = (i % 4) * 15;
  const ampm = hour < 12 ? 'AM' : 'PM';
  // eslint-disable-next-line no-nested-ternary
  const hour12 = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
  return {
    value: `${hour12}:${minute < 10 ? `0${minute}` : minute} ${ampm}`,
  };
});
