import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';

import { MessageType, MetaData, showMessage, SortDirection, usePagination } from 'helpers';
import { postPaymentStatus, PaymentStatus } from 'services/orders';
import { fetchMerchantOrderPayments, MerchantOrderPayment } from 'services/merchantPayments';

const MerchantReportContext = createContext<{
  merchantOrderPayments: MerchantOrderPayment[];
  metaData?: MetaData;
  currentPage: number;
  pendingPayment: boolean;
  merchantsLoading: boolean;
  updateMerchantPaymentStatus: (id: string, paymentStatus: PaymentStatus) => Promise<void>;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
}>({} as any);

export const MerchantReportPageProvider = ({ children = <Outlet /> }: Props) => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
  } = usePagination({ sortBy: 'orderDate', sortDirection: SortDirection.Desc });

  const [pendingPayment, setPendingPayment] = useState<boolean>(false);

  const { data: queryData, isLoading: merchantsLoading } = useQuery(
    ['merchantOrderPayments', currentPage, pageSize, sortBy, sortDirection, debouncedSearch],
    () =>
      fetchMerchantOrderPayments({ page: currentPage, pageSize, sortBy, sortDirection, search: debouncedSearch }, id!),
  );

  const merchantOrderPayments = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const updateMerchantPaymentStatus = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    async (id: string, paymentStatus: PaymentStatus) => {
      try {
        setPendingPayment(true);
        await postPaymentStatus(id, paymentStatus);
        queryClient.invalidateQueries('merchantOrderPayments');
        showMessage('Success', MessageType.Success);
      } catch (err) {
        showMessage('Something went wrong.', MessageType.Error);
      }

      setPendingPayment(false);
    },
    [queryClient],
  );

  const providerValue = useMemo(
    () => ({
      id,
      merchantOrderPayments,
      metaData,
      merchantsLoading,
      search,
      currentPage,
      pendingPayment,
      updateMerchantPaymentStatus,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onSortChange,
    }),
    [
      id,
      merchantOrderPayments,
      metaData,
      merchantsLoading,
      search,
      currentPage,
      pendingPayment,
      updateMerchantPaymentStatus,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onSortChange,
    ],
  );

  return <MerchantReportContext.Provider value={providerValue}>{children}</MerchantReportContext.Provider>;
};

export const useMerchantReportPage = () => {
  return useContext(MerchantReportContext);
};

interface Props {
  children?: React.ReactNode;
}
