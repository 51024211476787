import React, { createContext, useContext, useMemo, useState, useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { MetaData, SortDirection, usePagination, MessageType, showMessage } from 'helpers';
import { fetchReviews, Review, deleteReview, changeReviewStatus } from 'services/reviews';
import { PutReview } from 'services/reviews/consts';

const DriverReviewsContext = createContext<{
  driverReviews: Review[];
  metaData?: MetaData;
  currentPage: number;
  search: string;
  reviewsLoading: boolean;
  reviewToDelete: Review | null;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  onSortChange: (accessor: string, sort: SortDirection) => void;
  onSearchChange: (search: string) => void;
  openConfirmationModal: (selectedReview: Review | null) => void;
  closeConfirmationModal: () => void;
  onDeleteConfirm: () => void;
  onApproval: ({ id, status }: PutReview) => void;
  onRowClick: (row: Review) => void;
}>({} as any);

// This will be used in case we state shared inside the module
export const DriverReviewsPageProvider = ({ children = <Outlet /> }: Props) => {
  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
  } = usePagination({ sortBy: 'createdAt', sortDirection: SortDirection.Desc });

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [reviewToDelete, setReviewToDelete] = useState<Review | null>(null);

  const { data: queryData, isLoading: reviewsLoading } = useQuery(
    ['driverReviews', currentPage, pageSize, sortBy, sortDirection, debouncedSearch, filters],
    () => fetchReviews({ page: currentPage, pageSize, sortBy, sortDirection, search: debouncedSearch, filters }),
  );

  const { mutateAsync: removeReview } = useMutation(deleteReview);
  const { mutateAsync: approveReview } = useMutation(changeReviewStatus);

  const driverReviews = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onRowClick = useCallback(
    (row: Review) => {
      navigate(`/reviews/${row.id}`);
    },
    [navigate],
  );

  const onApproval = useCallback(
    async ({ id, status }: PutReview) => {
      try {
        await approveReview({ id, status });
        showMessage(`Review successfully ${status}.`, MessageType.Success);
        queryClient.invalidateQueries('driverReviews');
      } catch {
        showMessage('Something went wrong.', MessageType.Error);
      }
    },
    [queryClient, approveReview],
  );

  const onDeleteConfirm = useCallback(async () => {
    if (reviewToDelete) {
      try {
        await removeReview(reviewToDelete.id);
        showMessage('Review successfully deleted.', MessageType.Success);
        queryClient.invalidateQueries('driverReviews');
        setReviewToDelete(null);
      } catch {
        showMessage('Something went wrong.', MessageType.Error);
      }
    }
  }, [queryClient, removeReview, reviewToDelete]);

  const openConfirmationModal = useCallback((selectedReview: Review | null) => {
    if (selectedReview) setReviewToDelete(selectedReview);
  }, []);

  const closeConfirmationModal = useCallback(() => {
    setReviewToDelete(null);
  }, []);

  const providerValue = useMemo(
    () => ({
      driverReviews,
      metaData,
      reviewsLoading,
      search,
      currentPage,
      reviewToDelete,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onSortChange,
      openConfirmationModal,
      closeConfirmationModal,
      onDeleteConfirm,
      onApproval,
      onRowClick,
    }),
    [
      driverReviews,
      metaData,
      reviewsLoading,
      search,
      currentPage,
      reviewToDelete,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onSortChange,
      openConfirmationModal,
      closeConfirmationModal,
      onDeleteConfirm,
      onApproval,
      onRowClick,
    ],
  );

  return <DriverReviewsContext.Provider value={providerValue}>{children}</DriverReviewsContext.Provider>;
};

export const useDriverReviewPage = () => {
  return useContext(DriverReviewsContext);
};

interface Props {
  children?: React.ReactNode;
}
