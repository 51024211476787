import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export enum PageType {
  Create = 'new',
}

export enum PaymentStatus {
  Pending = 'pending',
  Paid = 'paid',
  Deleted = 'deleted',
}

export const currencyBase = {
  en: {
    locale: 'en-US',
    currency: 'USD',
    style: 'currency',
    maximumFractionDigits: 2,
  },
};

export const useIdFromParams = () => {
  const params = useParams();

  const id = useMemo(() => {
    if (params.id === 'new') {
      return PageType.Create;
    }
    return params.id || '';
  }, [params.id]);

  const isCreate = useMemo(() => {
    return id === PageType.Create;
  }, [id]);

  return { id, isCreate };
};

export const capitalizeFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const shortenId = (id: string | null) => {
  return id ? id.substring(id.length - 6) : '';
};

export const formatDate = (date: Date | null) => {
  return dayjs(date).format('L LT');
};

export const formatDateShort = (date: string) => {
  return dayjs(date).format('l');
};

export const formatNumber = (value: number, digits = 2) => {
  return value.toLocaleString(undefined, { minimumFractionDigits: digits });
};

export const createDownloadLink = (data: BlobPart, fileName: string, extension: string = 'csv') => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.${extension}`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const currencyFormatter = (value: number, options?: object): string => {
  const currency = new Intl.NumberFormat(currencyBase.en.locale, {
    style: currencyBase.en.style,
    currency: currencyBase.en.currency,
    maximumFractionDigits: currencyBase.en.maximumFractionDigits,
    ...options,
  });
  return currency.format(value);
};

export const numberFormatter = (value: number, options?: object): string => {
  const number = new Intl.NumberFormat(currencyBase.en.locale, {
    maximumFractionDigits: currencyBase.en.maximumFractionDigits,
    ...options,
  });
  return number.format(value);
};
