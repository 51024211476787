import { Button, Container, Header, Divider, FormItem, Input, Select } from 'components';
import { DaysInWeek } from './const';

import { useSettingPage } from './SettingPageProvider';

export const SettingPage = () => {
  const { formState, onSubmit, register, control } = useSettingPage();

  return (
    <>
      <Header title="Edit setting">
        <Button type="submit" onClick={onSubmit} label="Save" form="settingForm" />
      </Header>

      <Container>
        <div className="bg-white rounded-lg shadow">
          <div className="px-4 py-5 sm:p-6">
            <form className="space-y-10" onSubmit={onSubmit} noValidate id="settingForm">
              <Divider title="Setting information" />

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <FormItem
                  label="Admin email"
                  htmlFor="adminEmail"
                  error={formState.errors.adminEmail?.message}
                  required
                >
                  <Input
                    {...register('adminEmail')}
                    id="adminEmail"
                    type="text"
                    placeholder="Enter admin email"
                    autoComplete="adminEmail"
                    required
                    error={!!formState.errors.adminEmail?.message}
                  />
                </FormItem>

                <FormItem label="From email" htmlFor="fromEmail" error={formState.errors.fromEmail?.message} required>
                  <Input
                    {...register('fromEmail')}
                    id="fromEmail"
                    type="text"
                    placeholder="Enter from email"
                    autoComplete="fromEmail"
                    required
                    error={!!formState.errors.fromEmail?.message}
                  />
                </FormItem>

                <FormItem
                  label="From email name"
                  htmlFor="fromEmailName"
                  error={formState.errors.fromEmailName?.message}
                  required
                >
                  <Input
                    {...register('fromEmailName')}
                    id="fromEmailName"
                    type="text"
                    placeholder="Enter from email name"
                    autoComplete="fromEmailName"
                    required
                    error={!!formState.errors.fromEmailName?.message}
                  />
                </FormItem>

                <FormItem
                  label="Contact phone"
                  htmlFor="contactPhone"
                  error={formState.errors.contactPhone?.message}
                  required
                >
                  <Input
                    {...register('contactPhone')}
                    id="contactPhone"
                    type="text"
                    placeholder="Enter admin email"
                    autoComplete="contactPhone"
                    required
                    error={!!formState.errors.contactPhone?.message}
                  />
                </FormItem>

                <FormItem
                  label="Default driver payment (percentage)"
                  htmlFor="driverPercentage"
                  error={formState.errors.driverPercentage?.message}
                >
                  <Input
                    {...register('driverPercentage')}
                    id="driverPercentage"
                    type="number"
                    placeholder="Enter driver fee"
                    autoComplete="driverPercentage"
                    error={!!formState.errors.driverPercentage?.message}
                  />
                </FormItem>

                <FormItem
                  label="Default driver tip amount (percentage)"
                  htmlFor="driverTipPercentage"
                  error={formState.errors.driverTipPercentage?.message}
                >
                  <Input
                    {...register('driverTipPercentage')}
                    id="driverTipPercentage"
                    type="number"
                    placeholder="Enter driver payment"
                    autoComplete="driverTipPercentage"
                    error={!!formState.errors.driverTipPercentage?.message}
                  />
                </FormItem>

                <FormItem
                  label="Expedited fee (percentage)"
                  htmlFor="expeditedFeePercentage"
                  error={formState.errors.expeditedFeePercentage?.message}
                >
                  <Input
                    {...register('expeditedFeePercentage')}
                    id="expeditedFeePercentage"
                    type="number"
                    placeholder="Enter expedited fee"
                    autoComplete="expeditedFeePercentage"
                    error={!!formState.errors.expeditedFeePercentage?.message}
                  />
                </FormItem>

                <FormItem
                  label="Expedited fee treshold hours"
                  htmlFor="expeditedFeeThresholdHours"
                  error={formState.errors.expeditedFeeThresholdHours?.message}
                >
                  <Input
                    {...register('expeditedFeeThresholdHours')}
                    id="expeditedFeeThresholdHours"
                    type="number"
                    placeholder="Enter expedited fee"
                    autoComplete="expeditedFeeThresholdHours"
                    error={!!formState.errors.expeditedFeeThresholdHours?.message}
                  />
                </FormItem>

                <FormItem
                  label="First helper load fee"
                  htmlFor="firstHelperLoadFee"
                  error={formState.errors.firstHelperLoadFee?.message}
                >
                  <Input
                    {...register('firstHelperLoadFee')}
                    id="firstHelperLoadFee"
                    type="number"
                    placeholder="Enter first helper load"
                    autoComplete="firstHelperLoadFee"
                    error={!!formState.errors.firstHelperLoadFee?.message}
                  />
                </FormItem>

                <FormItem
                  label="First helper unload fee"
                  htmlFor="firstHelperPercentage"
                  error={formState.errors.firstHelperUnloadFee?.message}
                >
                  <Input
                    {...register('firstHelperUnloadFee')}
                    id="firstHelperUnloadFee"
                    type="number"
                    placeholder="Enter first helper payment"
                    autoComplete="firstHelperUnloadFee"
                    error={!!formState.errors.firstHelperUnloadFee?.message}
                  />
                </FormItem>

                <FormItem
                  label="First helper payment (percentage)"
                  htmlFor="firstHelperPercentage"
                  error={formState.errors.firstHelperPercentage?.message}
                >
                  <Input
                    {...register('firstHelperPercentage')}
                    id="firstHelperPercentage"
                    type="number"
                    placeholder="Enter first helper unload"
                    autoComplete="firstHelperPercentage"
                    error={!!formState.errors.firstHelperPercentage?.message}
                  />
                </FormItem>

                <FormItem label="Payment day" htmlFor="paymentDay" error={formState.errors.paymentDay?.message}>
                  <Select
                    options={Object.values(DaysInWeek)}
                    control={control}
                    name="paymentDay"
                    error={!!formState.errors.paymentDay?.message}
                  />
                </FormItem>

                <FormItem
                  label="Second helper load fee"
                  htmlFor="secondHelperLoadFee"
                  error={formState.errors.secondHelperLoadFee?.message}
                >
                  <Input
                    {...register('secondHelperLoadFee')}
                    id="secondHelperLoadFee"
                    type="number"
                    placeholder="Enter second helper load"
                    autoComplete="secondHelperLoadFee"
                    error={!!formState.errors.secondHelperLoadFee?.message}
                  />
                </FormItem>

                <FormItem
                  label="Second helper payment (percentage)"
                  htmlFor="secondHelperPercentage"
                  error={formState.errors.secondHelperPercentage?.message}
                >
                  <Input
                    {...register('secondHelperPercentage')}
                    id="secondHelperPercentage"
                    type="number"
                    placeholder="Enter second helper payment"
                    autoComplete="secondHelperPercentage"
                    error={!!formState.errors.secondHelperPercentage?.message}
                  />
                </FormItem>

                <FormItem
                  label="Second helper unload fee"
                  htmlFor="secondHelperUnloadFee"
                  error={formState.errors.secondHelperUnloadFee?.message}
                >
                  <Input
                    {...register('secondHelperUnloadFee')}
                    id="secondHelperUnloadFee"
                    type="number"
                    placeholder="Enter second helper unload"
                    autoComplete="secondHelperUnloadFee"
                    error={!!formState.errors.secondHelperUnloadFee?.message}
                  />
                </FormItem>

                <FormItem
                  label="Unassigned order time"
                  htmlFor="unassignedOrderTime"
                  error={formState.errors.unassignedOrderTime?.message}
                >
                  <Input
                    {...register('unassignedOrderTime')}
                    id="unassignedOrderTime"
                    type="number"
                    placeholder="Enter unassigned order time"
                    autoComplete="unassignedOrderTime"
                    error={!!formState.errors.unassignedOrderTime?.message}
                  />
                </FormItem>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};
