/* eslint-disable jsx-a11y/label-has-associated-control */
import { PlusIcon } from '@heroicons/react/outline';
import { Button, ConfirmationModal, Container, Header, Table } from 'components';
import { HeaderSearch } from 'components/Header/HeaderSearch';
import { ItemGroup } from 'services/itemGroups';

import { columns } from './const';
import { useItemGroupsPage } from './hooks';

export const ItemGroupsPage = () => {
  const {
    itemGroups,
    metaData,
    isItemGroupsDataLoading,
    itemGroupToDelete,
    isItemGroupDeleting,
    currentPage,
    search,
    onItemGroupRowClick,
    onDeleteConfirm,
    onNewItemGroupClick,
    openConfirmationModal,
    closeConfirmationModal,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onSearchChange,
  } = useItemGroupsPage();

  return (
    <>
      <Header title="Item Groups">
        <div className="flex justify-between flex-1">
          <HeaderSearch onChange={(e: any) => onSearchChange(e.target.value)} value={search} />
          <Button onClick={onNewItemGroupClick} label="New Item Group" icon={<PlusIcon />} />
        </div>
      </Header>

      <Container>
        <Table<ItemGroup>
          data={itemGroups}
          pagination={metaData}
          currentPage={currentPage}
          isLoading={isItemGroupsDataLoading}
          columns={columns(openConfirmationModal)}
          onRowClick={onItemGroupRowClick}
          pageClicked={onPageChange}
          pageSizeClicked={onPageSizeChange}
          headerClicked={onSortChange}
        />
      </Container>
      <ConfirmationModal
        title="Delete ItemGroup"
        body="Are you sure you want to delete this itemGroup?"
        open={!!itemGroupToDelete}
        onAction={onDeleteConfirm}
        onClose={closeConfirmationModal}
        actionButtonDisabled={isItemGroupDeleting}
      />
    </>
  );
};
