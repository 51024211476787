import * as yup from 'yup';
import { ProfileForm } from './types';

export const defaultProfileForm: ProfileForm = {
  firstName: '',
  lastName: '',
  password: '',
  repeatPassword: '',
};

export const PROFILE_VALIDATION_SCHEMA = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string(),
  repeatPassword: yup.string().test('is-repeated', 'Please repeat password', (value: any, allValues: any) => {
    const { password } = allValues.parent;
    return value === password;
  }),
});
