import { CellProps } from 'react-table';

import { TableColumn, Tooltip } from 'components';
import { shortenId } from 'helpers';
import { ItemCategory } from 'services/itemCategories';
import { CheckIcon, XIcon } from '@heroicons/react/solid';

export const columns = (deleteClicked: (rowData: ItemCategory) => void): TableColumn<ItemCategory>[] => [
  {
    Header: 'ID',
    accessor: 'id',
    isSortable: true,
    Cell: ({ row }: CellProps<ItemCategory>) => {
      const rowData = row.original;
      return (
        <Tooltip
          popoverMessage="Copied!"
          tooltipMessage={rowData.id}
          onClick={() => navigator.clipboard.writeText(rowData.id)}
        >
          {shortenId(rowData.id)}
        </Tooltip>
      );
    },
  },
  {
    Header: 'Name',
    accessor: 'name',
    isSortable: true,
  },
  {
    Header: 'Item Group',
    accessor: 'itemGroup' as any,
    Cell: ({ row }: CellProps<ItemCategory>) => {
      const rowData = row.original;

      return <span>{rowData.itemGroup?.name}</span>;
    },
    isSortable: true,
  },
  {
    Header: 'Show in app',
    accessor: 'showInApp' as any,
    Cell: ({ row }: CellProps<ItemCategory>) => {
      const rowData = row.original;

      return rowData.showInApp ? (
        <CheckIcon className="w-6 h-6 text-green-400" />
      ) : (
        <XIcon className="w-6 h-6 text-red-400" />
      );
    },
    isSortable: true,
  },
  {
    id: 'deleteButton',
    Header: '',
    Cell: ({ row }: CellProps<ItemCategory>) => {
      const rowData = row.original;

      return (
        <div className="flex justify-end">
          <button
            type="button"
            className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={(ev) => {
              ev.stopPropagation();
              deleteClicked(rowData);
            }}
          >
            Delete
          </button>
        </div>
      );
    },
    width: 'auto',
  },
];
