import { MerchantOrderPayment } from './types';

export const resToMerchantOrderPayment = (res: any): MerchantOrderPayment => {
  return {
    id: res.id,
    orderId: res.orderId ?? '',
    customerId: res.customerId ?? '',
    customerName: res.customerName ?? '',
    driverName: res.driverName ?? '',
    merchantId: res.merchantId ?? '',
    orderDate: res.orderDate ?? '',
    isHelper: res.isHelper || false,
    isExtraTip: res.isExtraTip || false,
    amount: res.amount ?? null,
    status: res.status ?? '',
    paidAt: res.paidAt ?? '',
    createdAt: res.createdAt ?? '',
    merchantName: res.merchantName ?? '',
  };
};
