import { PageType } from 'helpers';

import { WeightPriceForm } from './types';

export const defaultWeightPriceForm: WeightPriceForm = {
  id: PageType.Create,
  weightLbsFrom: null,
  weightLbsTo: null,
  weightFee: null,
};
