/* eslint-disable jsx-a11y/label-has-associated-control */
import { PlusIcon } from '@heroicons/react/outline';
import { Button, ConfirmationModal, Container, Header, Table } from 'components';
import { HeaderSearch } from 'components/Header/HeaderSearch';
import { PromoCode } from 'services/promoCodes';

import { usePromoCodesPage } from './hooks';
import { columns } from './const';

export const PromoCodesPage = () => {
  const {
    promoCodes,
    metaData,
    currentPage,
    isLoading,
    search,
    promoCodeToDelete,
    openConfirmationModal,
    closeConfirmationModal,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onRowClick,
    onDeleteConfirm,
    onNewClick,
    onSortChange,
  } = usePromoCodesPage();

  return (
    <>
      <Header title="Promo codes">
        <div className="flex justify-between flex-1">
          <HeaderSearch onChange={(e: any) => onSearchChange(e.target.value)} value={search} />

          <Button onClick={onNewClick} label="New promo code" icon={<PlusIcon />} />
        </div>
      </Header>

      <Container>
        <Table<PromoCode>
          data={promoCodes ?? []}
          columns={columns(openConfirmationModal)}
          pagination={metaData}
          currentPage={currentPage}
          isLoading={isLoading}
          onRowClick={onRowClick}
          pageClicked={onPageChange}
          pageSizeClicked={onPageSizeChange}
          headerClicked={onSortChange}
        />
      </Container>

      <ConfirmationModal
        title="Delete promo code"
        body="Are you sure you want to delete this promo code?"
        open={!!promoCodeToDelete}
        onAction={onDeleteConfirm}
        onClose={closeConfirmationModal}
      />
    </>
  );
};
