import { SearchIcon, TrashIcon } from '@heroicons/react/outline';
import { Button, FormItem, AsyncSelect, DatePicker } from 'components';
import { Driver, fetchDrivers } from 'services/drivers';

import { useDriverReportsPage } from '../DriverReportsPageProvider';

export const DriverReportsPageAdvancedSearch = () => {
  const { control, onSubmit, onClearFilters } = useDriverReportsPage();

  return (
    <div className="container z-10 p-4 pb-0 mx-auto sm:px-6 sm:pt-6 lg:px-8 lg:pt-8">
      <div className="gap-4 p-6 bg-white rounded-lg shadow">
        <div className="grid grid-cols-1 gap-4 align-middle lg:grid-cols-3">
          <FormItem label="Driver Name">
            <AsyncSelect
              asyncFunction={fetchDrivers}
              getOptionLabel={(option: Driver) => `${option.user?.firstName ?? ''} ${option.user?.lastName ?? ''}`}
              control={control}
              name="driverId"
              valueKey="id"
              placeholder="Select driver"
            />
          </FormItem>
          <FormItem label="From Date">
            <DatePicker placeholder="Select from Date" control={control} name="fromDate" />
          </FormItem>

          <FormItem label="To Date">
            <DatePicker placeholder="Select to Date" control={control} name="toDate" />
          </FormItem>
        </div>

        <div className="flex items-center justify-end w-full mt-4">
          <div className="flex items-center">
            <Button type="button" label="Search" onClick={onSubmit} icon={<SearchIcon />} className="mr-2" />
            <Button type="button" label="Clear" onClick={onClearFilters} icon={<TrashIcon />} color="secondary" />
          </div>
        </div>
      </div>
    </div>
  );
};
