import { useNavigate } from 'react-router-dom';

import { Button, Container, Header, Divider, FormItem, Input } from 'components';
import { Checkbox } from 'components/Checkbox';

import { useCustomerPage } from './CustomerPageProvider';

export const CustomerPage = () => {
  const { isCreate, formState, onSubmit, register } = useCustomerPage();
  const navigate = useNavigate();

  return (
    <>
      <Header title={isCreate ? 'New customer' : 'Edit customer'} onGoBackPress={() => navigate(-1)}>
        <Button type="submit" onClick={onSubmit} label="Save" form="customerForm" />
      </Header>

      <Container>
        <div className="overflow-hidden bg-white rounded-lg shadow">
          <div className="px-4 py-5 sm:p-6">
            <form className="space-y-10" onSubmit={onSubmit} noValidate id="customerForm">
              <Divider title="Customer information" />

              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <FormItem label="First name" htmlFor="firstName" error={formState.errors.firstName?.message} required>
                  <Input
                    {...register('firstName')}
                    id="firstName"
                    type="text"
                    placeholder="Enter first name"
                    autoComplete="firstName"
                    required
                    error={!!formState.errors.firstName?.message}
                  />
                </FormItem>

                <FormItem label="Last name" htmlFor="lastName" error={formState.errors.lastName?.message} required>
                  <Input
                    {...register('lastName')}
                    id="lastName"
                    type="text"
                    placeholder="Enter last name"
                    autoComplete="lastName"
                    required
                    error={!!formState.errors.lastName?.message}
                  />
                </FormItem>

                <FormItem label="Email" htmlFor="email" error={formState.errors.email?.message} required>
                  <Input
                    {...register('email')}
                    id="email"
                    type="text"
                    placeholder="Enter email"
                    autoComplete="email"
                    required
                    error={!!formState.errors.email?.message}
                  />
                </FormItem>

                <FormItem
                  label="Password"
                  htmlFor="password"
                  error={formState.errors.password?.message}
                  required={isCreate}
                >
                  <Input
                    {...register('password')}
                    id="password"
                    type="text"
                    placeholder="Enter password"
                    autoComplete="password"
                    required={isCreate}
                    error={!!formState.errors.password?.message}
                  />
                </FormItem>

                <FormItem label="Phone" htmlFor="phone" error={formState.errors.phone?.message} required>
                  <Input
                    {...register('phone')}
                    id="phone"
                    type="text"
                    placeholder="Enter phone"
                    autoComplete="phone"
                    required
                    error={!!formState.errors.phone?.message}
                  />
                </FormItem>

                <FormItem label="ZIP code" htmlFor="zip" error={formState.errors.zip?.message} required>
                  <Input
                    {...register('zip')}
                    id="zip"
                    type="text"
                    placeholder="Enter ZIP code"
                    autoComplete="zip"
                    required
                    error={!!formState.errors.zip?.message}
                  />
                </FormItem>

                <FormItem label="Lead source" htmlFor="leadSource" error={formState.errors.leadSource?.message}>
                  <Input
                    {...register('leadSource')}
                    id="leadSource"
                    type="text"
                    placeholder="Enter lead source"
                    autoComplete="leadSource"
                    error={!!formState.errors.leadSource?.message}
                  />
                </FormItem>

                <FormItem label="Referral code" htmlFor="referralCode" error={formState.errors.referralCode?.message}>
                  <Input
                    {...register('referralCode')}
                    id="referralCode"
                    type="text"
                    placeholder="Enter referral code"
                    autoComplete="referralCode"
                    error={!!formState.errors.referralCode?.message}
                  />
                </FormItem>

                <FormItem label="Customer type" htmlFor="type" error={formState.errors.type?.message} required>
                  <Input
                    {...register('type')}
                    id="type"
                    type="text"
                    placeholder="Enter customer type"
                    autoComplete="type"
                    required
                    error={!!formState.errors.type?.message}
                  />
                </FormItem>

                <div />

                <div>
                  <Checkbox
                    {...register('isInvoiced')}
                    id="isInvoiced"
                    label="Is customer to be invoiced?"
                    name="isInvoiced"
                  />

                  <Checkbox
                    {...register('isTest')}
                    id="isTest"
                    label="Is customer for testing purposes?"
                    name="isTest"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};
