import { SearchIcon, TrashIcon } from '@heroicons/react/outline';
import { Button, DatePicker, FormItem, Select, AsyncSelect, Checkbox } from 'components';
import { Customer, fetchCustomers } from 'services/customers';
import { Driver, fetchDrivers } from 'services/drivers';
import { fetchMerchants, Merchant } from 'services/merchants';
import { OrderStatus, PaymentStatus } from 'services/orders';

import { useOrdersPage } from '../OrdersPageProvider';

export const OrdersPageAdvancedSearch = () => {
  const { register, control, onSubmit, onClearFilters } = useOrdersPage();

  return (
    <div className="container z-10 p-4 pb-0 mx-auto sm:px-6 sm:pt-6 lg:px-8 lg:pt-8">
      <div className="gap-4 p-6 bg-white rounded-lg shadow">
        <div className="grid grid-cols-1 gap-4 align-middle lg:grid-cols-3">
          <FormItem label="Driver Name">
            <AsyncSelect
              asyncFunction={fetchDrivers}
              getOptionLabel={(option: Driver) => `${option.user?.firstName ?? ''} ${option.user?.lastName ?? ''}`}
              control={control}
              name="driverId"
              valueKey="id"
              placeholder="Select driver"
            />
          </FormItem>

          <FormItem label="Customer Name">
            <AsyncSelect
              asyncFunction={fetchCustomers}
              getOptionLabel={(option: Customer) => `${option.user?.firstName ?? ''} ${option.user?.lastName ?? ''}`}
              control={control}
              name="customerId"
              valueKey="id"
              placeholder="Select customer"
            />
          </FormItem>

          <FormItem label="Merchant Name">
            <AsyncSelect
              asyncFunction={fetchMerchants}
              getOptionLabel={(option: Merchant) => `${option.user?.firstName ?? ''} ${option.user?.lastName ?? ''}`}
              control={control}
              name="merchantId"
              valueKey="id"
              placeholder="Select merchant"
            />
          </FormItem>

          <FormItem label="Select Status">
            <Select control={control} name="status" options={Object.values(OrderStatus)} placeholder="Select status" />
          </FormItem>

          <FormItem label="Payment Status">
            <Select
              control={control}
              name="paymentStatus"
              options={Object.values(PaymentStatus)}
              placeholder="Select payment status"
            />
          </FormItem>
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
            <FormItem label="From Date">
              <DatePicker placeholder="Select from Date" control={control} name="dateFrom" />
            </FormItem>

            <FormItem label="To Date">
              <DatePicker placeholder="Select to Date" control={control} name="dateTo" />
            </FormItem>
          </div>
        </div>

        <div className="flex items-center justify-between w-full mt-4">
          <Checkbox {...register('isInvoiced')} label="Is Invoiced?" />

          <div className="flex items-center">
            <Button type="button" label="Search" onClick={onSubmit} icon={<SearchIcon />} className="mr-2" />
            <Button type="button" label="Clear" onClick={onClearFilters} icon={<TrashIcon />} color="secondary" />
          </div>
        </div>
      </div>
    </div>
  );
};
