import { DataWithMeta, PaginationFilters, PaginationRequest, paginationRequestToUrl } from 'helpers';
import { OrderBonusPayments, OrderReimbursement } from 'modules/Orders/types';
import { BulkOrder, BulkOrderForm } from 'modules/Upload/types';
import { fetchCustomer } from 'services/customers';

import { Order, OrderFormData, PaymentStatus } from './types';
import { api } from '../api';
import {
  resToOrder,
  resToPostOrderBonusPayments,
  transformOrderBulk,
  transformToOrderData,
  transformToOrderFilters,
  transformToPostBulkOrder,
} from './transformations';

export const fetchOrders = async (pagination: PaginationRequest): Promise<DataWithMeta<Order>> => {
  const url = paginationRequestToUrl('/orders', pagination, transformToOrderFilters);

  const resp = await api.get(url);

  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToOrder),
  };
};

export const fetchOrder = async (id: string): Promise<Order> => {
  const resp = await api.get(`/orders/${id}`);
  const order = resToOrder(resp.data);

  const customerId = order.customer?.id;
  if (customerId) {
    const customer = await fetchCustomer(customerId);
    if (order.customer) {
      order.customer.user = customer.user;
    }
  }

  return order;
};

export const postOrder = async (data: OrderFormData): Promise<Order> => {
  const resp = await api.post(`/orders`, transformToOrderData(data));
  return resToOrder(resp.data);
};

export const postOrderCsv = async (data: FormData): Promise<BulkOrder[]> => {
  const resp = await api.post(`/orders/csv/parse`, data);
  return resp.data.map(transformOrderBulk);
};

export const postOrderBulk = async (data: BulkOrderForm) => {
  const resp = await api.post(`/orders/bulk`, transformToPostBulkOrder(data));
  return resp.data;
};

export const updatePaymentStatus = async (id: string, paymentStatus: PaymentStatus | null): Promise<any> => {
  await api.put(`/orders/${id}/payment_status`, { paymentStatus });
};

export const bulkPayOrderPayments = async (orderIds: string[], paymentStatus: PaymentStatus): Promise<any> => {
  await api.post(`/order_payments/status`, { orderIds, paymentStatus });
};

export const postPaymentStatus = async (id: string, paymentStatus: PaymentStatus): Promise<any> => {
  await api.post(`/order_payments/${id}/status`, { paymentStatus });
};

export const exportOrders = async (filters: PaginationFilters, search: string): Promise<any> => {
  const url = paginationRequestToUrl(
    '/orders/export',
    { page: 1, pageSize: 999, filters, search },
    transformToOrderFilters,
  );

  const resp = await api.post(url);

  return resp.data;
};

export const putOrderBonusPayments = async ({
  orderId,
  orderBonusPayments,
}: {
  orderId: string;
  orderBonusPayments: OrderBonusPayments;
}): Promise<any> => {
  await api.put(`/orders/${orderId}/bonus_payments`, resToPostOrderBonusPayments(orderBonusPayments));
};

export const putOrderReimbursement = async ({
  orderId,
  reimbursement,
}: {
  orderId: string;
  reimbursement: OrderReimbursement;
}): Promise<any> => {
  await api.put(`/orders/${orderId}/reimbursement_payments`, {
    reimbursementPayment: Number(reimbursement.reimbursementPayment),
  });
};

export const postMerchantToOrder = async ({
  orderId,
  merchantId,
}: {
  orderId: string;
  merchantId: string;
}): Promise<any> => {
  await api.post(`/orders/${orderId}/merchant/${merchantId}`);
};

export const postDriverToOrder = async ({
  ordersIds,
  driverId,
}: {
  ordersIds: string[];
  driverId: string;
}): Promise<any> => {
  await api.post(`/orders/driver/${driverId}`, {
    ordersIds,
  });
};

export const postUnassignDriverFromOrder = async ({ ordersIds }: { ordersIds: string[] }): Promise<any> => {
  await api.post(`/orders/driver/unassign`, { ordersIds });
};
