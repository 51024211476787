import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { MessageType, showMessage, usePagination } from 'helpers';
import { deletePromoCode, fetchPromoCodes, PromoCode } from 'services/promoCodes';

// Drivers page state
export const usePromoCodesPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
  } = usePagination();

  const [promoCodeToDelete, setPromoCodeToDelete] = useState<PromoCode | null>(null);

  const { data: queryData, isLoading: driversLoading } = useQuery(
    ['promoCodes', currentPage, pageSize, sortBy, sortDirection, debouncedSearch],
    () => fetchPromoCodes({ page: currentPage, pageSize, sortBy, sortDirection, search: debouncedSearch }),
  );
  const { mutateAsync: removePromoCode } = useMutation(deletePromoCode);

  const promoCodes = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onRowClick = (row: PromoCode) => {
    navigate(`/promo-codes/${row.id}`);
  };

  const onNewClick = () => {
    navigate('/promo-codes/new');
  };

  const onDeleteConfirm = async () => {
    if (promoCodeToDelete) {
      try {
        await removePromoCode(promoCodeToDelete.id);
        showMessage('Promo code“ successfully deleted.', MessageType.Success);
        queryClient.invalidateQueries('promoCodes');
        setPromoCodeToDelete(null);
      } catch {
        showMessage('Something went wrong.', MessageType.Error);
      }
    }
  };

  const openConfirmationModal = (selectedPromoCode: PromoCode | null) => {
    if (selectedPromoCode) setPromoCodeToDelete(selectedPromoCode);
  };

  const closeConfirmationModal = () => {
    setPromoCodeToDelete(null);
  };

  return {
    promoCodes,
    metaData,
    currentPage,
    search,
    isLoading: driversLoading,
    promoCodeToDelete,
    openConfirmationModal,
    closeConfirmationModal,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onDeleteConfirm,
    onRowClick,
    onNewClick,
    onSortChange,
  };
};
