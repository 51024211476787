import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { Control, useForm } from 'react-hook-form';

import { MessageType, MetaData, showMessage, SortDirection, usePagination } from 'helpers';
import { DriverReport, fetchDriverReports } from 'services/driverReports';
import { bulkPayOrderPayments, PaymentStatus } from 'services/orders';

import { defaultDriverReportsAdvancedSearchForm } from '../consts';
import { DriverReportsAdvancedSearchForm } from '../types';

const DriverReportsPageContext = createContext<{
  driverReports: DriverReport[];
  metaData: MetaData | undefined;
  currentPage: number;
  search: string;
  isLoading: boolean;
  control: Control<DriverReportsAdvancedSearchForm, any>;
  selectedDriver: DriverReport | undefined;
  modalVisible: boolean;
  pendingPayment: boolean;
  onPayButtonClicked: (driver: DriverReport) => void;
  onConfirmationModalCancelClicked: () => void;
  onConfirmationModalConfirmClicked: () => void;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  onSearchChange: (searchValue: string) => void;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  onRowClick: (row: DriverReport) => void;
  onSortChange: (accessor: string, sort: SortDirection) => void;
  onClearFilters: () => void;
}>({} as any);

// Drivers reports state
export const DriverReportsPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onFiltersChange,
  } = usePagination({ sortBy: 'orderCount', sortDirection: SortDirection.Desc });

  const [selectedDriver, setSelectedDriver] = useState<DriverReport>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [pendingPayment, setPendingPayment] = useState<boolean>(false);

  const { control, handleSubmit, reset, watch } = useForm<DriverReportsAdvancedSearchForm>({
    defaultValues: defaultDriverReportsAdvancedSearchForm,
  });
  const fromDate = watch('fromDate');
  const toDate = watch('toDate');

  const { data: queryData, isLoading: driversLoading } = useQuery(
    ['driverReports', currentPage, pageSize, sortBy, sortDirection, debouncedSearch, filters],
    () =>
      fetchDriverReports(
        { page: currentPage, pageSize, sortBy, sortDirection, search: debouncedSearch, filters },
        fromDate,
        toDate,
      ),
  );

  const driverReports = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onSubmit = handleSubmit((data: DriverReportsAdvancedSearchForm) => {
    onFiltersChange(data as any);
  });

  const onRowClick = useCallback(
    (row: DriverReport) => {
      navigate(`/driver-payments/${row.driverId}`);
    },
    [navigate],
  );
  const onClearFilters = useCallback(() => {
    reset(defaultDriverReportsAdvancedSearchForm);
    onSubmit();
  }, [reset, onSubmit]);

  const onPayButtonClicked = useCallback(
    (driver: DriverReport) => {
      setSelectedDriver(driver);
      setModalVisible(true);
      setPendingPayment(false);
    },
    [setModalVisible],
  );

  const onConfirmationModalCancelClicked = useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  const onConfirmationModalConfirmClicked = useCallback(async () => {
    if (selectedDriver) {
      setPendingPayment(true);
      try {
        await bulkPayOrderPayments(selectedDriver.unpaidOrderPaymentIds, PaymentStatus.Paid);
        queryClient.invalidateQueries('driverReports');
        showMessage(`Succesfully paid orders for driver ${selectedDriver.driverName}.`, MessageType.Success);
      } catch (err) {
        showMessage('Something went wrong.', MessageType.Error);
        setPendingPayment(false);
      }

      setModalVisible(false);
    }
  }, [selectedDriver, queryClient]);

  const providerValue = useMemo(
    () => ({
      driverReports,
      metaData,
      currentPage,
      search,
      isLoading: driversLoading,
      control,
      selectedDriver,
      modalVisible,
      pendingPayment,
      onPayButtonClicked,
      onConfirmationModalCancelClicked,
      onConfirmationModalConfirmClicked,
      onSubmit,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onRowClick,
      onSortChange,
      onClearFilters,
    }),
    [
      driverReports,
      metaData,
      currentPage,
      search,
      driversLoading,
      control,
      selectedDriver,
      modalVisible,
      pendingPayment,
      onPayButtonClicked,
      onConfirmationModalCancelClicked,
      onConfirmationModalConfirmClicked,
      onSubmit,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onRowClick,
      onSortChange,
      onClearFilters,
    ],
  );

  return <DriverReportsPageContext.Provider value={providerValue}>{children}</DriverReportsPageContext.Provider>;
};

export const useDriverReportsPage = () => {
  return useContext(DriverReportsPageContext);
};

interface Props {
  children?: React.ReactNode;
}
