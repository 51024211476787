import { createContext, useContext, useEffect, useMemo } from 'react';
import { Control, FormState, useForm, UseFormRegister } from 'react-hook-form';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';

import { MessageType, showMessage, useIdFromParams } from 'helpers';
import {
  DriverGroup,
  fetchAllDriverGroup,
  fetchDriverGroup,
  postDriverGroup,
  putDriverGroup,
} from 'services/driverGroups';
import { fetchAllTrailers } from 'services/trailers';

import { DRIVER_GROUP_VALIDATION_SCHEMA } from './const';
import { DriverGroupForm } from '../types';
import { driverGroupToDriverGroupForm } from './transformation';
import { defaultDriverGroupForm } from '../consts';

const DriverGroupPageContext = createContext<{
  formState: FormState<DriverGroupForm>;
  isCreate: boolean;
  driverGroups: DriverGroup[] | undefined;
  driverGroupId: string | undefined;
  relatedDriverGroups: {
    value: string;
    label: string;
  }[];
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  register: UseFormRegister<DriverGroupForm>;
  control: Control<DriverGroupForm, any>;
  relatedTrailers: {
    value: string;
    label: string;
  }[];
}>({} as any);

// This will be used in case we state shared inside the module
export const DriverGroupPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id: driverGroupId, isCreate } = useIdFromParams();

  const { data: driverGroup } = useQuery(['driverGroups', driverGroupId], () => fetchDriverGroup(driverGroupId), {
    enabled: !isCreate,
  });

  const { data: trailers } = useQuery('allTrailers', () => fetchAllTrailers());
  const { data: driverGroups } = useQuery('allDriverGroups', () => fetchAllDriverGroup());

  const { mutateAsync: updateDriverGroup } = useMutation(putDriverGroup);
  const { mutateAsync: createDriverGroup } = useMutation(postDriverGroup);

  const { handleSubmit, register, formState, reset, control } = useForm<DriverGroupForm>({
    defaultValues: defaultDriverGroupForm,
    resolver: yupResolver(DRIVER_GROUP_VALIDATION_SCHEMA),
  });

  const driverGroupForm = useMemo(() => {
    if (driverGroup) {
      return driverGroupToDriverGroupForm(driverGroup);
    }
    return defaultDriverGroupForm;
  }, [driverGroup]);

  const relatedTrailers = useMemo(
    () => trailers?.map((trailer) => ({ value: trailer.id, label: trailer.title })) || [],
    [trailers],
  );

  const relatedDriverGroups = useMemo(
    () =>
      (driverGroups &&
        driverGroups
          .filter((dg) => dg.id !== driverGroupId)
          .map((group) => ({ value: group.id, label: group.name }))) ||
      [],
    [driverGroups, driverGroupId],
  );
  useEffect(() => {
    reset(driverGroupForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverGroupForm]);

  const onSubmit = handleSubmit(async (values: DriverGroupForm) => {
    if (isCreate) {
      try {
        await createDriverGroup(values);
        showMessage('Driver group successfully created.', MessageType.Success);
        queryClient.invalidateQueries('driverGroups');
        navigate('/driver-groups');
      } catch {
        showMessage('Theres been an error!', MessageType.Error);
      }
      return;
    }

    try {
      await updateDriverGroup(values);
      showMessage('Driver group successfully updated.', MessageType.Success);
      queryClient.invalidateQueries('driverGroups');
      navigate('/driver-groups');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const providerValue = useMemo(
    () => ({
      relatedTrailers,
      formState,
      isCreate,
      driverGroups,
      driverGroupId,
      relatedDriverGroups,
      onSubmit,
      register,
      control,
    }),
    [
      relatedTrailers,
      formState,
      isCreate,
      driverGroups,
      driverGroupId,
      relatedDriverGroups,
      onSubmit,
      register,
      control,
    ],
  );

  return <DriverGroupPageContext.Provider value={providerValue}>{children}</DriverGroupPageContext.Provider>;
};

export const useDriverGroupPage = () => {
  return useContext(DriverGroupPageContext);
};

interface Props {
  children?: React.ReactNode;
}
