import { numberFormatter } from 'helpers';

export const initialTotalOrdersByStatusData = {
  series: [],
  options: {
    labels: [],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: false,
              fontSize: '1.5em',
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
      formatter: (val: any) => {
        return `${numberFormatter(val)}%`;
      },
    },
    legend: {
      position: 'bottom',
    },
  },
  averageSum: 0,
  totalSum: 0,
  type: '',
};
