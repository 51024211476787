import { PageType } from 'helpers';

import { StopPriceForm } from './types';

export const defaultStopPriceForm: StopPriceForm = {
  id: PageType.Create,
  stopsFrom: null,
  stopsTo: null,
  shippingFactor: null,
  firstHelperFactor: null,
  secondHelperFactor: null,
};
