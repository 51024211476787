import { createContext, useContext, useEffect, useMemo } from 'react';
import { Control, FormState, useForm, UseFormRegister } from 'react-hook-form';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';

import { fetchWeightPrice, postWeightPrice, putWeightPrice } from 'services/weightPrices';
import { MessageType, showMessage, useIdFromParams } from 'helpers';

import { WEIGHT_PRICE_VALIDATION_SCHEMA } from './const';
import { WeightPriceForm } from '../types';
import { defaultWeightPriceForm } from '../consts';
import { weightPriceToWeightPriceForm } from './transformation';

const WeightPricePageContext = createContext<{
  formState: FormState<WeightPriceForm>;
  isCreate: boolean;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  register: UseFormRegister<WeightPriceForm>;
  control: Control<WeightPriceForm, any>;
}>({} as any);

// This will be used in case we state shared inside the module
export const WeightPricePageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id: weightPriceId, isCreate } = useIdFromParams();

  const { data: weightPrice } = useQuery(['weightPrices', weightPriceId], () => fetchWeightPrice(weightPriceId), {
    enabled: !isCreate,
  });
  const { mutateAsync: updateWeightPrice } = useMutation(putWeightPrice);
  const { mutateAsync: createWeightPrice } = useMutation(postWeightPrice);

  const { handleSubmit, register, formState, reset, control } = useForm<WeightPriceForm>({
    defaultValues: defaultWeightPriceForm,
    resolver: yupResolver(WEIGHT_PRICE_VALIDATION_SCHEMA),
  });

  const weightPriceForm = useMemo(() => {
    if (weightPrice) {
      return weightPriceToWeightPriceForm(weightPrice);
    }
    return defaultWeightPriceForm;
  }, [weightPrice]);

  useEffect(() => {
    reset(weightPriceForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weightPriceForm]);

  const onSubmit = handleSubmit(async (values: WeightPriceForm) => {
    if (isCreate) {
      try {
        await createWeightPrice(values);
        showMessage('Weight price successfully created.', MessageType.Success);
        queryClient.invalidateQueries('weightPrices');
        navigate('/weight-prices');
      } catch {
        showMessage('Theres been an error!', MessageType.Error);
      }
      return;
    }

    try {
      await updateWeightPrice(values);
      showMessage('Weight price successfully updated.', MessageType.Success);
      queryClient.invalidateQueries('weightPrices');
      navigate('/weight-prices');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const providerValue = useMemo(
    () => ({ formState, isCreate, onSubmit, register, control }),
    [formState, isCreate, onSubmit, register, control],
  );

  return <WeightPricePageContext.Provider value={providerValue}>{children}</WeightPricePageContext.Provider>;
};

export const useWeightPricePage = () => {
  return useContext(WeightPricePageContext);
};

interface Props {
  children?: React.ReactNode;
}
