import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { AuthCardLayout, Button, FormItem, Input } from 'components';
import { login, LoginRequest } from 'services/auth';
import { MessageType, showMessage } from 'helpers';
import { useAuth } from 'GlobalProvider';

import { VALIDATION_SCHEMA } from './const';

export function SignIn() {
  const { loginUser } = useAuth();
  const { register, handleSubmit } = useForm<LoginRequest>({
    defaultValues: {
      username: '',
      password: '',
    },
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const onSubmit = handleSubmit(async (values) => {
    try {
      const authData = await login(values);
      loginUser(authData);
      showMessage('Successfully logged in.', MessageType.Success);
    } catch (error: any) {
      if (error.response) {
        showMessage(error.response.data.title, MessageType.Error);
      } else {
        showMessage(error.message, MessageType.Error);
      }
    }
  });

  return (
    <AuthCardLayout title="Sign in to your account">
      <form className="space-y-6" onSubmit={onSubmit}>
        <FormItem label="Email address" htmlFor="username">
          <Input {...register('username')} id="username" type="username" autoComplete="username" required />
        </FormItem>

        <FormItem label="Password" htmlFor="password">
          <Input {...register('password')} id="password" type="password" autoComplete="current-password" />
        </FormItem>

        <div>
          <Button fullWidth type="submit" label="Sign in" />
        </div>
      </form>
    </AuthCardLayout>
  );
}
