import { useNavigate } from 'react-router-dom';

import { Button, Container, Header, Divider, FormItem, Input } from 'components';

import { useWeightPricePage } from './WeightPricePageProvider';

export const WeightPricePage = () => {
  const { isCreate, formState, onSubmit, register } = useWeightPricePage();
  const navigate = useNavigate();

  return (
    <>
      <Header title={isCreate ? 'New weight price' : 'Edit weight price'} onGoBackPress={() => navigate(-1)}>
        <Button type="submit" onClick={onSubmit} label="Save" form="weightPriceForm" />
      </Header>

      <Container>
        <div className="overflow-hidden bg-white rounded-lg shadow">
          <div className="px-4 py-5 sm:p-6">
            <form className="space-y-10" onSubmit={onSubmit} noValidate id="weightPriceForm">
              <Divider title="Weight price information" />

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <FormItem
                  label="Weight lbs from"
                  htmlFor="weightLbsFrom"
                  error={formState.errors.weightLbsFrom?.message}
                  required
                >
                  <Input
                    {...register('weightLbsFrom')}
                    id="weightLbsFrom"
                    type="number"
                    placeholder="Enter weight lbs from"
                    autoComplete="weightLbsFrom"
                    required
                    error={!!formState.errors.weightLbsFrom?.message}
                  />
                </FormItem>

                <FormItem
                  label="Weight lbs to"
                  htmlFor="weightLbsTo"
                  error={formState.errors.weightLbsTo?.message}
                  required
                >
                  <Input
                    {...register('weightLbsTo')}
                    id="weightLbsTo"
                    type="number"
                    placeholder="Enter weight lbs to"
                    autoComplete="weightLbsTo"
                    required
                    error={!!formState.errors.weightLbsTo?.message}
                  />
                </FormItem>

                <FormItem label="Price" htmlFor="weightFee" error={formState.errors.weightFee?.message} required>
                  <Input
                    {...register('weightFee')}
                    id="weightFee"
                    type="number"
                    placeholder="Enter price"
                    autoComplete="weightFee"
                    required
                    error={!!formState.errors.weightFee?.message}
                  />
                </FormItem>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};
