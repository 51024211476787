import { ChartCard } from 'components';
import { useMilesCharts } from '../hooks';

export const AverageMilesBarChart = () => {
  const { averageMilesData, innerCardAverageMiles } = useMilesCharts();
  return (
    <ChartCard
      title="Average Miles"
      subtitle="Per Vehicle Size"
      chartData={averageMilesData}
      innerCardData={innerCardAverageMiles}
      height="100%"
    />
  );
};
