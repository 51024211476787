import { DataWithMeta, PaginationRequest } from 'helpers';
import { PromoCodeForm } from 'modules/PromoCodes/types';

import { api } from '../api';
import { promoCodeToPostReq, promoCodeToPutReq, resToPromoCode } from './transformations';
import { PromoCode } from './types';

export const fetchPromoCodes = async (pagination: PaginationRequest): Promise<DataWithMeta<PromoCode>> => {
  let url = `/promo_codes?page=${pagination.page}&pageSize=${pagination.pageSize}`;

  if (pagination.sortBy) url += `&sortBy=${pagination.sortBy}`;
  if (pagination.sortDirection) url += `&sortDirection=${pagination.sortDirection}`;
  if (pagination.search) url += `&search=${pagination.search}`;

  const resp = await api.get(url);
  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToPromoCode),
  };
};

export const fetchPromoCode = async (id: string): Promise<PromoCode> => {
  const resp = await api.get(`/promo_codes/${id}`);
  return resToPromoCode(resp.data);
};

export const putPromoCode = async (data: PromoCodeForm): Promise<PromoCode> => {
  const resp = await api.put(`/promo_codes/${data.id}`, promoCodeToPutReq(data));
  return resToPromoCode(resp.data);
};

export const postPromoCode = async (data: PromoCodeForm): Promise<PromoCode> => {
  const resp = await api.post(`/promo_codes`, promoCodeToPostReq(data));
  return resToPromoCode(resp.data);
};

export const deletePromoCode = async (id: string): Promise<PromoCode> => {
  const resp = await api.delete(`/promo_codes/${id}`);
  return resToPromoCode(resp.data);
};
