import { PageType } from 'helpers';
import { ItemCategory, ItemCategoryFormData } from './types';

export const resToItemCategory = (res: any): ItemCategory => {
  return {
    id: res.id,
    name: res.name,
    itemCategoryImageFileId: res.image ? res.image.id : '',
    itemCategoryImageFileUrl: res.image ? res.image.url : '',
    itemGroupId: res.itemGroupId,
    itemGroup: res.itemGroup,
    showInApp: res.showInApp,
  };
};

export const itemCategoryToReq = (itemCategory: ItemCategoryFormData): any => {
  const payload: any = {
    id: itemCategory.id,
    name: itemCategory.name,
    itemGroupId: itemCategory.itemGroupId,
    showInApp: itemCategory.showInApp,
  };
  if (itemCategory.itemCategoryImageFile.id !== PageType.Create) {
    payload.fileId = itemCategory.itemCategoryImageFile.id;
  }

  return payload;
};

export const transformToItemCategoryFilters = (filters: any): any => {
  return {
    ...filters,
  };
};
