/* eslint-disable jsx-a11y/label-has-associated-control */
import { ConfirmationModal, Container, Header, Table } from 'components';
import { HeaderSearch } from 'components/Header/HeaderSearch';
import { DriverReport } from 'services/driverReports';

import { useDriverReportsPage } from './DriverReportsPageProvider';
import { DriverReportsPageAdvancedSearch } from './components/DriverReportsPageAdvancedSearch';
import { columns } from './const';

export const DriverReportsPage = () => {
  const {
    driverReports,
    metaData,
    currentPage,
    isLoading,
    search,
    selectedDriver,
    modalVisible,
    pendingPayment,
    onPayButtonClicked,
    onConfirmationModalCancelClicked,
    onConfirmationModalConfirmClicked,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onRowClick,
    onSortChange,
  } = useDriverReportsPage();

  return (
    <>
      <Header title="Driver reports">
        <div className="flex justify-between flex-1">
          <HeaderSearch onChange={(e: any) => onSearchChange(e.target.value)} value={search} />
        </div>
      </Header>

      <DriverReportsPageAdvancedSearch />

      <Container>
        <Table<DriverReport>
          data={driverReports ?? []}
          columns={columns(onPayButtonClicked)}
          pagination={metaData}
          currentPage={currentPage}
          isLoading={isLoading}
          onRowClick={onRowClick}
          pageClicked={onPageChange}
          pageSizeClicked={onPageSizeChange}
          headerClicked={onSortChange}
        />
      </Container>

      <ConfirmationModal
        title="Payment Confirmation"
        open={modalVisible}
        body={`Are you sure you want to pay all unpaid orders for the driver ${selectedDriver?.driverName}?`}
        onClose={onConfirmationModalCancelClicked}
        onAction={onConfirmationModalConfirmClicked}
        actionButtonDisabled={pendingPayment}
        actionButtonLabel="Confirm"
      />
    </>
  );
};
