import * as yup from 'yup';
import { CellProps } from 'react-table';
import { FormState, UseFormRegister } from 'react-hook-form';
import { TrashIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

import { PageType } from 'helpers/common';
import { Input, TableColumn } from 'components';

import { allItemPickupTimes } from 'services/merchants/const';
import { MerchantForm, ItemPickupTimes } from '../types';

const isCreate = (id: string) => {
  return id === PageType.Create;
};

export const MERCHANT_VALIDATION_SCHEMA = yup.object({
  email: yup.string().when('id', {
    is: isCreate,
    then: yup.string().required('E-mail is required'),
  }),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  password: yup.string().when('id', {
    is: isCreate,
    then: yup.string().required('Password is required'),
  }),
  phone: yup.string().required('Phone number is required'),
  businessName: yup.string().required('Business name is required'),
  itemPickupTimes: yup.array().of(
    yup.object({
      name: yup.string().required('Item pickup time name is required'),
      pickupTimeFrom: yup.string().required('Pickup time from is required'),
      pickupTimeTo: yup.string().required('Pickup time to is required'),
    }),
  ),
});

export const columns = (
  formState: FormState<MerchantForm>,
  register: UseFormRegister<MerchantForm>,
  handleRowDelete: (index: number) => void,
): TableColumn<ItemPickupTimes>[] => [
  {
    Header: '#',
    Cell: ({ row }: CellProps<ItemPickupTimes>) => <span>{row.index + 1}</span>,
  },
  {
    Header: 'Item Pickup Time Name',
    id: 'name',
    accessor: 'name',
    Cell: ({ row }: CellProps<ItemPickupTimes>) => {
      const { index } = row;

      return (
        <Input
          placeholder="e.g. 'Afternoon'"
          type="text"
          {...register(`itemPickupTimes.${index}.name`)}
          error={!!formState.errors.itemPickupTimes?.[index]?.name?.message}
        />
      );
    },
  },
  {
    Header: 'Pickup Time From',
    id: 'pickupTimeFrom',
    accessor: (row) => row.pickupTimeFrom,

    Cell: ({ row }: CellProps<ItemPickupTimes>) => {
      const { index } = row;

      return (
        <select
          className={clsx(
            'relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm h-9',
            {
              'border-gray-300 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500':
                !formState.errors.itemPickupTimes?.[index]?.pickupTimeFrom?.message,
              'border-red-300 placeholder-red-400 focus:ring-red-500 focus:border-red-500':
                !!formState.errors.itemPickupTimes?.[index]?.pickupTimeFrom?.message,
            },
          )}
          {...register(`itemPickupTimes.${index}.pickupTimeFrom`)}
        >
          <option disabled value="">
            Choose an option
          </option>
          {Object.values(allItemPickupTimes).map(({ value }) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
      );
    },
  },
  {
    Header: 'Pickup Time To',
    id: 'pickupTimeTo',
    accessor: (row) => row.pickupTimeTo,

    Cell: ({ row }: CellProps<ItemPickupTimes>) => {
      const { index } = row;

      return (
        <select
          className={clsx(
            'relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm h-9',
            {
              'border-gray-300 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500':
                !formState.errors.itemPickupTimes?.[index]?.pickupTimeTo?.message,
              'border-red-300 placeholder-red-400 focus:ring-red-500 focus:border-red-500':
                !!formState.errors.itemPickupTimes?.[index]?.pickupTimeTo?.message,
            },
          )}
          {...register(`itemPickupTimes.${index}.pickupTimeTo`)}
        >
          <option disabled value="">
            Choose an option
          </option>
          {Object.values(allItemPickupTimes).map(({ value }) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
      );
    },
  },
  {
    Header: () => <div className="text-right">Delete</div>,
    id: 'deleteButton',
    Cell: ({ row }: CellProps<ItemPickupTimes>) => (
      <button type="button" className="float-right mr-4" onClick={() => handleRowDelete(row.index)}>
        <TrashIcon className="h-5" />
      </button>
    ),
  },
];
