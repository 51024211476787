import { ChartCard } from 'components';
import { useMilesCharts } from '../hooks';

export const TotalMilesChart = () => {
  const { totalMilesData, innerCardTotalMiles } = useMilesCharts();
  return (
    <ChartCard
      title="Total Miles"
      subtitle="Per Vehicle Size"
      chartData={totalMilesData}
      innerCardData={innerCardTotalMiles}
      type="pie"
      height="100%"
    />
  );
};
