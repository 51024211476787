import { CellProps } from 'react-table';
import { StarIcon as FilledStar } from '@heroicons/react/solid';
import { ThumbUpIcon, BanIcon } from '@heroicons/react/outline';
import { TableColumn } from 'components';
import { Review } from 'services/reviews';
import { formatDateShort } from 'helpers';
import { PutReview, ReviewStatus } from 'services/reviews/consts';

export const columns = (
  deleteClicked: (rowData: Review) => void,
  approveClicked: ({ id, status }: PutReview) => void,
): TableColumn<Review>[] => [
  {
    Header: 'Date',
    accessor: 'createdAt' as any,
    Cell: ({ row }: CellProps<Review>) => {
      const rowData = row.original;
      return <>{formatDateShort(rowData.createdAt.slice(0, 10))}</>;
    },
    isSortable: true,
  },
  {
    Header: 'Name',
    accessor: 'customerFirstName' as any,
    Cell: ({ row }: CellProps<Review>) => {
      const rowData = row.original;
      return <>{`${rowData.customerFirstName} ${rowData.customerLastName}`}</>;
    },
    isSortable: true,
  },
  {
    Header: 'Driver Name',
    accessor: 'driverFirstName' as any,
    Cell: ({ row }: CellProps<Review>) => {
      const rowData = row.original;
      return <>{`${rowData.driverFirstName} ${rowData.driverLastName}`}</>;
    },
    isSortable: true,
  },
  {
    Header: 'Rate',
    accessor: 'score' as any,
    Cell: ({ row }: CellProps<Review>) => {
      const rowData = row.original;
      const stars = Array(5).fill(0);
      return (
        <div className="flex items-center h-full w-75">
          {stars.map((_, index) => (
            <FilledStar
              key={index + 1}
              className={`flex h-6 w-5 ${rowData.score <= index ? 'text-slate-300' : 'text-yellow-500'}`}
            />
          ))}
        </div>
      );
    },
    isSortable: true,
  },
  {
    Header: 'Review',
    accessor: 'description' as any,
    Cell: ({ row }: CellProps<Review>) => {
      const rowData = row.original;
      return (
        <p className="max-w-sm break-words whitespace-normal text-ellipsis min-w-fit min-h-fit">
          {rowData.description}
        </p>
      );
    },
  },

  {
    Header: 'Status',
    Cell: ({ row }: CellProps<Review>) => {
      const rowData = row.original;
      return <div>{rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1)}</div>;
    },
    isSortable: true,
  },
  {
    Header: 'Action',
    Cell: ({ row }: CellProps<Review>) => {
      const rowData = row.original;
      if (rowData.status === 'pending') {
        return (
          <div className="flex justify-between">
            <button
              type="button"
              onClick={(ev) => {
                ev.stopPropagation();
                approveClicked({ id: rowData.id, status: ReviewStatus.APPROVED });
              }}
            >
              <ThumbUpIcon className="w-5 h-6 cursor-pointer text-emerald-400 hover:text-emerald-600" />
            </button>
            <button
              type="button"
              onClick={(ev) => {
                ev.stopPropagation();
                approveClicked({ id: rowData.id, status: ReviewStatus.DENIED });
              }}
            >
              <BanIcon className="w-5 h-6 text-red-400 cursor-pointer hover:text-red-600" />
            </button>
          </div>
        );
      }
      if (rowData.status === 'approved') {
        return (
          <>
            <button
              type="button"
              onClick={(ev) => {
                ev.stopPropagation();
                approveClicked({ id: rowData.id, status: ReviewStatus.DENIED });
              }}
            >
              <BanIcon className="w-5 h-6 text-red-400 cursor-pointer hover:text-red-600" />
            </button>
          </>
        );
      }
      return (
        <>
          <button
            type="button"
            onClick={(ev) => {
              ev.stopPropagation();
              approveClicked({ id: rowData.id, status: ReviewStatus.APPROVED });
            }}
          >
            <ThumbUpIcon className="w-5 h-6 cursor-pointer text-emerald-400 hover:text-emerald-600" />
          </button>
        </>
      );
    },
  },
  {
    id: 'deleteButton',
    Header: '',
    Cell: ({ row }: CellProps<Review>) => {
      const rowData = row.original;

      return (
        <div className="flex justify-end">
          <button
            type="button"
            className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={(ev) => {
              ev.stopPropagation();
              deleteClicked(rowData);
            }}
          >
            Delete
          </button>
        </div>
      );
    },
    width: 'auto',
  },
];
