import { PageType } from 'helpers';

import { CustomerForm } from './types';

export const defaultCustomerForm: CustomerForm = {
  id: PageType.Create,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  zip: '',
  leadSource: '',
  referralCode: '',
  type: '',
  isTest: false,
  isInvoiced: false,
  isAdmin: false,
  isDriver: false,
  isMerchant: false,
  isCustomer: false,
};
