import { SettingForm } from './types';

export const defaultSettingForm: SettingForm = {
  adminEmail: '',
  contactPhone: '',
  driverPercentage: null,
  driverTipPercentage: null,
  expeditedFeePercentage: null,
  expeditedFeeThresholdHours: null,
  firstHelperLoadFee: null,
  firstHelperPercentage: null,
  firstHelperUnloadFee: null,
  fromEmail: '',
  fromEmailName: '',
  paymentDay: '',
  secondHelperLoadFee: null,
  secondHelperPercentage: null,
  secondHelperUnloadFee: null,
  unassignedOrderTime: '',
};
