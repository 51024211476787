import { User } from 'services/users';

import { UserForm } from '../types';

export const userToUserForm = (driver: User): UserForm => {
  return {
    id: driver.id,
    password: '',
    confirmPassword: '',
    firstName: driver.firstName,
    lastName: driver.lastName,
    email: driver.email,
    isDriver: driver.isDriver,
    isCustomer: driver.isCustomer,
    isMerchant: driver.isMerchant,
    isAdmin: driver.isAdmin,
  };
};
