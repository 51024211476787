import { Control, FormState } from 'react-hook-form';
import { CurrencyInput, FormItem, Modal } from 'components';
import { ModalProps } from 'components/Modal/Modal/types';
import { OrderReimbursement } from 'modules/Orders/types';

export const ReimbursementModal = (props: Props) => {
  const {
    control,
    isLoading,
    open,
    useCancelButton = true,
    title = '',
    onSaveLabel = 'Save',
    onSave,
    onClose,
    formState,
  } = props;
  return (
    <Modal
      open={open}
      onSave={onSave}
      onClose={onClose}
      useCancelButton={useCancelButton}
      title={title}
      onSaveLabel={onSaveLabel}
      isLoading={isLoading}
    >
      <div className="grid grid-cols-1 gap-4 py-5 lg:grid-cols-2">
        <FormItem label="Amount" htmlFor="reimbursementPayment">
          <CurrencyInput
            id="reimbursementPayment"
            name="reimbursementPayment"
            control={control}
            placeholder="Enter reimbursement payment amount"
            error={!!formState.errors?.reimbursementPayment?.message}
          />
        </FormItem>
      </div>
    </Modal>
  );
};

type Props = {
  control: Control<OrderReimbursement, any>;
  formState: FormState<OrderReimbursement>;
} & ModalProps;
