import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { MessageType, showMessage, SortDirection, usePagination } from 'helpers';
import { deleteStopPrice, fetchStopPrices, StopPrice } from 'services/stopPrices';

// Drivers page state
export const useStopPricesPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
  } = usePagination({ sortBy: 'stopsFrom', sortDirection: SortDirection.Asc });

  const [stopPriceToDelete, setStopPriceToDelete] = useState<StopPrice | null>(null);

  const { data: queryData, isLoading: driversLoading } = useQuery(
    ['stopPrices', currentPage, pageSize, sortBy, sortDirection, debouncedSearch],
    () => fetchStopPrices({ page: currentPage, pageSize, sortBy, sortDirection, search: debouncedSearch }),
  );
  const { mutateAsync: removeStopPrice } = useMutation(deleteStopPrice);

  const stopPrices = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onRowClick = (row: StopPrice) => {
    navigate(`/stop-prices/${row.id}`);
  };

  const onNewClick = () => {
    navigate('/stop-prices/new');
  };

  const onDeleteConfirm = async () => {
    if (stopPriceToDelete) {
      try {
        await removeStopPrice(stopPriceToDelete.id);
        showMessage('Stop price successfully deleted.', MessageType.Success);
        queryClient.invalidateQueries('stopPrices');
        setStopPriceToDelete(null);
      } catch {
        showMessage('Something went wrong.', MessageType.Error);
      }
    }
  };

  const openConfirmationModal = (selectedStopPrice: StopPrice | null) => {
    if (selectedStopPrice) setStopPriceToDelete(selectedStopPrice);
  };

  const closeConfirmationModal = () => {
    setStopPriceToDelete(null);
  };

  return {
    stopPrices,
    metaData,
    currentPage,
    search,
    isLoading: driversLoading,
    stopPriceToDelete,
    openConfirmationModal,
    closeConfirmationModal,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onDeleteConfirm,
    onRowClick,
    onNewClick,
    onSortChange,
  };
};
