import { ChartCard } from 'components';
import { useRevenueCharts } from '../hooks';

export const AverageRevenueBarChart = () => {
  const { averageRevenueData, innerCardAverageRevenue } = useRevenueCharts();
  return (
    <ChartCard
      title="Average Revenue"
      subtitle="By Vehicle Size"
      chartData={averageRevenueData}
      innerCardData={innerCardAverageRevenue}
      height="100%"
    />
  );
};
