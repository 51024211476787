import { FormItem, Input } from 'components';
import { formatDate, formatPrice } from 'helpers';

import { useOrderPage } from '../../OrderPageProvider';

export const OrderPaymentSummary = () => {
  const { order } = useOrderPage();

  return (
    <div className="py-5 grid grid-cols-1 gap-4 lg:grid-cols-2">
      <FormItem label="Payment Date">
        <Input type="text" disabled value={order.paymentDate ? formatDate(new Date(order.paymentDate)) : ''} />
      </FormItem>

      <FormItem label="Payment Type">
        <Input type="text" disabled value={order.paymentType || ''} />
      </FormItem>

      <FormItem label="Transaction ID">
        <Input type="text" disabled value={order.paymentTransactionId || ''} />
      </FormItem>

      <FormItem label="Status">
        <Input type="text" disabled value={order.paymentStatus || ''} />
      </FormItem>

      <FormItem label="Booking fee">
        <Input type="text" disabled value={formatPrice(order.bookingPrice ?? 0)} />
      </FormItem>

      <FormItem label="Expedited fee">
        <Input type="text" disabled value={formatPrice(order.expeditedFee ?? 0)} />
      </FormItem>

      <FormItem label="Weight Fee">
        <Input type="text" disabled value={formatPrice(order.weightPrice ?? 0)} />
      </FormItem>

      <FormItem label="Tip">
        <Input type="text" disabled value={formatPrice(order.tipPrice ?? 0)} />
      </FormItem>

      <FormItem label="Promo Code Discount">
        <Input type="text" disabled value={formatPrice(order.promoCodeDiscount ?? 0)} />
      </FormItem>

      <FormItem label="Additional Tip">
        <Input type="text" disabled value={formatPrice(order.extraTip ?? 0)} />
      </FormItem>

      <FormItem label="Order Amount">
        <Input type="text" disabled value={formatPrice(order.finalPrice ?? 0)} />
      </FormItem>

      <FormItem label="Trailer Price">
        <Input type="text" disabled value={formatPrice(order.trailerPrice ?? 0)} />
      </FormItem>

      <FormItem label="Mileage Price">
        <Input type="text" disabled value={formatPrice(order.mileagePrice ?? 0)} />
      </FormItem>
    </div>
  );
};
