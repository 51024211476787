import { Order } from 'services/orders';

import { OrderBonusPayments } from '../types';

export const orderToOrderBonusPaymentForm = (order: Order): OrderBonusPayments => {
  return {
    driverBonusPayment: order.driverBonusPayment,
    helperBonusPayment: order.helperBonusPayment,
  };
};
