import { useNavigate } from 'react-router-dom';

import { Button, Container, Header, Divider, FormItem, Input, Checkbox } from 'components';

import { useItemGroupPage } from './hooks';

export const ItemGroupPage = () => {
  const { isCreate, formState, onSubmit, register, itemGroupLoading } = useItemGroupPage();
  const navigate = useNavigate();

  return (
    <>
      <Header title={isCreate ? 'New Item Group' : 'Edit Item Group'} onGoBackPress={() => navigate(-1)}>
        <Button type="submit" onClick={onSubmit} label="Save" form="itemGroupForm" disabled={formState.isSubmitting} />
      </Header>

      <Container isLoading={itemGroupLoading}>
        <div className="overflow-hidden bg-white rounded-lg shadow">
          <div className="px-4 py-5 sm:p-6">
            <form className="space-y-10" onSubmit={onSubmit} noValidate id="itemGroupForm">
              <Divider title="Item Group information" />

              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <FormItem label="Name" htmlFor="name" error={formState.errors.name?.message} required>
                  <Input
                    {...register('name')}
                    id="name"
                    type="text"
                    placeholder="Enter name"
                    required
                    error={!!formState.errors.name?.message}
                    disabled={formState.isSubmitting}
                  />
                </FormItem>
              </div>
              <Checkbox
                {...register('showInApp')}
                id="showInApp"
                label="Is this item group visible on mobile app for customers?"
                name="showInApp"
              />
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};
