import { useNavigate } from 'react-router-dom';
import { PlusSmIcon } from '@heroicons/react/solid';

import { Button, Container, Header, Divider, FormItem, Input, Table, Select, Checkbox } from 'components';

import { useMerchantPage } from './MerchantPageProvider';
import { ItemPickupTimes } from '../types';
import { columns } from './const';

export const MerchantPage = () => {
  const {
    isCreate,
    formState,
    control,
    driverGroupsData,
    itemPickupTimes,
    orderErrors,
    onSubmit,
    register,
    handleAddNewRow,
    handleRowDelete,
  } = useMerchantPage();
  const navigate = useNavigate();

  return (
    <>
      <Header title={isCreate ? 'New merchant' : 'Edit merchant'} onGoBackPress={() => navigate(-1)}>
        <Button type="submit" onClick={onSubmit} label="Save" form="merchantForm" />
      </Header>

      <Container>
        <div className="bg-white rounded-lg shadow">
          <div className="px-4 py-5 sm:p-6">
            <form className="space-y-10" onSubmit={onSubmit} noValidate id="merchantForm">
              <Divider title="Merchant Information" />

              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <FormItem label="First name" htmlFor="firstName" error={formState.errors.firstName?.message} required>
                  <Input
                    {...register('firstName')}
                    id="firstName"
                    type="text"
                    placeholder="Enter first name"
                    autoComplete="firstName"
                    required
                    error={!!formState.errors.firstName?.message}
                  />
                </FormItem>

                <FormItem label="Last name" htmlFor="lastName" error={formState.errors.lastName?.message} required>
                  <Input
                    {...register('lastName')}
                    id="lastName"
                    type="text"
                    placeholder="Enter last name"
                    autoComplete="lastName"
                    required
                    error={!!formState.errors.lastName?.message}
                  />
                </FormItem>

                <FormItem label="Email" htmlFor="email" error={formState.errors.email?.message} required>
                  <Input
                    {...register('email')}
                    id="email"
                    type="text"
                    placeholder="Enter email"
                    autoComplete="email"
                    required
                    error={!!formState.errors.email?.message}
                  />
                </FormItem>

                <FormItem
                  label="Password"
                  htmlFor="password"
                  error={formState.errors.password?.message}
                  required={isCreate}
                >
                  <Input
                    {...register('password')}
                    id="password"
                    type="text"
                    placeholder="Enter password"
                    autoComplete="password"
                    required={isCreate}
                    error={!!formState.errors.password?.message}
                  />
                </FormItem>

                <FormItem label="Phone" htmlFor="phone" error={formState.errors.phone?.message} required>
                  <Input
                    {...register('phone')}
                    id="phone"
                    type="text"
                    placeholder="Enter phone"
                    autoComplete="phone"
                    required
                    error={!!formState.errors.phone?.message}
                  />
                </FormItem>

                <FormItem
                  label="Bussines Name"
                  htmlFor="businessName"
                  error={formState.errors.businessName?.message}
                  required
                >
                  <Input
                    {...register('businessName')}
                    id="businessName"
                    type="text"
                    placeholder="Enter business name"
                    autoComplete="businessName"
                    error={!!formState.errors.businessName?.message}
                    required
                  />
                </FormItem>

                <FormItem label="Driver Group" error={formState.errors.driverGroupId?.message}>
                  <Select
                    control={control}
                    error={!!formState.errors.driverGroupId?.message}
                    name="driverGroupId"
                    options={driverGroupsData}
                    placeholder="Select driver group"
                  />
                </FormItem>

                <FormItem
                  label="Merchant Payment Percentage"
                  htmlFor="merchantPaymentPercentage"
                  error={formState.errors.merchantPaymentPercentage?.message}
                >
                  <Input
                    {...register('merchantPaymentPercentage')}
                    id="merchantPaymentPercentage"
                    type="number"
                    placeholder="Enter merchant payment percentage"
                    autoComplete="merchantPaymentPercentage"
                    error={!!formState.errors.merchantPaymentPercentage?.message}
                  />
                </FormItem>
              </div>
              <Checkbox
                {...register('isSendingPriceToOnfleet')}
                id="isSendingPriceToOnfleet"
                label="Send price to OnFleet?"
                name="isSendingPriceToOnfleet"
              />

              <Divider title="Merchant Item Pickup Times" />

              {!!orderErrors.length && (
                <div className="w-full p-4 mb-8 overflow-y-auto text-sm text-white bg-red-500 rounded-md max-h-60">
                  {orderErrors.map((errorRow, index) => (
                    <div key={`err-row-${index}`} className="mb-6 last:mb-0">
                      <p key={index}>
                        <b>Row: {errorRow.row}</b>
                      </p>
                      <ul className="grid grid-cols-3 gap-2 list-disc pl-28 grid-rows-12">
                        {errorRow.errorMessages.map((errMsg, idx) => {
                          return (
                            <li key={idx} className="mx-4">
                              {errMsg}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ))}
                </div>
              )}

              {!!itemPickupTimes.length && (
                <Table<ItemPickupTimes>
                  data={itemPickupTimes}
                  columns={columns(formState, register, handleRowDelete)}
                />
              )}

              <Button className="mt-4" color="secondary" label="Add" onClick={handleAddNewRow} icon={<PlusSmIcon />} />
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};
