import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';

import { Container, Header, Divider, Button } from 'components';
import { OrderStop } from 'services/orders';
import { Tabs } from 'components/Tabs';

import {
  OrderDeliveryDetails,
  OrderFees,
  OrderItemSummary,
  OrderPaymentSummary,
  OrderPickupDetails,
  OrderImages,
  OrderSummary,
} from './components';
import { BonusPayementsModal } from './BonusPaymentsModal';
import { AssignMerchantModal } from './components/AssignMerchantModal';
import { useOrderPage } from './OrderPageProvider';
import { ReimbursementModal } from './components/ReimbursementModal';

export const OrderPage = () => {
  const {
    order,
    isLoading,
    control,
    isBonusPaymentsModalOpen,
    orderBonusPaymentsLoading,
    isAssignMerchantModalOpen,
    assignMerchantToOrderLoading,
    assignMerchantControl,
    assignMerchantFormState,
    handleAssignMerchantModalClose,
    handleAssignMerchantClick,
    onBonusPaymentSave,
    onAssignMerchant,
    handleBonusPaymentsModalOpen,
    handleBonusPaymentsModalClose,
    isReimbursementModalOpen,
    reimbursementFormState,
    reimbursementControl,
    onReimbursementSave,
    handleReimbursementModalClose,
    orderReimbursementLoading,
    handleReimbursementModalOpen,
  } = useOrderPage();
  const navigate = useNavigate();

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const selectedOrderStop: OrderStop = order.stops[currentIndex];

  const tabs = useMemo(() => {
    return order.stops.map((_, index) => ({
      name: `Stop ${index + 1}`,
      current: currentIndex === index,
    }));
  }, [order.stops, currentIndex]);

  const handleTabChange = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div>
      <Header title="Order" onGoBackPress={() => navigate(-1)}>
        <div className="flex items-center space-x-2">
          <Button
            type="button"
            onClick={handleReimbursementModalOpen}
            label="Reimbursement payment"
            disabled={!order?.itemCost}
          />
          <Button type="button" onClick={handleAssignMerchantClick} label="Assign Merchant" />
          <Button type="button" onClick={handleBonusPaymentsModalOpen} label="Bonus Payments" />
        </div>
      </Header>

      <Container isLoading={isLoading}>
        <div className="overflow-hidden bg-white rounded-lg shadow">
          <div className="px-4 py-5 sm:p-6">
            <Divider title="Order Summary" />
            <OrderSummary />

            <Divider title="Order Fees" />
            <OrderFees />

            <Divider title="Order Payment Summary" />
            <OrderPaymentSummary />

            <Divider title="Order Item Summary" />
            <OrderItemSummary />

            <div className="ml-2">
              <Tabs tabs={tabs} onClick={handleTabChange} />
            </div>

            {tabs.length > 0 && (
              <div className="p-4 border border-gray-300 rounded-md">
                <Divider title="Order Pickup Details" />
                <OrderPickupDetails selectedOrderStop={selectedOrderStop} order={order} />

                <Divider title="Order Delivery Details" />
                <OrderDeliveryDetails selectedOrderStop={selectedOrderStop} order={order} />
              </div>
            )}
            <div className="pt-5">
              <Divider title="Order Images" />
              <OrderImages />
            </div>
          </div>
        </div>
      </Container>
      <BonusPayementsModal
        open={isBonusPaymentsModalOpen}
        onSave={onBonusPaymentSave}
        onClose={handleBonusPaymentsModalClose}
        control={control}
        title="Bonus Payments"
        isLoading={orderBonusPaymentsLoading}
      />
      <ReimbursementModal
        open={isReimbursementModalOpen}
        onSave={onReimbursementSave}
        onClose={handleReimbursementModalClose}
        control={reimbursementControl}
        title="Reimbursement Payments"
        isLoading={orderReimbursementLoading}
        formState={reimbursementFormState}
      />
      <AssignMerchantModal
        open={isAssignMerchantModalOpen}
        onSave={onAssignMerchant}
        onClose={handleAssignMerchantModalClose}
        control={assignMerchantControl}
        formState={assignMerchantFormState}
        title="Assign Merchant"
        isLoading={assignMerchantToOrderLoading}
      />
    </div>
  );
};
