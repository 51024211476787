import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

export const StopPricesContext = React.createContext<{}>({});

// This will be used in case we state shared inside the module
export const StopPricesProvider = ({ children = <Outlet /> }: Props) => {
  const providerValue = useMemo(() => ({}), []);

  return <StopPricesContext.Provider value={providerValue}>{children}</StopPricesContext.Provider>;
};

interface Props {
  children?: React.ReactNode;
}
