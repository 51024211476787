export type InnerChartCardData = {
  data: number | undefined;
  type: 'currency' | 'display';
  title: string;
};

export enum Resolution {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}
