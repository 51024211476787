import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

export const MerchantsContext = React.createContext<{}>({});

// This will be used in case we state shared inside the module
export const MerchantsProvider = ({ children = <Outlet /> }: Props) => {
  const providerValue = useMemo(() => ({}), []);

  return <MerchantsContext.Provider value={providerValue}>{children}</MerchantsContext.Provider>;
};

interface Props {
  children?: React.ReactNode;
}
