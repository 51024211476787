import { CurrencyInput, FormItem, Input } from 'components';
import { formatPrice } from 'helpers';

import { useOrderPage } from '../../OrderPageProvider';

export const OrderFees = () => {
  const { order, control } = useOrderPage();

  return (
    <div className="grid grid-cols-1 gap-4 py-5 lg:grid-cols-2">
      <FormItem label="Driver Payment">
        <Input type="text" disabled value={formatPrice(order.driverPayment || 0)} />
      </FormItem>

      <FormItem label="Driver Bonus Payment" htmlFor="driverBonusPayment">
        <CurrencyInput
          id="driverBonusPayment"
          name="driverBonusPayment"
          control={control}
          placeholder="Driver bonus amount"
          disabled
        />
      </FormItem>

      <FormItem label="Helper Payment">
        <Input type="text" disabled value={formatPrice(order.helperPayment || 0)} />
      </FormItem>

      <FormItem label="Helper Bonus Payment" htmlFor="helperBonusPayment">
        <CurrencyInput
          id="helperBonusPayment"
          name="helperBonusPayment"
          control={control}
          placeholder="Helper bonus amount"
          disabled
        />
      </FormItem>
    </div>
  );
};
