export interface Image {
  id: string;
  name: string;
  url: string;
  buffer?: any; // For some reason react hook form does not work with ArrayBuffer type
}

export enum StorageType {
  Assets = 1,
  Images = 2,
}
