import { FormItem, Input } from 'components';
import { addressToString } from 'services/addresses/helpers';
import { OrderStop, Order, DeliveryWindowTime } from 'services/orders';

interface Props {
  selectedOrderStop: OrderStop;
  order: Order;
}

const getDeliveryWindow = (order: Order): string => {
  if (order.deliveryWindow) return DeliveryWindowTime[order.deliveryWindow] || order.deliveryWindow;

  return [order.pickupTimeFrom, order.pickupTimeUntil].filter((x) => !!x).join(' - ');
};

export const OrderDeliveryDetails = ({ selectedOrderStop, order }: Props) => {
  const deliveryAddress = selectedOrderStop?.toAddress;

  return (
    <div className="grid grid-cols-1 gap-4 py-5 lg:grid-cols-2">
      <FormItem label="Delivery Window">
        <Input type="text" disabled value={getDeliveryWindow(order)} />
      </FormItem>

      <FormItem label="Delivery Address">
        <Input type="text" disabled value={deliveryAddress ? addressToString(deliveryAddress) : ''} />
      </FormItem>

      <FormItem label="Contact for Delivery">
        <Input type="text" disabled value={selectedOrderStop?.receivePersonName ?? ''} />
      </FormItem>

      <FormItem label="Phone for Delivery">
        <Input type="text" disabled value={selectedOrderStop?.receivePersonPhone ?? ''} />
      </FormItem>

      <FormItem label="Delivery Information">
        <Input type="text" disabled value={selectedOrderStop?.driverDeliveryNote ?? ''} />
      </FormItem>
    </div>
  );
};
