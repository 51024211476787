export enum PromoCodeType {
  Amount = 'amount',
  Percentage = 'percentage',
}

export interface PromoCodeForm {
  id: string;
  code: string;
  activeFrom: Date | null;
  activeTo: Date | null;
  type: PromoCodeType;
  amount: number | null;
  quantity: number | null;
  maxUsesPerUser: number | null;
  status: string;
}
