import { useNavigate } from 'react-router-dom';

import { Button, Container, Header, Divider, FormItem, Input } from 'components';

import { useDistancePricePage } from './DistancePriceProvider';

export const DistancePricePage = () => {
  const { isCreate, formState, onSubmit, register } = useDistancePricePage();
  const navigate = useNavigate();

  return (
    <>
      <Header title={isCreate ? 'New distance price' : 'Edit distance price'} onGoBackPress={() => navigate(-1)}>
        <Button type="submit" onClick={onSubmit} label="Save" form="distancePriceForm" />
      </Header>

      <Container>
        <div className="overflow-hidden bg-white rounded-lg shadow">
          <div className="px-4 py-5 sm:p-6">
            <form className="space-y-10" onSubmit={onSubmit} noValidate id="distancePriceForm">
              <Divider title="Distance price information" />

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <FormItem
                  label="Miles From"
                  htmlFor="distanceMilesFrom"
                  error={formState.errors.distanceMilesFrom?.message}
                  required
                >
                  <Input
                    {...register('distanceMilesFrom')}
                    id="distanceMilesFrom"
                    type="number"
                    placeholder="Enter miles from"
                    autoComplete="distanceMilesFrom"
                    required
                    error={!!formState.errors.distanceMilesFrom?.message}
                  />
                </FormItem>

                <FormItem
                  label="Miles To"
                  htmlFor="distanceMilesTo"
                  error={formState.errors.distanceMilesTo?.message}
                  required
                >
                  <Input
                    {...register('distanceMilesTo')}
                    id="distanceMilesTo"
                    type="number"
                    placeholder="Enter miles to"
                    autoComplete="distanceMilesTo"
                    required
                    error={!!formState.errors.distanceMilesTo?.message}
                  />
                </FormItem>

                <FormItem label="Price" htmlFor="pricePerMile" error={formState.errors.pricePerMile?.message} required>
                  <Input
                    {...register('pricePerMile')}
                    id="pricePerMile"
                    type="number"
                    placeholder="Enter price per mile"
                    autoComplete="pricePerMile"
                    required
                    error={!!formState.errors.pricePerMile?.message}
                  />
                </FormItem>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};
