import { User } from 'services/users';

import { ProfileForm } from './types';

export const transformUserToProfileForm = (user: User): ProfileForm => {
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    password: '',
    repeatPassword: '',
  };
};
