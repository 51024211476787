import { currencyFormatter } from 'helpers';
import { useEffect, useMemo, useState } from 'react';
import { useReportingRevenue } from 'services/reporting';
import { useDashboardPageContext } from '../../DashboardPageProvider';
import { InnerChartCardData } from '../../types';

import { ChartType } from '../types';
import { initialAverageRevenueData } from './AverageRevenueBarChart/const';
import { initialTotalRevenueData } from './TotalRevenuePieChart/const';
import { PIE_CHART_COLORS } from '../../consts';

export const useRevenueCharts = () => {
  const { startDate, endDate } = useDashboardPageContext();
  const { data: dataByRevenue } = useReportingRevenue({ startDate, endDate });

  const [averageRevenueData, setAverageRevenueData] = useState<ChartType>(initialAverageRevenueData);
  const [totalRevenueData, setTotalRevenueData] = useState<ChartType>(initialTotalRevenueData);

  const revenueDataFormat = useMemo(() => {
    const totalRevenueSum = dataByRevenue?.totalRevenue;
    const averageRevenue = dataByRevenue?.averageRevenue;
    const averageSeries = dataByRevenue?.averageRevenuePerDriverGroup.map((i: any) => i.averageRevenue) || [];
    const totalSeries = dataByRevenue?.averageRevenuePerDriverGroup.map((i: any) => i.totalRevenue) || [];
    const labels = dataByRevenue?.averageRevenuePerDriverGroup.map((i: any) => i.driverGroup) || [];

    return {
      totalRevenueSum,
      averageRevenue,
      labels,
      averageSeries,
      totalSeries,
    };
  }, [dataByRevenue]);

  useEffect(() => {
    setAverageRevenueData((old) => {
      return {
        series: [
          {
            name: 'Average revenue',
            data: revenueDataFormat?.averageSeries,
          },
        ],
        options: {
          ...old.options,
          chart: {
            type: 'bar',
            height: 700,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            y: {
              formatter: (val: number) => currencyFormatter(val),
            },
          },
          xaxis: {
            categories: revenueDataFormat.labels,
            labels: {
              formatter: (val: string) => val,
            },
          },
        },
        averageSum: revenueDataFormat.averageRevenue || 0,
      };
    });
  }, [revenueDataFormat]);

  useEffect(() => {
    setTotalRevenueData((old) => {
      return {
        series: revenueDataFormat?.totalSeries,
        options: {
          ...old.options,
          labels: revenueDataFormat?.labels,
          colors: PIE_CHART_COLORS,
          tooltip: {
            y: {
              formatter: (val: number) => currencyFormatter(val),
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false,
                },
              },
            },
          },
        },
      };
    });
  }, [revenueDataFormat]);

  const innerCardAverageRevenue: InnerChartCardData[] = useMemo(() => {
    return [
      {
        data: averageRevenueData.averageSum,
        type: 'currency',
        title: 'Average revenue',
      },
    ];
  }, [averageRevenueData]);

  const innerCardTotalRevenue: InnerChartCardData[] = useMemo(() => {
    return [
      {
        data: dataByRevenue?.totalDriverPayment,
        type: 'currency',
        title: 'Total driver payment',
      },
      {
        data: dataByRevenue?.totalHelperPayment,
        type: 'currency',
        title: 'Total helper payment',
      },
      {
        data: dataByRevenue?.totalMerchantPayment,
        type: 'currency',
        title: 'Total merchant payment',
      },
      {
        data: dataByRevenue?.totalRevenue,
        type: 'currency',
        title: 'Total revenue',
      },
    ];
  }, [
    dataByRevenue?.totalDriverPayment,
    dataByRevenue?.totalHelperPayment,
    dataByRevenue?.totalMerchantPayment,
    dataByRevenue?.totalRevenue,
  ]);

  return {
    averageRevenueData,
    totalRevenueData,
    innerCardAverageRevenue,
    innerCardTotalRevenue,
  };
};
