import { PageType } from 'helpers';

import { MerchantForm } from './types';

export const defaultMerchantForm: MerchantForm = {
  id: PageType.Create,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  businessName: '',
  isSendingPriceToOnfleet: false,
  driverGroupId: null,
  itemPickupTimes: [],
  merchantPaymentPercentage: 0,
};

export const defaultItemPickupTimesRow = {
  name: '',
  pickupTimeFrom: '',
  pickupTimeTo: '',
};
