import { ReportingDeviceType, ReportingMileage, ReportingOrder, ReportingRevenue, ReportingUser } from './types';

export const resToReportingMileage = (res: any): ReportingMileage => {
  return {
    mileageSum: res.mileageSum || 0,
    mileageAverage: res.mileageAverage || 0,
    mileagePerDriverGroup: res.mileagePerDriverGroup
      ? res.mileagePerDriverGroup.map((item: any) => ({
          driverGroupName: item.driverGroupName || '',
          averageMileage: item.averageMileage || 0,
          totalMileage: item.totalMileage || 0,
        }))
      : [],
  };
};

export const resToReportingRevenue = (res: any): ReportingRevenue => {
  return {
    averageRevenue: res.averageRevenue || 0,
    totalDriverPayment: res.totalDriverPayment || 0,
    totalHelperPayment: res.totalHelperPayment || 0,
    totalMerchantPayment: res.totalMerchantPayment || 0,
    totalRevenue: res.totalRevenue || 0,
    averageRevenuePerDriverGroup: res.averageRevenuePerDriverGroup
      ? res.averageRevenuePerDriverGroup.map((item: any) => ({
          driverGroup: item.driverGroup || '',
          averageRevenue: item.averageRevenue || 0,
          totalRevenue: item.totalRevenue || 0,
        }))
      : [],
  };
};

export const resToReportingUser = (res: any): ReportingUser => {
  return {
    totalCustomers: res.totalCustomers || 0,
    customersOverTimeInterval: res.customersOverTimeInterval
      ? res.customersOverTimeInterval.map((coti: any) => ({
          day: coti.day || 0,
          week: coti.week || 0,
          month: coti.month || 0,
          year: coti.year || 0,
          newCustomers: coti.newCustomers || 0,
        }))
      : [],
    totalDrivers: res.totalDrivers || 0,
    driversOverTimeInterval: res.driversOverTimeInterval
      ? res.driversOverTimeInterval.map((doti: any) => ({
          day: doti.day || 0,
          week: doti.week || 0,
          month: doti.month || 0,
          year: doti.year || 0,
          newDrivers: doti.newDrivers || 0,
        }))
      : [],
    driversPerDriverGroup: res.driversPerDriverGroup
      ? res.driversPerDriverGroup.map((driverGroup: any) => ({
          driverGroupName: driverGroup.driverGroupName || '',
          driverCount: driverGroup.driverCount || 0,
        }))
      : [],
    totalHelpers: res.totalHelpers || 0,
  };
};

export const resToReportingOrder = (res: any): ReportingOrder => {
  return {
    ordersOverTime: res.ordersOverTime
      ? res.ordersOverTime.map((item: any) => ({
          day: item.day || 0,
          week: item.week || 0,
          month: item.month || 0,
          year: item.year || 0,
          orderCount: item.orderCount || 0,
        }))
      : [],
    driverGroupOrders: res.driverGroupOrders.map((item: any) => ({
      driverGroupName: item.driverGroupName || '',
      orderCount: item.orderCount || 0,
    })),
    totalNumberOfOrders: res.totalNumberOfOrders || 0,
    statusOrders: res.statusOrders.map((item: any) => ({
      status: item.status || '',
      orderCount: item.orderCount || 0,
    })),
  };
};

export const resToReportingDeviceType = (res: any): ReportingDeviceType => {
  return {
    deviceType: res.deviceType || '',
    count: res.count || 0,
  };
};
