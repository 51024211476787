import { useNavigate } from 'react-router-dom';

import { Button, Container, FormItem, Header, Input } from 'components';
import { useAuth } from 'GlobalProvider';

import { useProfilePage } from './hooks';

export const ProfilePage = () => {
  const { formState, register, onSubmit } = useProfilePage();
  const navigate = useNavigate();
  const { logoutUser } = useAuth();

  return (
    <>
      <Header title="Edit profile" onGoBackPress={() => navigate(-1)}>
        <Button className="mr-4" color="danger" type="submit" onClick={logoutUser} label="Sign Out" />

        <Button type="submit" onClick={onSubmit} label="Save" form="profileForm" />
      </Header>

      <Container>
        <div className="overflow-hidden bg-white rounded-lg shadow">
          <div className="px-4 py-5 sm:p-6">
            <form className="space-y-6" onSubmit={onSubmit} noValidate id="profileForm">
              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <FormItem label="First name" htmlFor="firstName" error={formState.errors.firstName?.message}>
                  <Input
                    {...register('firstName')}
                    id="firstName"
                    type="text"
                    autoComplete="firstName"
                    required
                    error={!!formState.errors.firstName?.message}
                  />
                </FormItem>

                <FormItem label="Last name" htmlFor="lastName" error={formState.errors.lastName?.message}>
                  <Input
                    {...register('lastName')}
                    id="lastName"
                    type="text"
                    autoComplete="off"
                    required
                    error={!!formState.errors.lastName?.message}
                  />
                </FormItem>

                <FormItem label="Password" htmlFor="password" error={formState.errors.password?.message}>
                  <Input
                    {...register('password')}
                    id="password"
                    type="password"
                    autoComplete="off"
                    required
                    error={!!formState.errors.password?.message}
                  />
                </FormItem>

                <FormItem
                  label="Repeat password"
                  htmlFor="repeatPassword"
                  error={formState.errors.repeatPassword?.message}
                >
                  <Input
                    {...register('repeatPassword')}
                    id="repeatPassword"
                    type="password"
                    autoComplete="off"
                    required
                    error={!!formState.errors.repeatPassword?.message}
                  />
                </FormItem>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};
