import clsx from 'clsx';

export const Tabs = ({ tabs, onClick }: Props) => {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          defaultValue={tabs.find((tab) => tab.current)?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="flex -mb-px space-x-8" aria-label="Tabs">
            {tabs.map((tab, index) => (
              <a
                key={tab.name}
                href={tab.href}
                onClick={() => onClick(index)}
                className={clsx('whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer', {
                  'border-indigo-500 text-indigo-600': tab.current,
                  'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': !tab.current,
                })}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

interface Props {
  tabs: {
    name: string;
    href?: string;
    current: boolean;
  }[];
  onClick: (index: number) => void;
}
