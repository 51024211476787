import { CellProps } from 'react-table';
import { CheckIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

import { TableColumn, Tooltip } from 'components';
import { formatPrice, shortenId } from 'helpers';
import { MerchantReport } from 'services/merchantReports';

export const columns = (onPayButtonClicked: (merchant: MerchantReport) => void): TableColumn<MerchantReport>[] => [
  {
    Header: 'ID',
    accessor: 'merchantId',
    isSortable: true,
    Cell: ({ row }: CellProps<MerchantReport>) => {
      const rowData = row.original;
      return (
        <Tooltip
          tooltipMessage={rowData.merchantId}
          onClick={() => navigator.clipboard.writeText(rowData.merchantId)}
          popoverMessage="Copied!"
        >
          {shortenId(rowData.merchantId)}
        </Tooltip>
      );
    },
  },
  {
    Header: 'Merchant Name',
    accessor: 'merchantName' as any,
    isSortable: true,
  },
  {
    Header: 'Total orders',
    accessor: 'orderCount' as any,
    isSortable: true,
  },
  {
    Header: 'Paid / Unpaid Orders',
    Cell: ({ row }: CellProps<MerchantReport>) => {
      const { orderPaidCount, orderUnpaidCount } = row.original;
      return <div>{`${orderPaidCount} / ${orderUnpaidCount}`}</div>;
    },
  },
  {
    Header: 'Paid Amount',
    accessor: 'orderPaidAmount' as any,
    isSortable: true,
    Cell: ({ row }: CellProps<MerchantReport>) => {
      const { orderPaidAmount } = row.original;

      return <div className={clsx(orderPaidAmount > 0 ? 'text-green-600' : '')}>{formatPrice(orderPaidAmount)}</div>;
    },
  },
  {
    Header: 'Unpaid Amount',
    accessor: 'orderUnpaidAmount' as any,
    isSortable: true,
    Cell: ({ row }: CellProps<MerchantReport>) => {
      const { orderUnpaidAmount } = row.original;

      return <div className={clsx(orderUnpaidAmount > 0 ? 'text-primary' : '')}>{formatPrice(orderUnpaidAmount)}</div>;
    },
  },
  {
    id: 'payButton',
    Header: '',
    Cell: ({ row }: CellProps<MerchantReport>) => {
      const merchant = row.original;

      if (merchant.orderTotalAmount > 0) {
        return (
          <div className="flex justify-end">
            {merchant.unpaidOrderPaymentIds.length ? (
              <button
                key="approve"
                type="button"
                className="inline-flex items-center px-2.5 py-1.5 mr-1 border border-green-300 shadow-sm text-xs font-medium rounded text-green-700 bg-white hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={(ev) => {
                  ev.stopPropagation();
                  onPayButtonClicked(merchant);
                }}
              >
                Pay
              </button>
            ) : (
              <div className="flex items-center justify-end">
                <CheckIcon className="w-4 h-4 text-green-500" />
                <p className="italic text-green-600 pr-1.5 pl-0.5 py-1.5 text-xs font-medium">Paid</p>
              </div>
            )}
          </div>
        );
      }
      return null;
    },
  },
];
