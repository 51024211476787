import { ArrowLeftIcon } from '@heroicons/react/outline';
import { ReactNode } from 'react';

type HeaderProps = {
  title: string;
  children?: ReactNode;
  onGoBackPress?(): void;
};

export function Header({ children, title, onGoBackPress }: HeaderProps) {
  return (
    <div className="sticky top-0 z-20 flex flex-shrink-0 px-6 py-2 bg-white shadow md:h-16 md:py-0 lg:px-8">
      <div className="flex-1 md:flex md:items-center md:justify-between">
        {onGoBackPress && (
          <button
            onClick={onGoBackPress}
            type="button"
            className="inline-flex items-center p-2 mr-2 text-black bg-transparent border border-transparent rounded-full shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 hover:text-black"
          >
            <ArrowLeftIcon className="w-4 h-4 text-gray-600" aria-hidden="true" />
          </button>
        )}
        <div className="min-w-0 mr-4">
          <h2 className="font-bold text-gray-900 text-md leading-7 sm:truncate">{title}</h2>
        </div>
        <div className="flex justify-end flex-1 mt-4 md:mt-0 md:ml-4">{children}</div>
      </div>
    </div>
  );
}
