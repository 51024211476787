import { useQuery } from 'react-query';

import {
  ChartFilters,
  ReportingDeviceType,
  ReportingMileage,
  ReportingOrder,
  ReportingRevenue,
  ReportingUser,
} from './types';
import { api } from '../api';
import {
  resToReportingDeviceType,
  resToReportingMileage,
  resToReportingOrder,
  resToReportingRevenue,
  resToReportingUser,
} from './transformations';
import { resolveUrl } from './helpers';

const DASHBOARD_BASE_ROUTE = '/dashboard';

const DASHBOARD_REPORTING_ROUTE = {
  mileage: `${DASHBOARD_BASE_ROUTE}/mileage`,
  revenueShare: `${DASHBOARD_BASE_ROUTE}/revenue-share`,
  user: `${DASHBOARD_BASE_ROUTE}/user`,
  order: `${DASHBOARD_BASE_ROUTE}/order`,
  deviceType: `${DASHBOARD_BASE_ROUTE}/device-type`,
};

enum QueryKeys {
  ReportingMileageKey = 'reporting_mileage',
  ReportingRevenueKey = 'reporting_revenue',
  ReportingUserKey = 'reporting_user',
  ReportingOrderKey = 'reporting_order',
  ReportingDeviceTypeKey = 'reporting_device_type',
}

const fetchReportingMileage = async ({ startDate, endDate }: ChartFilters): Promise<ReportingMileage> => {
  const url = resolveUrl(DASHBOARD_REPORTING_ROUTE.mileage, startDate, endDate);

  const res = await api.get(url);

  return resToReportingMileage(res.data);
};

const fetchReportingRevenue = async ({ startDate, endDate }: ChartFilters): Promise<ReportingRevenue> => {
  const url = resolveUrl(DASHBOARD_REPORTING_ROUTE.revenueShare, startDate, endDate);

  const res = await api.get(url);

  return resToReportingRevenue(res.data);
};

const fetchReportingUser = async ({ startDate, endDate, resolution }: ChartFilters): Promise<ReportingUser> => {
  const url = resolveUrl(DASHBOARD_REPORTING_ROUTE.user, startDate, endDate, resolution);
  const res = await api.get(url);

  return resToReportingUser(res.data);
};

const fetchReportingOrder = async ({ startDate, endDate, resolution }: ChartFilters): Promise<ReportingOrder> => {
  const url = resolveUrl(DASHBOARD_REPORTING_ROUTE.order, startDate, endDate, resolution);

  const res = await api.get(url);

  return resToReportingOrder(res.data);
};

const fetchReportingDeviceType = async ({ startDate, endDate }: ChartFilters): Promise<ReportingDeviceType[]> => {
  const url = resolveUrl(DASHBOARD_REPORTING_ROUTE.deviceType, startDate, endDate);

  const res = await api.get(url);

  return res.data.map(resToReportingDeviceType);
};

//
// GET hooks
//

export const useReportingMileage = ({ startDate, endDate }: ChartFilters) =>
  useQuery([QueryKeys.ReportingMileageKey, startDate, endDate], () => fetchReportingMileage({ startDate, endDate }));

export const useReportingRevenue = ({ startDate, endDate }: ChartFilters) =>
  useQuery([QueryKeys.ReportingRevenueKey, startDate, endDate], () => fetchReportingRevenue({ startDate, endDate }));

export const useReportingUser = ({ startDate, endDate, resolution }: ChartFilters) =>
  useQuery([QueryKeys.ReportingUserKey, startDate, endDate], () =>
    fetchReportingUser({ startDate, endDate, resolution }),
  );

export const useReportingOrder = ({ startDate, endDate, resolution }: ChartFilters) =>
  useQuery([QueryKeys.ReportingOrderKey, startDate, endDate], () =>
    fetchReportingOrder({ startDate, endDate, resolution }),
  );

export const useReportingDeviceType = ({ startDate, endDate }: ChartFilters) =>
  useQuery([QueryKeys.ReportingDeviceTypeKey, startDate, endDate], () =>
    fetchReportingDeviceType({ startDate, endDate }),
  );
