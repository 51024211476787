import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';

import { MessageType, showMessage, useIdFromParams } from 'helpers';
import {
  postMerchantToOrder,
  defaultOrder,
  fetchOrder,
  Order,
  putOrderBonusPayments,
  putOrderReimbursement,
} from 'services/orders';
import { Control, FormState, useForm, UseFormReset } from 'react-hook-form';

import { OrderBonusPayments, OrderReimbursement } from '../types';
import { defaultOrderBonusPayments, defaultOrderReimbursement } from '../consts';
import { orderToOrderBonusPaymentForm } from './transformations';
import { ASSIGN_MERCHANT_SCHEMA } from './const';

const OrderPageContext = createContext<{
  order: Order;
  isLoading: boolean;
  formState: FormState<OrderBonusPayments>;
  control: Control<OrderBonusPayments, any>;
  isBonusPaymentsModalOpen: boolean;
  orderBonusPaymentsLoading: boolean;
  isAssignMerchantModalOpen: boolean;
  assignMerchantToOrderLoading: boolean;
  assignMerchantControl: Control<{ merchantId: string }, any>;
  assignMerchantFormState: FormState<{ merchantId: string }>;
  handleAssignMerchantClick: () => void;
  reset: UseFormReset<OrderBonusPayments>;
  handleBonusPaymentsModalOpen: () => void;
  handleBonusPaymentsModalClose: () => void;
  handleAssignMerchantModalClose: () => void;
  handleReimbursementModalOpen: () => void;
  orderReimbursementLoading: boolean;
  reimbursementReset: UseFormReset<OrderReimbursement>;
  onBonusPaymentSave: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  onAssignMerchant: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  onReimbursementSave: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  handleReimbursementModalClose: () => void;
  reimbursementControl: Control<OrderReimbursement, any>;
  isReimbursementModalOpen: boolean;
  reimbursementFormState: FormState<OrderReimbursement>;
}>({} as any);

// This will be used in case we state shared inside the module
export const OrderPageProvider = ({ children = <Outlet /> }: Props) => {
  const { id: orderId, isCreate } = useIdFromParams();
  const queryClient = useQueryClient();

  const [isBonusPaymentsModalOpen, setIsBonusPaymentsModalOpen] = useState<boolean>(false);
  const [isAssignMerchantModalOpen, setIsAssignMerchantModalOpen] = useState<boolean>(false);
  const [isReimbursementModalOpen, setIsReimbursementModalOpen] = useState<boolean>(false);

  const {
    formState,
    handleSubmit: handleBonusPaymentsSubmit,
    reset,
    control,
  } = useForm<OrderBonusPayments>({
    defaultValues: defaultOrderBonusPayments,
    reValidateMode: 'onSubmit',
  });

  const {
    formState: reimbursementFormState,
    handleSubmit: handleReimbursementSubmit,
    reset: reimbursementReset,
    control: reimbursementControl,
  } = useForm<OrderReimbursement>({
    defaultValues: defaultOrderReimbursement,
    reValidateMode: 'onSubmit',
  });

  const {
    formState: assignMerchantFormState,
    handleSubmit: handleAssignMerchantSubmit,
    reset: assignMerchantReset,
    control: assignMerchantControl,
  } = useForm<{ merchantId: string }>({
    defaultValues: { merchantId: '' },
    resolver: yupResolver(ASSIGN_MERCHANT_SCHEMA),
  });

  const { data: order, isLoading } = useQuery(['orders', orderId], () => fetchOrder(orderId), {
    enabled: !isCreate,
  });

  const { mutateAsync: sendOrderBonusPayments, isLoading: orderBonusPaymentsLoading } =
    useMutation(putOrderBonusPayments);

  const { mutateAsync: sendOrderReimbursement, isLoading: orderReimbursementLoading } = useMutation(
    putOrderReimbursement,
    {
      onSuccess: () => queryClient.invalidateQueries(['orders', orderId]),
    },
  );

  const { mutateAsync: assignMerchantToOrder, isLoading: assignMerchantToOrderLoading } = useMutation(
    postMerchantToOrder,
    {
      onSuccess: () => queryClient.invalidateQueries(['orders', orderId]),
    },
  );

  useEffect(() => {
    if (order) {
      reset(orderToOrderBonusPaymentForm(order));
      reimbursementReset({ reimbursementPayment: order?.itemCost });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderToOrderBonusPaymentForm, order]);

  const onBonusPaymentSave = handleBonusPaymentsSubmit(async (bonusPaymentData) => {
    try {
      await sendOrderBonusPayments({ orderId, orderBonusPayments: bonusPaymentData });
      setIsBonusPaymentsModalOpen(false);
      showMessage('Bonus payments updated', MessageType.Success);
    } catch (e: any) {
      showMessage(e.response.data.title ?? 'Something went wrong', MessageType.Error);
    }
  });

  const onReimbursementSave = handleReimbursementSubmit(async (reimbursement) => {
    try {
      await sendOrderReimbursement({ orderId, reimbursement });
      setIsReimbursementModalOpen(false);
      showMessage('Reimbursement payments updated', MessageType.Success);
    } catch (e: any) {
      showMessage(e.response.data.title ?? 'Something went wrong', MessageType.Error);
    }
  });

  const onAssignMerchant = handleAssignMerchantSubmit(async ({ merchantId }) => {
    try {
      await assignMerchantToOrder({ orderId, merchantId });
      showMessage('Merchant successfully assigned', MessageType.Success);
    } catch {
      showMessage('Something went wrong', MessageType.Error);
    }

    setIsAssignMerchantModalOpen(false);
  });

  const handleBonusPaymentsModalOpen = useCallback(
    () => setIsBonusPaymentsModalOpen((prev) => !prev),
    [setIsBonusPaymentsModalOpen],
  );

  const handleReimbursementModalOpen = useCallback(() => setIsReimbursementModalOpen((prev) => !prev), []);

  const handleBonusPaymentsModalClose = useCallback(() => {
    setIsBonusPaymentsModalOpen(false);
    reset();
  }, [setIsBonusPaymentsModalOpen, reset]);

  const handleReimbursementModalClose = useCallback(() => {
    setIsReimbursementModalOpen(false);
    reimbursementReset();
  }, [reimbursementReset]);

  const handleAssignMerchantClick = useCallback(
    () => setIsAssignMerchantModalOpen(true),
    [setIsAssignMerchantModalOpen],
  );

  const handleAssignMerchantModalClose = useCallback(() => {
    setIsAssignMerchantModalOpen(false);
    assignMerchantReset();
  }, [setIsAssignMerchantModalOpen, assignMerchantReset]);

  const providerValue = useMemo(
    () => ({
      order: order ?? defaultOrder,
      isLoading,
      formState,
      control,
      isBonusPaymentsModalOpen,
      orderBonusPaymentsLoading,
      isAssignMerchantModalOpen,
      assignMerchantToOrderLoading,
      orderReimbursementLoading,
      assignMerchantControl,
      assignMerchantFormState,
      handleAssignMerchantModalClose,
      isReimbursementModalOpen,
      reimbursementFormState,
      onAssignMerchant,
      handleAssignMerchantClick,
      handleBonusPaymentsModalOpen,
      handleBonusPaymentsModalClose,
      reimbursementReset,
      onBonusPaymentSave,
      onReimbursementSave,
      reimbursementControl,
      handleReimbursementModalClose,
      handleReimbursementModalOpen,
      reset,
    }),
    [
      order,
      isLoading,
      formState,
      control,
      isBonusPaymentsModalOpen,
      orderBonusPaymentsLoading,
      isAssignMerchantModalOpen,
      assignMerchantToOrderLoading,
      orderReimbursementLoading,
      assignMerchantControl,
      assignMerchantFormState,
      handleAssignMerchantModalClose,
      isReimbursementModalOpen,
      reimbursementFormState,
      onAssignMerchant,
      handleAssignMerchantClick,
      handleBonusPaymentsModalOpen,
      handleBonusPaymentsModalClose,
      reimbursementReset,
      onBonusPaymentSave,
      onReimbursementSave,
      reimbursementControl,
      handleReimbursementModalOpen,
      handleReimbursementModalClose,
      reset,
    ],
  );

  return <OrderPageContext.Provider value={providerValue}>{children}</OrderPageContext.Provider>;
};

export const useOrderPage = () => {
  return useContext(OrderPageContext);
};

interface Props {
  children?: React.ReactNode;
}
