import { CellProps } from 'react-table';
import clsx from 'clsx';

import { TableColumn, Tooltip } from 'components';
import { shortenId } from 'helpers';
import { Driver, DriverStatus } from 'services/drivers';

export const columns = (
  deleteClicked: (rowData: Driver) => void,
  approveClicked: (rowData: Driver) => void,
  denyClicked: (rowData: Driver) => void,
  restoreClicked: (rowData: Driver) => void,
): TableColumn<Driver>[] => [
  {
    Header: '',
    id: 'driverStatus',
    accessor: 'isOnDuty',
    isSortable: true,
    Cell: ({ row }: CellProps<Driver>) => {
      const rowData = row.original;
      return rowData.isOnDuty ? (
        <Tooltip tooltipMessage="On Duty">
          <span className="w-2 h-2 inline-block bg-green-500 rounded-full" />
        </Tooltip>
      ) : (
        <Tooltip tooltipMessage="Off Duty">
          <span className="w-2 h-2 inline-block bg-red-500 rounded-full" />
        </Tooltip>
      );
    },
  },
  {
    Header: 'ID',
    accessor: 'id',
    isSortable: true,
    Cell: ({ row }: CellProps<Driver>) => {
      const rowData = row.original;
      return (
        <Tooltip
          popoverMessage="Copied!"
          tooltipMessage={rowData.id}
          onClick={() => navigator.clipboard.writeText(rowData.id)}
        >
          {shortenId(rowData.id)}
        </Tooltip>
      );
    },
  },
  {
    Header: 'First Name',
    accessor: 'userFirstName' as any,
    isSortable: true,
  },
  {
    Header: 'Last Name',
    accessor: 'userLastName' as any,
    isSortable: true,
  },
  {
    Header: 'Email',
    accessor: 'userEmail' as any,
    isSortable: true,
  },
  {
    Header: 'Driver Group',
    accessor: 'driverGroupName' as any,
    isSortable: true,
  },
  {
    Header: 'Status',
    accessor: 'status' as any,
    isSortable: true,
    Cell: ({ row }: CellProps<Driver>) => {
      const rowData = row.original;

      return (
        <span
          className={clsx('inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium', {
            'bg-green-100 text-green-800': rowData.status === DriverStatus.APPROVED,
            'bg-yellow-100 text-yellow-800': rowData.status === DriverStatus.PENDING,
            'bg-red-100 text-red-800': rowData.status === DriverStatus.DENIED,
            'bg-gray-100 text-gray-800': rowData.status === DriverStatus.DELETED,
          })}
        >
          {rowData.status?.toUpperCase() || 'N/A'}
        </span>
      );
    },
  },
  {
    id: 'deleteButton',
    Header: '',
    Cell: ({ row }: CellProps<Driver>) => {
      const rowData = row.original;

      const approveButton = (
        <button
          key="approve"
          type="button"
          className="inline-flex items-center px-2.5 py-1.5 mr-1 border border-green-300 shadow-sm text-xs font-medium rounded text-green-700 bg-white hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={(ev) => {
            ev.stopPropagation();
            approveClicked(rowData);
          }}
        >
          Approve
        </button>
      );

      const denyButton = (
        <button
          key="deny"
          type="button"
          className="inline-flex items-center px-2.5 py-1.5 mr-1 border border-red-300 shadow-sm text-xs font-medium rounded text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={(ev) => {
            ev.stopPropagation();
            denyClicked(rowData);
          }}
        >
          Deny
        </button>
      );

      const deleteButton = (
        <button
          key="delete"
          type="button"
          className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={(ev) => {
            ev.stopPropagation();
            deleteClicked(rowData);
          }}
        >
          Delete
        </button>
      );

      const restoreButton = (
        <button
          key="restore"
          type="button"
          className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={(ev) => {
            ev.stopPropagation();
            restoreClicked(rowData);
          }}
        >
          Restore
        </button>
      );

      let renderedButtons: React.ReactElement[] = [];
      switch (rowData.status) {
        case DriverStatus.PENDING:
          renderedButtons = [approveButton, denyButton, deleteButton];
          break;
        case DriverStatus.APPROVED:
          renderedButtons = [denyButton, deleteButton];
          break;
        case DriverStatus.DENIED:
          renderedButtons = [approveButton, deleteButton];
          break;
        case DriverStatus.DELETED:
          renderedButtons = [restoreButton];
          break;
        default:
          renderedButtons = [approveButton, denyButton, deleteButton];
          break;
      }
      return <div className="flex justify-end">{renderedButtons}</div>;
    },
    width: 'auto',
  },
];
