import Chart from 'react-apexcharts';

export const ApexChart = ({ options, series, type = 'bar', width, height, ...rest }: ChartProps) => {
  return <Chart options={options} series={series} type={type} width={width} height={height} {...rest} />;
};

type ChartProps = {
  options: object;
  series: any[];
  type?: 'donut' | 'pie' | 'bar' | 'line';
  width?: string | number;
  height?: string | number;
};
