import { CustomerForm } from 'modules/Customers/types';
import { resToUser } from '../users';
import { Customer } from './types';

export const resToCustomer = (res: any): Customer => {
  const customer: Customer = {
    id: res.id,
    user: res.user ? resToUser(res.user) : null,
    zipCode: res.zipCode ?? '',
    leadSource: res.leadSource ?? '',
    referralCode: res.referralCode ?? '',
    customerTypeId: res.customerTypeId ?? '',
    isTest: res.isTest ?? false,
    isToBeInvoiced: res.isToBeInvoiced ?? false,
    userFirstName: '',
    userLastName: '',
    userEmail: '',
  };

  if (!res.user) {
    customer.userFirstName = res.userFirstName ?? '';
    customer.userLastName = res.userLastName ?? '';
    customer.userEmail = res.userEmail ?? '';
  } else {
    customer.userFirstName = res.user.firstName ?? '';
    customer.userLastName = res.user.lastName ?? '';
    customer.userEmail = res.user.email ?? '';
  }

  return customer;
};

export const customerToPostReq = (customer: CustomerForm): any => {
  return {
    user: {
      email: customer.email,
      firstName: customer.firstName,
      lastName: customer.lastName,
      phoneNumber: customer.phone,
      password: customer.password,
      isDriver: false,
      isCustomer: true,
      isMerchant: false,
      isAdmin: false,
    },
    zipCode: customer.zip,
    leadSource: customer.leadSource,
    referralCode: customer.referralCode,
    customerTypeId: customer.type,
    isTest: customer.isTest,
    isToBeInvoiced: customer.isInvoiced,
  };
};

export const customerToPutReq = (customer: CustomerForm): any => {
  const payload: any = {
    user: {
      email: customer.email,
      firstName: customer.firstName,
      lastName: customer.lastName,
      phoneNumber: customer.phone,
      isDriver: customer.isDriver,
      isCustomer: customer.isCustomer,
      isMerchant: customer.isMerchant,
      isAdmin: customer.isAdmin,
    },
    zipCode: customer.zip,
    leadSource: customer.leadSource,
    referralCode: customer.referralCode,
    customerTypeId: customer.type,
    isTest: customer.isTest,
    isToBeInvoiced: customer.isInvoiced,
  };

  if (customer.password) {
    payload.user.password = customer.password;
  }

  return payload;
};
