import { useNavigate } from 'react-router-dom';
import { Button, Container, Header, Divider, FormItem, Input, Textarea, Select } from 'components';
import { useDriverReviewPage } from './DriverReviewPageProvider';

export const DriverReviewPage = () => {
  const { formState, onSubmit, register, control } = useDriverReviewPage();
  const navigate = useNavigate();
  return (
    <>
      <Header title="Edit review" onGoBackPress={() => navigate(-1)}>
        <Button type="submit" onClick={onSubmit} label="Save" form="driverReviewForm" />
      </Header>

      <Container>
        <div className="overflow-hidden bg-white rounded-lg shadow">
          <div className="px-4 py-5 sm:p-6">
            <form className="space-y-10" onSubmit={onSubmit} noValidate id="driverReviewForm">
              <Divider title="Review information" />

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <FormItem label="First Name" htmlFor="firstName" error={formState.errors.firstName?.message}>
                  <Input
                    {...register('firstName')}
                    id="firstName"
                    type="text"
                    placeholder="This can be changed only on existing review"
                    autoComplete="firstName"
                    error={!!formState.errors.firstName?.message}
                    disabled
                  />
                </FormItem>
                <FormItem label="Last Nme" htmlFor="lastName" error={formState.errors.lastName?.message}>
                  <Input
                    {...register('lastName')}
                    id="lastName"
                    type="text"
                    placeholder="This can be changed only on existing review"
                    autoComplete="lastName"
                    error={!!formState.errors.lastName?.message}
                    disabled
                  />
                </FormItem>

                <FormItem label="Rate" htmlFor="score" error={formState.errors.score?.message}>
                  <Select
                    control={control}
                    name="score"
                    options={[
                      { name: '1', id: 1 },
                      { name: '2', id: 2 },
                      { name: '3', id: 3 },
                      { name: '4', id: 4 },
                      { name: '5', id: 5 },
                    ]}
                    error={!!formState.errors.score?.message}
                  />
                </FormItem>
                <FormItem label="Status" htmlFor="status" error={formState.errors.status?.message}>
                  <Select
                    control={control}
                    name="status"
                    options={[
                      { name: 'Pending', id: 'pending' },
                      { name: 'Approved', id: 'approved' },
                      { name: 'Denied', id: 'denied' },
                    ]}
                    error={!!formState.errors.status?.message}
                  />
                </FormItem>

                <FormItem label="Description" htmlFor="description" error={formState.errors.description?.message}>
                  <Textarea
                    {...register('description')}
                    id="description"
                    placeholder="Enter first description"
                    autoComplete="description"
                    className="h-32"
                    error={!!formState.errors.description?.message}
                  />
                </FormItem>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};
