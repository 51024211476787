import * as yup from 'yup';

export const SETTINGS_VALIDATION_SCHEMA = yup.object({
  adminEmail: yup.string().email('E-mail not valid.').required('E-mail is required'),
  contactPhone: yup.string().required('Contact phone is required.'),
  fromEmail: yup.string().email('E-mail not valid.').required('E-mail is required'),
  fromEmailName: yup.string().required('From e-mail name is required'),
});

export enum DaysInWeek {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}
