import { DataWithMeta, PaginationRequest, paginationRequestToUrl } from 'helpers';
import { CustomerForm } from 'modules/Customers/types';

import { api } from '../api';
import { customerToPostReq, customerToPutReq, resToCustomer } from './transformations';
import { Customer } from './types';

export const fetchCustomers = async (pagination: PaginationRequest): Promise<DataWithMeta<Customer>> => {
  const url = paginationRequestToUrl('/customers', pagination);

  const resp = await api.get(url);
  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToCustomer),
  };
};

export const fetchCustomer = async (id: string): Promise<Customer> => {
  const resp = await api.get(`/customers/${id}`);
  return resToCustomer(resp.data);
};

export const putCustomer = async (data: CustomerForm): Promise<Customer> => {
  const resp = await api.put(`/customers/${data.id}`, customerToPutReq(data));
  return resToCustomer(resp.data);
};

export const postCustomer = async (data: CustomerForm): Promise<Customer> => {
  const resp = await api.post(`/customers`, customerToPostReq(data));
  return resToCustomer(resp.data);
};

export const deleteCustomer = async (id: string): Promise<Customer> => {
  const resp = await api.delete(`/customers/${id}`);
  return resToCustomer(resp.data);
};

export const exportCustomers = async (search: string): Promise<any> => {
  const url = paginationRequestToUrl('/customers/export', { page: 1, pageSize: 999, search });

  const resp = await api.post(url);

  return resp.data;
};
