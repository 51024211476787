/* eslint-disable no-nested-ternary */
import dayjs from 'dayjs';

import { Resolution } from './types';
import { MONTHS } from './consts';

export const formatCustomDateTime = (date: string) => dayjs(date).format('MM-DD-YYYY hh:mm A');

export const resolveCustomDateResolution = (startDate: string, endDate: string): Resolution => {
  const start = dayjs(startDate);
  let end = dayjs(endDate);

  if (!end.isValid()) {
    end = dayjs();
  }
  const diff = end.diff(start, 'day');

  if (diff <= 14) {
    return Resolution.Day;
  }
  if (diff <= 60) {
    return Resolution.Week;
  }
  return Resolution.Month;
};

const displayMonth = (number: number) => {
  if (!number) {
    return '';
  }

  return MONTHS[number - 1];
};

const displayDay = (number: number) => {
  if (!number) {
    return '';
  }

  const suffix =
    number % 10 === 1 && number !== 11
      ? 'st'
      : number % 10 === 2 && number !== 12
      ? 'nd'
      : number % 10 === 3 && number !== 13
      ? 'rd'
      : 'th';

  return `${number}${suffix}`;
};

const getDaysOfWeek = (weekNumber: number, year: number) => {
  // Get the date for the first day of the year
  const date = dayjs(`${year}-01-01`);

  // Calculate the date for the first day of the target week
  const daysToFirstWeekDay = (7 - date.day() + 1) % 7;
  const firstWeekDay = daysToFirstWeekDay + (weekNumber - 1) * 7;
  const firstDayOfWeek = date.add(firstWeekDay, 'day');

  // Calculate the date for the last day of the target week
  const lastDayOfWeek = firstDayOfWeek.add(6, 'day');

  // Format the range of days as a string
  const formatString = 'MMM D';
  const firstDayString = firstDayOfWeek.locale('en').format(formatString);
  const lastDayString = lastDayOfWeek.locale('en').format(formatString);
  const rangeString = `${firstDayString} - ${lastDayString}`;

  return rangeString;
};

export const resolveLabels = (
  x: { day: number; week: number; month: number; year: number },
  resolution: Resolution,
) => {
  switch (resolution) {
    case Resolution.Day:
      return `${displayMonth(x.month)} ${displayDay(x.day)}`;
    case Resolution.Week:
      return getDaysOfWeek(x.week, x.year);
    case Resolution.Month:
      return `${displayMonth(x.month)} ${x.year}`;
    default:
      return '';
  }
};
