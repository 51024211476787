import { ItemGroup, ItemGroupForm } from './types';

export const resToItemGroup = (res: any): ItemGroup => {
  return {
    id: res.id,
    name: res.name,
    showInApp: res.showInApp,
  };
};

export const itemGroupToReq = (itemGroup: ItemGroupForm): any => {
  const payload: any = {
    name: itemGroup.name,
    showInApp: itemGroup.showInApp,
  };

  return payload;
};

export const transformToItemGroupsFilters = (filters: any): any => {
  return {
    name: filters.name,
  };
};
