export const initialOrdersByDeviceTypeChartData = {
  series: [],
  options: {
    labels: [],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: false,
              fontSize: '1.5em',
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: 'bottom',
    },
  },
};
