import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { Control, FormState, useFieldArray, useForm, UseFormRegister } from 'react-hook-form';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';

import { MessageType, showMessage, useIdFromParams } from 'helpers';
import { fetchMerchant, postMerchant, putMerchant } from 'services/merchants';
import { DriverGroup, fetchDriverGroups } from 'services/driverGroups';

import { MERCHANT_VALIDATION_SCHEMA } from './const';
import { ItemPickupTimes, MerchantForm } from '../types';
import { merchantToMerchantForm } from './transformation';
import { defaultMerchantForm, defaultItemPickupTimesRow } from '../consts';

const MerchantPageContext = createContext<{
  isCreate: boolean;
  formState: FormState<MerchantForm>;
  control: Control<MerchantForm, any>;
  driverGroupsData: DriverGroup[];
  itemPickupTimes: ItemPickupTimes[];
  orderErrors: {
    row: number;
    errorMessages: string[];
  }[];
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  register: UseFormRegister<MerchantForm>;
  handleRowDelete: (index: number) => void;
  handleAddNewRow: () => void;
}>({} as any);

// This will be used in case we state shared inside the module
export const MerchantPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id: merchantId, isCreate } = useIdFromParams();

  const { data: merchant } = useQuery(['merchants', merchantId], () => fetchMerchant(merchantId), {
    enabled: !isCreate,
  });
  const { data: driverGroups } = useQuery(['driverGroups'], () => fetchDriverGroups({ page: 1, pageSize: 100 }));

  const { mutateAsync: updateMerchant } = useMutation(putMerchant);
  const { mutateAsync: createMerchant } = useMutation(postMerchant);

  const { formState, control, handleSubmit, register, reset, clearErrors } = useForm<MerchantForm>({
    defaultValues: defaultMerchantForm,
    resolver: yupResolver(MERCHANT_VALIDATION_SCHEMA),
    reValidateMode: 'onSubmit',
  });

  const driverGroupsData = useMemo(() => driverGroups?.data || [], [driverGroups]);

  const {
    fields: itemPickupTimes,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'itemPickupTimes',
  });

  const orderErrors = useMemo(() => {
    const errorList: { row: number; errorMessages: string[] }[] = [];

    Object.keys(formState.errors.itemPickupTimes || []).forEach((key) => {
      const errorObject = formState.errors.itemPickupTimes?.[parseInt(key, 10)];

      const errorMessages = Object.values(errorObject || {}).map((error: any) => error.message);

      errorList.push({
        row: parseInt(key, 10) + 1,
        errorMessages,
      });
    });

    return errorList;
  }, [formState.errors]);

  const merchantForm = useMemo(() => {
    if (merchant) {
      return merchantToMerchantForm(merchant);
    }
    return defaultMerchantForm;
  }, [merchant]);

  useEffect(() => {
    reset(merchantForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantForm]);

  useEffect(() => {
    if (!itemPickupTimes.length) {
      clearErrors();
    }
  }, [itemPickupTimes, clearErrors]);

  const handleRowDelete = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  const handleAddNewRow = useCallback(() => {
    append(defaultItemPickupTimesRow, { shouldFocus: false });
  }, [append]);

  const onSubmit = handleSubmit(async (values: MerchantForm) => {
    if (isCreate) {
      try {
        await createMerchant(values);
        showMessage('Merchant successfully created.', MessageType.Success);
        queryClient.invalidateQueries('merchants');
        navigate('/merchants');
      } catch {
        showMessage('Theres been an error!', MessageType.Error);
      }
      return;
    }

    try {
      await updateMerchant(values);
      showMessage('Merchant successfully updated.', MessageType.Success);
      queryClient.invalidateQueries('merchants');
      navigate('/merchants');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const providerValue = useMemo(
    () => ({
      formState,
      control,
      isCreate,
      driverGroupsData,
      itemPickupTimes,
      orderErrors,
      register,
      onSubmit,
      handleRowDelete,
      handleAddNewRow,
    }),
    [
      formState,
      isCreate,
      control,
      driverGroupsData,
      itemPickupTimes,
      orderErrors,
      register,
      onSubmit,
      handleRowDelete,
      handleAddNewRow,
    ],
  );

  return <MerchantPageContext.Provider value={providerValue}>{children}</MerchantPageContext.Provider>;
};

export const useMerchantPage = () => {
  return useContext(MerchantPageContext);
};

interface Props {
  children?: React.ReactNode;
}
