import { Control, FormState } from 'react-hook-form';
import { AsyncSelect, FormItem, Modal } from 'components';
import { ModalProps } from 'components/Modal/Modal/types';
import { fetchMerchants } from 'services/merchants';

export const AssignMerchantModal = (props: Props) => {
  const {
    control,
    isLoading,
    open,
    useCancelButton = true,
    title = '',
    onSaveLabel = 'Assign',
    formState,
    onSave,
    onClose,
  } = props;
  return (
    <Modal
      open={open}
      onSave={onSave}
      onClose={onClose}
      useCancelButton={useCancelButton}
      title={title}
      onSaveLabel={onSaveLabel}
      isLoading={isLoading}
    >
      <div className="grid grid-cols-1 gap-4 py-5">
        <FormItem label="Merchant">
          <AsyncSelect
            name="merchantId"
            control={control}
            placeholder="Select merchant"
            error={formState.errors.merchantId?.message}
            asyncFunction={fetchMerchants}
            valueKey="id"
            getOptionLabel={(option) => option.businessName}
          />
        </FormItem>
      </div>
    </Modal>
  );
};

type Props = {
  control: Control<{ merchantId: string }, any>;
  formState: FormState<{ merchantId: string }>;
} & ModalProps;
