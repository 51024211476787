import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

export const DistancePricesContext = React.createContext<{}>({});

// This will be used in case we state shared inside the module
export const DistancePricesProvider = ({ children = <Outlet /> }: Props) => {
  const providerValue = useMemo(() => ({}), []);

  return <DistancePricesContext.Provider value={providerValue}>{children}</DistancePricesContext.Provider>;
};

interface Props {
  children?: React.ReactNode;
}
