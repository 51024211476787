import { StopPrice } from './types';

export const resToStopPrice = (res: any): StopPrice => {
  return {
    id: res.id,
    stopsFrom: res.stopsFrom,
    stopsTo: res.stopsTo,
    shippingFactor: res.shippingFactor,
    firstHelperFactor: res.firstHelperFactor,
    secondHelperFactor: res.secondHelperFactor,
  };
};

export const stopPriceToPostReq = (price: any): any => {
  return {
    stopsFrom: price.stopsFrom,
    stopsTo: price.stopsTo,
    shippingFactor: price.shippingFactor,
    firstHelperFactor: price.firstHelperFactor,
    secondHelperFactor: price.secondHelperFactor,
  };
};

export const stopPriceToPutReq = (price: any): any => {
  return {
    stopsFrom: price.stopsFrom,
    stopsTo: price.stopsTo,
    shippingFactor: price.shippingFactor,
    firstHelperFactor: price.firstHelperFactor,
    secondHelperFactor: price.secondHelperFactor,
  };
};
