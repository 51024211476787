import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAuth } from 'GlobalProvider';
import { updateAuthUser } from 'services/auth';
import { MessageType, showMessage } from 'helpers';

import { defaultProfileForm, PROFILE_VALIDATION_SCHEMA } from './const';
import { ProfileForm } from './types';
import { transformUserToProfileForm } from './transformations';

export const useProfilePage = () => {
  const { user, updateUser } = useAuth();

  const { handleSubmit, register, formState, reset } = useForm<ProfileForm>({
    defaultValues: defaultProfileForm,
    resolver: yupResolver(PROFILE_VALIDATION_SCHEMA),
  });

  useEffect(() => {
    if (user) {
      reset(transformUserToProfileForm(user));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSubmit = handleSubmit(async (values: ProfileForm) => {
    try {
      const updatedUser = await updateAuthUser(values, user!);
      updateUser(updatedUser);
      showMessage('Your profile has been updated', MessageType.Success);
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  return { formState, register, onSubmit };
};
