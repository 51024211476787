import { PageType } from 'helpers';

import { PromoCodeForm, PromoCodeType } from './types';

export const defaultPromoCode: PromoCodeForm = {
  id: PageType.Create,
  code: '',
  activeFrom: null,
  activeTo: null,
  type: PromoCodeType.Amount,
  amount: null,
  quantity: null,
  maxUsesPerUser: null,
  status: '',
};
