import { DataWithMeta, PaginationRequest, paginationRequestToUrl } from 'helpers';
import { DriverReviewForm } from 'modules/DriverReviews/DriverReviewPage/types';

import { api } from '../api';
import { PutReview } from './consts';
import { transformToReviewFilters, resToReview, reviewToPutReq } from './transformations';
import { Review } from './types';

export const fetchReviews = async (pagination: PaginationRequest): Promise<DataWithMeta<Review>> => {
  const url = paginationRequestToUrl('/reviews', pagination, transformToReviewFilters);

  const resp = await api.get(url);

  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToReview),
  };
};

export const fetchReview = async (id: string): Promise<Review> => {
  const resp = await api.get(`/reviews/${id}`);
  return resToReview(resp.data);
};

export const deleteReview = async (id: string): Promise<Review> => {
  const resp = await api.delete(`/reviews/${id}`);
  return resToReview(resp.data);
};

export const changeReviewStatus = async ({ id, status }: PutReview): Promise<Review> => {
  const resp = await api.put(`/reviews/${id}/status`, { status });
  return resToReview(resp.data);
};

export const putDriverReview = async (data: DriverReviewForm): Promise<Review> => {
  const resp = await api.put(`/reviews/${data.id}`, reviewToPutReq(data));

  return resToReview(resp.data);
};
