import {
  AsyncSelect,
  Button,
  Container,
  CurrencyInput,
  DatePicker,
  Divider,
  FormItem,
  Header,
  Input,
  NumberInput,
  RadioButton,
  Select,
  Tabs,
  Textarea,
  ImageDropzone,
} from 'components';
import { useNavigate } from 'react-router-dom';

import { ItemCategory, fetchItemCategories } from 'services/itemCategories';
import { Customer, fetchCustomers } from 'services/customers';
import { DriverGroup, fetchDriverGroups } from 'services/driverGroups';
import { Driver, fetchDrivers } from 'services/drivers';
import { Merchant, fetchMerchants } from 'services/merchants';
import { PromoCode, fetchPromoCodes } from 'services/promoCodes';
import { DeviceType, PaymentType, PickupType } from 'services/orders';
import { allItemPickupTimes } from 'services/merchants/const';
import { Trailer, fetchTrailers } from 'services/trailers';
import clsx from 'clsx';
import { useNewOrderPage } from './NewOrderPageProvider';
import { getDeliveryWindowOptions } from './helpers';
import { defaultAddress } from './const';

export const NewOrderPage = () => {
  const navigate = useNavigate();
  const {
    onSubmit,
    formState,
    control,
    register,
    stops,
    addressSearch,
    setValue,
    tabs,
    fetchAddress,
    address,
    setAddressToStop,
    isHouseNumber,
    currentIndex,
    onAddPress,
    handleTabChange,
    setCurrentIndex,
    setAddress,
  } = useNewOrderPage();

  return (
    <div>
      <Header title="Order" onGoBackPress={() => navigate(-1)}>
        <div className="flex items-center space-x-2">
          <Button type="button" onClick={onSubmit} label="Save" form="orderForm" disabled={formState.isSubmitting} />
        </div>
      </Header>

      <Container isLoading={formState.isSubmitting}>
        <div className="overflow-hidden bg-white rounded-lg shadow">
          <div className="px-4 py-5 sm:p-6">
            <form className="space-y-10" onSubmit={onSubmit} noValidate id="orderForm">
              <Divider title="Driver Info" />

              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <FormItem
                  label="Driver Group"
                  htmlFor="driverGroupId"
                  error={!!formState.errors.driverGroupId?.message}
                  required
                >
                  <AsyncSelect
                    asyncFunction={fetchDriverGroups}
                    getOptionLabel={(option: DriverGroup) => `${option.name ?? ''}`}
                    control={control}
                    name="driverGroupId"
                    valueKey="id"
                    placeholder="Select Driver Group"
                    error={formState.errors.driverGroupId?.message}
                  />
                </FormItem>

                <FormItem label="Driver" htmlFor="driverId" error={formState.errors.driverId?.message}>
                  <AsyncSelect
                    asyncFunction={fetchDrivers}
                    getOptionLabel={(option: Driver) => `${option.userFirstName ?? ''} ${option.userLastName ?? ''}`}
                    control={control}
                    name="driverId"
                    valueKey="id"
                    placeholder="Select Driver"
                  />
                </FormItem>
              </div>

              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <FormItem
                  label="Number of Load Helpers"
                  htmlFor="loadHelperCount"
                  error={formState.errors.loadHelperCount?.message}
                  required
                >
                  <NumberInput
                    name="loadHelperCount"
                    required
                    error={!!formState.errors.loadHelperCount?.message}
                    control={control}
                    decimalPlaces={0}
                  />
                </FormItem>

                <FormItem
                  label="Number of Unload Helpers"
                  htmlFor="unloadHelperCount"
                  error={formState.errors.unloadHelperCount?.message}
                  required
                >
                  <NumberInput
                    control={control}
                    name="unloadHelperCount"
                    required
                    error={!!formState.errors.unloadHelperCount?.message}
                    decimalPlaces={0}
                  />
                </FormItem>
              </div>

              <Divider title="Delivery Info" />

              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <FormItem label="Trailer" htmlFor="trailerId" error={formState.errors.trailerId?.message}>
                  <AsyncSelect
                    asyncFunction={fetchTrailers}
                    getOptionLabel={(option: Trailer) => `${option.title ?? ''}`}
                    control={control}
                    name="trailerId"
                    valueKey="id"
                    placeholder="Select Trailer"
                  />
                </FormItem>

                <FormItem label="Merchant" htmlFor="merchantId" error={formState.errors.merchantId?.message}>
                  <AsyncSelect
                    asyncFunction={fetchMerchants}
                    getOptionLabel={(option: Merchant) =>
                      `${option.user?.firstName ?? ''} ${option.user?.lastName ?? ''}`
                    }
                    control={control}
                    name="merchantId"
                    valueKey="id"
                    placeholder="Select Merchant"
                  />
                </FormItem>
              </div>

              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <FormItem
                  label="Start Date Time"
                  htmlFor="startDateTime"
                  error={formState.errors.startDateTime?.message}
                  required
                >
                  <DatePicker
                    control={control}
                    useTime
                    name="startDateTime"
                    dateFormat="MM.dd.yyyy. HH:mm"
                    placeholder="Enter start date time"
                    error={!!formState.errors.startDateTime?.message}
                  />
                </FormItem>

                <FormItem
                  label="Date Time of Completion"
                  htmlFor="completedAt"
                  error={formState.errors.completedAt?.message}
                  required
                >
                  <DatePicker
                    control={control}
                    useTime
                    name="completedAt"
                    dateFormat="MM.dd.yyyy. HH:mm"
                    placeholder="Enter date time of completion"
                    error={!!formState.errors.completedAt?.message}
                  />
                </FormItem>
              </div>

              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <FormItem
                  label="Pickup Type"
                  htmlFor="pickupType"
                  error={formState.errors.pickupType?.message}
                  required
                >
                  <Select
                    options={Object.values(PickupType)}
                    control={control}
                    name="pickupType"
                    placeholder="Select pickup type"
                  />
                </FormItem>
                <FormItem
                  label="Delivery Window"
                  htmlFor="deliveryWindow"
                  error={formState.errors.deliveryWindow?.message}
                  required
                >
                  <Select
                    options={getDeliveryWindowOptions()}
                    control={control}
                    name="deliveryWindow"
                    placeholder="Select pickup type"
                  />
                </FormItem>
              </div>

              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <FormItem
                  label="Pickup Time From"
                  htmlFor="pickupTimeFrom"
                  error={formState.errors.pickupTimeFrom?.message}
                  required
                >
                  <Select
                    options={Object.values(allItemPickupTimes).map(({ value }) => ({ name: value, id: value }))}
                    control={control}
                    name="pickupTimeFrom"
                    placeholder="From"
                  />
                </FormItem>
                <FormItem
                  label="Pickup Time Until"
                  htmlFor="pickupTimeUntil"
                  error={formState.errors.pickupTimeUntil?.message}
                  required
                >
                  <Select
                    options={Object.values(allItemPickupTimes).map(({ value }) => ({ name: value, id: value }))}
                    control={control}
                    name="pickupTimeUntil"
                    placeholder="Until"
                  />
                </FormItem>
              </div>

              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <FormItem
                  label="Pickup Location Description"
                  htmlFor="pickupLocationDescription"
                  error={formState.errors.pickupLocationDescription?.message}
                >
                  <Textarea
                    {...register('pickupLocationDescription')}
                    id="pickupLocationDescription"
                    placeholder="Enter description"
                    autoComplete="pickupLocationDescription"
                    className="h-28"
                    error={!!formState.errors.pickupLocationDescription?.message}
                  />
                </FormItem>
                <FormItem
                  label="Delivery Location Description"
                  htmlFor="deliveryLocationDescription"
                  error={formState.errors.deliveryLocationDescription?.message}
                >
                  <Textarea
                    {...register('deliveryLocationDescription')}
                    id="deliveryLocationDescription"
                    placeholder="Enter description"
                    autoComplete="deliveryLocationDescription"
                    className="h-28"
                    error={!!formState.errors.deliveryLocationDescription?.message}
                  />
                </FormItem>
              </div>

              <Divider title="Order Stop" />
              <div className="ml-2">
                <Tabs tabs={tabs} onClick={handleTabChange} />
              </div>

              {stops.map((stop, index) => (
                <div
                  className={clsx(
                    stop.stopNumber === currentIndex ? 'block' : 'hidden',
                    'p-4 border border-gray-300 rounded-md',
                  )}
                  key={stop.keyID}
                >
                  <Divider title="Order Stop Details" />

                  {addressSearch[currentIndex - 1].address?.street1 === '' ? (
                    <div className="grid grid-cols-1 gap-4 py-5 lg:grid-cols-2">
                      <FormItem
                        label="Address"
                        htmlFor="addressName"
                        error={formState.errors.stops?.[index]?.addressName?.message}
                      >
                        <Input
                          {...register(`stops.${index}.addressName`)}
                          id="addressName"
                          type="text"
                          placeholder="Enter Address"
                          error={!!formState.errors.stops?.[index]?.addressName?.message}
                        />
                      </FormItem>
                      <div className="flex items-end">
                        <Button
                          type="button"
                          onClick={() => fetchAddress(addressSearch[currentIndex - 1]?.addressName || '')}
                          label="search"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="grid grid-cols-1 gap-4 py-5 lg:grid-cols-2">
                      <FormItem
                        label="Address"
                        htmlFor="address"
                        error={formState.errors.stops?.[index]?.address?.message}
                      >
                        <Input
                          {...register(`stops.${index}.address.street1`)}
                          id="address"
                          type="text"
                          placeholder="Enter"
                          error={!!formState.errors.stops?.[index]?.address?.message}
                          disabled
                          className="bg-gray-100 cursor-not-allowed"
                        />
                      </FormItem>
                      <div className="flex items-end">
                        <Button
                          type="button"
                          onClick={() => setValue(`stops.${index}.address`, defaultAddress)}
                          label="delete"
                        />
                      </div>
                    </div>
                  )}
                  <div className="grid grid-cols-1 gap-4 py-5 items-center justify-center">
                    {address.length > 0 && isHouseNumber ? (
                      <ul className="flex flex-col gap-2 border border-primary rounded-md p-2 overflow-auto">
                        {address.map((item, idx) => (
                          <li key={idx}>
                            <button
                              type="button"
                              onClick={() => {
                                setAddressToStop(item.place_id);
                                setAddress([]);
                              }}
                              className="w-full hover:bg-primary-100 rounded-md px-2 py-1"
                            >
                              {item.description}
                            </button>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="flex items-center justify-center">
                        <p className="text-red-500">
                          {address.length < 1 && isHouseNumber === false ? 'Address is not valid' : null}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="grid grid-cols-1 gap-4 py-5 lg:grid-cols-2">
                    <FormItem
                      label="Contact Name"
                      htmlFor="contactName"
                      error={formState.errors.stops?.[index]?.contactName?.message}
                    >
                      <Input
                        {...register(`stops.${index}.contactName`)}
                        id="contactName"
                        type="text"
                        placeholder="Enter Contact Name"
                        error={!!formState.errors.stops?.[index]?.contactName?.message}
                      />
                    </FormItem>
                    <FormItem
                      label="Contact Phone"
                      htmlFor="contactPhone"
                      error={formState.errors.stops?.[index]?.contactPhone?.message}
                    >
                      <Input
                        {...register(`stops.${index}.contactPhone`)}
                        id="contactPhone"
                        type="text"
                        placeholder="Enter Contact Phone"
                        error={!!formState.errors.stops?.[index]?.contactPhone?.message}
                      />
                    </FormItem>
                  </div>
                  <div className="grid grid-cols-1 gap-4 py-5">
                    <FormItem
                      label="Pickup Note"
                      htmlFor="pickupNote"
                      error={formState.errors.stops?.[index]?.pickupNote?.message}
                    >
                      <Textarea
                        {...register(`stops.${index}.pickupNote`)}
                        id="pickupNote"
                        placeholder="Enter Pickup Note"
                        autoComplete="pickupNote"
                        className="h-28"
                        error={!!formState.errors.stops?.[index]?.pickupNote?.message}
                      />
                    </FormItem>
                  </div>
                  <div className="grid grid-cols-1 gap-4 py-5">
                    <FormItem
                      label="Driver Delivery Note"
                      htmlFor="driverDeliveryNote"
                      error={formState.errors.stops?.[index]?.driverDeliveryNote?.message}
                    >
                      <Textarea
                        {...register(`stops.${index}.driverDeliveryNote`)}
                        id="driverDeliveryNote"
                        placeholder="Enter Driver Delivery Note"
                        autoComplete="driverDeliveryNote"
                        className="h-28"
                        error={!!formState.errors.stops?.[index]?.driverDeliveryNote?.message}
                      />
                    </FormItem>
                  </div>
                  <div className="grid grid-cols-1 gap-4 py-5">
                    <FormItem
                      label="Items Note"
                      htmlFor="itemsToBeDeliveredNote"
                      error={formState.errors.stops?.[index]?.itemsToBeDeliveredNote?.message}
                    >
                      <Textarea
                        {...register(`stops.${index}.itemsToBeDeliveredNote`)}
                        id="itemsToBeDeliveredNote"
                        placeholder="Enter Items Note"
                        autoComplete="itemsToBeDeliveredNote"
                        className="h-28"
                        error={!!formState.errors.stops?.[index]?.itemsToBeDeliveredNote?.message}
                      />
                    </FormItem>
                  </div>
                  <div className="flex flex-1 justify-end w-full">
                    <Button
                      type="button"
                      label="Add New Stop"
                      onClick={() => {
                        setCurrentIndex(stops.length + 1);
                        onAddPress(stops);
                      }}
                    />
                  </div>
                </div>
              ))}

              <Divider title="Other Info" />

              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <FormItem label="Customer" htmlFor="customerId" error={formState.errors.customerId?.message} required>
                  <AsyncSelect
                    asyncFunction={fetchCustomers}
                    getOptionLabel={(option: Customer) => `${option.userFirstName ?? ''} ${option.userLastName ?? ''}`}
                    control={control}
                    name="customerId"
                    valueKey="id"
                    placeholder="Select Item customer"
                  />
                </FormItem>

                <FormItem
                  label="Device Type"
                  htmlFor="deviceType"
                  error={formState.errors.deviceType?.message}
                  required
                >
                  <Select
                    options={Object.values(DeviceType)}
                    control={control}
                    name="deviceType"
                    placeholder="Select device type"
                  />
                </FormItem>
              </div>

              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <FormItem label="Promo code" htmlFor="promoCodeId" error={formState.errors.promoCodeId?.message}>
                  <AsyncSelect
                    asyncFunction={fetchPromoCodes}
                    getOptionLabel={(option: PromoCode) => `${option.code ?? ''}`}
                    control={control}
                    name="promoCodeId"
                    valueKey="id"
                    placeholder="Select Promo Code"
                  />
                </FormItem>

                <FormItem
                  label="Merchant Order Number"
                  htmlFor="merchantOrderNumber"
                  error={formState.errors.merchantOrderNumber?.message}
                >
                  <Input
                    {...register('merchantOrderNumber')}
                    id="merchantOrderNumber"
                    type="text"
                    placeholder="Enter merchantOrderNumber"
                    required
                    error={!!formState.errors.merchantOrderNumber?.message}
                    disabled={formState.isSubmitting}
                  />
                </FormItem>
              </div>

              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <FormItem
                  label="Payment Type"
                  htmlFor="paymentType"
                  required
                  error={formState.errors.paymentType?.message}
                >
                  <RadioButton label="Invoice" {...register('paymentType')} value={PaymentType.Invoiced} id="invoice" />
                  <RadioButton
                    label="Credit Card"
                    {...register('paymentType')}
                    value={PaymentType.CreditCard}
                    id="cc"
                  />
                </FormItem>

                <FormItem label="Tip over ride" htmlFor="tipOverride">
                  <CurrencyInput id="tipOverride" name="tipOverride" control={control} placeholder="Enter amount" />
                </FormItem>
              </div>

              <Divider title="Item Info" />

              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <FormItem label="Width" htmlFor="width" error={formState.errors.width?.message} required>
                  <Input
                    {...register('width')}
                    id="width"
                    type="text"
                    placeholder="Enter width"
                    required
                    error={!!formState.errors.width?.message}
                    disabled={formState.isSubmitting}
                  />
                </FormItem>
                <FormItem label="Height" htmlFor="height" error={formState.errors.height?.message} required>
                  <Input
                    {...register('height')}
                    id="height"
                    type="text"
                    placeholder="Enter height"
                    required
                    error={!!formState.errors.height?.message}
                    disabled={formState.isSubmitting}
                  />
                </FormItem>
              </div>

              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <FormItem label="Weight" htmlFor="weight" error={formState.errors.weight?.message} required>
                  <NumberInput
                    control={control}
                    name="weight"
                    required
                    error={!!formState.errors.weight?.message}
                    decimalPlaces={0}
                  />
                </FormItem>
                <FormItem label="Length" htmlFor="length" error={formState.errors.length?.message} required>
                  <Input
                    {...register('length')}
                    id="length"
                    type="text"
                    placeholder="Enter length"
                    required
                    error={!!formState.errors.length?.message}
                    disabled={formState.isSubmitting}
                  />
                </FormItem>
              </div>

              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <FormItem label="Item Cost" htmlFor="itemCost">
                  <CurrencyInput id="itemCost" name="itemCost" control={control} placeholder="Enter amount" />
                </FormItem>
                <FormItem label="Item Category" htmlFor="categoryId" required>
                  <AsyncSelect
                    asyncFunction={fetchItemCategories}
                    getOptionLabel={(option: ItemCategory) => `${option.name ?? ''}`}
                    control={control}
                    name="categoryId"
                    valueKey="id"
                    placeholder="Select Item Category"
                    error={formState.errors.categoryId?.message}
                  />
                </FormItem>
              </div>
              <FormItem
                label="Item Description"
                htmlFor="itemsDescription"
                error={formState.errors.itemsDescription?.message}
              >
                <Textarea
                  {...register('itemsDescription')}
                  id="itemsDescription"
                  placeholder="Enter description"
                  autoComplete="itemsDescription"
                  className="h-28"
                  error={!!formState.errors.itemsDescription?.message}
                />
              </FormItem>
              <Divider title="Images" />
              <ImageDropzone name="images" control={control} label="Images" />
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
};
