import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { MessageType, showMessage, usePagination } from 'helpers';
import { deleteDistancePrice, DistancePrice, fetchDistancePrices } from 'services/distancePrices';

// Drivers page state
export const useDistancePricesPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
  } = usePagination();

  const [distancePriceToDelete, setDistancePriceToDelete] = useState<DistancePrice | null>(null);

  const { data: queryData, isLoading: distancePricesLoading } = useQuery(
    ['distancePrices', currentPage, pageSize, sortBy, sortDirection, debouncedSearch],
    () => fetchDistancePrices({ page: currentPage, pageSize, sortBy, sortDirection, search: debouncedSearch }),
  );
  const { mutateAsync: removeDistancePrice } = useMutation(deleteDistancePrice);

  const distancePrices = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onRowClick = (row: DistancePrice) => {
    navigate(`/distance-prices/${row.id}`);
  };

  const onNewClick = () => {
    navigate('/distance-prices/new');
  };

  const onDeleteConfirm = async () => {
    if (distancePriceToDelete) {
      try {
        await removeDistancePrice(distancePriceToDelete.id);
        showMessage('Distance price successfully deleted.', MessageType.Success);
        queryClient.invalidateQueries('distancePrices');
        setDistancePriceToDelete(null);
      } catch {
        showMessage('Something went wrong.', MessageType.Error);
      }
    }
  };

  const openConfirmationModal = (selectedDistancePrice: DistancePrice | null) => {
    if (selectedDistancePrice) setDistancePriceToDelete(selectedDistancePrice);
  };

  const closeConfirmationModal = () => {
    setDistancePriceToDelete(null);
  };

  return {
    distancePrices,
    metaData,
    currentPage,
    search,
    isLoading: distancePricesLoading,
    distancePriceToDelete,
    openConfirmationModal,
    closeConfirmationModal,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onDeleteConfirm,
    onRowClick,
    onNewClick,
    onSortChange,
  };
};
