import { formatPrice } from 'helpers';
import { FormItem, Input } from 'components';
import { useOrderPage } from '../../OrderPageProvider';

export const OrderItemSummary = () => {
  const { order } = useOrderPage();

  return (
    <>
      <div className="grid grid-cols-1 gap-4 py-5 lg:grid-cols-2">
        <FormItem label="Item Description">
          <Input type="text" disabled value={order?.itemsDescription ?? ''} />
        </FormItem>
        <FormItem label="Item Amount">
          <Input type="text" disabled value={formatPrice(order?.itemCost)} />
        </FormItem>
      </div>

      <div className="grid grid-cols-1 gap-4 pb-5 lg:grid-cols-4">
        <FormItem label="Item Width">
          <Input type="text" disabled value={order.width || '-'} />
        </FormItem>

        <FormItem label="Item Height">
          <Input type="text" disabled value={order.height || '-'} />
        </FormItem>

        <FormItem label="Item Length">
          <Input type="text" disabled value={order.length || '-'} />
        </FormItem>

        <FormItem label="Item Weight">
          <Input type="text" disabled value={order.weight || '-'} />
        </FormItem>
      </div>
    </>
  );
};
