import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { Control, FormState, useForm, UseFormRegister, UseFormReset } from 'react-hook-form';

import { createDownloadLink, MessageType, showMessage, SortDirection, usePagination } from 'helpers';
import {
  exportOrders,
  fetchOrders,
  Order,
  PaymentStatus,
  updatePaymentStatus,
  postDriverToOrder,
  postUnassignDriverFromOrder,
} from 'services/orders';
import { ButtonWithDropdownMenuItem } from 'components';

import { OrderAdvancedSearchForm } from '../types';
import { defaultOrderAdvancedSearchForm } from '../consts';
import { OrderRowForm } from './types';
import { ASSIGN_DRIVER_SCHEMA } from './validations';

const OrdersPageContext = createContext<{
  orders: Order[];
  metaData: any;
  currentPage: number;
  search: string;
  isLoading: boolean;
  control: Control<OrderAdvancedSearchForm, any>;
  controlTable: Control<OrderRowForm, any>;
  isLoadingExport: boolean;
  changeAssignmentMenuItems: ButtonWithDropdownMenuItem[];
  unassignDriverConfirmationModalOpen: boolean;
  unassignDriverFromOrdersLoading: boolean;
  onUnassignDriverFromOrders: () => Promise<void>;
  onUnassignDriverConfirmationModalClose: () => void;
  exportOrdersCSV: () => Promise<void>;
  register: UseFormRegister<OrderAdvancedSearchForm>;
  onPaymentStatusChange: (id: string, paymentStatus: PaymentStatus | null) => void;
  onSearchChange: (search: string) => void;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onRowClick: (row: Order) => void;
  onSortChange: (accessor: string, sort: SortDirection) => void;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  onClearFilters: () => void;
  setSelectedOrderIds: React.Dispatch<React.SetStateAction<string[]>>;
  assignDriverFormState: FormState<{
    driverId: string;
  }>;
  assignDriverReset: UseFormReset<{
    driverId: string;
  }>;
  assignDriverControl: Control<
    {
      driverId: string;
    },
    any
  >;
  onAssignDriver: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  assignDriverToOrdersModalOpen: boolean;
  assignDriverToOrdersLoading: boolean;
  handleAssignModalClose: () => void;
}>({} as any);

// This will be used in case we state shared inside the module
export const OrdersPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onFiltersChange,
  } = usePagination({ sortBy: 'createdAt', sortDirection: SortDirection.Desc });

  const queryClient = useQueryClient();

  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [selectedOrderIds, setSelectedOrderIds] = useState<string[]>([]);
  const [assignDriverToOrdersModalOpen, setAssignDriverToOrdersModalOpen] = useState(false);
  const [unassignDriverConfirmationModalOpen, setUnassignDriverConfirmationModalOpen] = useState<boolean>(false);

  const { mutateAsync: assignDriverToOrders, isLoading: assignDriverToOrdersLoading } = useMutation(postDriverToOrder, {
    onSuccess: () => queryClient.invalidateQueries(['orders']),
  });
  const { mutateAsync: unassignDriverFromOrders, isLoading: unassignDriverFromOrdersLoading } = useMutation(
    postUnassignDriverFromOrder,
    {
      onSuccess: () => queryClient.invalidateQueries(['orders']),
    },
  );

  const { register, control, handleSubmit, reset } = useForm<OrderAdvancedSearchForm>({
    defaultValues: defaultOrderAdvancedSearchForm,
  });

  const { control: controlTable, reset: resetTable } = useForm<OrderRowForm>({
    defaultValues: { orders: [] },
  });

  const {
    formState: assignDriverFormState,
    handleSubmit: handleAssignDriverSubmit,
    reset: assignDriverReset,
    control: assignDriverControl,
  } = useForm<{ driverId: string }>({
    defaultValues: { driverId: '' },
    resolver: yupResolver(ASSIGN_DRIVER_SCHEMA),
  });

  const { data: queryData, isLoading: driversLoading } = useQuery(
    ['orders', currentPage, pageSize, sortBy, sortDirection, debouncedSearch, filters],
    () => fetchOrders({ page: currentPage, pageSize, sortBy, sortDirection, search: debouncedSearch, filters }),
  );

  const orders = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  useEffect(() => {
    // check if filters object has the same keys as defaultOrderAdvancedSearchForm
    const hasAllKeys = Object.keys(defaultOrderAdvancedSearchForm).every((key) => key in filters);
    if (hasAllKeys) {
      reset(filters as OrderAdvancedSearchForm);
    }
  }, [filters, reset]);

  useEffect(() => {
    resetTable({ orders: orders.map((o) => ({ id: o.id, paymentStatus: o.paymentStatus })) });
  }, [orders, resetTable]);

  const onSubmit = handleSubmit((data: OrderAdvancedSearchForm) => {
    onFiltersChange(data as any);
  });

  const onRowClick = useCallback(
    (row: Order) => {
      navigate(`/orders/${row.id}`);
    },
    [navigate],
  );

  const onClearFilters = useCallback(() => {
    reset(defaultOrderAdvancedSearchForm);
    onSubmit();
  }, [reset, onSubmit]);

  const onPaymentStatusChange = useCallback(async (id: string, paymentStatus: PaymentStatus | null) => {
    try {
      await updatePaymentStatus(id, paymentStatus);
      showMessage('Payment status updated', MessageType.Success);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const onAssignDriverClick = useCallback(() => {
    if (selectedOrderIds.length === 0) {
      showMessage('Please select at least one order', MessageType.Warning);
      return;
    }
    setAssignDriverToOrdersModalOpen(true);
  }, [selectedOrderIds.length]);

  const handleAssignModalClose = useCallback(() => {
    setAssignDriverToOrdersModalOpen(false);
    assignDriverReset();
  }, [assignDriverReset]);

  const onAssignDriver = handleAssignDriverSubmit(async ({ driverId }) => {
    try {
      await assignDriverToOrders({ ordersIds: selectedOrderIds, driverId });
      showMessage('Driver successfully assigned', MessageType.Success);
    } catch (err: any) {
      showMessage(err.response.data.title || 'Something went wrong', MessageType.Error);
    }

    setAssignDriverToOrdersModalOpen(false);
  });

  const exportOrdersCSV = useCallback(async () => {
    setIsLoadingExport(true);
    try {
      const data = await exportOrders(filters, search);
      createDownloadLink(data, 'orders');
    } catch {
      showMessage('An has error occured while trying to export orders data', MessageType.Error);
    }
    setIsLoadingExport(false);
  }, [filters, search]);

  const onUnassignDriverFromOrders = useCallback(async () => {
    try {
      await unassignDriverFromOrders({ ordersIds: selectedOrderIds });
      showMessage('Driver successfully unassigned', MessageType.Success);
    } catch (err: any) {
      showMessage(err.response.data.title || 'Something went wrong', MessageType.Error);
    }

    setUnassignDriverConfirmationModalOpen(false);
  }, [selectedOrderIds, unassignDriverFromOrders]);

  const openUnassignDriverConfirmationModal = useCallback(() => {
    if (selectedOrderIds.length === 0) {
      showMessage('Please select at least one order', MessageType.Warning);
      return;
    }

    setUnassignDriverConfirmationModalOpen(true);
  }, [selectedOrderIds.length]);

  const onUnassignDriverConfirmationModalClose = useCallback(() => {
    setUnassignDriverConfirmationModalOpen(false);
  }, []);

  const changeAssignmentMenuItems: ButtonWithDropdownMenuItem[] = useMemo(
    () => [
      {
        label: 'Assign Driver',
        onClick: onAssignDriverClick,
      },
      {
        label: 'Unassign Driver',
        onClick: openUnassignDriverConfirmationModal,
      },
    ],
    [onAssignDriverClick, openUnassignDriverConfirmationModal],
  );

  const providerValue = useMemo(
    () => ({
      orders,
      metaData,
      currentPage,
      search,
      isLoading: driversLoading,
      control,
      controlTable,
      isLoadingExport,
      changeAssignmentMenuItems,
      unassignDriverConfirmationModalOpen,
      unassignDriverFromOrdersLoading,
      onUnassignDriverFromOrders,
      onUnassignDriverConfirmationModalClose,
      exportOrdersCSV,
      register,
      onPaymentStatusChange,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onRowClick,
      onSortChange,
      onSubmit,
      onClearFilters,
      setSelectedOrderIds,
      assignDriverFormState,
      assignDriverReset,
      assignDriverControl,
      onAssignDriver,
      assignDriverToOrdersModalOpen,
      assignDriverToOrdersLoading,
      handleAssignModalClose,
    }),
    [
      orders,
      metaData,
      currentPage,
      search,
      driversLoading,
      control,
      controlTable,
      isLoadingExport,
      changeAssignmentMenuItems,
      unassignDriverConfirmationModalOpen,
      unassignDriverFromOrdersLoading,
      onUnassignDriverFromOrders,
      onUnassignDriverConfirmationModalClose,
      exportOrdersCSV,
      register,
      onPaymentStatusChange,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onRowClick,
      onSortChange,
      onSubmit,
      onClearFilters,
      setSelectedOrderIds,
      assignDriverFormState,
      assignDriverReset,
      assignDriverControl,
      onAssignDriver,
      assignDriverToOrdersModalOpen,
      assignDriverToOrdersLoading,
      handleAssignModalClose,
    ],
  );

  return <OrdersPageContext.Provider value={providerValue}>{children}</OrdersPageContext.Provider>;
};

export const useOrdersPage = () => {
  return useContext(OrdersPageContext);
};

interface Props {
  children?: React.ReactNode;
}
