import { forwardRef } from 'react';
import clsx from 'clsx';

export const Checkbox = forwardRef<HTMLInputElement, Props>(({ className, label, subtitle, ...props }, ref) => {
  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <input
          type="checkbox"
          className={clsx('cursor-pointer h-4 w-4 border-gray-300 rounded', className)}
          {...props}
          ref={ref}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={props.id} className="font-medium text-gray-700">
          {label}
        </label>
        {subtitle && <p className="text-gray-500">{subtitle}</p>}
      </div>
    </div>
  );
});

type Props = {
  label: string;
  subtitle?: string;
} & JSX.IntrinsicElements['input'];
