/* eslint-disable jsx-a11y/label-has-associated-control */
import { SearchIcon } from '@heroicons/react/outline';

interface HeaderSearchProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {}

export function HeaderSearch({ ...headerInputProps }: HeaderSearchProps) {
  return (
    <div className="flex mr-5">
      <div className="flex w-full md:ml-0">
        <label htmlFor="search-field" className="sr-only">
          Search
        </label>
        <div className="relative w-full text-gray-400 focus-within:text-gray-600">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <SearchIcon className="w-5 h-5" aria-hidden="true" />
          </div>

          <input
            className="block w-full h-full py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 border-0 border-b border-transparent focus:outline-none focus:placeholder-gray-400 focus:ring-0 sm:text-sm focus:border-primary-600"
            placeholder="Search"
            {...headerInputProps}
          />
        </div>
      </div>
    </div>
  );
}
