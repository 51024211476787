import { DataWithMeta, PaginationRequest, paginationRequestToUrl } from 'helpers';

import { api } from '../api';
import { resToMerchantReport, transformToMerchantReportsFilters } from './transformations';
import { MerchantReport } from './types';

export const fetchMerchantReports = async (
  pagination: PaginationRequest,
  fromDate: Date | null,
  toDate: Date | null,
): Promise<DataWithMeta<MerchantReport>> => {
  let url = paginationRequestToUrl('/reports/merchant_payments', pagination, transformToMerchantReportsFilters);

  if (fromDate) url += `&fromDate=${fromDate.toISOString()}`;
  if (toDate) url += `&toDate=${toDate.toISOString()}`;

  const resp = await api.get(url);
  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToMerchantReport),
  };
};
