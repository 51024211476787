import { SettingForm } from 'modules/Settings/types';
import { Setting } from './types';

export const resToSetting = (res: any): Setting => {
  return {
    adminEmail: res.adminEmail ?? '',
    contactPhone: res.contactPhone ?? '',
    driverPercentage: res.driverPercentage ?? null,
    driverTipPercentage: res.driverTipPercentage ?? null,
    expeditedFeePercentage: res.expeditedFeePercentage ?? null,
    expeditedFeeThresholdHours: res.expeditedFeeThresholdHours ?? null,
    firstHelperLoadFee: res.firstHelperLoadFee ?? null,
    firstHelperPercentage: res.firstHelperPercentage ?? null,
    firstHelperUnloadFee: res.firstHelperUnloadFee ?? null,
    fromEmail: res.fromEmail ?? '',
    fromEmailName: res.fromEmailName ?? '',
    paymentDay: res.paymentDay ?? '',
    secondHelperLoadFee: res.secondHelperLoadFee ?? null,
    secondHelperPercentage: res.secondHelperPercentage ?? null,
    secondHelperUnloadFee: res.secondHelperUnloadFee ?? null,
    unassignedOrderTime: res.unassignedOrderTime ?? '',
  };
};

export const settingToPostReq = (setting: SettingForm): any => {
  return setting;
};

export const settingToPutReq = (setting: SettingForm): any => {
  return setting;
};
