import { CellProps } from 'react-table';
import dayjs from 'dayjs';

import { TableColumn, Tooltip } from 'components';
import { shortenId } from 'helpers';
import { PromoCode } from 'services/promoCodes';

export const columns = (deleteClicked: (rowData: PromoCode) => void): TableColumn<PromoCode>[] => [
  {
    Header: 'ID',
    accessor: 'id',
    isSortable: true,
    Cell: ({ row }: CellProps<PromoCode>) => {
      const rowData = row.original;
      return (
        <Tooltip
          popoverMessage="Copied!"
          tooltipMessage={rowData.id}
          onClick={() => navigator.clipboard.writeText(rowData.id)}
        >
          {shortenId(rowData.id)}
        </Tooltip>
      );
    },
  },
  {
    Header: 'Code',
    accessor: 'code' as any,
    isSortable: true,
  },
  {
    Header: 'Active From',
    accessor: 'activeFrom' as any,
    Cell: ({ row }: CellProps<PromoCode>) => {
      const rowData = row.original;

      return <>{rowData.activeFrom ? dayjs(rowData.activeFrom).format('YYYY-MM-DD HH:mm') : ''}</>;
    },
    isSortable: true,
  },
  {
    Header: 'Active To',
    accessor: 'activeTo' as any,
    Cell: ({ row }: CellProps<PromoCode>) => {
      const rowData = row.original;

      return <>{rowData.activeTo ? dayjs(rowData.activeTo).format('YYYY-MM-DD HH:mm') : ''}</>;
    },
    isSortable: true,
  },
  {
    id: 'amount',
    Header: 'Amount',
    Cell: ({ row }: CellProps<PromoCode>) => {
      const rowData = row.original;
      const amount = rowData.discountAmount ? `$${rowData.discountAmount}` : `${rowData.discountPercentage}%`;

      return <>{amount}</>;
    },
  },
  {
    id: 'deleteButton',
    Header: '',
    Cell: ({ row }: CellProps<PromoCode>) => {
      const rowData = row.original;

      return (
        <div className="flex justify-end">
          <button
            type="button"
            className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={(ev) => {
              ev.stopPropagation();
              deleteClicked(rowData);
            }}
          >
            Delete
          </button>
        </div>
      );
    },
    width: 'auto',
  },
];
