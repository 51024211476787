/* eslint-disable import/no-extraneous-dependencies */
import { Control, useController, useWatch } from 'react-hook-form';
import { useDropzone, FileRejection } from 'react-dropzone';

import { Button } from 'components';
import { StorageTypeId, useStorageFileMutation, Image } from 'services/storage';
import { MessageType, showMessage } from 'helpers';
import { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { TrashIcon } from '@heroicons/react/solid';

export const ImageDropzone = ({
  name,
  label,
  control,
  setDefault,
  maxFiles,
  connectedFieldName,
  connectedFieldValue,
  dropzoneHintLabel,
  dropzoneMainLabel,
  disabled,
  disabledMainLabel,
}: Props & ConditionalProps) => {
  const mainImage = useWatch({ control, name: 'mainImage' });

  const { field } = useController({
    control,
    name,
  });

  const { addStorageFile } = useStorageFileMutation();

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (maxFiles === 1 && rejectedFiles.find((file) => file.errors.find((err) => err.code === 'too-many-files'))) {
        showMessage('Upload only 1 image', MessageType.Error);
        return;
      }
      if (acceptedFiles) {
        const images = await Promise.all(
          acceptedFiles.map(async (file: File) => {
            return {
              id: -1,
              name: file.name,
              url: URL.createObjectURL(file),
              buffer: await file.arrayBuffer(),
            };
          }),
        );

        try {
          const imagesToUpload = [] as any[];

          await Promise.all(
            images.map(async (image) => {
              const res = await addStorageFile.mutateAsync({ file: image, type: StorageTypeId.Assets });

              imagesToUpload.push(res);
            }),
          );

          if (maxFiles && maxFiles === 1 && imagesToUpload.length === 1) {
            field.onChange(imagesToUpload[0]);
            if (connectedFieldName && connectedFieldValue) {
              setDefault(connectedFieldName, [...connectedFieldValue, ...imagesToUpload]);
            }
          } else {
            field.onChange([...field.value, ...imagesToUpload]);
          }
          showMessage('Images successfully uploaded!', MessageType.Success);
        } catch {
          showMessage('There has been an error!', MessageType.Error);
        }
      }
    },
    [addStorageFile, connectedFieldName, connectedFieldValue, field, maxFiles, setDefault],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg', '.gif'],
    },
    maxFiles: maxFiles || 0,
    multiple: !maxFiles || maxFiles > 1,
    disabled,
  });

  const removeClicked = (ev: any, index: number, image: any) => {
    ev.preventDefault();

    if (image.id === mainImage?.id) {
      setDefault('mainImage', null);
    }

    field.onChange([...field.value.slice(0, index), ...field.value.slice(index + 1)]);
  };

  const singleImageRemoveClicked = () => {
    field.onChange(null);
    if (connectedFieldName && connectedFieldValue) {
      setDefault(
        connectedFieldName,
        connectedFieldValue.filter((image) => image.id !== field.value.id),
      );
    }
  };

  const renderImgDropzone = useMemo(
    () => (
      <div
        {...getRootProps()}
        className={clsx(
          'w-full h-full border border-gray-300 bg-gray-50 border-dashed rounded-xl flex items-center justify-center p-6 text-center',
          { 'hover:border-blue-500 hover:cursor-pointer': !disabled },
          { 'border-blue-500 bg-blue-50': isDragActive && !disabled },
          { 'cursor-not-allowed border-gray-400 text-gray-500 bg-gray-200': disabled },
        )}
      >
        <input {...getInputProps()} />
        {isDragActive && <p className="text-xs text-blue-700">Upload active drag</p>}
        {!isDragActive && !addStorageFile.isLoading && (
          <div className="flex items-center flex-col">
            <p className="text-xs text-primary">
              {(!disabled ? dropzoneMainLabel : disabledMainLabel) || 'Upload multiple'}
            </p>
            {!disabled && <p className="text-xs text-gray-500 mt-3">{dropzoneHintLabel || 'Upload multiple hint'}</p>}
          </div>
        )}
        {addStorageFile.isLoading && (
          <svg
            className="animate-spin mb-2 h-12 w-12 text-blue-700"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        )}
      </div>
    ),
    [
      addStorageFile.isLoading,
      disabled,
      disabledMainLabel,
      dropzoneHintLabel,
      dropzoneMainLabel,
      getInputProps,
      getRootProps,
      isDragActive,
    ],
  );

  return (
    <div>
      {label && <label className="block leading-4 mb-1 -tracking-[.02em] text-sm">{label}</label>}

      <div
        className={twMerge(
          clsx('grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 rounded-md relative gap-4', {
            'grid-cols-1 sm:grid-cols-1 lg:grid-cols-1': (!maxFiles || maxFiles > 1) && field.value?.length === 0,
          }),
        )}
      >
        {maxFiles && maxFiles === 1 && field.value && (
          <div className="relative rounded-lg bg-gray-100 aspect-square overflow-hidden primary-500 flex-shrink-0 group">
            <img src={field.value?.url} className="object-cover h-full w-full" alt="" />
            <div className="absolute top-1 right-1 hidden group-hover:block group">
              <Button
                className="w-8 h-8 px-0 py-0 rounded-3xl bg-gray-50 hover:bg-white text-black"
                type="button"
                onClick={singleImageRemoveClicked}
                icon={<TrashIcon className="m-0 text-black w-5 h-5 hover:text-red-500" />}
              />
            </div>
          </div>
        )}
        {(!maxFiles || maxFiles > 1) &&
          field.value?.map((image: any, index: number) => (
            <div
              className="relative rounded-lg bg-gray-100 aspect-square overflow-hidden primary-500 flex-shrink-0 group"
              key={index}
            >
              <img src={image?.url} className="object-cover h-full w-full" alt="" />
              <div className="absolute top-1 right-1 hidden group-hover:block group">
                <Button
                  className="w-8 h-8 px-0 py-0 rounded-3xl bg-gray-50 text-red hover:bg-white"
                  type="button"
                  onClick={(ev) => removeClicked(ev, index, image)}
                  icon={<TrashIcon className="m-0 text-black w-5 h-5 hover:text-red-500" />}
                />
              </div>
            </div>
          ))}
        {maxFiles === 1 ? !mainImage && renderImgDropzone : renderImgDropzone}
      </div>
    </div>
  );
};

interface Props {
  name: string;
  label?: string;
  control: Control<any, any>;
  setDefault?: any;
  maxFiles?: number;
  dropzoneMainLabel?: string;
  dropzoneHintLabel?: string;
  disabled?: boolean;
  disabledMainLabel?: string;
}

type ConditionalProps =
  | { connectedFieldName: string; connectedFieldValue: Image[] }
  | { connectedFieldName?: never; connectedFieldValue?: never };
