import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { MessageType, showMessage, useIdFromParams } from 'helpers';
import { ItemCategoryFormData, useItemCategory, useItemCategoryMutations } from 'services/itemCategories';

import { ITEM_CATEGORY_VALIDATION_SCHEMA } from './validations';
import { itemCategoryToItemCategoryForm } from './transformation';
import { defaultItemCategoryForm } from './consts';

export const useItemCategoryPage = () => {
  const navigate = useNavigate();
  const { id: itemCategoryId, isCreate } = useIdFromParams();

  const { data: itemCategory, isLoading: itemCategoryLoading } = useItemCategory({ id: itemCategoryId, isCreate });
  const { addItemCategory, editItemCategory } = useItemCategoryMutations();

  const { formState, control, handleSubmit, register, reset } = useForm<ItemCategoryFormData>({
    defaultValues: defaultItemCategoryForm,
    resolver: yupResolver(ITEM_CATEGORY_VALIDATION_SCHEMA),
  });

  const itemGroup = itemCategory?.itemGroup;

  useEffect(() => {
    if (itemCategory) {
      reset(itemCategoryToItemCategoryForm(itemCategory));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemCategory]);

  const onSubmit = handleSubmit(async (values: ItemCategoryFormData) => {
    if (isCreate) {
      try {
        await addItemCategory.mutateAsync(values);
        showMessage('Item Category successfully created.', MessageType.Success);
        navigate('/item-categories');
      } catch {
        showMessage('Theres been an error!', MessageType.Error);
      }
      return;
    }

    try {
      await editItemCategory.mutateAsync({ data: values, id: itemCategoryId });
      showMessage('Item Category successfully updated.', MessageType.Success);
      navigate('/item-categories');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  return {
    register,
    onSubmit,
    isCreate,
    itemCategoryLoading,
    formState,
    control,
    itemGroup,
  };
};
