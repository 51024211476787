import { useNavigate } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/solid';

import { PaymentStatus } from 'services/orders';
import { formatDateShort, formatPrice } from 'helpers';
import { Container, Header, PaymentCardItem, StatusIndicator } from 'components';
import { Pagination } from 'components/Table/Pagination';

import { useDriverReportPage } from './DriverReportPageProvider';

export const DriverReportPage = () => {
  const {
    driverOrderPayments,
    currentPage,
    metaData,
    pendingPayment,
    driversLoading,
    onPageChange,
    onPageSizeChange,
    updateDriverPaymentStatus,
  } = useDriverReportPage();
  const navigate = useNavigate();

  return (
    <div>
      <Header title="View Order Payment Detail" onGoBackPress={() => navigate(-1)} />
      <Container isLoading={driversLoading}>
        {driverOrderPayments.map((item) => (
          <div className="relative grid gap-4 px-4 py-5 bg-white mb-7 grid-cols-2" key={item.id}>
            {item.isHelper && (
              <span className="absolute px-2 py-1 text-xs font-bold text-white rounded-md top-4 right-4 bg-primary">
                HELPER
              </span>
            )}

            {!!item.amount && (
              <div className="absolute px-2 py-1 bottom-4 right-4">
                {item.status !== 'paid' ? (
                  <button
                    key="approve"
                    type="button"
                    disabled={pendingPayment}
                    className="inline-flex items-center px-2.5 py-1.5 mr-1 border border-green-300 shadow-sm text-sm font-medium rounded text-green-700 bg-white hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      updateDriverPaymentStatus(item.id, PaymentStatus.Paid);
                    }}
                  >
                    Pay
                  </button>
                ) : (
                  <div className="flex">
                    <div className="flex items-center justify-end">
                      <CheckIcon className="w-5 h-5 text-green-500" />
                      <p className="italic text-green-600 pr-1.5 pl-0.5 py-1.5 text-base font-medium">Paid</p>
                    </div>

                    <button
                      key="cancel"
                      type="button"
                      disabled={pendingPayment}
                      className="inline-flex items-center px-2.5 py-1.5 ml-4 border border-red-300 shadow-sm text-sm font-medium rounded text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        updateDriverPaymentStatus(item.id, PaymentStatus.Pending);
                      }}
                    >
                      Cancel Payment
                    </button>
                  </div>
                )}
              </div>
            )}

            <PaymentCardItem label="Created At:" value={formatDateShort(item.createdAt.slice(0, 10))} />

            <PaymentCardItem label="Order Date:" value={formatDateShort(item.orderDate.slice(0, 10))} />

            <PaymentCardItem
              label="Completed Date:"
              value={item.paidAt ? formatDateShort(item.paidAt.slice(0, 10)) : '-'}
            />

            <PaymentCardItem label="Order ID:" value={item.orderId} />

            <PaymentCardItem label="Driver Name:" value={item.driverName} />

            <PaymentCardItem
              label={item.isExtraTip ? 'Extra tip payment:' : 'Payment:'}
              value={formatPrice(item.amount ?? 0)}
            />

            <PaymentCardItem label="Customer:" value={item.customerName || '-'} />

            {item.merchantName && <PaymentCardItem label="Merchant Name:" value={item.merchantName || '-'} />}

            <PaymentCardItem label="Status:" value={<StatusIndicator status={item.status} />} />

            <div className="flex justify-end col-span-2 bg-slate-200" />
          </div>
        ))}
      </Container>
      {!!metaData && (
        <div className="sticky bottom-0 left-0">
          <Pagination
            currentPage={currentPage}
            pagination={metaData}
            pageClicked={onPageChange}
            pageSizeClicked={onPageSizeChange!}
            key={`${metaData.totalPages}-${metaData.pageSize}`}
          />
        </div>
      )}
    </div>
  );
};
