import clsx from 'clsx';
import { forwardRef } from 'react';

type Props = {
  label: string;
  error?: boolean;
} & JSX.IntrinsicElements['input'];

export const RadioButton = forwardRef<HTMLInputElement, Props>(({ className, label, ...props }, ref) => {
  return (
    <div className="flex items-center cursor-pointer">
      <input
        type="radio"
        className={clsx('focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300', className)}
        {...props}
        ref={ref}
      />
      <label htmlFor={props.id} className="block ml-3 text-sm font-medium text-gray-700 cursor-pointer">
        {label}
      </label>
    </div>
  );
});
