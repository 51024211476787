import { useNavigate } from 'react-router-dom';

import { Button, Container, Header, Divider, FormItem, Input, ImageUpload, Textarea, MultipleSelect } from 'components';
import { Checkbox } from 'components/Checkbox';

import { useDriverGroupPage } from './DriverGroupPageProvider';

export const DriverGroupPage = () => {
  const { isCreate, formState, onSubmit, register, relatedDriverGroups, relatedTrailers, control } =
    useDriverGroupPage();
  const navigate = useNavigate();

  return (
    <>
      <Header title={isCreate ? 'New driver group' : 'Edit driver group'} onGoBackPress={() => navigate(-1)}>
        <Button type="submit" onClick={onSubmit} label="Save" form="driverGroupForm" />
      </Header>

      <Container>
        <div className="overflow-hidden bg-white rounded-lg shadow">
          <div className="px-4 py-5 sm:p-6">
            <form className="space-y-10" onSubmit={onSubmit} noValidate id="driverGroupForm">
              <Divider title="Driver group information" />

              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <FormItem label="Name" htmlFor="name" error={formState.errors.name?.message} required>
                  <Input
                    {...register('name')}
                    id="name"
                    type="text"
                    placeholder="Enter first name"
                    autoComplete="name"
                    required
                    error={!!formState.errors.name?.message}
                  />
                </FormItem>
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                  <FormItem
                    label="Booking fee"
                    htmlFor="bookingFee"
                    error={formState.errors.bookingFee?.message}
                    required
                  >
                    <Input
                      {...register('bookingFee')}
                      id="bookingFee"
                      type="number"
                      placeholder="Enter first bookingFee"
                      autoComplete="bookingFee"
                      required
                      error={!!formState.errors.name?.message}
                    />
                  </FormItem>

                  <FormItem label="Position in app" htmlFor="position" error={formState.errors.position?.message}>
                    <Input
                      {...register('position')}
                      id="position"
                      type="text"
                      placeholder={isCreate ? 'This can be changed only on existing driver group' : 'Enter position'}
                      autoComplete="position"
                      error={!!formState.errors.position?.message}
                      disabled={isCreate}
                    />
                  </FormItem>
                </div>
                <FormItem label="Related Trailers" htmlFor="trailerIds" error={formState.errors.trailerIds?.message}>
                  <MultipleSelect
                    name="trailerIds"
                    control={control}
                    options={relatedTrailers}
                    placeholder="Select related trailers"
                  />
                </FormItem>
                <FormItem
                  label="Related Driver Groups"
                  htmlFor="relatedDriverGroupIds"
                  error={formState.errors.relatedDriverGroupIds?.message}
                >
                  <MultipleSelect
                    name="relatedDriverGroupIds"
                    control={control}
                    options={relatedDriverGroups}
                    placeholder="Select related driver groups"
                  />
                </FormItem>
                <FormItem label="Description" htmlFor="description" error={formState.errors.description?.message}>
                  <Textarea
                    {...register('description')}
                    id="description"
                    placeholder="Enter first description"
                    autoComplete="description"
                    className="h-28"
                    error={!!formState.errors.description?.message}
                  />
                </FormItem>

                <ImageUpload
                  name="vehicleImageFile"
                  control={control}
                  label="Image"
                  error={formState.errors.vehicleImageFile?.url?.message}
                />

                <div className="flex flex-col gap-2">
                  <Checkbox
                    {...register('showInApp')}
                    id="showInApp"
                    label="Is this driver group visible on mobile app for customers?"
                    name="showInApp"
                  />

                  <Checkbox
                    {...register('isTest')}
                    id="isTest"
                    label="Is this driver group visible only for test purposes?"
                    name="isTest"
                  />
                  <Checkbox {...register('isHelper')} id="isHelper" label="Is helper group?" name="isHelper" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};
