import { useMemo } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

import { usePagination } from '@mantine/hooks';
import { ButtonBase } from 'components';
import { MetaData } from 'helpers';
import { PageSize } from './PageSize';

export const Pagination = ({ pagination, currentPage, pageClicked, pageSizeClicked }: Props) => {
  const { range } = usePagination({
    total: pagination.totalPages,
    page: currentPage,
  });

  const showing = currentPage * pagination.pageSize - pagination.pageSize + 1;
  const to = useMemo(() => {
    const calculatedTo = currentPage * pagination.pageSize;
    if (calculatedTo > pagination.totalRows) {
      return pagination.totalRows;
    }
    return calculatedTo;
  }, [currentPage, pagination.pageSize, pagination.totalRows]);

  return (
    <div className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6">
      <div className="sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="flex items-center">
          {/* TODO: Fix this */}
          <p className="mr-4 text-sm text-gray-700">
            Showing <span className="font-medium">{showing}</span> to <span className="font-medium">{to}</span> of{' '}
            <span className="font-medium">{pagination.totalRows}</span> results
          </p>
          {/* <p className="mr-4 text-sm text-gray-700">Per page</p> */}

          <PageSize pageSize={pagination.pageSize} pageSizeClicked={pageSizeClicked} />
        </div>
        <div>
          <nav className="relative z-0 inline-flex rounded-md" aria-label="Pagination">
            {currentPage > 1 && (
              <ButtonBase
                onClick={() => pageClicked(currentPage - 1)}
                disabled={currentPage === 1}
                className={clsx(
                  'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 hover:bg-primary text-sm font-medium !text-gray-700 hover:!text-white bg-gray-50 mr-1',
                )}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
              </ButtonBase>
            )}
            {range.map((pageNumber: any, index: any) => {
              if (pageNumber === 'dots') {
                return (
                  <span
                    className="relative inline-flex items-center px-4 py-2 text-sm rounded-md font-medium text-gray-700 bg-white border border-gray-300"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`dots${index}`}
                  >
                    ...
                  </span>
                );
              }
              return (
                <ButtonBase
                  key={pageNumber}
                  onClick={() => pageClicked(pageNumber)}
                  className={clsx(
                    pageNumber === currentPage
                      ? 'z-10 bg-primary border-primary-500 text-white'
                      : ' border-gray-300 !text-gray-700 hover:!text-white hover:bg-primary bg-gray-50',
                    'hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium mx-1',
                  )}
                >
                  {pageNumber}
                </ButtonBase>
              );
            })}

            {currentPage < pagination.totalPages && (
              <ButtonBase
                disabled={currentPage === pagination.totalPages}
                onClick={() => pageClicked(currentPage + 1)}
                className="relative inline-flex items-center px-2 py-2 ml-1 text-sm font-medium !text-gray-700 hover:!text-white bg-gray-50 border border-gray-300 rounded-r-md hover:bg-primary"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
              </ButtonBase>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

interface Props {
  pagination: MetaData;
  currentPage: number;
  pageClicked: (newPage: number) => void;
  pageSizeClicked: (newPageSize: number) => void;
}
