import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { Control, useForm } from 'react-hook-form';

import { MessageType, MetaData, showMessage, SortDirection, usePagination } from 'helpers';
import { fetchMerchantReports, MerchantReport } from 'services/merchantReports';
import { bulkPayOrderPayments, PaymentStatus } from 'services/orders';

import { defaultMerchantReportsAdvancedSearchForm } from '../consts';
import { MerchantReportsAdvancedSearchForm } from '../types';

const MerchantReportsPageContext = createContext<{
  merchantReports: MerchantReport[];
  metaData: MetaData | undefined;
  currentPage: number;
  search: string;
  isLoading: boolean;
  control: Control<MerchantReportsAdvancedSearchForm, any>;
  selectedMerchant: MerchantReport | undefined;
  modalVisible: boolean;
  pendingPayment: boolean;
  onPayButtonClicked: (merchant: MerchantReport) => void;
  onConfirmationModalCancelClicked: () => void;
  onConfirmationModalConfirmClicked: () => void;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  onSearchChange: (searchValue: string) => void;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  onRowClick: (row: MerchantReport) => void;
  onSortChange: (accessor: string, sort: SortDirection) => void;
  onClearFilters: () => void;
}>({} as any);

export const MerchantReportsPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onFiltersChange,
  } = usePagination({ sortBy: 'orderCount', sortDirection: SortDirection.Desc });

  const [selectedMerchant, setSelectedMerchant] = useState<MerchantReport>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [pendingPayment, setPendingPayment] = useState<boolean>(false);

  const { control, handleSubmit, reset, watch } = useForm<MerchantReportsAdvancedSearchForm>({
    defaultValues: defaultMerchantReportsAdvancedSearchForm,
  });
  const fromDate = watch('fromDate');
  const toDate = watch('toDate');

  const { data: queryData, isLoading: merchantsLoading } = useQuery(
    ['merchantReports', currentPage, pageSize, sortBy, sortDirection, debouncedSearch, filters],
    () =>
      fetchMerchantReports(
        { page: currentPage, pageSize, sortBy, sortDirection, search: debouncedSearch, filters },
        fromDate,
        toDate,
      ),
  );

  const merchantReports = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onSubmit = handleSubmit((data: MerchantReportsAdvancedSearchForm) => {
    onFiltersChange(data as any);
  });

  const onRowClick = useCallback(
    (row: MerchantReport) => {
      navigate(`/merchant-payments/${row.merchantId}`);
    },
    [navigate],
  );

  const onClearFilters = useCallback(() => {
    reset(defaultMerchantReportsAdvancedSearchForm);
    onSubmit();
  }, [reset, onSubmit]);

  const onPayButtonClicked = useCallback(
    (merchant: MerchantReport) => {
      setSelectedMerchant(merchant);
      setModalVisible(true);
      setPendingPayment(false);
    },
    [setModalVisible],
  );

  const onConfirmationModalCancelClicked = useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  const onConfirmationModalConfirmClicked = useCallback(async () => {
    if (selectedMerchant) {
      setPendingPayment(true);
      try {
        await bulkPayOrderPayments(selectedMerchant.unpaidOrderPaymentIds, PaymentStatus.Paid);
        queryClient.invalidateQueries('merchantReports');
        showMessage(`Succesfully paid orders for merchant ${selectedMerchant.merchantName}.`, MessageType.Success);
      } catch (err) {
        showMessage('Something went wrong.', MessageType.Error);
        setPendingPayment(false);
      }

      setModalVisible(false);
    }
  }, [selectedMerchant, queryClient]);

  const providerValue = useMemo(
    () => ({
      merchantReports,
      metaData,
      currentPage,
      search,
      isLoading: merchantsLoading,
      control,
      selectedMerchant,
      modalVisible,
      pendingPayment,
      onPayButtonClicked,
      onConfirmationModalCancelClicked,
      onConfirmationModalConfirmClicked,
      onSubmit,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onRowClick,
      onSortChange,
      onClearFilters,
    }),
    [
      merchantReports,
      metaData,
      currentPage,
      search,
      merchantsLoading,
      control,
      selectedMerchant,
      modalVisible,
      pendingPayment,
      onPayButtonClicked,
      onConfirmationModalCancelClicked,
      onConfirmationModalConfirmClicked,
      onSubmit,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onRowClick,
      onSortChange,
      onClearFilters,
    ],
  );

  return <MerchantReportsPageContext.Provider value={providerValue}>{children}</MerchantReportsPageContext.Provider>;
};

export const useMerchantReportsPage = () => {
  return useContext(MerchantReportsPageContext);
};

interface Props {
  children?: React.ReactNode;
}
