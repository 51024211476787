import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Control,
  FieldArrayWithId,
  FormState,
  UseFieldArrayAppend,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { Outlet, useNavigate } from 'react-router-dom';
import { OrderFormData, postOrder } from 'services/orders';
import { useMutation, useQueryClient } from 'react-query';
import { MessageType, showMessage } from 'helpers';
import { AddressComponent, AddressFormData } from 'services/addresses';
import { searchAddress, verifyAddress } from 'services/addresses/api';
import { defaultOrderForm, defaultOrderStopForm } from './const';
import { ORDER_VALIDATION_SCHEMA } from './validations';

const newOrderPageContext = React.createContext<{
  formState: FormState<OrderFormData>;
  control: Control<OrderFormData>;
  register: UseFormRegister<OrderFormData>;
  reset: UseFormReset<OrderFormData>;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  stops: FieldArrayWithId<OrderFormData, 'stops', 'keyID'>[];
  append: UseFieldArrayAppend<OrderFormData, 'stops'>;
  setValue: UseFormSetValue<OrderFormData>;
  addressSearch: OrderFormData['stops'];
  tabs: { name: string; current: boolean }[];
  fetchAddress: (search: string) => Promise<void>;
  address: any[];
  setAddressToStop: (placeId: string) => Promise<void>;
  isHouseNumber: boolean;
  setAddress: React.Dispatch<React.SetStateAction<any[]>>;
  onAddPress: (data: any) => void;
  handleTabChange: (index: number) => void;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
}>({} as any);

export const NewOrderPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [currentIndex, setCurrentIndex] = useState<number>(1);
  const [address, setAddress] = useState<any[]>([]);
  const [isHouseNumber, setIsHouseNumber] = useState<boolean>(true);

  const { mutateAsync: createOrder } = useMutation(postOrder);

  const { formState, control, handleSubmit, register, reset, setValue, watch } = useForm<OrderFormData>({
    defaultValues: defaultOrderForm,
    resolver: yupResolver(ORDER_VALIDATION_SCHEMA),
  });

  const formStops = useFieldArray({ control, name: 'stops', keyName: 'keyID' });

  const addressSearch = watch('stops');

  const { fields: stops, append, remove } = formStops;

  const onSubmit = handleSubmit(async (values: OrderFormData) => {
    try {
      await createOrder(values);
      showMessage('Order successfully created.', MessageType.Success);
      queryClient.invalidateQueries('orders');
      navigate('/orders');
    } catch {
      showMessage('There has been an error!', MessageType.Error);
    }
  });

  const resToAddress = (data: any): AddressFormData => {
    const ac: AddressComponent = {};

    data.address_components.forEach((item: any) => {
      item.types.forEach((type: string) => {
        if (type === 'political') return;
        ac[type] = {
          long_name: item.long_name,
          short_name: item.short_name,
        };
      });
    });

    return {
      street1: `${ac.route?.long_name || ''} ${ac.street_number?.long_name || ''} `,
      street2: '',
      city: ac.locality?.long_name || '',
      state: ac.administrative_area_level_1?.short_name || '',
      zip: ac.postal_code?.long_name || '',
      country: ac.country?.long_name || '',
      lat: data.geometry.location.lat,
      lng: data.geometry.location.lng,
    };
  };

  const fetchAddress = useCallback(async (search: string) => {
    if (!search) return;
    try {
      const { predictions } = await searchAddress(search);
      if (predictions?.[0]?.terms.length > 4) {
        setAddress(predictions);
        setIsHouseNumber(true);
      } else {
        setAddress([]);
        setIsHouseNumber(false);
      }
    } catch (error) {
      showMessage('There has been an error!', MessageType.Error);
    }
  }, []);

  const setAddressToStop = useCallback(
    async (placeId: string) => {
      try {
        const data = await verifyAddress(placeId);
        setValue(`stops.${currentIndex - 1}.address`, resToAddress(data));
      } catch (error) {
        showMessage('There has been an error!', MessageType.Error);
      }
    },
    [currentIndex, setValue],
  );

  const tabs = useMemo(() => {
    return stops.map((_, index) => ({
      name: `Stop ${index + 1}`,
      current: currentIndex === index + 1,
    }));
  }, [stops, currentIndex]);

  const handleTabChange = useCallback((index: number) => {
    setCurrentIndex(index + 1);
    setAddress([]);
  }, []);

  const onAddPress = useCallback(
    (data: any) => {
      append({ ...data[currentIndex], ...defaultOrderStopForm, stopNumber: stops.length + 1 });
    },
    [append, currentIndex, stops.length],
  );

  useEffect(() => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setValue('startDateTimeClientTimeZone', tz);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const providerValue = useMemo(
    () => ({
      formState,
      control,
      register,
      reset,
      onSubmit,
      stops,
      append,
      remove,
      setValue,
      addressSearch,
      tabs,
      fetchAddress,
      address,
      setAddressToStop,
      isHouseNumber,
      setAddress,
      onAddPress,
      handleTabChange,
      currentIndex,
      setCurrentIndex,
    }),
    [
      formState,
      control,
      register,
      reset,
      onSubmit,
      stops,
      append,
      remove,
      setValue,
      addressSearch,
      tabs,
      fetchAddress,
      address,
      setAddressToStop,
      isHouseNumber,
      setAddress,
      onAddPress,
      handleTabChange,
      currentIndex,
      setCurrentIndex,
    ],
  );

  return <newOrderPageContext.Provider value={providerValue}>{children}</newOrderPageContext.Provider>;
};

export const useNewOrderPage = () => {
  return useContext(newOrderPageContext);
};

interface Props {
  children?: React.ReactNode;
}
