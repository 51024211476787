import { DataWithMeta, PaginationRequest, paginationRequestToUrl } from 'helpers';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api } from 'services/api';

import { itemGroupToReq, resToItemGroup, transformToItemGroupsFilters } from './transformations';
import { ItemGroup, ItemGroupForm } from './types';

enum QueryKeys {
  ItemGroups = 'itemGroups',
  SingleItemGroup = 'singleItemGroup',
}

export const fetchItemGroups = async (pagination: PaginationRequest): Promise<DataWithMeta<ItemGroup>> => {
  const url = paginationRequestToUrl('/item_groups', pagination, transformToItemGroupsFilters);
  const { data: res } = await api.get(url);
  return {
    data: res.data.map(resToItemGroup),
    meta: res.meta,
  };
};

export const fetchAllItemGroups = async (): Promise<ItemGroup[]> => {
  const resp = await api.get(`/item_groups`);
  return resp.data.data.map(resToItemGroup);
};

export const fetchItemGroup = async (id: string): Promise<ItemGroup> => {
  const res = await api.get(`/item_groups/${id}`);
  return resToItemGroup(res.data);
};

export const postItemGroup = async (data: ItemGroupForm): Promise<ItemGroup> => {
  const res = await api.post('/item_groups', itemGroupToReq({ ...data }));
  return resToItemGroup(res.data);
};

export const putItemGroup = async (data: ItemGroupForm, id: string): Promise<ItemGroup> => {
  const res = await api.put(`/item_groups/${id}`, itemGroupToReq({ ...data }));
  return resToItemGroup(res.data);
};

export const deleteItemGroup = async (id: string): Promise<ItemGroup> => {
  const res = await api.delete(`/item_groups/${id}`);
  return resToItemGroup(res.data);
};

// GET itemGroups

export const useItemGroups = ({ currentPage, pageSize, sortBy, sortDirection, debouncedSearch, filters }: any) => {
  return useQuery([QueryKeys.ItemGroups, currentPage, pageSize, sortBy, sortDirection, debouncedSearch, filters], () =>
    fetchItemGroups({ page: currentPage, pageSize, sortBy, sortDirection, search: debouncedSearch, filters }),
  );
};

export const useItemGroup = ({ id, isCreate }: { id: string; isCreate: boolean }) => {
  return useQuery([QueryKeys.SingleItemGroup, id], () => fetchItemGroup(id), {
    enabled: !isCreate,
  });
};

// ItemGroups mutation

export const useItemGroupMutations = () => {
  const queryClient = useQueryClient();

  return {
    addItemGroup: useMutation(postItemGroup),
    editItemGroup: useMutation(({ data, id }: { data: ItemGroupForm; id: string }) => putItemGroup(data, id)),
    deleteItemGroup: useMutation(deleteItemGroup, {
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.ItemGroups]),
    }),
  };
};
