import { Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { ItemGroupPage, ItemGroupsPage, ItemGroupsProvider } from 'modules/ItemGroups';
import { ItemCategoriesPage, ItemCategoriesProvider, ItemCategoryPage } from 'modules/ItemCategories';
/* import { ChatPageProvider } from 'modules/ChatPage';
import { ChatPage } from 'modules/ChatPage/ChatPage'; */
import { NewOrderPage, NewOrderPageProvider } from 'modules/Orders/NewOrderPage';
import { MainLayout } from './components';
import { GlobalProvider } from './GlobalProvider';
import { SignIn } from './modules/SignIn';
import { DashboardPage, DashboardPageProvider } from './modules/Dashboard';
import { DriverPage, DriverPageProvider, DriversPage, DriversProvider } from './modules/Drivers';
import { CustomersProvider, CustomersPage, CustomerPageProvider, CustomerPage } from './modules/Customers';
import { MerchantPage, MerchantPageProvider, MerchantsPage, MerchantsProvider } from './modules/Merchants';
import {
  DistancePricePage,
  DistancePricePageProvider,
  DistancePricesPage,
  DistancePricesProvider,
} from './modules/DistancePrices';
import {
  WeightPricePage,
  WeightPricePageProvider,
  WeightPricesPage,
  WeightPricesProvider,
} from './modules/WeightPrices';
import { StopPricePage, StopPricePageProvider, StopPricesPage, StopPricesProvider } from './modules/StopPrices';
import { SettingPage, SettingPageProvider, SettingsProvider } from './modules/Settings';
import { ProfilePage } from './modules/Profile';
import { PromoCodePage, PromoCodePageProvider, PromoCodesPage, PromoCodesProvider } from './modules/PromoCodes';
import { DriverGroupsPage, DriverGroupsProvider } from './modules/DriverGroups';
import { DriverGroupPage, DriverGroupPageProvider } from './modules/DriverGroups/DriverGroupPage';
import { OrderPage, OrderPageProvider, OrdersPage, OrdersProvider } from './modules/Orders';
import { UserPage, UserPageProvider, UsersPage, UsersProvider } from './modules/Users';
import { OrdersPageProvider } from './modules/Orders/OrdersPage';
import { DriverGroupsPageProvider } from './modules/DriverGroups/DriverGroupsPage/DriverGroupsPageProvider';
import { DriverReportsProvider } from './modules/DriverPayments';
import { DriverReportsPage, DriverReportsPageProvider } from './modules/DriverPayments/DriverReportsPage';
import { DriverReportPage, DriverReportPageProvider } from './modules/DriverPayments/DriverReportPage';
import { DriverReviewsProvider } from './modules/DriverReviews';
import { DriverReviewsPageProvider, DriverReviewsPage } from './modules/DriverReviews/DriverReviewsPage';
import { DriverReviewPageProvider, DriverReviewPage } from './modules/DriverReviews/DriverReviewPage';
import { UploadProvider } from './modules/Upload/UploadProvider';
import { UploadPage, UploadPageProvider } from './modules/Upload/UploadPage';
import { SmsNotificationsProvider } from './modules/SmsNotifications/SmsNotificationsProvider';
import { SmsNotificationsPage, SmsNotificationsPageProvider } from './modules/SmsNotifications/SmsNotificationsPage';
import { DriversPageProvider } from './modules/Drivers/DriversPage/DriversPageProvider';
import { MerchantReportsProvider } from './modules/MerchantPayments';
import { MerchantReportsPage, MerchantReportsPageProvider } from './modules/MerchantPayments/MerchantReportsPage';
import { MerchantReportPage, MerchantReportPageProvider } from './modules/MerchantPayments/MerchantReportPage';
import { TrailerPage, TrailersPage, TrailersProvider } from './modules/Trailers';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
dayjs.extend(localizedFormat);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalProvider>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route element={<DashboardPageProvider />}>
              <Route index element={<DashboardPage />} />
            </Route>
            {/*      <Route path="chat" element={<ChatPageProvider />}>
              <Route index element={<ChatPage />} />
            </Route> */}
            <Route path="drivers" element={<DriversProvider />}>
              <Route element={<DriversPageProvider />}>
                <Route index element={<DriversPage />} />
              </Route>
              <Route path=":id" element={<DriverPageProvider />}>
                <Route index element={<DriverPage />} />
              </Route>
            </Route>
            <Route path="driver-groups" element={<DriverGroupsProvider />}>
              <Route element={<DriverGroupsPageProvider />}>
                <Route index element={<DriverGroupsPage />} />
              </Route>
              <Route path=":id" element={<DriverGroupPageProvider />}>
                <Route index element={<DriverGroupPage />} />
              </Route>
            </Route>
            <Route path="trailers" element={<TrailersProvider />}>
              <Route index element={<TrailersPage />} />
              <Route path=":id" element={<TrailerPage />} />
            </Route>
            <Route path="item-groups" element={<ItemGroupsProvider />}>
              <Route index element={<ItemGroupsPage />} />
              <Route path=":id" element={<ItemGroupPage />} />
            </Route>
            <Route path="item-categories" element={<ItemCategoriesProvider />}>
              <Route index element={<ItemCategoriesPage />} />
              <Route path=":id" element={<ItemCategoryPage />} />
            </Route>
            <Route path="customers" element={<CustomersProvider />}>
              <Route index element={<CustomersPage />} />
              <Route path=":id" element={<CustomerPageProvider />}>
                <Route index element={<CustomerPage />} />
              </Route>
            </Route>
            <Route path="merchants" element={<MerchantsProvider />}>
              <Route index element={<MerchantsPage />} />
              <Route path=":id" element={<MerchantPageProvider />}>
                <Route index element={<MerchantPage />} />
              </Route>
            </Route>
            <Route path="upload" element={<UploadProvider />}>
              <Route element={<UploadPageProvider />}>
                <Route index element={<UploadPage />} />
              </Route>
            </Route>
            <Route path="notification" element={<SmsNotificationsProvider />}>
              <Route element={<SmsNotificationsPageProvider />}>
                <Route index element={<SmsNotificationsPage />} />
              </Route>
            </Route>
            <Route path="distance-prices" element={<DistancePricesProvider />}>
              <Route index element={<DistancePricesPage />} />
              <Route path=":id" element={<DistancePricePageProvider />}>
                <Route index element={<DistancePricePage />} />
              </Route>
            </Route>
            <Route path="weight-prices" element={<WeightPricesProvider />}>
              <Route index element={<WeightPricesPage />} />
              <Route path=":id" element={<WeightPricePageProvider />}>
                <Route index element={<WeightPricePage />} />
              </Route>
            </Route>
            <Route path="stop-prices" element={<StopPricesProvider />}>
              <Route index element={<StopPricesPage />} />
              <Route path=":id" element={<StopPricePageProvider />}>
                <Route index element={<StopPricePage />} />
              </Route>
            </Route>
            <Route path="settings" element={<SettingsProvider />}>
              <Route element={<SettingPageProvider />}>
                <Route index element={<SettingPage />} />
              </Route>
            </Route>
            <Route path="promo-codes" element={<PromoCodesProvider />}>
              <Route index element={<PromoCodesPage />} />
              <Route path=":id" element={<PromoCodePageProvider />}>
                <Route index element={<PromoCodePage />} />
              </Route>
            </Route>
            <Route path="driver-payments" element={<DriverReportsProvider />}>
              <Route element={<DriverReportsPageProvider />}>
                <Route index element={<DriverReportsPage />} />
              </Route>
              <Route path=":id" element={<DriverReportPageProvider />}>
                <Route index element={<DriverReportPage />} />
              </Route>
            </Route>
            <Route path="merchant-payments" element={<MerchantReportsProvider />}>
              <Route element={<MerchantReportsPageProvider />}>
                <Route index element={<MerchantReportsPage />} />
              </Route>
              <Route path=":id" element={<MerchantReportPageProvider />}>
                <Route index element={<MerchantReportPage />} />
              </Route>
            </Route>
            <Route path="orders" element={<OrdersProvider />}>
              <Route element={<OrdersPageProvider />}>
                <Route index element={<OrdersPage />} />
              </Route>
              <Route path=":id" element={<OrderPageProvider />}>
                <Route index element={<OrderPage />} />
              </Route>
              <Route path="new" element={<NewOrderPageProvider />}>
                <Route index element={<NewOrderPage />} />
              </Route>
            </Route>
            <Route path="reviews" element={<DriverReviewsProvider />}>
              <Route element={<DriverReviewsPageProvider />}>
                <Route index element={<DriverReviewsPage />} />
              </Route>
              <Route path=":id" element={<DriverReviewPageProvider />}>
                <Route index element={<DriverReviewPage />} />
              </Route>
            </Route>
            <Route path="users" element={<UsersProvider />}>
              <Route index element={<UsersPage />} />
              <Route path=":id" element={<UserPageProvider />}>
                <Route index element={<UserPage />} />
              </Route>
            </Route>
            <Route path="profile" element={<ProfilePage />} />
          </Route>
          <Route path="/sign-in" element={<SignIn />} />
        </Routes>
      </GlobalProvider>
    </QueryClientProvider>
  );
}

export default App;
