import { api } from 'services';
import { DataWithMeta, PaginationRequest, paginationRequestToUrl } from 'helpers';

import { resToUser, userToPostReq, userToPutReq } from './transformations';
import { User } from './types';

export const fetchUsers = async (pagination: PaginationRequest): Promise<DataWithMeta<User>> => {
  const url = paginationRequestToUrl('/users', pagination);

  const resp = await api.get(url);
  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToUser),
  };
};

export const fetchUser = async (id: string): Promise<User> => {
  const resp = await api.get(`/users/${id}`);
  return resToUser(resp.data);
};

export const putUser = async (data: any): Promise<User> => {
  const resp = await api.put(`/users/${data.id}`, userToPutReq(data));
  return resToUser(resp.data);
};

export const postUser = async (data: any): Promise<User> => {
  const resp = await api.post(`/users`, userToPostReq(data));
  return resToUser(resp.data);
};

export const deleteUser = async (id: string): Promise<User> => {
  const resp = await api.delete(`/users/${id}`);
  return resToUser(resp.data);
};
