export const PaymentCardItem: React.FC<{
  label: string;
  value: any;
}> = ({ label, value }) => {
  return (
    <div className="items-center justify-between col-span-2 px-6 md:col-span-1">
      <span className="text-base font-medium text-gray-900 lg:text-lg">{label}</span>
      <p className="text-base lg:text-lg">{value}</p>
    </div>
  );
};
