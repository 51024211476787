import { DataWithMeta, PaginationRequest, paginationRequestToUrl } from 'helpers';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api } from 'services/api';
import { uploadImages } from 'services/images';
import { resToTrailer, trailerToReq, transformToTrailerFilters } from './transformations';
import { Trailer, TrailerFormData } from './types';

enum QueryKeys {
  Trailers = 'trailers',
  SingleTrailer = 'singleTrailer',
}

export const fetchTrailers = async (pagination: PaginationRequest): Promise<DataWithMeta<Trailer>> => {
  const url = paginationRequestToUrl('/trailers', pagination, transformToTrailerFilters);
  const { data: res } = await api.get(url);
  return {
    data: res.data.map(resToTrailer),
    meta: res.meta,
  };
};

export const fetchAllTrailers = async (): Promise<Trailer[]> => {
  const resp = await api.get(`/trailers`);
  return resp.data.data.map(resToTrailer);
};

export const fetchTrailer = async (id: string): Promise<Trailer> => {
  const res = await api.get(`/trailers/${id}`);
  return resToTrailer(res.data);
};

export const postTrailer = async (data: TrailerFormData): Promise<Trailer> => {
  const images = await uploadImages({ trailerImageFile: data.trailerImageFile });

  const res = await api.post('/trailers', trailerToReq({ ...data, ...images }));
  return resToTrailer(res.data);
};

export const putTrailer = async (data: TrailerFormData, id: string): Promise<Trailer> => {
  const images = await uploadImages({ trailerImageFile: data.trailerImageFile });

  const res = await api.put(`/trailers/${id}`, trailerToReq({ ...data, ...images }));
  return resToTrailer(res.data);
};

export const deleteTrailer = async (id: string): Promise<Trailer> => {
  const res = await api.delete(`/trailers/${id}`);
  return resToTrailer(res.data);
};

// GET trailers

export const useTrailers = ({ currentPage, pageSize, sortBy, sortDirection, debouncedSearch, filters }: any) => {
  return useQuery([QueryKeys.Trailers, currentPage, pageSize, sortBy, sortDirection, debouncedSearch, filters], () =>
    fetchTrailers({ page: currentPage, pageSize, sortBy, sortDirection, search: debouncedSearch, filters }),
  );
};

export const useTrailer = ({ id, isCreate }: { id: string; isCreate: boolean }) => {
  return useQuery([QueryKeys.SingleTrailer, id], () => fetchTrailer(id), {
    enabled: !isCreate,
  });
};

// Trailers mutation

export const useTrailerMutations = () => {
  const queryClient = useQueryClient();

  return {
    addTrailer: useMutation(postTrailer),
    editTrailer: useMutation(({ data, id }: { data: TrailerFormData; id: string }) => putTrailer(data, id)),
    deleteTrailer: useMutation(deleteTrailer, {
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.Trailers]),
    }),
  };
};
