import { useMutation, useQuery } from 'react-query';

import { api } from 'services';

import { imageToFormData, resToStorageFile, fileToFormData } from './transformations';
import { StorageFile } from './types';

enum QueryKeys {
  StorageFileSingle = 'file',
  AddStorageFile = 'addFile',
}

//
// API calls
//
const fetchStorageFile = async (fileName: string): Promise<StorageFile> => {
  const resp = await api.get(`/storage/${fileName}`);
  return resToStorageFile(resp.data);
};

const postStorageFile = async (data: any): Promise<StorageFile> => {
  const resp = await api.post(`/storage/files`, data.type ? fileToFormData(data) : imageToFormData(data.file), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return resToStorageFile(resp.data);
};

//
// GET hooks
//
export const useStorageFile = ({ fileName }: { fileName: string }) => {
  return useQuery([QueryKeys.StorageFileSingle, fileName], () => fetchStorageFile(fileName));
};

//
// Mutation hooks
//
export const useStorageFileMutation = () => {
  return {
    addStorageFile: useMutation(postStorageFile, {
      mutationKey: [QueryKeys.AddStorageFile],
    }),
  };
};
