import { WeightPriceForm } from 'modules/WeightPrices/types';

import { WeightPrice } from './types';

export const resToWeightPrice = (res: any): WeightPrice => {
  return {
    id: res.id,
    weightLbsFrom: res.weightLbsFrom,
    weightLbsTo: res.weightLbsTo,
    weightFee: res.weightFee,
  };
};

export const weightPriceToPostReq = (price: WeightPriceForm): any => {
  return {
    weightLbsFrom: price.weightLbsFrom,
    weightLbsTo: price.weightLbsTo,
    weightFee: price.weightFee,
  };
};

export const weightPriceToPutReq = (price: WeightPriceForm): any => {
  return {
    weightLbsFrom: price.weightLbsFrom,
    weightLbsTo: price.weightLbsTo,
    weightFee: price.weightFee,
  };
};
