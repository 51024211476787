import { currencyFormatter, numberFormatter } from 'helpers';
import { InnerChartCardData } from 'modules/Dashboard/DashboardPage/types';
import React from 'react';

export const InnerChartCard = ({ cardData, index, dataLength }: InnerChartCardProps) => {
  const fullWidth = dataLength % 2 !== 0 && index === dataLength - 1;
  return (
    <div
      className={`${fullWidth ? 'col-span-12' : `col-span-6`} border bg-gray-100 shadow-sm rounded-md p-5 text-start`}
    >
      <p className="text-primary-light font-sans font-medium text-2xl">
        {cardData.type === 'currency'
          ? currencyFormatter(cardData.data ?? 0)
          : `${numberFormatter(cardData.data ?? 0)}`}
      </p>
      <p className="text-gray-600 font-sans font-medium text-sm">{cardData.title}</p>
    </div>
  );
};

type InnerChartCardProps = {
  cardData: InnerChartCardData;
  index: number;
  dataLength: number;
};
