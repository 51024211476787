import { Customer } from 'services/customers';

import { CustomerForm } from '../types';

export const customerToCustomerForm = (customer: Customer): CustomerForm => {
  return {
    id: customer.id,
    firstName: customer.user?.firstName || '',
    lastName: customer.user?.lastName || '',
    email: customer.user?.email || '',
    phone: customer.user?.phoneNumber || '',
    password: '',
    zip: customer.zipCode || '',
    leadSource: customer.leadSource || '',
    referralCode: customer.referralCode,
    type: customer.customerTypeId,
    isTest: customer.isTest,
    isInvoiced: customer.isToBeInvoiced,
    isAdmin: customer.user?.isAdmin || false,
    isDriver: customer.user?.isDriver || false,
    isMerchant: customer.user?.isMerchant || false,
    isCustomer: customer.user?.isCustomer || false,
  };
};
