import { MerchantReport } from './types';

export const resToMerchantReport = (res: any): MerchantReport => {
  const parsedUnpaidOrderPaymentIds = JSON.parse(res.unpaidOrderPaymentIds);

  return {
    merchantId: res.merchantId,
    merchantName: res.merchantName,
    orderCount: res.orderCount,
    orderTotalAmount: res.orderTotalAmount,
    orderPaidAmount: res.orderPaidAmount,
    orderPaidCount: res.orderPaidCount,
    orderUnpaidAmount: res.orderUnpaidAmount,
    orderUnpaidCount: res.orderUnpaidCount,
    unpaidOrderPaymentIds: parsedUnpaidOrderPaymentIds ?? [],
  };
};

export const transformToMerchantReportsFilters = (filters: any): any => {
  return {
    merchantId: filters.merchantId,
  };
};
