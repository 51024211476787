import { ChartCard } from 'components';
import { useRevenueCharts } from '../hooks';

export const TotalRevenuePieChart = () => {
  const { totalRevenueData, innerCardTotalRevenue } = useRevenueCharts();
  return (
    <ChartCard
      title="Total Revenue"
      subtitle="By Vehicle Size"
      chartData={totalRevenueData}
      type="pie"
      innerCardData={innerCardTotalRevenue}
      height="100%"
    />
  );
};
