import * as yup from 'yup';

import { PageType } from 'helpers/common';

const isCreate = (id: string) => {
  return id === PageType.Create;
};

export const MERCHANT_VALIDATION_SCHEMA = yup.object({
  email: yup.string().when('id', {
    is: isCreate,
    then: yup.string().required('E-mail is required'),
  }),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  password: yup.string().when('id', {
    is: isCreate,
    then: yup.string().required('Password is required'),
  }),
  phone: yup.string().required('Phone number is required'),
  businessName: yup.string().required('Business name is required'),
});

export const ASSIGN_MERCHANT_SCHEMA = yup.object({
  merchantId: yup.string().required('Merchant is required'),
});
