import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Control, FormState, useFieldArray, useForm, UseFormRegister } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from 'react-query';

import { fetchAllMerchants } from 'services/merchants';
import { fetchAllDriverGroup } from 'services/driverGroups';
import { postOrderBulk, postOrderCsv } from 'services/orders';
import { ItemPickupTimes } from 'modules/Merchants/types';
import { MessageType, showMessage } from 'helpers';

import { IMPORT_VALIDATION_SCHEMA } from './const';
import { BulkOrderForm } from '../types';
import { defaultBulkOrderForm, defaultBulkOrderRow } from '../consts';

const UploadPageContext = createContext<{
  formState: FormState<BulkOrderForm>;
  loadingUpload: boolean;
  control: Control<BulkOrderForm, any>;
  inputRef: React.RefObject<HTMLInputElement>;
  orders: BulkOrderForm['orders'];
  orderErrors: {
    row: number;
    errorMessages: string[];
  }[];
  driverGroups: { label: string; value: string }[];
  selectedMerchantId: string | undefined;
  selectedMerchantItemPickupTimes: ItemPickupTimes[];
  handleRowDelete: (index: number) => void;
  handleUploadCsv: (data: any) => void;
  handleButtonClick: () => void;
  handleAddNewRow: () => void;
  register: UseFormRegister<BulkOrderForm>;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
}>({} as any);

// This will be used in case we state shared inside the module
export const UploadPageProvider = ({ children = <Outlet /> }: Props) => {
  const { control, formState, register, handleSubmit, reset, setValue, watch, clearErrors } = useForm<BulkOrderForm>({
    defaultValues: defaultBulkOrderForm,
    resolver: yupResolver(IMPORT_VALIDATION_SCHEMA),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { data: queryDriverGroups } = useQuery(['driverGroups', 1, 9999], () => fetchAllDriverGroup());
  const { data: queryMerchants } = useQuery(['merchants', 1, 9999], () => fetchAllMerchants());

  const { fields: orders, append, remove } = useFieldArray({ control, name: 'orders' });

  const [loadingUpload, setLoadingUpload] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const selectedMerchantId = watch('merchantId');

  const merchants = useMemo(() => queryMerchants ?? [], [queryMerchants]);

  const driverGroups = useMemo(
    () =>
      queryDriverGroups?.map((driverGroup) => ({
        label: driverGroup.name,
        value: driverGroup.id,
      })) ?? [],
    [queryDriverGroups],
  );

  const selectedMerchantDriverGroupId = useMemo(() => {
    const selectedMerchant = queryMerchants?.find((m) => m.id === selectedMerchantId);

    return selectedMerchant?.driverGroupId;
  }, [queryMerchants, selectedMerchantId]);

  const selectedMerchantItemPickupTimes = useMemo(
    () => merchants.find((merchant) => merchant.id === selectedMerchantId)?.itemPickupTimes ?? [],
    [merchants, selectedMerchantId],
  );

  const orderErrors = useMemo(() => {
    const errorList: { row: number; errorMessages: string[] }[] = [];

    // formState.errors.orders is an array that can have undefined values
    // Object.keys() is used to get the indexes of the errors
    Object.keys(formState.errors.orders || []).forEach((key) => {
      const errorObject = formState.errors.orders?.[parseInt(key, 10)];
      const errorMessages = Object.values(errorObject || {}).map((error: any) => error.message);

      errorList.push({
        row: parseInt(key, 10) + 1,
        errorMessages,
      });
    });

    return errorList;
  }, [formState.errors]);

  const resetFileInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }, []);

  useEffect(() => {
    if (selectedMerchantId && selectedMerchantDriverGroupId) {
      orders.forEach((_, index) => {
        setValue(`orders.${index}.deliveryTypeId`, selectedMerchantDriverGroupId);
      });
    }
  }, [selectedMerchantDriverGroupId, selectedMerchantId, orders, setValue]);

  useEffect(() => {
    clearErrors('orders');
    reset({ merchantId: selectedMerchantId, orders: [] });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMerchantId]);

  useEffect(() => {
    if (!orders.length) {
      clearErrors();
      resetFileInput();
    }
  }, [orders, resetFileInput, clearErrors]);

  const handleRowDelete = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  const handleAddNewRow = useCallback(() => {
    append(defaultBulkOrderRow, { shouldFocus: false });
  }, [append]);

  const handleButtonClick = useCallback(() => {
    if (!selectedMerchantId) {
      showMessage('Please select a merchant first', MessageType.Error);
      return;
    }
    inputRef.current?.click();
  }, [inputRef, selectedMerchantId]);

  const handleUploadCsv = useCallback(
    async (e: any) => {
      const { files } = e.target;

      if (files[0].type !== 'text/csv') {
        showMessage('Only .csv files are allowed', MessageType.Error);
        setLoadingUpload(false);
        return;
      }

      const formData = new FormData();
      formData.append('file', files[0]);
      setLoadingUpload(true);

      try {
        const orderCsvFile = await postOrderCsv(formData);
        setValue('orders', orderCsvFile);
        setLoadingUpload(false);
        showMessage('CSV file uploaded successfully', MessageType.Success);
      } catch (err: any) {
        const errorMsg = err?.response?.data?.title;
        showMessage(errorMsg, MessageType.Error);
        setLoadingUpload(false);
      }
    },
    [setValue],
  );

  const onSubmit = handleSubmit(async (data: any) => {
    setLoadingUpload(true);

    try {
      await postOrderBulk(data);
      setLoadingUpload(false);
      showMessage('Csv successfully imported.', MessageType.Success);
      reset(defaultBulkOrderForm);
    } catch (err: any) {
      const errorMsg = err?.response?.data?.title;
      showMessage(errorMsg, MessageType.Error);
      setLoadingUpload(false);
    }
  });

  const providerValue = useMemo(
    () => ({
      control,
      loadingUpload,
      formState,
      inputRef,
      orders,
      orderErrors,
      driverGroups,
      selectedMerchantId,
      selectedMerchantItemPickupTimes,
      handleRowDelete,
      handleAddNewRow,
      handleUploadCsv,
      handleButtonClick,
      register,
      onSubmit,
    }),
    [
      control,
      loadingUpload,
      formState,
      inputRef,
      orders,
      orderErrors,
      driverGroups,
      selectedMerchantId,
      selectedMerchantItemPickupTimes,
      handleRowDelete,
      handleAddNewRow,
      handleUploadCsv,
      handleButtonClick,
      register,
      onSubmit,
    ],
  );

  return <UploadPageContext.Provider value={providerValue}>{children}</UploadPageContext.Provider>;
};

export const useUploadPageProvider = () => {
  return useContext(UploadPageContext);
};

interface Props {
  children?: React.ReactNode;
}
