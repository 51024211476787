import React, { createContext, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

const DriverReviewsContext = createContext<{}>({} as any);

// This will be used in case we state shared inside the module
export const DriverReviewsProvider = ({ children = <Outlet /> }: Props) => {
  const providerValue = useMemo(() => ({}), []);

  return <DriverReviewsContext.Provider value={providerValue}>{children}</DriverReviewsContext.Provider>;
};

interface Props {
  children?: React.ReactNode;
}
