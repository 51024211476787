import clsx from 'clsx';

export const ButtonBase = ({
  fullWidth = false,
  color = 'primary',
  type = 'button',
  disabled = false,
  className: propClassName = '',
  ...props
}: ButtonBaseProps) => {
  const className = clsx(
    // eslint-disable-next-line max-len
    'justify-center items-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2',
    {
      'w-full flex': fullWidth,
      'inline-flex': !fullWidth,
      'border-transparent text-white bg-primary-500 hover:bg-primary-600 focus:ring-primary-500': color === 'primary',
      'border-secondary-200 text-secondary-800 bg-transparent hover:bg-secondary-50 focus:ring-secondary-500':
        color === 'secondary',
      'border-transparent text-white bg-red-600 hover:bg-red-700 focus:ring-red-500': color === 'danger',
      'border-transparent text-white bg-orange-400 hover:bg-orange-600 focus:ring-orange-500': color === 'warning',
      'opacity-60': disabled,
    },
    propClassName,
  );

  // eslint-disable-next-line react/button-has-type
  return <button {...props} disabled={disabled} className={className} type={type} />;
};

export type ButtonBaseProps = {
  fullWidth?: boolean;
  color?: 'primary' | 'secondary' | 'danger' | 'warning';
} & JSX.IntrinsicElements['button'];
