import { Setting } from 'services/settings';

import { SettingForm } from '../types';

export const settingToSettingForm = (setting: Setting): SettingForm => {
  return {
    adminEmail: setting.adminEmail,
    contactPhone: setting.contactPhone,
    driverPercentage: setting.driverPercentage,
    driverTipPercentage: setting.driverTipPercentage,
    expeditedFeePercentage: setting.expeditedFeePercentage,
    expeditedFeeThresholdHours: setting.expeditedFeeThresholdHours,
    firstHelperLoadFee: setting.firstHelperLoadFee,
    firstHelperPercentage: setting.firstHelperPercentage,
    firstHelperUnloadFee: setting.firstHelperUnloadFee,
    fromEmail: setting.fromEmail,
    fromEmailName: setting.fromEmailName,
    paymentDay: setting.paymentDay,
    secondHelperLoadFee: setting.secondHelperLoadFee,
    secondHelperPercentage: setting.secondHelperPercentage,
    secondHelperUnloadFee: setting.secondHelperUnloadFee,
    unassignedOrderTime: setting.unassignedOrderTime,
  };
};
