import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';
import { Link, Navigate, NavLink, Outlet } from 'react-router-dom';
import { useToggle } from 'react-use';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

import { useAuth } from 'GlobalProvider';

import { MenuItem } from '../MenuItem';
import { useNavigation } from './const';

type MainLayoutProps = {
  children?: ReactNode;
};

export function MainLayout({ children = <Outlet /> }: MainLayoutProps) {
  const { isLoggedIn } = useAuth();
  const [sidebarOpen, toggleSidebarOpen] = useToggle(false);
  const { user } = useAuth();

  const fullName = `${user?.firstName} ${user?.lastName}`;

  const NAVIGATION = useNavigation();

  if (!isLoggedIn) {
    return <Navigate to="/sign-in" />;
  }

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-30 flex md:hidden" onClose={toggleSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex flex-col flex-1 w-full max-w-xs bg-gray-200">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 pt-2 -mr-12">
                  <button
                    type="button"
                    // eslint-disable-next-line max-len
                    className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={toggleSidebarOpen}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="w-6 h-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto ">
                <div className="flex items-center flex-shrink-0 px-4">
                  <Link to="/">
                    <img className="w-auto h-12" src="/assets/images/dashboard-logo.png" alt="logo" />
                  </Link>
                </div>
                <nav className="px-2 mt-5 space-y-1">
                  {NAVIGATION.map((item) => (
                    <MenuItem item={item} key={item.name} onClickItem={toggleSidebarOpen} />
                  ))}
                </nav>
              </div>
              <div className="flex flex-shrink-0 p-4 bg-gray-200">
                <Link to="/profile" className="flex-shrink-0 block group">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block w-10 h-10 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-base font-medium text-black">{fullName}</p>
                      <p className="text-sm font-medium text-gray-500">View profile</p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden shadow md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        <div className="flex flex-col flex-1 min-h-0 bg-gray-200">
          <div className="flex flex-col flex-1 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 h-16 px-4 bg-white shadow">
              <Link to="/">
                <img className="w-auto h-12" src="/assets/images/dashboard-logo.png" alt="logo" />
              </Link>
            </div>
            <nav className="flex-1 px-2 mt-5 space-y-1">
              {NAVIGATION.map((item) => (
                <MenuItem item={item} key={item.name} />
              ))}
            </nav>
          </div>
          <div className="flex flex-shrink-0 p-2 bg-gray-200">
            <NavLink
              to="/profile"
              className={({ isActive }) =>
                clsx('flex-shrink-0 block w-full group rounded-md p-2', {
                  'bg-sidebar-red': isActive,
                  'hover:bg-sidebar-red': !isActive,
                })
              }
            >
              {({ isActive }) => (
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block rounded-full h-9 w-9"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p
                      className={clsx('text-sm font-medium', {
                        'text-white': isActive,
                        'text-black group-hover:text-white': !isActive,
                      })}
                    >
                      {fullName}
                    </p>
                    <p
                      className={clsx('text-xs font-medium', {
                        'text-red-200': isActive,
                        'text-gray-500 group-hover:text-red-200': !isActive,
                      })}
                    >
                      View profile
                    </p>
                  </div>
                </div>
              )}
            </NavLink>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 h-screen md:pl-64">
        <div className="sticky top-0 pt-1 pl-1 md:hidden sm:pl-3 sm:pt-3">
          <button
            type="button"
            // eslint-disable-next-line max-len
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={toggleSidebarOpen}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="w-6 h-6" aria-hidden="true" />
          </button>
        </div>

        <main className="flex flex-col flex-1 h-full">{children}</main>
      </div>
    </div>
  );
}
