/* eslint-disable jsx-a11y/label-has-associated-control */
import { PlusIcon } from '@heroicons/react/outline';
import { Button, ConfirmationModal, Container, Header, Table } from 'components';
import { HeaderSearch } from 'components/Header/HeaderSearch';
import { WeightPrice } from 'services/weightPrices';

import { useWeightPricesPage } from './hooks';
import { columns } from './const';

export const WeightPricesPage = () => {
  const {
    weightPrices,
    metaData,
    currentPage,
    isLoading,
    search,
    weightPriceToDelete,
    openConfirmationModal,
    closeConfirmationModal,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onRowClick,
    onDeleteConfirm,
    onNewClick,
    onSortChange,
  } = useWeightPricesPage();

  return (
    <>
      <Header title="Weight prices">
        <div className="flex justify-between flex-1">
          <HeaderSearch onChange={(e: any) => onSearchChange(e.target.value)} value={search} />

          <Button onClick={onNewClick} label="New weight price" icon={<PlusIcon />} />
        </div>
      </Header>

      <Container>
        <Table<WeightPrice>
          data={weightPrices ?? []}
          columns={columns(openConfirmationModal)}
          pagination={metaData}
          currentPage={currentPage}
          isLoading={isLoading}
          onRowClick={onRowClick}
          pageClicked={onPageChange}
          pageSizeClicked={onPageSizeChange}
          headerClicked={onSortChange}
        />
      </Container>

      <ConfirmationModal
        title="Delete weight price"
        body="Are you sure you want to delete this weight price?"
        open={!!weightPriceToDelete}
        onAction={onDeleteConfirm}
        onClose={closeConfirmationModal}
      />
    </>
  );
};
