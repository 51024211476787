import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { MessageType, showMessage, usePagination } from 'helpers';
import { deleteMerchant, fetchMerchants, Merchant } from 'services/merchants';

// Drivers page state
export const useMerchantsPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
  } = usePagination();

  const [merchantToDelete, setMerchantToDelete] = useState<Merchant | null>(null);

  const { data: queryData, isLoading: driversLoading } = useQuery(
    ['merchants', currentPage, pageSize, sortBy, sortDirection, debouncedSearch],
    () => fetchMerchants({ page: currentPage, pageSize, sortBy, sortDirection, search: debouncedSearch }),
  );
  const { mutateAsync: removeMerchant } = useMutation(deleteMerchant);

  const merchants = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onRowClick = (row: Merchant) => {
    navigate(`/merchants/${row.id}`);
  };

  const onNewClick = () => {
    navigate('/merchants/new');
  };

  const onDeleteConfirm = async () => {
    if (merchantToDelete) {
      try {
        await removeMerchant(merchantToDelete.id);
        showMessage('Merchant successfully deleted.', MessageType.Success);
        queryClient.invalidateQueries('merchants');
        setMerchantToDelete(null);
      } catch {
        showMessage('Something went wrong.', MessageType.Error);
      }
    }
  };

  const openConfirmationModal = (selectedMerchant: Merchant | null) => {
    if (selectedMerchant) setMerchantToDelete(selectedMerchant);
  };

  const closeConfirmationModal = () => {
    setMerchantToDelete(null);
  };

  return {
    merchants,
    metaData,
    currentPage,
    search,
    isLoading: driversLoading,
    merchantToDelete,
    openConfirmationModal,
    closeConfirmationModal,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onDeleteConfirm,
    onRowClick,
    onNewClick,
    onSortChange,
  };
};
