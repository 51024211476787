import { Review } from 'services/reviews';
import { DriverReviewForm } from './types';

export const driverReviewToDriverReviewForm = (review: Review): DriverReviewForm => {
  return {
    id: review.id,
    driverId: review.driverId,
    customerId: review.customerId,
    orderId: review.orderId,
    score: review.score,
    description: review.description,
    status: review.status,
    firstName: review.driverFirstName,
    lastName: review.driverLastName,
  };
};
