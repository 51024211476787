import { PromoCodeForm, PromoCodeType } from 'modules/PromoCodes/types';

import { PromoCode } from './types';

export const resToPromoCode = (res: any): PromoCode => {
  return {
    id: res.id,
    code: res.code ?? '',
    activeFrom: res.activeFrom ?? null,
    activeTo: res.activeTo ?? null,
    discountAmount: res.discountAmount,
    discountPercentage: res.discountPercentage,
    quantity: res.quantity,
    maxUsesPerUser: res.maxUsesPerUser,
    useCount: res.useCount,
    status: res.status ?? '',
  };
};

export const promoCodeToPostReq = (promoCodeForm: PromoCodeForm): any => {
  const amounts =
    promoCodeForm.type === PromoCodeType.Amount
      ? { discountAmount: promoCodeForm.amount, discountPercentage: null }
      : { discountPercentage: promoCodeForm.amount, discountAmount: null };

  return {
    code: promoCodeForm.code,
    activeFrom: promoCodeForm.activeFrom?.toISOString() || null,
    activeTo: promoCodeForm.activeTo?.toISOString() || null,
    quantity: promoCodeForm.quantity,
    maxUsesPerUser: promoCodeForm.maxUsesPerUser,
    status: promoCodeForm.status,
    ...amounts,
  };
};

export const promoCodeToPutReq = (promoCodeForm: PromoCodeForm): any => {
  const amounts =
    promoCodeForm.type === PromoCodeType.Amount
      ? { discountAmount: promoCodeForm.amount, discountPercentage: null }
      : { discountPercentage: promoCodeForm.amount, discountAmount: null };

  return {
    code: promoCodeForm.code,
    activeFrom: promoCodeForm.activeFrom?.toISOString() || null,
    activeTo: promoCodeForm.activeTo?.toISOString() || null,
    quantity: promoCodeForm.quantity,
    maxUsesPerUser: promoCodeForm.maxUsesPerUser,
    status: promoCodeForm.status,
    ...amounts,
  };
};
