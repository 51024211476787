import { Driver } from 'services/drivers';
import { idAndUrlToImage } from 'services/images';
import { initialImage } from 'services/images/consts';

import { DriverForm } from '../types';

export const driverToDriverForm = (driver: Driver): DriverForm => {
  return {
    id: driver.id,
    password: '',
    firstName: driver.user?.firstName ?? '',
    lastName: driver.user?.lastName ?? '',
    email: driver.user?.email ?? '',
    phoneNumber: driver.user?.phoneNumber ?? '',
    dateOfBirth: driver.user?.dateOfBirth ? new Date(driver.user.dateOfBirth) : null,
    address1: driver.user?.homeAddress?.street1 ?? '',
    address2: driver.user?.homeAddress?.street2 ?? '',
    city: driver.user?.homeAddress?.city ?? '',
    state: driver.user?.homeAddress?.state ?? '',
    zip: driver.user?.homeAddress?.zip ?? '',
    driverGroupIds: driver.driverGroupIds ?? [],
    driversLicenseNumber: driver.driversLicenseNumber,
    driversLicenseState: driver.driversLicenseState,
    driversLicenseExpirationDate: driver.driversLicenseExpirationDate
      ? new Date(driver.driversLicenseExpirationDate)
      : null,
    driversLicenseFile: driver.driversLicenseFileUrl
      ? idAndUrlToImage(driver.driversLicenseFileId, driver.driversLicenseFileUrl)
      : initialImage,
    vehicleMake: driver.vehicleMake,
    vehicleModel: driver.vehicleModel,
    vehicleYear: driver?.vehicleYear ?? null,
    vehicleColor: driver.vehicleColor,
    isHelper: driver.isHelper,
    maximumDrivingDistanceMiles: driver?.maximumDrivingDistanceMiles ?? null,
    expectedAvailabilityId: driver.expectedAvailabilityId,
    profilePictureFile: driver.profilePictureFileUrl
      ? idAndUrlToImage(driver.profilePictureFileId, driver.profilePictureFileUrl)
      : initialImage,
    vehicleInsuranceFile: driver.vehicleInsuranceFileUrl
      ? idAndUrlToImage(driver.vehicleInsuranceFileId, driver.vehicleInsuranceFileUrl)
      : initialImage,
    vehicleRegistrationFile: driver.vehicleRegistrationFileUrl
      ? idAndUrlToImage(driver.vehicleRegistrationFileId, driver.vehicleRegistrationFileUrl)
      : initialImage,
    orderPaymentPercentage: driver?.orderPaymentPercentage ?? null,
    firstHelperPaymentPercentage: driver?.firstHelperPaymentPercentage ?? null,
    secondHelperPaymentPercentage: driver?.secondHelperPaymentPercentage ?? null,
    approveDenyNote: driver.approveDenyNote,
    description: driver.description,
    status: driver.status,
    isAdmin: driver.user?.isAdmin ?? false,
    isCustomer: driver.user?.isCustomer ?? false,
    isDriver: driver.user?.isDriver ?? false,
    isMerchant: driver.user?.isMerchant ?? false,
  };
};
