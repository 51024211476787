import { Fragment, useMemo, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { XIcon } from '@heroicons/react/solid';
import { FilterIcon } from '@heroicons/react/outline';

import { FilterDropdownItem, FilterDropdownProps } from './types';

export const FilterDropdown = ({
  items,
  className,
  menuItemsWrapperClassName,
  menuButtonClassName,
  menuItemClassName,
  onClearFilter,
}: FilterDropdownProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const selectedItem = useMemo(() => {
    if (selectedIndex === null) {
      return null;
    }

    return items[selectedIndex];
  }, [selectedIndex, items]);

  const filteredItems = selectedItem ? items.filter((item) => item.id !== selectedItem.id) : items;

  const handleItemClick = (item: FilterDropdownItem) => {
    const index = items.findIndex((i) => i.id === item.id);
    setSelectedIndex(index);
    item.onItemClick();
  };

  const handleClearClick = () => {
    setSelectedIndex(null);
    onClearFilter();
  };

  return (
    <Menu as="div" className={twMerge(clsx('relative inline-block text-left', className))}>
      <div className="flex">
        {selectedItem && (
          <div className="inline-flex items-center select-none justify-between px-3 font-medium py-2 text-sm bg-gray-100 rounded-md mr-4 whitespace-nowrap">
            {selectedItem.label}
            {selectedItem.customDateRange && `: ${selectedItem.customDateRange}`}
            <XIcon
              className="text-gray-600 hover:text-gray-800 hover:cursor-pointer h-5 w-5 ml-1"
              onClick={handleClearClick}
            />
          </div>
        )}
        <Menu.Button
          className={twMerge(
            clsx(
              'h-10 w-10 p-2 rounded-md bg-gray-100 text-gray-700 hover:bg-gray-300 hover:text-gray-800',
              menuButtonClassName,
            ),
          )}
        >
          <FilterIcon />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={twMerge(
            clsx(
              'absolute right-0 mt-2 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg',
              'ring-1 ring-black ring-opacity-5 focus:outline-none',
              menuItemsWrapperClassName,
            ),
          )}
        >
          {filteredItems.map((item) => (
            <Menu.Item key={item.id}>
              {({ active }) => (
                <button
                  type="button"
                  className={twMerge(
                    clsx(
                      'group flex w-full items-center text-left px-3 py-2 text-sm font-medium first-of-type:rounded-t-md last-of-type:rounded-b-md',
                      { 'bg-primary text-white': active },
                      { 'text-gray-900': !active },
                      menuItemClassName,
                    ),
                  )}
                  onClick={() => handleItemClick(item)}
                >
                  {item.label}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
