import { Fragment, useEffect, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon, XIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

import { DataWithMeta, initialPaginationRequest, MetaData, PaginationRequest, useDebounce } from 'helpers';

export const AsyncSelect = <T extends unknown>({
  className,
  error,
  control,
  name,
  valueKey,
  placeholder,
  initialValue = null,
  asyncFunction,
  getOptionLabel,
  getSelectedLabel = getOptionLabel,
}: Props<T>) => {
  const { field } = useController({ control, name });

  const [value, setValue] = useState<T | null>(initialValue);
  const [pagination, setPagination] = useState<PaginationRequest>(initialPaginationRequest);
  const [metaData, setMetaData] = useState<MetaData>();
  const [results, setResults] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const debouncedSearch = useDebounce(pagination.search, 500);

  const callAsyncFunction = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    try {
      const { data, meta } = await asyncFunction(pagination);
      if (pagination.page === 1) {
        setResults(data);
      } else {
        setResults((prevResults) => [...prevResults, ...data]);
      }
      setMetaData(meta);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpen) {
      callAsyncFunction();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, pagination.page]);

  useEffect(() => {
    if (valueKey && value && value[valueKey] !== field.value && !initialValue) {
      setValue(results.find((result) => result[valueKey] === field.value) || null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  const handleOpen = () => {
    setIsOpen(true);
    if (!results.length) {
      callAsyncFunction();
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setPagination(initialPaginationRequest);
    setResults([]);
    setMetaData(undefined);
  };

  const handleScroll = (e: any) => {
    if (!pagination.page || !pagination.pageSize || !metaData) {
      return;
    }

    // if scrolled to bottom
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight && metaData.totalRows > results.length) {
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  };

  const handleChange = (val: T) => {
    setValue(val);
    field.onChange(valueKey ? val[valueKey] : val);
  };

  const handleClear = (e: any) => {
    e.stopPropagation();
    resetAll();
  };

  const resetAll = () => {
    setValue(null);
    field.onChange(null);
    setResults([]);
    setMetaData(undefined);
    setPagination(initialPaginationRequest);
  };

  return (
    <Combobox value={value} onChange={handleChange}>
      <div className="relative mt-1">
        <Combobox.Input
          className={clsx(
            // eslint-disable-next-line max-len
            'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm',
            {
              'border-gray-300 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500': !error,
              'border-red-300 placeholder-red-400 focus:ring-red-500 focus:border-red-500': !!error,
            },
            className,
          )}
          displayValue={(val: T) => (val ? getSelectedLabel(val) : '')}
          onChange={(event) => setPagination((old) => ({ ...old, search: event.target.value }))}
          placeholder={placeholder}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
          {isLoading && <Spinner />}
          {!!value && <XIcon className="w-5 h-5 text-gray-400" aria-hidden="true" onClick={handleClear} />}
          <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={handleClose}
          afterEnter={handleOpen}
        >
          <Combobox.Options
            onScroll={handleScroll}
            className="absolute z-10 w-full mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {results.length === 0 && debouncedSearch !== '' ? (
              <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">Nothing found.</div>
            ) : (
              results.map((result, index) => (
                <Combobox.Option
                  key={index}
                  className={({ active }) =>
                    clsx(
                      active ? 'text-white bg-primary-500' : 'text-gray-900',
                      'cursor-default select-none relative py-2 pl-8 pr-4',
                    )
                  }
                  value={result}
                >
                  {({ selected, active }) => (
                    <>
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        {getOptionLabel(result)}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? 'text-white' : 'text-teal-600'
                          }`}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
      <span className="text-sm leadin-[1.25rem] text-red-500">{error}</span>
    </Combobox>
  );
};

const Spinner = () => (
  <div role="status">
    <svg
      aria-hidden="true"
      className="w-5 h-5 mr-2 text-gray-400 animate-spin dark:text-gray-600 fill-primary-500"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>
    <span className="sr-only">Loading...</span>
  </div>
);

interface Props<T> {
  className?: string;
  error?: string;
  control: Control<any, any>;
  name: string;
  valueKey?: keyof T;
  placeholder?: string;
  initialValue?: T | null;
  asyncFunction: (pagination: PaginationRequest) => Promise<DataWithMeta<T>>;
  getOptionLabel: (option: T) => string;
  getSelectedLabel?: (option: T) => string;
}
