import { Fragment, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { Menu, Transition } from '@headlessui/react';
import { useClickOutside } from '@mantine/hooks';
import { ChevronDownIcon } from '@heroicons/react/outline';

import { Button } from 'components';

export const ButtonWithDropdown = ({
  containerClassName,
  onButtonClick,
  isButtonDisabled,
  label,
  buttonClassName,
  arrowButtonClassName,
  isArrowButtonDisabled,
  dropdownMenuClassName,
  menuItems,
  menuItemButtonClassName,
  buttonClickSameAsIconClick,
  fullWidth,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useClickOutside(() => {
    setIsOpen(false);
  });

  const buttonClick = () => {
    onButtonClick?.();
    if (isOpen && buttonClickSameAsIconClick) {
      setIsOpen(false);
    } else if (!isOpen && buttonClickSameAsIconClick) {
      setIsOpen(true);
    }
  };

  return (
    <div
      className={twMerge(
        clsx(
          'inline-flex h-auto',
          {
            'w-full flex': fullWidth,
            'inline-flex': !fullWidth,
          },
          containerClassName,
        ),
      )}
      ref={ref}
    >
      <Button
        type="button"
        label={label}
        onClick={buttonClick}
        disabled={isButtonDisabled}
        className={twMerge(clsx('rounded-none rounded-l-md focus:ring-0 focus:ring-offset-0', buttonClassName))}
      />
      <Menu as="div" className="relative -ml-px block h-full">
        <Menu.Button
          className={twMerge(
            clsx(
              'relative inline-flex items-center rounded-r-md bg-primary-500 px-2 py-2 text-sm font-medium text-white h-full',
              'hover:bg-primary-600 border-transparent disabled:bg-gray-300 disabled:cursor-not-allowed',
              arrowButtonClassName,
            ),
          )}
          disabled={isArrowButtonDisabled}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="sr-only">Open options</span>
          <ChevronDownIcon width={20} height={20} />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          show={isOpen}
        >
          <Menu.Items
            className={twMerge(
              clsx(
                'absolute right-0 z-10 mt-1 origin-top-right rounded bg-white',
                'shadow-[0_2px_2px_rgba(0,0,0,0.16)] focus:outline-none flex flex-col',
                dropdownMenuClassName,
              ),
            )}
            static
          >
            {menuItems.map((item) => {
              if (item.hidden) return null;
              return (
                <Menu.Item key={item.label}>
                  <button
                    type="button"
                    onClick={() => {
                      item.onClick();
                      setIsOpen(false);
                    }}
                    className={twMerge(
                      clsx(
                        'text-gray-700 block text-left px-3 py-2 mr-0 text-sm hover:bg-primary w-full hover:text-white',
                        'truncate first-of-type:rounded-t-md last-of-type:rounded-b-md',
                        menuItemButtonClassName,
                      ),
                    )}
                    disabled={item.disabled}
                  >
                    {item.label}
                  </button>
                </Menu.Item>
              );
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

interface Props {
  containerClassName?: string;
  onButtonClick?: () => void;
  isButtonDisabled?: boolean;
  label: string;
  buttonClassName?: string;
  arrowButtonClassName?: string;
  isArrowButtonDisabled?: boolean;
  dropdownMenuClassName?: string;
  menuItems: ButtonWithDropdownMenuItem[];
  menuItemButtonClassName?: string;
  buttonClickSameAsIconClick?: boolean;
  fullWidth?: boolean;
}

export interface ButtonWithDropdownMenuItem {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  hidden?: boolean;
}
