import { SearchIcon, TrashIcon } from '@heroicons/react/outline';

import { Button, FormItem, Select } from 'components';
import { DriverStatus } from 'services/drivers';

import { useDriversPage } from '../DriversPageProvider';

export const DriversPageAdvancedSearch = () => {
  const { control, onSubmit, onClearFilters } = useDriversPage();

  return (
    <div className="container z-10 p-4 pb-0 mx-auto sm:px-6 sm:pt-6 lg:px-8 lg:pt-8">
      <div className="gap-4 p-6 bg-white rounded-lg shadow">
        <FormItem label="Status">
          <Select control={control} name="status" options={Object.values(DriverStatus)} placeholder="Select status" />
        </FormItem>

        <div className="flex items-center justify-end w-full mt-4">
          <div className="flex items-center">
            <Button type="button" label="Search" onClick={onSubmit} icon={<SearchIcon />} className="mr-2" />
            <Button type="button" label="Clear" onClick={onClearFilters} icon={<TrashIcon />} color="secondary" />
          </div>
        </div>
      </div>
    </div>
  );
};
