import dayjs from 'dayjs';
import * as yup from 'yup';

export const VALIDATION_SCHEMA = yup.object().shape({
  customStartDate: yup
    .string()
    .required('Start date is required')
    .test('start-date-not-in-the-future', 'Start date cannot be in the future', (value) => {
      return !dayjs(value).isAfter(dayjs(), 'date');
    })
    .nullable(),
  customEndDate: yup
    .string()
    .notRequired()
    .test('end-date-not-in-the-future', 'End date cannot be in the future', (value) => {
      return !dayjs(value).isAfter(dayjs(), 'date');
    })
    // eslint-disable-next-line func-names
    .test('end-date-after-start-date', 'End date cannot be before the start date', function (value) {
      const startDate = this.parent.customStartDate;
      if (startDate && value) {
        return !dayjs(value).isBefore(dayjs(startDate), 'date');
      }
      return true;
    })
    .nullable(),
});
