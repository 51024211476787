import { numberFormatter } from 'helpers';
import { useEffect, useMemo, useState } from 'react';
import { useReportingMileage } from 'services/reporting';
import { useDashboardPageContext } from '../../DashboardPageProvider';

import { InnerChartCardData } from '../../types';
import { ChartType } from '../types';
import { initialAverageMilesChartData } from './AverageMilesBarChart/const';
import { initialTotalMilesChartData } from './TotalMilesPieChart/const';
import { PIE_CHART_COLORS } from '../../consts';

export const useMilesCharts = () => {
  const { startDate, endDate } = useDashboardPageContext();
  const { data: dataByMileage } = useReportingMileage({ startDate, endDate });

  const [averageMilesData, setAverageMilesData] = useState<ChartType>(initialAverageMilesChartData);
  const [totalMilesData, setTotalMilesData] = useState<ChartType>(initialTotalMilesChartData);

  const mileageDataFormat = useMemo(() => {
    const averageMilesPerOrder = dataByMileage?.mileageAverage;
    const totalMiles = dataByMileage?.mileageSum;
    const averageSeries = dataByMileage?.mileagePerDriverGroup.map((i) => i.averageMileage) || [];
    const totalSeries = dataByMileage?.mileagePerDriverGroup.map((i) => i.totalMileage) || [];
    const labels = dataByMileage?.mileagePerDriverGroup.map((i) => i.driverGroupName) || [];

    return {
      averageMilesPerOrder,
      labels,
      averageSeries,
      totalSeries,
      totalMiles,
    };
  }, [dataByMileage]);

  useEffect(() => {
    setAverageMilesData((old) => {
      return {
        series: [
          {
            name: 'Average miles',
            data: mileageDataFormat?.averageSeries,
          },
        ],
        options: {
          ...old.options,
          chart: {
            type: 'bar',
            height: 700,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            y: {
              formatter: (val: number) => `${numberFormatter(val)} miles`,
            },
          },
          xaxis: {
            categories: mileageDataFormat.labels,
            labels: {
              formatter: (val: string) => val,
            },
          },
        },
        averageSum: mileageDataFormat.averageMilesPerOrder || 0,
      };
    });
  }, [mileageDataFormat]);

  useEffect(() => {
    setTotalMilesData((old) => {
      return {
        series: mileageDataFormat?.totalSeries,
        options: {
          ...old.options,
          labels: mileageDataFormat?.labels,
          colors: PIE_CHART_COLORS,
          tooltip: {
            y: {
              formatter: (val: number) => `${numberFormatter(val)} miles`,
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false,
                },
              },
            },
          },
        },
        totalSum: mileageDataFormat.totalMiles || 0,
      };
    });
  }, [mileageDataFormat]);

  const innerCardAverageMiles: InnerChartCardData[] = useMemo(() => {
    return [
      {
        data: averageMilesData.averageSum,
        type: 'display',
        title: 'Average Miles per Order',
      },
    ];
  }, [averageMilesData]);

  const innerCardTotalMiles: InnerChartCardData[] = useMemo(() => {
    return [
      {
        data: totalMilesData.totalSum,
        type: 'display',
        title: 'Total Miles',
      },
    ];
  }, [totalMilesData]);

  return {
    averageMilesData,
    totalMilesData,
    innerCardAverageMiles,
    innerCardTotalMiles,
  };
};
