import { createContext, useContext, useEffect, useMemo } from 'react';
import { Control, FormState, useForm, UseFormRegister } from 'react-hook-form';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';

import { MessageType, showMessage, useIdFromParams } from 'helpers';
import { fetchCustomer, postCustomer, putCustomer } from 'services/customers';

import { CUSTOMER_VALIDATION_SCHEMA } from './const';
import { CustomerForm } from '../types';
import { customerToCustomerForm } from './transformation';
import { defaultCustomerForm } from '../consts';

const CustomerPageContext = createContext<{
  formState: FormState<CustomerForm>;
  isCreate: boolean;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  register: UseFormRegister<CustomerForm>;
  control: Control<CustomerForm, any>;
}>({} as any);

// This will be used in case we state shared inside the module
export const CustomerPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id: customerId, isCreate } = useIdFromParams();

  const { data: customer } = useQuery(['customers', customerId], () => fetchCustomer(customerId), {
    enabled: !isCreate,
  });
  const { mutateAsync: updateCustomer } = useMutation(putCustomer);
  const { mutateAsync: createCustomer } = useMutation(postCustomer);

  const { handleSubmit, register, formState, reset, control } = useForm<CustomerForm>({
    defaultValues: defaultCustomerForm,
    resolver: yupResolver(CUSTOMER_VALIDATION_SCHEMA),
  });

  const customerForm = useMemo(() => {
    if (customer) {
      return customerToCustomerForm(customer);
    }
    return defaultCustomerForm;
  }, [customer]);

  useEffect(() => {
    reset(customerForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerForm]);

  const onSubmit = handleSubmit(async (values: CustomerForm) => {
    if (isCreate) {
      try {
        await createCustomer(values);
        showMessage('Customer successfully created.', MessageType.Success);
        queryClient.invalidateQueries('customers');
        navigate('/customers');
      } catch {
        showMessage('Theres been an error!', MessageType.Error);
      }
      return;
    }

    try {
      await updateCustomer(values);
      showMessage('Customer successfully updated.', MessageType.Success);
      queryClient.invalidateQueries('customers');
      navigate('/customers');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const providerValue = useMemo(
    () => ({ formState, isCreate, onSubmit, register, control }),
    [formState, isCreate, onSubmit, register, control],
  );

  return <CustomerPageContext.Provider value={providerValue}>{children}</CustomerPageContext.Provider>;
};

export const useCustomerPage = () => {
  return useContext(CustomerPageContext);
};

interface Props {
  children?: React.ReactNode;
}
