import { DataWithMeta, PaginationRequest, paginationRequestToUrl } from 'helpers';

import { api } from '../api';
import { resToDriverOrderPayment } from './transformations';
import { DriverOrderPayment } from './types';

export const fetchDriverOrderPayments = async (
  pagination: PaginationRequest,
  id: string,
): Promise<DataWithMeta<DriverOrderPayment>> => {
  const url = paginationRequestToUrl(`/order_payments`, pagination);

  const resp = await api.get(`${url}&driverId=${id}`);

  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToDriverOrderPayment),
  };
};
