import { Header, Select, Container, FormItem, Button, Textarea } from 'components';
import { useSmsNotificationsPageProvider } from './SmsNotificationsPageProvider';

export const SmsNotificationsPage = () => {
  const { control, onSubmit, driverGroupsOptions, register, loadingSms, formState } = useSmsNotificationsPageProvider();
  return (
    <>
      <Header title="Send SMS" />

      {loadingSms ? (
        <Container>
          <div className="flex items-center justify-center py-4 bg-white shadow">
            <div className="block text-sm font-medium text-gray-700">Sending...</div>
          </div>
        </Container>
      ) : (
        <Container>
          <div className="p-6 bg-white rounded-lg shadow grid-cols-1 gap-4">
            <div className="align-middle grid grid-cols-1 gap-4">
              <FormItem label="Select Drivers Team:" error={formState.errors.driverGroupId?.message} required>
                <Select
                  control={control}
                  name="driverGroupId"
                  options={driverGroupsOptions}
                  error={!!formState.errors.driverGroupId?.message}
                />
              </FormItem>

              <FormItem label="Enter SMS text:" htmlFor="message" error={formState.errors.message?.message} required>
                <Textarea
                  {...register('message')}
                  id="message"
                  placeholder="Enter your message"
                  autoComplete="message"
                  className="h-28"
                  error={!!formState.errors.message?.message}
                />
              </FormItem>
            </div>

            <div className="flex justify-end w-full mt-4">
              <Button type="button" label="Send" onClick={onSubmit} className="mr-2" />
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
