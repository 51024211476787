import { ProfileForm } from 'modules/Profile/types';
import { resToUser, User } from 'services/users';

import { AuthData } from './types';

export const resToAuthData = (res: any): AuthData => {
  return {
    token: res.token ?? '',
    user: res.user ? resToUser(res.user) : null,
  };
};

export const userToPutAuthUser = (data: ProfileForm, user: User) => {
  const requestData: any = {
    firstName: data.firstName,
    lastName: data.lastName,
    isAdmin: user.isAdmin,
    isDriver: user.isDriver,
    isCustomer: user.isCustomer,
    isMerchant: user.isMerchant,
  };

  if (data.password) {
    requestData.password = data.password;
  }

  return requestData;
};
