import ReactCurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import clsx from 'clsx';
import { Control, useController } from 'react-hook-form';

type InputProps = {
  error?: boolean;
  className?: string;
  name: string;
  control: Control<any, any>;
} & CurrencyInputProps;

export const CurrencyInput = (props: InputProps) => {
  const {
    className,
    error = false,
    prefix = '$',
    allowNegativeValue = false,
    decimalsLimit = 2,
    decimalScale = 2,
    placeholder,
    name,
    control,
    ...rest
  } = props;
  const { field } = useController({ name, control });

  return (
    <ReactCurrencyInput
      className={clsx(
        'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm',
        {
          'border-gray-300 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500': !error,
          'border-red-300 placeholder-red-400 focus:ring-red-500 focus:border-red-500': error,
        },
        className,
      )}
      decimalsLimit={decimalsLimit}
      allowNegativeValue={allowNegativeValue}
      prefix={prefix}
      value={field.value}
      onValueChange={(value, onChangeName, values) => {
        if (!value) {
          field.onChange(0);
        } else {
          field.onChange(value, onChangeName, values);
        }
      }}
      placeholder={placeholder}
      decimalScale={decimalScale}
      {...rest}
    />
  );
};
