import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { createDownloadLink, MessageType, showMessage, usePagination } from 'helpers';
import { Customer, deleteCustomer, exportCustomers, fetchCustomers } from 'services/customers';

// Drivers page state
export const useCustomersPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
  } = usePagination();

  const [customerToDelete, setCustomerToDelete] = useState<Customer | null>(null);
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const { data: queryData, isLoading: driversLoading } = useQuery(
    ['customers', currentPage, pageSize, sortBy, sortDirection, debouncedSearch],
    () => fetchCustomers({ page: currentPage, pageSize, sortBy, sortDirection, search: debouncedSearch }),
  );
  const { mutateAsync: removeCustomer } = useMutation(deleteCustomer);

  const customers = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onRowClick = (row: Customer) => {
    navigate(`/customers/${row.id}`);
  };

  const onNewClick = () => {
    navigate('/customers/new');
  };

  const onDeleteConfirm = async () => {
    if (customerToDelete) {
      try {
        await removeCustomer(customerToDelete.id);
        showMessage('Customer successfully deleted.', MessageType.Success);
        queryClient.invalidateQueries('customers');
        setCustomerToDelete(null);
      } catch {
        showMessage('Something went wrong.', MessageType.Error);
      }
    }
  };

  const exportCustomersCSV = async () => {
    setIsLoadingExport(true);
    try {
      const data = await exportCustomers(search);
      createDownloadLink(data, 'customers');
    } catch {
      showMessage('An error has occured while trying to export customers data', MessageType.Error);
    }
    setIsLoadingExport(false);
  };

  const openConfirmationModal = (selectedCustomer: Customer | null) => {
    if (selectedCustomer) setCustomerToDelete(selectedCustomer);
  };

  const closeConfirmationModal = () => {
    setCustomerToDelete(null);
  };

  return {
    customers,
    metaData,
    currentPage,
    search,
    isLoading: driversLoading,
    customerToDelete,
    isLoadingExport,
    exportCustomersCSV,
    openConfirmationModal,
    closeConfirmationModal,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onDeleteConfirm,
    onRowClick,
    onNewClick,
    onSortChange,
  };
};
