import { useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { MessageType, showMessage, usePagination } from 'helpers';
// sortDirection here

import { ItemGroup, useItemGroupMutations, useItemGroups } from 'services/itemGroups';

export const useItemGroupsPage = () => {
  const navigate = useNavigate();
  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onFiltersChange,
  } = usePagination({ sortBy: 'name' });

  const [itemGroupToDelete, setItemGroupToDelete] = useState<ItemGroup | null>(null);

  const { data: itemGroupsData, isLoading: isItemGroupsDataLoading } = useItemGroups({
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onFiltersChange,
  });

  const itemGroups = useMemo(() => itemGroupsData?.data ?? [], [itemGroupsData?.data]);
  const metaData = useMemo(() => itemGroupsData?.meta, [itemGroupsData?.meta]);

  const { deleteItemGroup } = useItemGroupMutations();

  const onItemGroupRowClick = useCallback(
    (row: ItemGroup) => {
      navigate(`/item-groups/${row.id}`);
    },
    [navigate],
  );

  const onNewItemGroupClick = useCallback(() => {
    navigate('/item-groups/new');
  }, [navigate]);

  const onDeleteConfirm = useCallback(async () => {
    if (itemGroupToDelete) {
      try {
        await deleteItemGroup.mutateAsync(itemGroupToDelete.id);
        showMessage('Item Group successfully deleted.', MessageType.Success);
      } catch (err) {
        showMessage((err as any).response.data.title || 'Something went wrong.', MessageType.Error);
      }
      setItemGroupToDelete(null);
    }
  }, [itemGroupToDelete, deleteItemGroup]);

  const openConfirmationModal = useCallback((selectedItemGroup: ItemGroup | null) => {
    if (selectedItemGroup) setItemGroupToDelete(selectedItemGroup);
  }, []);

  const closeConfirmationModal = useCallback(() => {
    setItemGroupToDelete(null);
  }, []);

  return {
    itemGroups,
    metaData,
    isItemGroupsDataLoading,
    itemGroupToDelete,
    isItemGroupDeleting: deleteItemGroup.isLoading,
    onItemGroupRowClick,
    onNewItemGroupClick,
    onDeleteConfirm,
    openConfirmationModal,
    closeConfirmationModal,
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onFiltersChange,
  };
};
