import * as yup from 'yup';

import { PageType } from 'helpers/common';

const isCreate = (id: string) => {
  return id === PageType.Create;
};

export const getDriverValidationSchema = (isHelper: boolean) => {
  const commonSchema = {
    email: yup.string().when('id', {
      is: isCreate,
      then: yup.string().required('E-mail is required'),
    }),
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    password: yup.string().when('id', {
      is: isCreate,
      then: yup.string().required('Password is required'),
    }),
    phoneNumber: yup.string().required('Phone number is required'),
    dateOfBirth: yup.date().typeError('Date of birth is required'),
    address1: yup.string().required('Address is required'),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    zip: yup.string().required('Zip is required'),
  };

  const driverSchema = {
    driversLicenseExpirationDate: yup.date().typeError('Drivers license expiration date is required'),
    driversLicenseNumber: yup.string().required('Drivers license number is required'),
    driversLicenseState: yup.string().required('Drivers license state is required'),
    vehicleMake: yup.string().required('Vehicle make is required'),
    vehicleModel: yup.string().required('Vehicle model is required'),
    vehicleYear: yup
      .number()
      .typeError('Vehicle year is required')
      .test('len', 'Vehicle year must have 4 digits', (val: any) => val.toString().length === 4),
    vehicleColor: yup.string().required('Vehicle color is required'),
    maximumDrivingDistanceMiles: yup.number().typeError('Maximum driving distance is required'),
    orderPaymentPercentage: yup
      .number()
      .typeError('Order payment percentage is required')
      .min(0, 'Order payment percentage must betweeen 0 and 100')
      .max(100, 'Order payment percentage must betweeen 0 and 100'),
    firstHelperPaymentPercentage: yup
      .number()
      .typeError('Order payment percentage is required')
      .min(0, 'Order payment percentage must betweeen 0 and 100')
      .max(100, 'Order payment percentage must betweeen 0 and 100'),
    secondHelperPaymentPercentage: yup
      .number()
      .typeError('Order payment percentage is required')
      .min(0, 'Order payment percentage must betweeen 0 and 100')
      .max(100, 'Order payment percentage must betweeen 0 and 100'),
    vehicleInsuranceFile: yup.object({
      url: yup.string().required('Vehicle insurance file is required'),
    }),
    vehicleRegistrationFile: yup.object({
      url: yup.string().required('Vehicle registration file is required'),
    }),
    driversLicenseFile: yup.object({
      url: yup.string().required('Drivers license file is required'),
    }),
  };

  return yup.object(isHelper ? commonSchema : { ...commonSchema, ...driverSchema });
};
